import { useState } from 'react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { RenderTermText } from '../../../../shared-components';
import { NRoundedButton } from '../../../../shared-components';

export function DialogTerms({
  open,
  handleModal,
  title,
  briefing,
}) {
  const [agree, setAgree] = useState(false);

  function openInvoice() {
    window.open(briefing.Payments[0]?.bankSlipUrl)
  }

  function handleAgree() {
    setAgree(() => !agree);
  }

  return (
    <DialogStyled
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <RenderTermText
          briefing={briefing}
        />
        <div>
          <Checkbox
            checked={agree}
            onChange={handleAgree}
          />
          <span>Estou de acordo com os termos</span>
        </div>
      </DialogContent>
      <DialogActions>
        <NRoundedButton
          active={true}
          color={"#adb5bd"}
          title={"Fechar"}
          onClick={handleModal}
        />
        <NRoundedButton
          active={agree}
          disabled={!agree}
          color={"#FF8400"}
          title={"Abrir boleto"}
          onClick={openInvoice}
        />
      </DialogActions>
    </DialogStyled>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }

  p {
    line-height: 2.4rem;
    margin: 2rem;
    font-size: 1.6rem;
  }

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  
`
