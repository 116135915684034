import styled from "@emotion/styled";
import Avatar from '@mui/material/Avatar';
import Expositor from '../../../../images/Frame-122.png';
import Montador from '../../../../images/Frame-124.png';
import Projetista from '../../../../images/Frame-126.png';
import Promotor from '../../../../images/Frame-123.png';

export const AvatarExpositor = ({ ...otherProps }) => <Avatar src={Expositor} {...otherProps} />
export const AvatarMontador = ({ ...otherProps }) => <Avatar src={Montador} {...otherProps} />
export const AvatarProjetista = ({ ...otherProps }) => <Avatar src={Projetista} {...otherProps} />
export const AvatarPromotor = ({ ...otherProps }) => <Avatar src={Promotor} {...otherProps} />

export const Wrapper = styled.div`
  padding: 2rem 4rem;
  flex-wrap: wrap;


  @media screen and (max-width: 898px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    
  }
  
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  
  > span {
    display: flex; 
    align-items: center;
  }


  @media screen and (max-width: 898px) {
    display: flex;
    flex-direction: column;
    width: 100%;

  }

`

export const Space = styled.div`
  width: 6rem;
  
`


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin: 1rem 0;

  @media screen and (max-width: 898px) {
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
`

export const Alert = styled.p`
  font-size: 1.8rem;
  font-weight: 600;

  strong {
    color: #ff8400;
  }
`