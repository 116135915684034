import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { listBuildersFitBriefing } from "../../../../api/admin";

export function useBuilder({
  briefingId,
}) {
  const [modalOpenBuilder, setModalOpenBuilder] = useState(false)
  const [builders, setBuilders] = useState([]);

  const { data } = useQuery(`builderfit-${briefingId}`,
    () => listBuildersFitBriefing({ briefingId, }))


  useEffect(() => {
    const builderIds = data?.map(v => v.Builder?.Builders[0]?.usersId)
    setBuilders(builderIds);
  }, [data])

  function handleBuilders({ target: { value } }) {
    if (!value) return;
    setBuilders([
      ...value,
    ])
  }

  function handleCloseModalBuilder() {
    setModalOpenBuilder(false)
  }

  function handleOpenModalBuilder() {
    setModalOpenBuilder(true);
  }

  return {
    handleBuilders,
    modalOpenBuilder,
    setModalOpenBuilder,
    handleCloseModalBuilder,
    handleOpenModalBuilder,
    builders
  }

}