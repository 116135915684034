import styled from "@emotion/styled";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import {
  generateImageLink
} from "../../../../helpers/generateImageLink"

function brightnessImage(props) {
  const { makeChoose, active } = props;
  if (makeChoose && !active) {
    return 'brightness(50%);';
  }
  if (active) {
    return 'brightness(100%);';
  }

}


export function FragmentReferences({
  briefingReference,
  position,
  BriefingRequestReference,
  makeChoose,
}) {

  const prices = BriefingRequestReference
    .find(e => e.briefingReferencesId === briefingReference.id) || { priceMin: "", priceMax: "" };

  let active = false;

  if (briefingReference?.id === makeChoose) {
    active = true;
  }

  const imageReference = generateImageLink(briefingReference)

  return (
    <ContainerReference
      makeChoose={makeChoose}
      active={active}
    >
      <span>{position}</span>
      <img src={imageReference.image} alt="" />
      <div>
        <LocalOfferOutlinedIcon
          style={{ color: "black", width: "20px", height: "20px" }}
        />
        <p>Preço Estimado por m²</p>
      </div>
      <WrapperPrice>
        <span>R$</span>
        <input
          className="price-plan"
          placeholder="____"
          type="number"
          name={`minPrice`}
          disabled
          value={prices?.minPrice || ""}
        />
        <span>até</span>
        <input
          className="price-plan"
          placeholder="____"
          type="number"
          disabled
          name={`maxPrice`}
          value={prices?.maxPrice || ""}
        />
      </WrapperPrice>
    </ContainerReference>
  )
}



const ContainerReference = styled.div`
  flex-direction: column;
  position: relative;
  
  > span {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: 500;
    font-size: 1.4rem;
    right: 20px;
    top: 10px;
    z-index: 1;
  }

  > div {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 100%;
    margin: 10px 0;
  
    p {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  img {
      width: 100%;
      min-width: 250px;
      height: 240px;
      object-fit: cover;
      border-radius: 6px;
      filter: ${(props) => brightnessImage(props)};
    }
`
const WrapperPrice = styled.div`
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  gap: 2px;
  padding: 2px;
  margin: 10px 0;

  > span {
    flex: 0.3;
    font-size: 1.7rem;
    align-items: center;
  }

  > input {
     background-color: #F6F6F6;
     height: 20px;
     font-size: 1.7rem;
  }
`