const handleNextBriefingStep = ({
  data,
  setBriefing,
  activeStep,
  setActiveStep,
  steps,
  saveBriefing,
  notification
}) => {

  let lastStandsURL;
  if (data?.lastStandsUrls) {
    lastStandsURL = Object.values(data?.lastStandsUrls).map(v => v) || []
  }
  setBriefing({
    evento: data.evento,
    eventoId: data.eventoId,
    precisa: data.precisa,
    cidade: data.cidade,
    local: data.local,
    email: data.email,
    phone: data.phone,
    alternatephone: data.alternatephone,
    address1: data.address1,
    address2: data.address2,
    country: data.country,
    cardnumber: data.cardnumber,
    cardmonth: data.cardmonth,
    cardyear: data.cardyear,
    minOrcamento: data.minOrcamento,
    maxOrcamento: data.maxOrcamento,
    prazoDate: data.prazoDate,
    largeArea: data.largeArea,
    ComprimentoArea: data.comprimentoArea,
    total: data.total,
    formaPagamento: data.formaPagamento,
    balcao: data.balcao,
    objetosSuspensos: data.suspenso,
    freezer: data.freezer,
    pontosEletricos: data.pontosEletricos,
    tvQuantidade: data.tvQuantidade,
    painelLed: data.painelLed,
    lixeiras: data.lixeiras,
    promoter: data.promoter,
    buffet: data.buffet,
    link: data.link,
    references: data.references,
    blueprint: data.blueprint || data.blueprintUrl,
    freeInformation: data.freeInformation,
    lastStands: data.lastStands,
    balcao_obs: data.balcao_obs,
    suspenso_obs: data.suspenso_obs,
    estoque_obs: data.estoque_obs,
    mobilia_obs: data.mobilia_obs,
    coffeeBreak_obs: data.coffeeBreak_obs,
    movelExpositor_obs: data.movelExpositor_obs,
    freezer_obs: data.freezer_obs,
    salaReuniao_obs: data.salaReuniao_obs,
    paisagismo_obs: data.paisagismo_obs,
    alturaStand_obs: data.alturaStand_obs,
    alturaPiso_obs: data.alturaPiso_obs,
    testeira_obs: data.testeira_obs,
    mezanino_obs: data.mezanino_obs,
    pontosEletricos_obs: data.pontosEletricos_obs,
    tvTamanho_obs: data.tvTamanho_obs,
    tvQuantidade_obs: data.tvQuantidade_obs,
    painelLed_obs: data.painelLed_obs,
    iluminacao_obs: data.iluminacao_obs,
    caixaSom_obs: data.caixaSom_obs,
    lixeiras_obs: data.lixeiras_obs,
    projetctUpload: data.projetctUpload,
  })

  setActiveStep(() => activeStep + 1);
  if (activeStep >= 1) {
    saveBriefing({
      eventName: data.evento,
      eventId: data.eventoId,
      city: data.cidade,
      briefingTypeName: data.briefingType,
      priceMin: Number(data.minOrcamento),
      priceMax: Number(data.maxOrcamento),
      length: Number(data.comprimentoArea),
      depth: Number(data.largeArea),
      deadline: data.prazoDate,
      paymentTypeName: data.formaPagamento,
      references: data.references,
      standTypeName: data.standType,
      promoter: data.promoter,
      buffet: data.buffet,
      blueprint: data.blueprint || data.blueprintUrl,
      freeInformation: data.freeInformation,
      lastStands: data.lastStands.concat(lastStandsURL),
      projects: [data.projetctUpload],
      structs: [
        { type: "BALCÃO", value: data.balcao, observation: data.balcao_obs },
        { type: "FREEZER", value: data.freezer, observation: data.freezer_obs },
        { type: "OBJETOS_SUSPENSOS", value: data.suspenso, observation: data.suspenso_obs },
        { type: "COFFEBREAK", value: data.coffeeBreak, observation: data.coffeeBreak_obs },
        { type: "ESTOQUE", value: data.estoque, observation: data.estoque_obs },
        { type: "MOBILIA", value: data.mobilia, observation: data.mobilia_obs },
        { type: "MOVEL_EXPOSITOR", value: data.movelExpositor, observation: data.movelExpositor_obs },
        { type: "PAISAGISMO", value: data.paisagismo, observation: data.paisagismo_obs },
        { type: "SALA_REUNIÃO", value: data.salaReuniao, observation: data.salaReuniao_obs },
      ],
      elements: [
        { type: "ALTURA_PISO", value: data.alturaPiso, observation: data.alturaPiso_obs },
        { type: "ALTURA_STAND", value: data.alturaStand, observation: data.alturaStand_obs },
        { type: "TIPO_PISO", value: data.tipoPiso, observation: data.tipoPiso_obs },
        { type: "TESTEIRA", value: data.testeira, observation: data.testeira_obs },
        { type: "MEZANINO", value: data.mezanino, observation: data.mezanino_obs },
        { type: "PONTOS_ELETRICOS", value: data.pontosEletricos, observation: data.pontosEletricos_obs },
      ],
      multimedias: [
        { type: "TV_QUANTIDADE", value: data.tvQuantidade, observation: data.tvQuantidade_obs },
        { type: "TV_TAMANHO", value: data.tvTamanho, observation: data.tvTamanho_obs },
        { type: "PAINEL_LED", value: data.painelLed, observation: data.painelLed_obs },
        { type: "ILUMINAÇÃO", value: data.iluminacao, observation: data.iluminacao_obs },
        { type: "CAIXA_SOM", value: data.caixaSom, observation: data.caixaSom_obs },
        { type: "LIXEIRAS", value: data.lixeiras, observation: data.lixeiras_obs },
      ]

    }).then(res => {
      notification.show({
        message: res.data.message,
      })
    }).catch(error => {
      console.error(error)
      notification.show({
        message: error.response.data.message || "Houve um erro aqui",
        type: "error"
      })
    })
  }
};


export {
  handleNextBriefingStep
}