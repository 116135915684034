import React, { useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Link, useNavigate } from "react-router-dom";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import logo from "../../images/logo-colorido.png";
import "./sidebarExpo1.css";
import { Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMatchMedia from "use-match-media-hook";
import { useUser } from "../../context/UserContext";
import { deleteToken } from "../../api/login";

const size = ["(max-width: 768px)", "(min-width: 769px)"];

const SideBarPojetista = ({ sideBarOff }) => {
  // state and function to open/close sidebar
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [mobile, desktop] = useMatchMedia(size);
  const { userData, saveUserData } = useUser();
  const navigate = useNavigate();

  function handleLogout() {
    window.location.assign("https://westand.com.br");
    saveUserData({})
    deleteToken();
  }

  function handleHomePage() {
    navigate('/')
  }

  function handleNovoPedido() {
    navigate("/projetista/novo-briefing");
  }

  return (
    <div
      className="container1"
      style={{
        backgroundColor: "#F5F5F9",
      }}
    >
      {/* ----- INICIO: Bloco DESKTOP ------ */}
      {desktop && !sideBarOff && (
        <div
          className="sidebar1"
          style={{
            width: isOpen ? "300px" : "90px",
            backgroundColor: "white",
          }}
        >
          <div className="top_section">
            <img
              src={logo}
              alt=""
              width="130px"
              style={{ display: isOpen ? "block" : "none" }}
            />
            <div
              className="bars"
              style={{
                marginLeft: isOpen ? "80px" : "0px",
              }}
            >
              <ArrowBackIosOutlinedIcon
                onClick={toggle}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#FDC22A',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  color: 'white',
                  position: 'absolute',
                  right: '-10px',
                  transition: 'all 1s',
                  transform: `rotate(${isOpen ? "0deg" : "180deg"})`
                }}
              />
            </div>
          </div>
          <div className="icons-desk">
            <Link to="" className="link2" id="active">
              <div className="icon">
                <HomeOutlinedIcon style={{ width: "25px", height: "40px" }} />
              </div>
              <div
                className="link_text"
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                Dashboard
              </div>
            </Link>
            <br />
            {/* <p
              className="tittle"
              style={{ display: isOpen ? "block" : "none" }}
            >
              - DASH
            </p>
            <Link to="" className="link2" id="active">
              <div className="icon" style={{ marginBottom: "10px" }}>
                <HomeOutlinedIcon style={{ width: "25px", height: "40px" }} />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                ADM Projetista
              </div>
            </Link> */}
            <br />
            <p
              className="tittle"
              style={{ display: isOpen ? "block" : "none" }}
            >
              - FLUXOS
            </p>
            <Link to="/projetista/lista" className="link2" id="active">
              <div className="icon" style={{ marginBottom: "10px" }}>
                <ArticleOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Solicitações de <br /> projeto
              </div>
            </Link>
            <Link to="/projetista/projeto&montagem" className="link2" id="active">
              <div className="icon" style={{ marginBottom: "10px" }}>
                <PlaylistAddIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Fluxo Gestor
              </div>
            </Link>
            {/* <br /> */}
            {/* <Link to="" className="link2" id="active">
              <div className="icon">
                <CheckBoxOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Contrato aprovado
              </div>
            </Link> */}
            {/* <Link to="" className="link2" id="active">
              <div className="icon">
                <RequestQuoteOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Pagamentos
              </div>
            </Link> */}
            <br />
            <p
              className="tittle"
              style={{ display: isOpen ? "block" : "none" }}
            >
              - PAGES
            </p>
            <br />
            <Link to="/perfil-projetista" className="link2" id="active">
              <div className="icon">
                <ManageAccountsOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Editar Perfil
              </div>
            </Link>
          </div>
        </div>
      )}
      {/* ----- FIM: Bloco DESKTOP ------ */}

      {/* ----- INICIO: Bloco MOBILE ------ */}
      {mobile || sideBarOff && (
        <div
          className="sidebar1"
          style={{
            width: isOpen ? "300px" : "0px",
            backgroundColor: "white",
          }}
        >
          <div className="top_section">
            <img
              src={logo}
              alt=""
              width="130px"
              style={{ display: isOpen ? "block" : "none" }}
            />
            <div
              className="bars"
              style={{
                marginLeft: isOpen ? "80px" : "0px",
              }}
            >
              <ArrowBackIosOutlinedIcon
                onClick={toggle}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#FDC22A",
                  borderRadius: "10px",
                  width: "20px",
                  height: "20px",
                  color: "white",
                  position: "absolute",
                  marginTop: "-12px",
                  transition: "all 0.5s",
                  display: isOpen ? "block" : "none",
                }}
              />
            </div>
          </div>
          <div className="icons-mobile">
            <Link
              to=""
              className="link2"
              id="active"
              style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon">
                <HomeOutlinedIcon
                  style={{
                    width: "25px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                className="link_text"
                style={{
                  display: isOpen ? "flex" : "none",
                }}
              >
                Dashboard
              </div>
            </Link>
            <br />
            <p className="tittle" style={{ display: isOpen ? "flex" : "none" }}>
              - DASH
            </p>
            <Link
              to=""
              className="link2"
              id="active"
              style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon" style={{ marginBottom: "10px" }}>
                <HomeOutlinedIcon
                  style={{
                    width: "25px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                ADM Projetista
              </div>
            </Link>
            <br />
            <p className="tittle" style={{ display: isOpen ? "flex" : "none" }}>
              - FLUXOS
            </p>
            <Link
              to="/solict-aberto-projetista"
              className="link2"
              id="active"
              style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon">
                <ArticleOutlinedIcon
                  style={{
                    width: "25px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                Solicitações de <br /> projeto
              </div>
            </Link>
            <Link
              to=""
              className="link2"
              id="active"
              style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon">
                <CheckBoxOutlinedIcon
                  style={{
                    width: "25px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                Contrato Aprovado
              </div>
            </Link>
            <Link
              to=""
              className="link2"
              id="active"
              style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon" style={{ marginBottom: "10px" }}>
                <RequestQuoteOutlinedIcon
                  style={{
                    width: "25px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                Pagamentos
              </div>
            </Link>
            <br />
            <p className="tittle" style={{ display: isOpen ? "flex" : "none" }}>
              - PAGES
            </p>
            <Link
              to="/perfil-projetista"
              className="link2"
              id="active"
              style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon">
                <ManageAccountsOutlinedIcon
                  style={{
                    width: "25px",
                    height: "40px",
                  }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                Editar Perfil
              </div>
            </Link>
          </div>
          <div
            className="overshadow"
            style={{ display: isOpen ? "block" : "none", overflowY: "scroll" }}
          ></div>
        </div>
      )}
      {/* ----- FIM: Bloco MOBILE ------ */}
      <div className="profile-bar"
        style={{
          marginLeft: sideBarOff ? "20px" : ""
        }}
      >
      {
        !sideBarOff &&
        <>
          <div className="menu-bar">
            <MenuIcon onClick={toggle} />
          </div>
          <span
            onClick={handleNovoPedido}
          >Criar um Briefing
          </span>
        </>
      }
        {userData ? (<span onClick={handleLogout}>Sair</span>) : ''}
        <Avatar style={{ position: "inherit" }} src={userData.avatar} />
      </div>
    </div>
  );
};

export default SideBarPojetista;
