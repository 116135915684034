import { useState, useEffect, useRef } from "react";
import {
  onValue,
  listenRoom,
  sendMessage
} from "../../libs/firebase";

function useChat({ roomId }) {

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const scrollBottomRef = useRef(null);

  useEffect(() => {
    onValue(listenRoom(`/rooms/support/${roomId}`), (snap) => {
      const data = snap.val();
      setMessages(data)
    })

    scrollToBottom();
  }, [])

  useEffect(() => {
    scrollToBottom();

  }, [messages])

  function handleSendMessage(key) {
    if (key.code === "Enter" && inputMessage) {
      sendMessage(`/rooms/support/${roomId}`, {
        text: inputMessage,
        type: 'admin'
      })

      setInputMessage("")
    }
  }

  function scrollToBottom() {
    scrollBottomRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }

  return {
    messages,
    setMessages,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    scrollBottomRef,
  }

}

export {
  useChat,
}