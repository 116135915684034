import React from 'react'
import styled from "@emotion/styled";
import { useUser } from '../context/UserContext';
import { COLORS } from '../helpers/types';


const AlertComponent = ({ info, color, ...otherProps }) => {
  const { userData } = useUser();

  const roleData = userData?.role?.name;
  const activeColor = roleData ? COLORS[roleData] : color;

  return (
    <Wrapper
      {...otherProps}
      color={activeColor}
    >
      <h2>Atenção!</h2>
      <p>{info}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2rem 4rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  flex-direction: column;

  > p {
    font-size: 2.3rem;
    text-align: center;
  }


  @media screen and (max-width: 898px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    
  }


`;

export default AlertComponent