import { useForm } from "react-hook-form";
import { getIncompleteBriefing } from "../../../api/briefing";

export function useFormBriefing() {

  const { briefingData } = getIncompleteBriefing()

  const methods = useForm({
    defaultValues: {
      evento: briefingData?.eventName || "",
      cidade: briefingData?.city || "",
      prazoDate: briefingData?.deadline || "",
      formaPagamento: briefingData?.paymentTypeName || "",
      minOrcamento: briefingData?.priceMin || "",
      maxOrcamento: briefingData?.priceMax || "",
      standType: briefingData?.standTypeName || "",
      precisa: "",
      local: "",
      email: "",
      phone: "",
      alternatephone: "",
      address1: "",
      address2: "",
      country: "",
      cardnumber: "",
      cardmonth: "",
      cardyear: "",
      largeArea: briefingData?.largeArea || "",
      comprimentoArea: briefingData?.comprimentoArea || "",
      total: briefingData?.total || "",
      balcao: "",
      objetosSuspensos: "",
      freezer: "",
      estoque: [""],
      pontosEletricos: "",
      tvQuantidade: "",
      painelLed: "",
      lixeiras: "",
      promoter: "",
      link: "",
      buffet: "",
      blueprint: "",
      freeInformation: "",
      references: [],
      lastStands: [],
      orcamento: {},
    }
  });

  return [methods];
}