import React, { useState, useEffect } from 'react'
import { DashInput } from './DashInput'
import styled from '@emotion/styled'
import { NRoundedButton } from './NRoundedButton';
import InputMask from 'react-input-mask';
import { updateBriefingTaxa } from '../api/briefing';
import { useNotification } from '../context/NotificationContext';


const TaxaInput = (props) => {

  return (
    <InputMask mask="99%" maskChar={null} {...props} className='input-value'>
      {(inputProps) => <input type="text" {...inputProps} />}
    </InputMask>
  );
};

const BriefingTaxa = ({ briefingData }) => {

  const taxa = briefingData?.TaxBriefing?.value || 0;
  const [taxaInputValue, setTaxaInputValue] = useState(taxa);
  const notification = useNotification();

  const handleTaxaInputChange = (event) => {
    setTaxaInputValue(event.target.value);
  };

  const handleInputAdd = () => {
    const value = Number(taxaInputValue.replace("%", ""))
    updateBriefingTaxa(briefingData.id, value);

    try {
      notification.show({
        message: `A taxa de ${value}% foi adicionada ao briefing`
      })
    } catch (error) {
      notification.show({
        message: 'Houve um erro ao enviar os itens...',
        type: 'error'
      })
    }
  }


  return (
    <Wrapper>
      <h3>Modifique aqui a taxa de serviço desse briefing</h3>
      <div>
        <TaxaInput width="60%" value={taxaInputValue} onChange={handleTaxaInputChange} />
        <NRoundedButton title={'Adicionar Taxa'} color={'#ff8400'} active={true} onClick={handleInputAdd} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 130px;
  background-color: white;
  margin: 1rem 0;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  padding: 1rem 1.5rem;

  > div {
    width: 100%;
    display: flex;
    gap: 3rem;
    padding-top: 1rem;
  }

  .input-value {
    border: 1px solid #ccc;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #F3F3F3;
    border-radius: 4px;
    font-size: 1.6rem;
    outline: none;

    &:focus {
      border: 2px solid  #ff8400;
    }
  }

`;

export default BriefingTaxa