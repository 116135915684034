import {
  Wrapper,
} from "./Styles";
import { useStepActions } from "../useStepActions";
import { useStepForm } from "../StepFormContext";
import { ROLE, COLORS } from "../../../../helpers/types";
import { NRoundedButton } from "../../../../shared-components";
import { acceptDonations } from "../../../../api/donations";
import { useNotification } from "../../../../context/NotificationContext";

const PreDoacao = ({ step, setActiveStep }) => {

  const { stateForm } = useStepForm();
  const notification = useNotification();

  function handleAcceptDonation(status) {
    acceptDonations(stateForm.Briefing.id, status)
      .then(res => {
        notification.show({
          message: 'Suas preferências de doações foram salvas.'
        })
        if (!status) {
          setActiveStep(() => step + 2)
        } else {
          setActiveStep(() => step + 1)
        }
      })
      .catch(err => {
        notification.show({
          message: 'Não foi possível salvar suas preferências',
          type: 'error'
        })
        if (!status) {
          setActiveStep(() => step + 2)
        } else {
          setActiveStep(() => step + 1)
        }
      })
  }

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Doações</h2>
      </div>
      <div style={{
        fontSize: '2rem',
      }}>
        <strong style={{
          color: `${COLORS.MONTADOR}`
        }}>Montador</strong>, você gostaria de fazer doações dos itens desse stand? Você poderá doar itens para o seu contratante ou para <strong>Instituições de caridade ou ONGs que a Westand apoia</strong>.
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '40vh'
      }}>
        <NRoundedButton
          color={COLORS.MONTADOR}
          onClick={() => handleAcceptDonation(false)}
          title={"não, obrigado"}
        />
        <NRoundedButton
          color={COLORS.MONTADOR}
          active={true}
          onClick={() => handleAcceptDonation(true)}
          title={"quero fazer doações"}
        />
      </div>

    </Wrapper>
  );
};


export { PreDoacao }