import { NAlert } from "../../../../shared-components";
import { useNotification } from "../../../../context/NotificationContext";
import { acceptProposal } from "../../../../api/briefing";

export function AlertWarningProposal({ open, setOpen, requestId }) {

  const notification = useNotification();
  function handleCancel() {
    setOpen(false)
  }

  function handleConfirm() {
    handleCancel();
  }

  return (
    <NAlert
      title="Aviso!"
      open={open}
      handleConfirm={handleConfirm}
      titleConfirm={"Ok, vou esperar"}
    >
      <span>Espere até receber um valor estimado para o projeto.</span>
    </NAlert>
  )
}