export function getValueFromType(data, typeName) {
  const finded = Object.values(data)
    .find(e => e.type.toLowerCase() === typeName.toLowerCase())

  return finded?.value || ""
}

export function getValueFromTypeArray(data, typeName) {
  const finded = Object.values(data)
    .find(e => e.type.toLowerCase() === typeName.toLowerCase())

  return finded?.value || []
}

export function getValueObservation(data, typeName) {
  const finded = Object.values(data)
    .find(e => e.type.toLowerCase() === typeName.toLowerCase())

  return finded?.observation || ""
}
