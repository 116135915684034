import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Navigation } from "../../components/home/Navigation";
import "./CadStyle.css";
import { useSimpleForm } from "../../hooks/useSimpleForm";
import { createOng } from "./actioForm";
import { useNotification } from "../../context/NotificationContext";
import MenuItem from "@mui/material/MenuItem";
import { DashSelect, NRoundedButton } from "../../shared-components";
import { getCauses } from "../../api/ong";
import logoexpo from "../../images/banner-enlightment.png";


const OngRegister = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [disable, setDisable] = useState(false);
  const notification = useNotification();

  const [APIData, setAPIData] = useState([]);

  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    name: "",
    ownerName: "",
    externalLink: "",
    causes: "",
    email: "",
    ownerPhone: "",
  });

  function submitForm(event) {
    createOng({
      event,
      stateForm,
      setHasError,
      setErrorMessage,
      notification,
      setStateForm,
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCauses().then((response) => {
      setAPIData(response);
    });
  }, []);

  return (
    <div id="ong">
      <Navigation />
      <div className="main-expo">
        <div className="expo-1">
          <div className="expo-img">
            <img src={logoexpo} alt="" width="160px" />
          </div>
          <h2>
            Aqui você pode cadastrar sua <span style={{ color: "#FF8401" }}>ONG</span> na plataforma Westand.
          </h2>
          <div className="circ-img">
          </div>
        </div>
        <div className="form-expo">
          <h4>Preencha as seguintes informações:</h4>
          <Form className="form2">
            <Form.Group className="md-3 input-line" controlId="formBasicName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="name"
                value={stateForm.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group
              className="md-3 input-line"
              controlId="formBasicOwnerName"
            >
              <Form.Label>Responsável</Form.Label>
              <Form.Control
                type="text"
                name="ownerName"
                className="ownerName"
                value={stateForm.ownerName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group
              className="md-3 input-line"
              controlId="formBasicExternalLink"
            >
              <Form.Label>Site</Form.Label>
              <Form.Control
                type="text"
                name="externalLink"
                className="externalLink"
                value={stateForm.externalLink}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="md-3 input-line dashSelect-bordeless" controlId="formBasicCauses">
              <Form.Label>Causa</Form.Label>
              <DashSelect
                width="100%"
                name="causes"
                className="causes"
                value={stateForm?.causes}
                onChange={handleChange}
                required
              >
                {APIData.map((cause, index) => (
                  <MenuItem key={index} value={cause.id}>
                    {cause.name}
                  </MenuItem>
                ))}
              </DashSelect>
            </Form.Group>
            <Form.Group className="md-3 input-line" controlId="formBasicEmail">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="text"
                name="email"
                className="email"
                value={stateForm.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group
              className="md-3 input-line"
              controlId="formBasicOwnerPhone"
            >
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                type="text"
                name="ownerPhone"
                className="ownerPhone"
                value={stateForm.ownerPhone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <div className="button-cad">
              <div className="cad-error">
                {hasError && <span>{errorMessage}</span>}
              </div>
              <NRoundedButton
                color="#363636"
                active={true}
                title={"Cadastrar"}
                onClick={submitForm}
                disabled={disable}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OngRegister;
