import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AvatarExpositor, AvatarMontador, AvatarPromotor } from '../../../components/DashboardExpositorComponents/fluxProjetoEmontagem/stepsComponents/Styles';
import { upsertTermsApproval } from '../../../api/gestor';
import { useNavigate } from 'react-router-dom';
import useNotification from 'antd/es/notification/useNotification';

export default function DialogTerms({
  handleClose,
  open,
  userData,
  setAcceptedTerms
}) {

  const terms = userData?.User?.Approval?.terms;
  const role = userData?.role?.name;

  function handleApprove() {
    upsertTermsApproval("terms", "approved")
      .then(() => {
        setAcceptedTerms(true);
        handleClose();
      })
      .catch(err => {
        console.log(err)
      })
  }

  const USER_BY_ROLE = {
    MONTADOR: <>a <strong>MONTADORA</strong></>,
    EXPOSITOR: <>o <strong>EXPOSITOR</strong></>,
    PROJETISTA: <>o <strong>PROJETISTA</strong></>
  }[role];

  const USER_BY_ROLE_CAPITAL = {
    MONTADOR: <>A <strong>MONTADORA</strong></>,
    EXPOSITOR: <>O <strong>EXPOSITOR</strong></>,
    PROJETISTA: <>O <strong>PROJETISTA</strong></>
  }[role];

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogContent>
          <Column>
            <h2 style={{ color: "#FF8400" }}>WESTAND - TERMOS E CONDIÇÕES DE USO</h2>
            <br />
            <br />
            <h3>
              <strong>
                Quem é <span style={{ color: "#FF8400", fontSize: "2rem" }}>você</span> no mundo dos eventos?
              </strong>
            </h3>
            <Row>
              <h4 style={{ color: "#FF8400" }}>Expositor</h4>
              <h4 style={{ color: "#E70E4C" }}>Promotor</h4>
              <h4 style={{ color: "#22da28" }}>Montadora</h4>
            </Row>
            <br />
            <br />
            <h3 style={{ color: "#FF8400" }}>PLATAFORMA DE GESTÃO DE PROJETOS DE STANDS E CENOGRAFIA</h3>
            <p>
              Bem vindo à WESTAND, uma Plataforma de Gestão de Projetos de Stands e Cenografia, que conecta montadoras,
              expositores, promotores, profissionais de eventos e projetistas.
            </p>
            <p>
              Estes Termos e Condições de Uso (Termos) tem por finalidade definir as condições que irão disciplinar a utilização da WESTAND,
              propriedade da  ECOWORK NEGÓCIOS E CURSOS LTDA, inscrita no CNPJ sob o nº  28.448.375/0001-38, com nome fantasia WESTAND.
            </p>
            <p>
              A utilização da WESTAND é regulada por estes Termos e Condições de Uso, razão pela qual recomendamos sua leitura na íntegra,
              uma vez que o aceite dos Termos é obrigatório para o cadastro e utilização da WESTAND.
            </p>
            <p>
              Ficamos à disposição para esclarecer suas dúvidas, receber feedbacks ou reclamações através do seguinte e-mail: contato@westand.com.br.
            </p>
            <h3 style={{ color: "#FF8400" }}>QUAIS AS INFORMAÇÕES QUE VOCÊ ENCONTRARÁ NESTES TERMOS</h3>
            <ol style={{
              listStyle: "number"
            }}>
              <UnderlinedLi>
                CONCEITOS IMPORTANTES NESTES TERMOS
              </UnderlinedLi>
              <UnderlinedLi>
                WESTAND: A EVOLUÇÃO DOS PROJETOS DE CENOGRAFIA
              </UnderlinedLi>
              <UnderlinedLi>
                O QUE A WESTAND OFERECE PARA O EXPOSITOR?
              </UnderlinedLi>
              <UnderlinedLi>
                COMO PARTICIPAR DA WESTAND?
              </UnderlinedLi>
              <UnderlinedLi>
                CONDIÇÕES IMPORTANTES SOBRE CONTA E SENHA
              </UnderlinedLi>
              <UnderlinedLi>
                VALORES E PLANOS
              </UnderlinedLi>
              <UnderlinedLi>
                DIREITO DE FISCAUnderlinedLiZAÇÃO
              </UnderlinedLi>
              <UnderlinedLi>
                OBRIGAÇÕES DA WESTAND
              </UnderlinedLi>
              <UnderlinedLi>
                OBRIGAÇÕES DO EXPOSITOR
              </UnderlinedLi>
              <UnderlinedLi>
                CONDUTAS PROIBIDAS
              </UnderlinedLi>
              <UnderlinedLi>
                PROPRIEDADE INTELECTUAL
              </UnderlinedLi>
              <UnderlinedLi>
                ENCERRAMENTO DA CONTA
              </UnderlinedLi>
              <UnderlinedLi>
                DISPOSIÇÕES GERAIS
              </UnderlinedLi>
            </ol>
            <ol style={{
              listStyle: "number"
            }}>
              <MainLi>
                <strong>CONCEITOS IMPORTANTES NESTES TERMOS</strong>
                <br />
                <em style={{ textDecoration: "initial" }}>Abaixo, alguns conceitos para auxiliar na compreensão destes Termos</em>
                <br />
                <br />
                <p>
                  <strong>WESTAND:</strong> refere-se à ECOWORK NEGÓCIOS E CURSOS LTDA, inscrita no CNPJ sob o nº  28.448.375/0001-38, que conc.
                </p>
                <p>
                  <strong>PLATAFORMA WESTAND:</strong> refere-se à plataforma que faz a conexão entre as partes de um projeto de cenografia e oferece um sistema de gestão e organização das etapas do fluxo do projeto.
                </p>
                <p>
                  <strong>MONTADORAS:</strong> refere-se a uma pessoa jurídica que anuncia e comercializa seus produtos ou serviços por meio da Plataforma WESTAND,
                  sendo o único responsável pela composição e cumprimento da oferta, entrega, suporte, atendimento ao Expositor e procedimentos diretamente relacionados
                  à entrega dos produtos e/ou serviços respectivos.
                </p>
                <p>
                  <strong>EXPOSITOR:</strong> refere-se à pessoa física ou jurídica que utiliza a <strong>WESTAND</strong> para intermediar a contratação de uma <strong>MONTADORA.</strong>
                </p>
              </MainLi>
              <MainLi>
                <strong>WESTAND: A EVOLUÇÃO DOS PROJETOS DE CENOGRAFIA</strong>
                <br />
                <br />
                <p>
                  <strong>A Plataforma WESTAND:</strong> é uma plataforma que faz a conexão entre as partes de um projeto de cenografia e oferece um sistema de gestão e organização das etapas do fluxo do projeto.
                </p>
                <p>
                  É importante ressaltar que a <strong>WESTAND</strong> não presta serviços de montagem de Stands, nem executa nenhuma estruturação dos eventos. Quaisquer produtos ou serviços realizados pela
                  <strong> MONTADORA</strong> são de sua exclusiva e integral responsabilidade. O <strong>EXPOSITOR</strong> e a <strong>MONTADORA</strong> firmam contrato entre si, através da Plataforma,
                  mas sem qualquer vinculação com a <strong>WESTAND</strong>.
                </p>
                <p>
                  A <strong>WESTAND</strong> fornece em sua plataforma uma nova maneira de gerenciamento de contratação para execução de Stands, por meio de uma ferramenta de busca onde o
                  <strong> EXPOSITOR</strong> pode solicitar orçamento e contratar a <strong>MONTADORA</strong> que mais lhe interessar. Todo o processo de aprovação do projeto do Stand e da
                  contratação da <strong>MONTADORA</strong> ocorre dentro da plataforma, onde é possível acompanhar todos os passos da negociação.
                </p>
                <p>
                  Nos canais de comunicação da <strong>WESTAND</strong>, as <strong>Recomendações</strong> terão disclaimer próprio indicando sua caracterização como <strong>Recomendação</strong>.
                </p>
              </MainLi>
              <MainLi>
                {
                  role === "EXPOSITOR" ?
                    <>
                      <strong>O QUE A WESTAND OFERECE PARA O EXPOSITOR?</strong>
                      <br />
                      <br />
                      <p>
                        A <strong>WESTAND</strong> oferece para o <strong>EXPOSITOR</strong> uma plataforma com inúmeras <strong>MONTADORAS</strong>, <strong>PROJETISTAS</strong> e <strong>PROMOTORES </strong>
                        cadastrados, o que permite ao <strong>EXPOSITOR</strong>  buscar a proposta que mais se adequa à sua realidade, tendo todo o fluxo da negociação registrado na Plataforma, desde a aprovação
                        do projeto até a execução do serviço contratado.
                      </p>
                      <p>
                        Ressalta-se que o serviço ofertado pela <strong>WESTAND</strong> é de meio, não tendo garantia de qualidade e/ou de entrega dos serviços oferecidos por terceiros aos <strong>EXPOSITORES</strong>,
                        ainda que cadastrados na Plataforma.
                      </p>
                    </>
                    :
                    role === "MONTADOR" ?
                    <>
                      <strong>O QUE A WESTAND OFERECE PARA A MONTADORA?</strong>
                      <br />
                      <br />
                      <p>
                        A <strong>WESTAND</strong> oferece para a <strong>MONTADORA</strong> uma plataforma com alto potencial de captação de clientes e uma maneira segura de contratação,
                        tendo todo o fluxo da negociação registrados na plataforma, desde a aprovação do projeto até o efetivo pagamento.
                      </p>
                      <p>
                        Ressalta-se que o serviço ofertado pela <strong>WESTAND</strong> é de meio, não tendo garantia de captação de <strong>EXPOSITORES</strong> e nem de adimplência destes.
                      </p>
                    </>
                    :
                    <>
                      <strong>O QUE A WESTAND OFERECE PARA O PROJETISTA?</strong>
                      <br />
                      <br />
                      <p>
                        A <strong>WESTAND</strong> oferece para o <strong>PROJETISTA</strong> uma plataforma com alto potencial de captação de clientes e uma maneira segura de contratação,
                        tendo todo o fluxo da negociação registrados na plataforma, desde a aprovação do projeto até o efetivo pagamento.
                      </p>
                      <p>
                        Ressalta-se que o serviço ofertado pela <strong>WESTAND</strong> é de meio, não tendo garantia de captação de <strong>EXPOSITORES</strong> e nem de adimplência destes.
                      </p>
                    </>
                }
              </MainLi>
              <MainLi>
                <strong>COMO PARTICIPAR DA WESTAND?</strong>
                <br />
                <br />
                <p>
                  Para acessar os serviços da <strong>WESTAND</strong> {USER_BY_ROLE} deve realizar o seu cadastro por meio do link  disponível na página
                  <a href={`https://app.westand.com.br/cadastro-${role?.toLowerCase()}`}>https://app.westand.com.br/cadastro</a> e seguir as orientações de cadastro.
                </p>
                <p>
                  Durante este processo serão solicitados d{USER_BY_ROLE} dados necessários para a realização de cadastro e onboarding (concessão de acesso à Plataforma).
                </p>
                <p>
                  Tais dados serão tratados de acordo com o disposto no Aviso de Privacidade da <strong>WESTAND</strong>, atendendo às diretrizes da Lei Geral de Proteção de Dados e boas práticas de segurança e privacidade.
                </p>
              </MainLi>
              <MainLi>
                <strong>INFORMAÇÕES IMPORTANTES SOBRE CONTA E SENHA</strong>
                <br />
                <br />
                <p>
                  Para utilização da <strong>WESTAND</strong> há necessidade de confirmação da sua identidade e autenticação da respectiva conta d{USER_BY_ROLE}.
                </p>
                <p>
                  Para acessar a conta é necessário cadastrar senha de acesso, pessoal e intransferível, que jamais deve ser compartilhada com outras pessoas.
                </p>
                <p>
                  {USER_BY_ROLE_CAPITAL} deve obedecer se atentar as seguintes práticas:
                </p>
                <p>
                  a) não informar login próprio para terceiros; <br />
                  b) não usar login de outros usuários;<br />
                  c) não replicar materiais da <strong>WESTAND</strong> em nenhum lugar;<br />
                  d) estar ciente de que tudo é propriedade intelectual própria da <strong>WESTAND</strong>, sendo vedado reproduzir, compartilhar e disponibilizar para
                  outrem qualquer dos conteúdos disponibilizados, mesmo que cite a <strong>WESTAND</strong> como fonte;
                </p>
                <p>
                  Caso note algum tipo de atividade estranha ou possível utilização indevida da conta, imediatamente entre em contato através do e-mail contato@westand.com.br.
                </p>
              </MainLi>
              <MainLi>
                <strong>VALORES E PLANOS</strong>
                <br />
                <br />
                <p>
                  Não será cobrado nenhum valor d{USER_BY_ROLE} pelo cadastro e utilização da Plataforma <strong>WESTAND</strong>,
                  {
                    role === "EXPOSITOR" ?
                      <> tendo o <strong>EXPOSITOR </strong>
                        que arcar apenas com os custos do serviço contratado, o que será pago diretamente via plataforma <strong>WESTAND </strong></>
                      :
                      <>sendo cobrado apenas uma taxa em porcentagem dos projetos que tenham sido fechados via Plataforma <strong>WESTAND</strong>. A porcentagem
                        pode variar de 7% (sete por cento) a 10% (dez por cento),a depender do projeto.
                      </>
                  }.
                </p>
                <p>
                  {
                    role === "EXPOSITOR" ?
                      <>
                        O <strong>EXPOSITOR</strong> ao aceitar o orçamento proposto pela <strong>MONTADORA</strong>, será direcionado para a assinatura do contrato padrão gerado via plataforma
                        <strong> WESTAND</strong>, que deverá ser assinado pelo <strong>EXPOSITOR</strong> e pela <strong>MONTADORA</strong>, onde estarão previstas todas as informações referentes ao serviço contratado.
                      </>
                      : role === "MONTADOR" ?
                      <>
                        A <strong>MONTADORA</strong> receberá os valores dos projetos diretamente do <strong>EXPOSITOR</strong>, via plataforma, e emitirá Nota Fiscal. A plataforma receberá  a taxa pela intermediação do serviço e pela gestão da qualidade das etapas do projeto até a finalização.
                      </>
                      :
                      <>
                        O <strong>PROJETISTA</strong> prestará serviços de criação de conceitos de stands e design 3D, além de plantas técnicas incluindo a elaboração de planta técnica e vistas, 
                        memorial de projeto e fazer alterações quando necessário , nos termos do briefing que lhe for fornecido e receberá os seguintes valores por projetos realizado via plataforma:

                        <ul style={{
                          listStyleType: "initial"
                        }}>
                          <li>
                          R$100,00 (cem reais) por cada projeto 3D apresentado e não aprovado;
                          </li>
                          <li>
                          R$200,00 (duzentos reais) por cada projeto aprovado (incluindo desenho técnico e cotas);
                          </li>
                          <li>
                          Bônus de R$50,00 (cinquenta reais) caso o projeto seja aprovado sem alterações.
                          </li>
                        </ul>
                      </>
                  }
                </p>
                {
                  role === "PROJETISTA" &&
                  <p>
                    Os valores não são cumulativos, ou seja, caso o <strong>PROJETISTA</strong> faça um projeto que seja executado e aprovado, receberá o total de R$200,00 (duzentos reais), 
                    que poderá ser beneficiado com o bônus de R$50,00 (cinquenta reais) caso o projeto tenha sido aprovado sem alterações.
                  </p>
                }
              </MainLi>
              <MainLi>
                <strong>DIREITO DE FISCALIZAÇÃO</strong>
                <br />
                <br />
                <p>
                  A <strong>WESTAND</strong> terá o direito de realizar inspeções em todas as obrigações estabelecidas neste <strong>Termo</strong> e nos contratos em que tiver intermediado via plataforma,
                  acompanhadas por um representante designado pelo fornecedor para esse fim.
                </p>
                <p>
                  Serviços incompletos na data prevista para sua conclusão não serão considerados como executados ou etapas concluídas.
                </p>
                <p>
                  A inspeção e fiscalização dos Serviços pela <strong>WESTAND</strong> não eximem a <strong>MONTADORA</strong> de sua responsabilidade pela qualidade do trabalho executado
                  e não implicam em qualquer tipo de responsabilidade solidária ou subsidiária à <strong>WESTAND</strong>, considerando que tais serviços são prestados diretamente pela <strong>MONTADORA</strong>.
                </p>
              </MainLi>
              <MainLi>
                <strong>OBRIGAÇÕES DA WESTAND</strong>
                <br />
                <br />
                <p>
                  A <strong>WESTAND</strong> prestará os seus serviços de acordo com as condições descritas nestes <strong>Termos</strong>, mantendo {USER_BY_ROLE}
                  informado quanto a quaisquer alterações, atualizando este documento.
                </p>
                <p>
                  As obrigações da <strong>WESTAND</strong> se limitam a assegurar as funcionalidades e segurança da <strong>WESTAND</strong>, bem como realizar as correções e/ou alterações eventualmente necessárias.
                </p>
                <p>
                  A <strong>WESTAND</strong> <u style={{ textDecoration: "underline" }}><em style={{ textDecoration: "italic" }}>não terá nenhuma responsabilidade:</em></u>
                  <ul style={{
                    listStyleType: "initial"
                  }}>
                    <br />
                    <li>
                      Por qualquer transação, operação  ou serviço realizado entre a <strong>MONTADORA</strong> e <strong>EXPOSITOR</strong>. <br /><br />
                    </li>
                    <li>
                      Conduta dos <strong>EXPOSITORES</strong> e <strong>MONTADORAS</strong>, seja dentro ou fora da <strong>WESTAND</strong>. <br /><br />
                    </li>
                    <li>
                      Pelo descumprimento pel{USER_BY_ROLE} de suas obrigações, em desconformidade com estes <strong>Termos</strong> e legislações aplicáveis.<br /><br />
                    </li>
                    <li>
                      Quaisquer prejuízos, perdas ou danos, de qualquer natureza e qualquer espécie, causados pelo <strong>EXPOSITOR</strong>, <strong>MONTADORA</strong> ou por terceiros.<br /><br />
                    </li>
                    <li>
                      Danos que o <strong>EXPOSITOR</strong> possa ter em decorrência do mau uso da <strong>WESTAND</strong>, como o acesso irregular de terceiros à sua conta de acesso.<br /><br />
                    </li>
                    <li>
                      Danos que {USER_BY_ROLE} possa experimentar por ações exclusivas de terceiros, bem como falhas na conexão de rede e interações maliciosas como vírus.<br /><br />
                    </li>
                    <li>
                      Danos que {USER_BY_ROLE} possa experimentar por ações exclusivas de <strong>MONTADORA</strong> contratada via <strong>WESTAND</strong>.<br /><br />
                    </li>
                    <li>
                      Casos fortuitos ou de força maior.<br /><br />
                    </li>
                    <li>
                      Por eventuais falhas técnicas ou operacionais que prejudiquem ou inviabilizam o uso da <strong>WESTAND</strong> por determinado período de tempo, uma vez que tais
                      falhas podem ocorrer e são esperadas pelo uso da tecnologia.
                    </li>
                  </ul>
                </p>
                <p>
                  {USER_BY_ROLE} é exclusivamente responsável pelos dados e informações inseridos em sua conta, portanto, não é obrigação da <strong>WESTAND</strong>
                  verificar e atestar a veracidade ou inadequação dos dados fornecidos. <br /><br />
                </p>
              </MainLi>
              <MainLi>
                <strong>OBRIGAÇÕES D{USER_BY_ROLE_CAPITAL}</strong>
                <br />
                <br />
                <p>
                  É obrigação d{USER_BY_ROLE} utilizar a <strong>WESTAND</strong> de acordo com as condições descritas nestes <strong>Termos</strong>, as legislações aplicáveis e,
                  caso haja, contratos firmados entre as partes.
                </p>
                <p>
                  {USER_BY_ROLE_CAPITAL} deve usar a <strong>WESTAND</strong> sempre de maneira honesta e ética, bem como se abster de qualquer tipo de conduta abusiva, ilegal ou ofensiva.
                </p>
                <p>
                  É de responsabilidade exclusiva d{USER_BY_ROLE} para todos os efeitos, inclusive jurídicos, pelo teor das informações que inserir e pelos compromissos que assume.
                </p>
                {
                  role === "EXPOSITOR" ?
                    <></>
                    :
                    <p>
                      A <strong>MONTADORA</strong> está ciente e de acordo que os contratos fechados com intermédio da <strong>WESTAND</strong> seguirão o padrão exigido pela plataforma,
                      que disponibilizará o contrato, possuindo entre outras obrigações a previsão de <strong>multa de 30% (trinta por cento) em caso de entrega incompleta</strong>,
                      diversa ou fora da qualidade definida no escopo do projeto aceito.
                    </p>
                }
              </MainLi>
              <MainLi>
                <strong>CONDUTAS PROIBIDAS</strong>
                <br />
                <br />
                <p>
                  {USER_BY_ROLE_CAPITAL} declara e concorda em não:
                  <ul style={{
                    listStyleType: "initial"
                  }}>
                    <br />
                    <li>
                      Violar quaisquer regras, regulamento ou legislações aplicáveis à relação entre a <strong>WESTAND</strong>, <strong>MONTADORES</strong> e <strong>EXPOSITORES</strong>; <br /><br />
                    </li>
                    {
                      role === "EXPOSITOR" ?
                        <li>
                          Entrar em contato diretamente com as <strong>MONTADORAS</strong> que tenham tido acesso por meio da <strong>WESTAND</strong>, e caso o faça, será devida a porcentagem à <strong>WESTAND</strong>,
                          como se o projeto tivesse ocorrido por intermédio da plataforma. <br /><br />
                        </li>
                        :
                        <li>
                          Entrar em contato diretamente com os <strong>EXPOSITORES</strong> que tenham tido acesso por meio da <strong>WESTAND</strong>, e caso o faça, será devida a porcentagem à <strong>WESTAND</strong>,
                          como se o projeto tivesse ocorrido por intermédio da plataforma. <br /><br />
                        </li>
                    }
                    <li>
                      É vedada a reprodução e distribuição, no todo ou em parte, a qualquer terceiro, sem prévia e expressa autorização da <strong>WESTAND</strong>, de seus conteúdos disponibilizados,
                      ficando os infratores sujeitos às penas dos crimes previstos na Lei 9.609/98, sem prejuízo da responsabilidade civil pelos danos eventualmente causados pelo uso e distribuição de
                      cópias não autorizadas do conteúdo disponibilizado ou por qualquer outra violação aos direitos de propriedade, incluindo, mas não se limitando, o acesso de terceiros não autorizados;<br /><br />
                    </li>
                    <li>
                      Violar ou tentar violar quaisquer medidas de segurança da Plataforma; <br /><br />
                    </li>
                    <li>
                      Acessar ou tentar acessar qualquer conta ou identificação de terceiros;<br /><br />
                    </li>
                    <li>
                      Publicar ou submeter qualquer informação imprecisa, falsa ou incompleta, incluindo módulos de propriedade, indicadores de produção, entre outros;<br /><br />
                    </li>
                    <li>
                      Carregar, publicar, transmitir ou armazenar qualquer conteúdo que: I) seja ilegal, ofensivo, difamatório, fraudulento, enganoso, danoso, ameaçador, constrangedor,
                      obsceno, discriminatório ou que ofenda propriedade intelectual ou direito de autor; II) perturbe ou interfira nas operações normais do site, tais como a publicação ou a
                      transmissão de vírus, a publicação continuada de materiais repetidos ou a publicação de arquivos de grandes volumes; III) contenha dados pessoais sensíveis, tais como aqueles
                      que indicam raça/etnia, informações sobre saúde ou vida sexual, dados biométricos ou genéticos, filiação à sindicato, organização religiosa filosófica ou política. IV) não seja permitido pela
                      <strong> WESTAND</strong>, incluindo, sem limitação, quaisquer materiais de publicidade não autorizada, materiais de propaganda não solicitada, mensagens de spam, cartas em "corrente", esquemas
                      de pirâmide, franquias, distribuição, associação em clubes, acordos de venda ou outros materiais não aceitos.<br /><br />
                    </li>
                    <li>
                      Agir de forma desrespeitosa e/ou inadequada com os demais participantes da <strong>WESTAND</strong>.<br /><br />
                    </li>
                    <li>
                      Realização de propaganda indevida dentro da <strong>WESTAND</strong> para os demais <strong>Usuários</strong>.
                    </li>
                    <li>
                      Violar direitos de terceiro ou direitos da <strong>WESTAND</strong>.<br /><br />
                    </li>
                    <li>
                      Qualquer tipo de tentativa de fraude ou manipulação.<br /><br />
                    </li>
                    <li>
                      Violar direito de propriedade intelectual da <strong>WESTAND</strong> ou de terceiro. <br /><br />
                    </li>
                    <li>
                      Utilizar a <strong>WESTAND</strong> para qualquer outro fim que não seja o disposto nestes <strong>Termos e Condições de uso</strong>. <br /><br />
                    </li>
                  </ul>
                </p>
                <p>
                  A <strong>WESTAND</strong> poderá, a seu exclusivo critério, suspender ou excluir o acesso d{USER_BY_ROLE} aos seus serviços, sempre que for detectada uma conduta proibida,
                  ou o descumprimento de suas obrigações, sem necessidade de aviso prévio ou direito a qualquer tipo de compensação
                </p>
                <p>
                  As medidas indicadas acima não afastam a possibilidade da responsabilização civil, criminal ou administrativa d{USER_BY_ROLE}.
                </p>
              </MainLi>
              <MainLi>
                <strong>PROPRIEDADE INTELECTUAL</strong>
                <br />
                <br />
                <p>
                  A titularidade e os direitos relativos à <strong>WESTAND</strong> pertencem exclusivamente à <strong>WESTAND</strong>.
                </p>
                <p>
                  {USER_BY_ROLE_CAPITAL} não adquire, em razão do acesso e utilização regular da <strong>WESTAND</strong>, ou por meio destes <strong>Termos</strong>,
                  nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou direitos sobre informações
                  confidenciais ou segredos de negócio, sobre ou relacionados a <strong>WESTAND</strong>, os quais são de sua propriedade exclusiva.
                </p>
                <p>
                  Em caso de violação das proibições contidas nestes <strong>Termos</strong> ou nas legislações aplicáveis, incluindo, mas não se limitando à Lei de Propriedade Intelectual,
                  a parte causadora poderá ser responsabilizada, civil e criminalmente, pelas infrações cometidas.
                </p>
              </MainLi>
              <MainLi>
                <strong>ENCERRAMENTO DA CONTA</strong>
                <br />
                <br />
                <p>
                  {USER_BY_ROLE_CAPITAL} poderá solicitar o encerramento da sua conta na WESTAND a qualquer momento. Basta entrar em contato através do e-mail contato@westand.com.br.
                </p>
                <p>
                  Além disso, a <strong>WESTAND</strong> poderá encerrar a conta d{USER_BY_ROLE} nas hipóteses previstas nestes <strong>Termos</strong>.
                </p>
              </MainLi>
              <MainLi>
                <strong>AVISO DE PRIVACIDADE </strong>
                <br />
                <br />
                <p>
                  É disponibilizado, por meio de um <strong>Aviso de Privacidade</strong>, todas as informações sobre a coleta, utilização, processamento e divulgação das informações e dados pessoais
                  {
                    role === "EXPOSITOR" ?
                      <>dos <strong>EXPOSITORES </strong></>
                      : role === "MONTADOR" ?
                      <>das <strong>MONTADORAS </strong></>
                      :
                      <>dos <strong>PROJETISTAS</strong></>
                  }
                  tratados pela <strong>WESTAND</strong>, em conformidade com a Lei Geral de Proteção de Dados Pessoais <strong>(LGPD)</strong>.
                </p>
                <p>
                  O <strong>Aviso de Privacidade</strong> está disponível no site <a href="https://app.westand.com.br/">https://app.westand.com.br/</a>
                  e é parte integrante destes <strong>Termos e Condições de Uso</strong>.
                </p>
                <p>
                  Por isso, solicitamos que você leia, entenda e aceite de forma livre, inequívoca e informada o <strong>Aviso de Privacidade</strong>.
                </p>
              </MainLi>
              <MainLi>
                <strong>DISPOSIÇÕES GERAIS</strong>
                <br />
                <br />
                <p>
                  A <strong>WESTAND</strong> informa que poderá, a qualquer momento, unilateralmente, modificar/atualizar estes <strong>Termos e Condições de Uso</strong>.
                </p>
                <p>
                  Caso isso aconteça, {USER_BY_ROLE} será notificado e a versão atualizada valerá a partir de sua publicação. A continuidade de acesso ou utilização da <strong>WESTAND</strong>,
                  depois da divulgação, confirmará a vigência dos novos <strong>Termos e Condições de Uso</strong> pel{USER_BY_ROLE}.
                </p>
                <p>
                  Qualquer cláusula ou condição destes <strong>Termos e Condições de Uso</strong> que, eventualmente, venha a ser considerada nula ou ineficaz por juízo, lei ou tribunal,
                  não afetará a validade das demais cláusulas e condições.
                </p>
                <p>
                  A <strong>WESTAND</strong> poderá, unilateralmente, realizar modificações em sua plataforma, incluindo mas não se limitando à sua configuração, apresentação, desenho, conteúdo, funcionalidades, ferramentas, inclusive o seu desativamento.
                </p>
                <p>
                  Estes <strong>Termos</strong> são regidos e interpretados de acordo com as leis da República Federativa do Brasil.
                </p>
                <p>
                  Fica eleito o foro da comarca de <strong>São Paulo/SP</strong> para serem dirimidos eventuais conflitos que estes <strong>Termos e Condições de Uso</strong> possam suscitar, renunciando as partes a qualquer outro foro, por mais privilegiado que seja ou possa parecer.
                </p>
              </MainLi>
            </ol>
          </Column>
          {
            terms?.status === "approved" ?
              <DialogActions>
                <span style={{ color: "#495cff" }}>Termos de uso aceitos</span>
              </DialogActions>
              :
              userData.status === "disapproved" ?
                <DialogActions>
                  <span style={{ color: "#c11919" }}>Termos de uso não aceitos</span>
                </DialogActions>
                :
                <ActionButtons>
                  <Button color={"#adb5bd"} onClick={handleClose}>Fechar</Button>
                  <Button color={"#FCA500"} onClick={handleApprove}>Aprovar</Button>
                </ActionButtons>
          }
        </DialogContent>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #FDC22A;
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & > h2 {
    font-size: 3rem;
    text-align: center;
  }

  & > h3 {
    text-align: center;
  }

  & p {
    font-size: 1.5rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.5rem;
  }

  ol {
    margin: 1rem;
  }
  
`

const UnderlinedLi = styled.li`
  text-decoration: underline;
  margin: 1rem;
`

const MainLi = styled.li`
margin-bottom: 50px;
  &::before {
    font-weight: bold;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: '100%';
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;
  height: 50px;
  padding: 3rem 30rem;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    padding: 0;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#FDC22A" : color};
  background: ${({ color }) => !color ? "#FDC22A" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`

const Space = styled.div`
  width: 1rem;
`