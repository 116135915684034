import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin: 10px 0;
  padding: 10px;
  gap: 20px;

`

export const Content = styled.div`
  display: flex;
  gap: 10px;

  > div:nth-of-type(1) {
    flex: 3;
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    flex: 1;
    padding: 10px 0;
  }

  @media screen and (max-width: 898px) {
    flex-direction: column;

    div {
      flex-direction: column;
    }
  }
`

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 4vh;
  padding: 2vh 0;
`