import styled from "@emotion/styled";

const ActionButton = styled.div`
  margin: 20px 0;
  
  @media screen and (max-width: 898px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

export {
  ActionButton
}