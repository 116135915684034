import { useState } from "react";

function useSimpleForm(initValue = {}) {
  const [stateForm, setStateForm] = useState(initValue);

  function handleChange({ target: { name, value } }) {
    setStateForm({
      ...stateForm,
      [name]: value,
    });
  }

  return { stateForm, handleChange, setStateForm };
}

export { useSimpleForm };
