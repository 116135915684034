import styled from "@emotion/styled";
import Header from "../Header";
import { NRoundedButton } from "../../../shared-components/NRoundedButton";
import { useSimpleForm } from "../../../hooks/useSimpleForm";
import { createEvent } from "../../../api/promoter";
import { useState } from "react";
import { FragmentBriefing, FragmentBuilder, FragmentCauses, FragmentDateTime, FragmentDescription, FragmentImage, FragmentLocation, FragmentResponsibility, FragmentTickets, FragmentType, FragmentVisibility } from "../fragments";
import { useNotification } from "../../../context/NotificationContext";
import { COLORS } from "../../../helpers/types";

export default function CriarEvento({ role }) {
  const [modalBriefing, setModalBriefing] = useState(false);
  const notification = useNotification();

  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    address: "",
    location: "",
    zipcode: "",
    neighbourhood: "",
    complement: "",
    city: "",
    name: "",
    largeImage: "",
    category: "",
    matter: "",
    description: "",
    selectedCauseList: [],
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    ticketType: "",
    ticketName: "",
    responsibility: false,
    visibility: "publico",
  });

  const handleOpenCloseBriefing = () => {
    setModalBriefing(modalBriefing ? false : true);
  }

  const handleChipSelectedCauses = (chip) => {
    if (stateForm.selectedCauseList && stateForm.selectedCauseList.includes(chip)) {
      let filteredList = stateForm.selectedCauseList.filter((selectedChip) => selectedChip !== chip);
      setStateForm({
        ...stateForm,
        selectedCauseList: filteredList,
      });
    } else {
      setStateForm({
        ...stateForm,
        selectedCauseList: stateForm.selectedCauseList ? [...stateForm.selectedCauseList, chip] : [chip],
      });
    }
  };

  const handleChipTicket = (type) => {
    if (stateForm.ticketType === type) {
      setStateForm({
        ...stateForm,
        ticketType: "",
      });
    } else {
      setStateForm({
        ...stateForm,
        ticketType: type,
      });
    }
  };

  const handleResponsibilityChange = (event) => {
    setStateForm({
      ...stateForm,
      responsibility: event.target.checked
    });
  };

  const handleVisibilityChange = (event) => {
    setStateForm({
      ...stateForm,
      visibility: event.target.value
    });
  };

  function handleSubmit(event) {
    event.preventDefault();
    createEvent(stateForm)
      .then(res => {
        notification.show({ message: 'Evento criado com sucesso.' })
      })
      .catch(err => {
        notification.show({ message: 'Não foi possível criar o evento.', type: "error" })
      })
  }

  return (
    <>
      <Header role={role} title="Vamos falar sobre o seu evento" />
      <WrapperForm role={role} onSubmit={handleSubmit}>
        <FragmentBriefing role={role} stateForm={stateForm} isOpen={modalBriefing} handleOpenCloseBriefing={handleOpenCloseBriefing} />
        <FragmentLocation handleChange={handleChange} stateForm={stateForm} setStateForm={setStateForm} />
        <FragmentImage handleChange={handleChange} stateForm={stateForm} />
        <FragmentType handleChange={handleChange} stateForm={stateForm} />
        <FragmentDescription
          handleChange={handleChange}
          stateForm={stateForm}
        />
        <FragmentBuilder handleChange={handleChange} stateForm={stateForm} />
        <FragmentCauses role={role} handleChange={handleChange} stateForm={stateForm} handleChipSelectedCauses={handleChipSelectedCauses} />
        <FragmentDateTime handleChange={handleChange} stateForm={stateForm} />
        <FragmentTickets role={role} handleChange={handleChange} stateForm={stateForm} handleChipTicket={handleChipTicket} />
        <FragmentResponsibility role={role} handleChange={handleChange} stateForm={stateForm} handleResponsibilityChange={handleResponsibilityChange} />
        <FragmentVisibility role={role} handleChange={handleChange} stateForm={stateForm} handleVisibilityChange={handleVisibilityChange} />
        <ActionForm>
          <NRoundedButton
            title={"Publicar evento"}
            color={COLORS[role]}
            active={true}
            type="submit"
          />

        </ActionForm>
      </WrapperForm>
    </>
  );
}

const WrapperForm = styled.form`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 0.375rem;
  padding: 30px;

  span {
    font-size: 2rem;
    font-weight: 800;
  }

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }

  @media screen and (max-width: 898px) {
    flex-direction: column;

    div {
      flex-direction: column;
    }
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => COLORS[props.role]};
  }
`;

const ActionForm = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  height: 10vh;
`;
