import { useUser } from "../../context/UserContext"
import { useQuery } from "react-query";
import { listOpenBriefings } from "../../api/briefing";
import Loading from "../../shared-components/Loading";
import BriefingTableUsers from "./components/BriefingTableUsers";
import BriefingTableExpositor from "./components/BriefingTableExpositor";
import { ROLE } from "../../helpers/types";

export default function ListBriefing() {

  const { data, isLoading } = useQuery('list-briefing', listOpenBriefings);
  const { userData } = useUser();

  if (isLoading) {
    return <Loading />
  }

  if (userData.role.name === ROLE.EXPOSITOR) {
    return (
      <>
        <BriefingTableExpositor
          data={data}
          setFetchType={() => { }}
        />
      </>
    )
  }

  return (
    <>
      <BriefingTableUsers
        data={data}
        setFetchType={() => { }}
        isAdminRole={userData.role.name === ROLE.ADMIN}
      />
    </>
  )
}