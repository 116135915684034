export const BRIEFING_TYPE_DB = Object.freeze({
  1: "PROJETO",
  2: "PROJETO_MONTADORA",
  3: "MONTAGEM",
  4: "OUTRO"
})

export const BRIEFING_TYPE_DB_NAME = Object.freeze({
  "PROJETO": 1,
  "PROJETO_MONTADORA": 2,
  "MONTAGEM": 3,
  "OUTRO": 4,
})

// fazer para aparecer para o usuario junto com o 1.
export const BRIEFING_TYPE = Object.freeze({
  "PROJETO": "Projeto",
  "PROJETO_MONTADORA": "Projeto + Montagem",
  "MONTAGEM": "Montagem",
  "OUTRO": "Outro"
})

export const ROLE = Object.freeze({
  EXPOSITOR: "EXPOSITOR",
  MONTADOR: "MONTADOR",
  ADMIN: "ADMIN",
  ADMIN_ASSISTENT: "ADMIN_ASSISTENT",
  PROMOTR: "PROMOTOR",
  PROJETISTA: "PROJETISTA",
});

export const COLORS = Object.freeze({
  EXPOSITOR: "#FF8400",
  MONTADOR: "#22da28",
  PROJETISTA: "#FDC22A",
  PROMOTOR: "#E70E4C",
  ADMIN: "#764faa"
});
