import styled from "@emotion/styled";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import Header from "../DashboardMontadorComponents/Header";
import { CardProfileSession } from "./CardProfileSession";

const cardsSessions = [
  {
    Icon: BusinessCenterOutlinedIcon,
    title: "Sua página",
    subTitle: "Edite aqui sua página",
    link: "/edit-pagina-montador"
  },
  {
    Icon: PeopleAltOutlinedIcon,
    title: "Informações pessoais",
    subTitle: "Edite aqui o seu perfil",
    link: "/informacoes-pessoais-montador"
  },
  {
    Icon: LockOpenIcon,
    title: "Dados da empresa",
    subTitle: "Edite aqui o seu perfil da empresa",
    link: "/montador/dados-empresa"
  },
  {
    Icon: HowToRegOutlinedIcon,
    title: "Entrar & Segurança",
    subTitle: "Edite aqui o seus dados de login",
    link: "/entrar-seguranca-montador"
  },
]


export function MontadorProfile() {
  return (
    <>
      <Header
        title={"Editar perfil"}
      />
      <LinkSession>
        {cardsSessions.map(card => (
          <CardProfileSession
            Icon={card.Icon}
            title={card.title}
            subTitle={card.subTitle}
            link={card.link}
          />
        ))}

      </LinkSession>

    </>
  )
}

const LinkSession = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  margin: 2rem 0;
`