import styled from "@emotion/styled";
import { forwardRef } from "react";

import { Select, FormHelperText } from "@mui/material";



const DashSelect = forwardRef((props, ref) => {
  const {
    children,
    title,
    helperText,
    ...otherProps
  } = props;

  return (
    <Wrapper>
      {title ? <p>{title}</p> : ""}
      <Component
        {...otherProps}
        ref={ref}
        defaultValue=""
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                fontSize: "1.6rem",
                fontWeight: "300",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.6rem"

              },
            },
          },
        }}
      >
        {children}
      </Component>
      {helperText ? (<FormHelperText>{helperText}</FormHelperText>) : ''}


    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;

  > p {
    margin: 2px;
    font-size: 1.6rem;
    font-weight: 500;
    width: 100%;
  }
`;

const Component = styled(Select)`
  width: ${({ width }) => (width ? width : "100%")};

  & fieldset {
    border: none;
  }

  & .MuiSelect-select.MuiSelect-outlined {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #f3f3f3;
    white-space: pre-line;
    font-size: 1.6rem;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    height: 34px;
  }

  & .MuiSelect-select.MuiSelect-outlined:active {
    border: 2px solid #ff8400;
  }

  .MuiSelect-select.MuiSelect-outlined:hover {
    border: 1px solid black;
  }



  @media screen and (max-width: 898px) {
    width: 94%;

    & .MuiOutlinedInput-input {
      padding-right: 0 !important;
    }

    & .MuiSelect-select.MuiSelect-outlined {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export { DashSelect };
