import { useState } from 'react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const DashInputPassword = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false);

  function handleClickVisible() {
    setVisible(() => !visible)
  }


  const {
    title,
    placeholder,
    variant,
    min = 0,
    max,
    ...otherProps } = props;

  return (
    <>
      <Wrapper>
        {title ? <p>{title}</p> : ''}
        <Component
          id="outlined-adornment-password"
          type={visible ? 'text' : 'password'}
          {...otherProps}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickVisible}
                edge="end"
              >
                {visible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          } />
      </Wrapper>

    </>
  )
})

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;

    > p {
      margin: 2px;
      font-size: 1.6rem;
      font-weight: 500;
    }
`

const Component = styled(OutlinedInput)`
  width: ${({ width }) => width ? width : '100%'};
  background-color:#F3F3F3;
  & .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
   
    border-radius: 0;
  } 

  & input {
    height: 10px;
    background-color:#F3F3F3;
    border-radius: 6px ;
    font-size: 1.6rem;
  }

  input::placeholder {
    font-size: 1.6rem;
    text-align: left;
  }

/* Firefox */
  input[type=number] {
    font-size: 1.6rem;
    text-align: left;
  }


`
export { DashInputPassword };
