import { useState } from "react";
import styled from "@emotion/styled";
import { searchCep, searchCities } from "../../../api/open";
import { Autocomplete } from "@mui/material";
import { DashInput } from "../../../shared-components/DashInput";

export default function FragmentLocation({ handleChange, stateForm, setStateForm }) {
  const [cities, setCities] = useState([]);

  async function handleSearchCity(e) {
    if (!e) return;
    const result = await searchCities(e.target.value);
    if (result.length >= 1) {
      setCities(
        result.map((v) => ({
          label: v.name,
        }))
      );
      return;
    }
    setCities([]);
  }

  function handleSearchZip() {
    let local = '';
    searchCep(stateForm?.zipcode)
      .then(res => {
        res.localidade !== undefined ? local = res.localidade + "/" + res.uf : local = '';
        setStateForm({
          ...stateForm,
          address: res.logradouro,
          complement: res.complemento,
          city: local,
          neighbourhood: res.bairro,
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      <span>Onde o seu evento vai acontecer?</span>
      <InputsWrappers>
        <Row>
          <DashInput
            title="Informe o endereço"
            fullWidth
            required
            width="100%"
            name="address"
            value={stateForm?.address}
            onChange={handleChange}
          />
          <DashInput
            required
            title="Nome do local"
            fullWidth
            width="100%"
            name="location"
            value={stateForm?.location}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <DashInput
            title="CEP"
            placeholder="_____-__"
            onBlur={handleSearchZip}
            fullWidth
            width="100%"
            name="zipcode"
            value={stateForm?.zipcode}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                handleChange(e);
              }
            }}
          />
          <DashInput
            required
            title="Bairro"
            fullWidth
            width="100%"
            name="neighbourhood"
            value={stateForm?.neighbourhood}
            onChange={handleChange}
          />
          <DashInput
            title="Complemento"
            fullWidth
            width="100%"
            name="complement"
            value={stateForm?.complement}
            onChange={handleChange}
          />
          <InputContainer className="input-group mb-3" id="search-input">
            <Autocomplete
              style={{
                width: '100%',
              }}
              disablePortal
              disableClearable
              freeSolo
              options={cities}
              onInputChange={handleSearchCity}
              name="city"
              value={stateForm?.city}
              onChange={(event, value) => {
                if (value) {
                  handleChange({
                    target: { name: "city", value: value.label },
                  });
                }
              }}
              renderInput={(params) => (
                <DashInput {...params}
                  title="Cidade/Estado"
                  fullWidth
                  width="100%"
                  required
                />
              )}
            />
          </InputContainer>
        </Row>
      </InputsWrappers>
    </>
  );
}


const InputsWrappers = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 30px;
  margin: 2rem 0;
  > p {
    flex: 2;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  
  & .MuiAutocomplete-root {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }

  & .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 100%;
  }

  & .MuiAutocomplete-listbox {
    font-size: 1.7rem ;
  }

  & input {
    height: 10px !important;
    font-size: 1.6rem;
  }

  & svg {
    display: none;
  }

`