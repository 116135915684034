import {
  Wrapper,
  Row,
} from "./Styles";
import { useState } from "react";
import { useStepActions } from "../useStepActions";
import { useStepForm } from "../StepFormContext";
import { useNotification } from "../../../../context/NotificationContext";
import {
  NRoundedButton,
  ToDoTasks
} from "../../../../shared-components";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from '@mui/material/Button';
import { updateBriefingTodo } from "../../../../api/briefing";
import { FragmentProjects } from "../../solicitacaoPlataforma/fragments/FragmentProjects";
import { DialogProject } from "../../solicitacaoPlataforma/fragments/DialogProject";

function renderTextAlert(version) {

  if (version === 'v1') {
    return (
      <span>
        Atenção!<p style={{
          fontSize: '1.8rem',
          margin: '1rem 0'
        }}>Ao avançar você estará enviado as solicitações de alteração. <strong>Você ainda tem mais 2 alterações</strong>. Deseja continuar? <br /> <br /> Deseja enviá-las agora?</p>
      </span>
    )
  }
  if (version === 'v2') {
    return (
      <span>
        Atenção!<p style={{
          fontSize: '1.8rem',
          margin: '1rem 0'
        }}>Ao avançar você estará enviado as solicitações de alteração. <strong>Você ainda tem mais 1 alteração</strong>. Deseja continuar? <br /> <br /> Deseja enviá-las agora?</p>
      </span>
    )
  }

}


const Step1 = ({ version, versionTitle }) => {
  const { stateForm, setStateForm } = useStepForm();
  const [openModalProject, setOpenModalProject] = useState(false);
  const [projectViewZoom, setProjectViewZoom] = useState();
  const notification = useNotification();

  const [tasks, setTasks] = useState(() => {
    if (stateForm?.Briefing?.BriefingManagerTasks.length > 0) {

      if (version === 'v1') {
        let taskList = stateForm?.Briefing?.BriefingManagerTasks
          .filter(v => v.type === version);

        if (taskList.length === 0) {
          taskList = stateForm?.Briefing?.BriefingManagerTasks
            .filter(v => v.type === 'v0');
        }

        if (taskList.length === 0) {
          return []
        }

        return JSON.parse(taskList[0].tasks);
      }

      if (version === 'v2') {
        let taskList = stateForm?.Briefing?.BriefingManagerTasks
          .filter(v => v.type === version);

        if (taskList.length === 0) {
          return []
        }
        return JSON.parse(taskList[0]?.tasks);
      }
    }
    return [];
  });

  const [open, setOpen] = useState(false);
  const {
    isBuilder,
    isExhibitor,
    isDraftsman,
  } = useStepActions();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleUpdateTasks() {
    updateBriefingTodo(stateForm?.Briefing?.id, tasks, version)
      .then((res) => {
        notification.show({
          message: "As alterações foram enviadas para o projetista.",
        });
      })
      .catch((error) => {
        console.log(error)
        notification.show({
          message: error.response?.data?.message || "Houve um erro ao enviar as alterações",
          type: "error"
        });
      });
  }

  function toggleTask(taskId) {
    const newTasks = tasks.map(v => {
      if (v.id === taskId) {
        return ({
          ...v,
          done: !v.done
        })
      } else {
        return v;
      }
    })

    setTasks(newTasks)

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: newTasks,
    });
  }

  function handleAddTask(taskName) {
    if (isBuilder()) {
      return;
    }
    const newTask = {
      id: +new Date(),
      description: taskName,
      done: false,
    };

    setTasks([
      ...tasks,
      newTask
    ])

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: [
        ...tasks,
        newTask
      ],
    });

  };

  function handleRemoveTask(taskId) {
    const newTasks = tasks.filter(v => v.id !== taskId);

    setTasks(newTasks);

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: newTasks,
    });
  }

  const expositor = isExhibitor();
  const projetista = isDraftsman();

  function handleModalProject(projectLink) {
    setProjectViewZoom(projectLink);
    setOpenModalProject(true);
  }

  function closeModalProject() {
    setOpenModalProject(false);
  }

  return (
    <Wrapper>
      <div style={{
        textAlign: 'center'
      }}>
        <h2 className="reg-feira">{`Projeto - ${versionTitle}`}</h2>
        <h4>Nessa etapa você poderá solicitar alterações no seu projeto.</h4>
      </div>
      <DialogProject
        open={openModalProject}
        handleClose={closeModalProject}
        projectViewZoom={projectViewZoom}
      />
      <Row style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '2rem 0',
        flexWrap: 'wrap'
      }}>
        {stateForm.Briefing.BriefingProjects.map(value => (
          <FragmentProjects
            key={value?.id}
            briefingProject={value}
            handleClick={handleModalProject}
          />
        ))}
      </Row>
      <Row style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '2rem 0'
      }}>

        <div className="project-wrapper">
          <div className="release-project">
            <ToDoTasks
              name="checklistCorrecoes"
              title={`Adicionar alterações no Projeto - ${versionTitle}`}
              subTitle={"Após enviadas as solicitações tem um prazo de até 5 dias úteis para serem executadas."}
              color={"#FFA500"}
              tasks={tasks}
              handleAddTask={handleAddTask}
              onToggle={toggleTask}
              onRemove={handleRemoveTask}
              disableRemove={projetista}
              disableTitle={projetista}
            />
            <div>
              {expositor && (
                <NRoundedButton
                  title="Enviar alterações"
                  color="#ff8400"
                  active={true}
                  onClick={handleClickOpen}
                />
              )}
              {projetista &&
                <NRoundedButton
                  title="Atualizar checklist"
                  color="#ff8400"
                  active={true}
                  onClick={handleUpdateTasks} />}
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {renderTextAlert(version)}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} style={{
                  fontFamily: "Montserrat",
                  fontSize: "1.5rem",
                  color: "#92939c",
                }}
                >
                  Revisar
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                    handleUpdateTasks();
                  }}
                  autoFocus
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "1.5rem",
                    color: "#ff8400",
                    fontWeight: "bold"
                  }}>
                  Enviar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Row>
    </Wrapper>
  );
};


export { Step1 }