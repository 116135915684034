import styled from '@emotion/styled';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { COLORS } from '../../../helpers/types';

export default function FragmentBriefing({ role, stateForm, isOpen, handleOpenCloseBriefing }) {
  return (
    <>
      <DialogStyled
        open={isOpen}
        onClose={handleOpenCloseBriefing}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Briefing"}
        </DialogTitle>
        <DialogContent>
          <SubTitle>Onde e Quando</SubTitle>
          <Row>
            <Column>
              <strong>Nome do evento </strong>
              {stateForm?.name}
            </Column>
            <Column>
              <div>
                <strong>Assunto: </strong>
                {stateForm?.matter}
              </div>

              <div>
                <strong>Categoria: </strong>
                {stateForm?.category}
              </div>

            </Column>
            <Column>
              <div>
                <strong>Cidade: </strong>
                {stateForm?.city}
              </div>
            </Column>
            <Column>
              <div>
                <strong>Data Inicio: </strong>
                {stateForm?.startDate}
              </div>

              <div>
                <strong>Data Término: </strong>
                {stateForm?.endDate}
              </div>

            </Column>
          </Row>

          <Row>
            <Column>
              <SubTitle>Ingressos</SubTitle>
              <Column>
                <div>
                  <strong>Tipo do Ingresso: </strong>
                  {stateForm?.ticketType}
                </div>

                <div>
                  <strong>Nomenclatura: </strong>
                  {stateForm?.ticketName}
                </div>
              </Column>
            </Column>

            <Column>
              <SubTitle>Evento</SubTitle>
              <Column>
                <div>
                  <strong>Visibilidade: </strong>
                  {stateForm?.visibility}
                </div>

                <Row style={{ alignItems: "start" }}>
                  <strong>Causas sustentáveis: &nbsp; </strong>
                  <div>

                    <ul>
                      {stateForm?.selectedCauseList && stateForm?.selectedCauseList.map((cause) => (
                        <li key={cause}>- {cause}</li>
                      ))}
                    </ul>
                  </div>
                </Row>
              </Column>
            </Column>
          </Row>

          <Row>
            <Column>
              <SubTitle>Descrição do Evento</SubTitle>
              <div>
                <strong>Descrição: </strong>
                {stateForm?.description}
              </div>
            </Column>
          </Row>

        </DialogContent>
        <DialogActions role={role}>
          <Button role={role} color={"#adb5bd"} onClick={handleOpenCloseBriefing}>Fechar</Button>
        </DialogActions>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: ${props => COLORS[props.role]};
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & p {
    font-size: 1.8rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? `${props => COLORS[props.role]}` : color};
  background: ${({ color }) => !color ? `${props => COLORS[props.role]}` : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`