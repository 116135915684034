import * as React from 'react';
import { styled } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'; import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export function TooltipInfo({
  title,
}) {
  return (
    <LightTooltip
      title={title}
    >
      <IconButton size="small"  >
        <HelpOutlineOutlinedIcon />
      </IconButton>
    </LightTooltip>
  );
}