import styled from "@emotion/styled"


export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 0.375rem;
  padding: 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 10rem;
  cursor: pointer;
  width: 98%;
  align-items: center;
  gap: 2vw;
  margin: 0.6vh 0;
  transition: all linear 200ms;

  & .MuiList-root{
    background-color: red;
  }

  &:hover {
    box-shadow: 0 0 0.6rem 0.25rem rgb(161 172 184 / 65%);
  }
  h2 {
    font-size: 2rem;
    font-weight: 800;
  }
 
  div:nth-of-type(1) {
    flex: 2;
  }
  div:nth-of-type(2) {
    flex: 1;
  }
   div:nth-of-type(3) {
    flex: 2;
  }


  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }

`

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CardSectionRow = styled(CardSection)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  img {
    width: 40px;
    height: 40px;
  }

`


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin: 10px 0;
  padding: 10px;
  gap: 20px;
`


export const Title = styled.span`
  display: flex;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  color: #FF8400;
`

export const Content = styled.div`
  display: flex;
  gap: 10px;

  > div:nth-of-type(1) {
    flex: 3;
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    flex: 1;
    padding: 10px 0;
  }

  @media screen and (max-width: 898px) {
    flex-direction: column;

    div {
      flex-direction: column;
    }
  }
`

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 4vh;
  padding: 2vh 0;
`