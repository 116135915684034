import styled from "@emotion/styled";
import { forwardRef } from "react";

import { Select } from "@mui/material";

const NSelect = forwardRef((props, ref) => {
  const { children, width, title, ...otherProps } = props;
  return (
    <Wrapper width={width}>
      {title ? <p>{title}</p> : ""}
      <Component
        width={width}
        {...otherProps}
        ref={ref}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                fontSize: "1.6rem",
                fontWeight: "300",
              },
            },
          },
        }}
      >
        {children}
      </Component>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  height: 5.5rem;

  > p {
    margin: 2px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 898px) {
    width: 100%;
    display: flex;
    align-items: center;

    > p {
      margin: 2px;
      width: 100%;
      font-size: 1.2rem;
    }
  }
`;

const Component = styled(Select)`
  width: ${({ width }) => (width ? width : "300px")};
  & fieldset {
    border: none;
  }

  & .MuiSelect-select.MuiSelect-outlined {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #e3e3e3;
    white-space: pre-line;
    font-size: 1.2rem;
    padding: 4px 10px;
    display: flex;
    align-items: center;
  }

  & .MuiSelect-select.MuiSelect-outlined:active {
    border: 2px solid #ff8400;
  }

  .MuiSelect-select.MuiSelect-outlined:hover {
    border: 1px solid black;
  }

  svg {
    display: flex;
    align-items: center;
    top: 0;
    background-color: transparent;
  }

  @media screen and (max-width: 898px) {
    background-color: red;
  }
`;

export { NSelect };
