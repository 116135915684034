import styled from "@emotion/styled"

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    border-radius: 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    min-height: 40rem;
    margin: 10px 0;
    padding: 0;
  
`

export const ContainerReference = styled.div`
  flex-direction: column;
  margin: 5px;
  position: relative;


  > span {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: 500;
    font-size: 1.4rem;
    right: 2px;
    top: 2px;
  }

  img {
      width: 100%;
      min-width: 250px;
      max-width: 300px;
      height: 240px;
      object-fit: cover;
      border-radius: 6px;
    }
`
export const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #FDC22A;
  margin: 14px 0;
  
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;
  font-size: 1.6rem;

  & p {
    font-size: 1.6rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.6rem;
    font-weight: bold;
  }
  
`
export const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;

  > div {
    display: flex;
    flex: 0.4;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`


export const Button = styled.button`
  min-width: 130px;
  font-size: 1.4rem;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#FDC22A" : color};
  background: ${({ color }) => !color ? "#FDC22A" : color};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`

export const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #e3e3e3;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
`

export const ProjectBlock = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 10px;
  width: 100%;
  flex: 1 0 21%;

`

export const ContentCard = styled.div`
    width: 100%;
    background-color: white;
    margin: 1rem 0;
    border-radius: 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    padding: 1rem 1.5rem;
  
    > h3 {
      margin: 20px 0;
    }

    p {
      font-size: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      background-color: #F3F3F3;
      border-radius: 5px;
      font-weight: bold;
      margin: .5rem 0;
    }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px;
    padding: 0 10px;
    
  }

  > span {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
   
  }

  @media only screen and (max-width: 1200px) {
    align-items: center;
    flex-direction: column;

    > span {
      img {
        min-width: 380px;
        height: 240px;
      }
    }
  }

    @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    > span {
      flex-direction: column;

      img {
        min-width: 280px;
        height: 240px;
      }
    }
  }

`