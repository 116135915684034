import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useFilterTable from "../hooks/useFilterTable";
import { DashInput, NRoundedButton } from "../../../shared-components";
import { formatDateLocale } from "../../../helpers";
import { useUser } from "../../../context/UserContext";
import { ROLE } from "../../../helpers/types";

const columns = [
  { id: "eventName", label: "Nome do Evento", minWidth: 170 },
  { id: "date", label: "Criado/Atualizado", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
];


function renderButtonStatus(status) {
  if (status === "ABERTO") {
    return <button className="btn-aberto">Sem proposta</button>;
  }
  if (status === "EM_NEGOCIAÇÃO") {
    return <button className="btn-andamento">Com proposta</button>;
  }
  if (status === "ACEITO") {
    return <button className="btn-aprovado">Proposta aceita</button>;
  }
}

function RowCell({ briefing }) {
  const navigate = useNavigate();
  const { userData } = useUser();

  function openBriefing() {

    if (userData.role.name === ROLE.MONTADOR) {
      const briefingId = briefing.briefingId || briefing?.Briefing?.id;
      navigate(`/montador/briefing/${briefingId}`);
    }
    if (userData.role.name === ROLE.PROJETISTA) {
      const briefingId = briefing.briefingId || briefing?.Briefing?.id;
      navigate(`/projetista/briefing/${briefingId}`);
    }

    if (userData.role.name === ROLE.EXPOSITOR) {
      const briefingId = briefing.briefingId || briefing?.id;
      navigate(`/expositor/briefing/${briefingId}`);
    }

  }

  const totalArea = briefing?.length * briefing?.depth || briefing?.Briefing?.length * briefing?.Briefing?.depth;

  const owner = "Empresa Montadora";

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={briefing.id}
      style={{
        cursor: "pointer",
      }}
    >
      <TableCell
        key={`eventName-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontWeight: 700,
          fontSize: "1.3rem",
          padding: "2rem",
        }}
      >
        {owner} - {briefing?.eventName || briefing?.Briefing?.eventName || "Sem nome de evento"} - {totalArea} m²
        - {briefing.briefingId || briefing?.id}
      </TableCell>
      <TableCell
        key={`date-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontSize: "1.2rem",
          padding: "2rem",
        }}
      >
        <div>
          <strong>Criado:</strong> {formatDateLocale(briefing.createdAt)}
        </div>
        <div>
          <strong>Atualizado:</strong> {formatDateLocale(briefing.updatedAt)}
        </div>
      </TableCell>
      <TableCell
        key={`status-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontSize: "1.2rem",
          padding: "2rem",
          justifyContent: "flex-start",
        }}
      >
        {renderButtonStatus(briefing?.status || briefing?.status)}
      </TableCell>
    </TableRow>
  );
}

const BriefingTableExpositor = ({ data, setFetchType }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const {
    handleFilter,
    handleFilterType,
    searchTerm,
    setSearchTerm,
    filteredBriefings,
    filter,
    filterType,
    setFilter,
  } = useFilterTable(data);

  const handleClearFilter = () => {
    setFilter("all");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Paper
        sx={{
          overflow: "hidden",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "wrap",
            padding: "2rem",
          }}
        >
          <span
            style={{
              flex: 1,
              display: "flex",
              flexWrap: "wrap",
              flexDirection: {
                md: "row",
                xs: "column",
              },
            }}
          >
            <DashInput
              value={searchTerm}
              placeholder={"Busque por um termo..."}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: {
                  xs: "100%",
                },
              }}
            />
            <ButtonType
              title={"Projeto"}
              active={filterType === "projeto"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("projeto");
                handleFilterType("projeto");
              }}
            />
            <ButtonType
              title={"Montagem"}
              active={filterType === "montagem"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("montagem");
                handleFilterType("montagem");
              }}
            />
            <ButtonType
              title={"Projeto + Montagem"}
              active={filterType === "projeto_montagem"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("projeto_montagem");
                handleFilterType("projeto_montagem");
              }}
            />
            <ButtonType
              title={"Todos"}
              active={filterType === "todos"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("todos");
                handleFilterType("todos");
              }}
            />
            <ButtonType
              title={"Sem proposta"}
              active={filter === "ABERTO"}
              color={"#764FAA"}
              onClick={() => handleFilter("ABERTO")}
            />
            <ButtonType
              title={"Com proposta"}
              active={filter === "EM_NEGOCIAÇÃO"}
              color={"#764FAA"}
              onClick={() => handleFilter("EM_NEGOCIAÇÃO")}
            />
            <ButtonType
              title={"Proposta aceita"}
              active={filter === "ACEITO"}
              color={"#764FAA"}
              onClick={() => handleFilter("ACEITO")}
            />
            <ButtonType
              title={"mostrar todos"}
              color={"#764FAA"}
              onClick={handleClearFilter}
              active={filter === "all"}
            />
          </span>
          <span>
            <MoreVertSharpIcon
              sx={{
                cursor: "pointer",
              }}
            />
          </span>
        </Toolbar>
        <TableContainer sx={{ height: "70vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontSize: "1.2rem" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBriefings
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((briefing, index) => (
                  <RowCell key={`cell-${index}`} briefing={briefing} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredBriefings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Linhas por página"}
        />
      </Paper>
    </div>
  );
};

const ButtonType = styled(NRoundedButton)`
  padding: 0rem 1rem;
  min-width: 2rem;
  margin: 0 1rem;
`;

const ButtonHidden = styled(NRoundedButton)`
  padding: 0rem 1rem;
  min-width: 1rem;
  margin: 0 1rem;
  font-size: 1rem;

  &:hover {
    color: #764faa;
  }
`;

export default BriefingTableExpositor;
