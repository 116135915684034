import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import "./styleSolicitPlataforma.css";
import { listRequestBriefings } from "../../../api/briefing";
import Header from "../Header";
import FragmentBriefingBudget from "../../DashboardExpositorComponents/solicitacaoPlataforma/FragmentBriefingBudget";
import { ActionButton } from "./Styles";


//Agrupamento pro Propostas
//TODO: Pagamento
export const groupedBriefingsPerPropostals = (requests = []) => {
  return Object.values(
    requests.reduce((acc, request) => {
      const key = JSON.stringify({ briefing: request.Briefing });
      if (!acc[key]) {
        acc[key] = {
          id: request.id,
          builderId: request.builderId,
          briefingId: request.briefingId,
          proposals: [
            {
              requestId: request.id,
              finalPriceMin: request.finalPriceMin,
              finalPriceMax: request.finalPriceMax,
              finalPrice: request.finalPrice,
              observation: request.observation || "",
              deliveryAt: request.deliveryAt,
            },
          ],
          status: request.status,
          briefingReferencesId: null,
          createdAt: request.createdAt,
          updatedAt: request.updatedAt,
          BriefingRequestReference: request.BriefingRequestReference,
          Briefing: {
            id: request.Briefing.id,
            eventName: request.Briefing.eventName,
            otherType: request.Briefing.otherType,
            city: request.Briefing.city,
            priceMin: request.Briefing.priceMin,
            priceMax: request.Briefing.priceMax,
            length: request.Briefing.length,
            depth: request.Briefing.depth,
            deadline: request.Briefing.deadline,
            observations: request.Briefing.observations,
            blueprint: request.Briefing.blueprint,
            blueprintType: request.Briefing.blueprintType,
            blueprint_observation: request.Briefing.blueprint_observation,
            promoterQuantity: request.Briefing.promoterQuantity,
            buffet: request.Briefing.buffet,
            status: request.Briefing.status,
            standTypeId: request.Briefing.standTypeId,
            briefingTypeId: request.Briefing.briefingTypeId,
            usersId: request.Briefing.usersId,
            paymentTypeId: request.Briefing.paymentTypeId,
            createdAt: request.Briefing.createdAt,
            updatedAt: request.Briefing.updatedAt,
            eventsId: request.Briefing.eventsId,
            BriefingReferences: request.Briefing.BriefingReferences,
            BriefingProjects: request.Briefing.BriefingProjects,
            BriefingManagerTasks: request.Briefing.BriefingManagerTasks,
            User: {
              id: request.Briefing.User.id,
              rolesId: request.Briefing.User.rolesId,
              status: request.Briefing.User.status,
              Exhibitors: request.Briefing.User.Exhibitors,
              Builders: request.Briefing.User.Builders,
              Promoter: request.Briefing.User.Promoter,
            },
          },
        };
      } else {
        acc[key].proposals.push({
          requestId: request.id,
          finalPriceMin: request.finalPriceMin,
          finalPriceMax: request.finalPriceMax,
          finalPrice: request.finalPrice,
          observation: request.observation || "",
          deliveryAt: request.deliveryAt,
        });
      }
      return acc;
    }, {})
  );
}


const SolicitEmNegociacao = (props) => {
  const { data, isLoading } = useQuery("request-briefings", listRequestBriefings);
  const [newData, setNewData] = useState(() => groupedBriefingsPerPropostals(data)
  );


  useEffect(() => {
    setNewData(() => groupedBriefingsPerPropostals(data))
  }, [data])

  const handleRemoveBriefingRequest = (briefingRequestId) => {
    const updatedData = newData.filter(
      (briefingRequest) => briefingRequest.Briefing.id !== briefingRequestId
    );
    setNewData(updatedData);
  };

  return (
    <>
      <Header title="Solicitações em negociação" />
      <ActionButton>
        <Link to="/solicit-aberto-expositor">
          <button className="btn-open-mont">solicitação em aberto</button>
        </Link>
        <button className="btn-open2">em negociação</button>
        <Link to="/solicit-aprovada">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </ActionButton>
      {/* <NRoundedButton title="enviar alterações" color="#ff8400" active={true} /> */}
      <ContentWrapper>
        {isLoading ? (
          <>carregando...</>
        ) : (data &&
          newData &&
          newData.map((value) => (
            <FragmentBriefingBudget
              key={`budget-${value.id}`}
              {...props}
              briefingRequest={value}
              onRemoveBriefingRequest={handleRemoveBriefingRequest}
            />
          )))}

      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
`;

export default SolicitEmNegociacao;
