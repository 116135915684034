import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextField } from "@mui/material";

const NTextField = forwardRef((props, ref) => {
  const { title, placeholder, variant, min = 0, max, ...otherProps } = props;

  return (
    <>
      {variant === "standard" ? (
        <ComponentStandard
          ref={ref}
          InputProps={{ inputProps: { min, max } }}
          {...otherProps}
          placeholder={placeholder}
          variant="standard"
        />
      ) : (
        <Wrapper>
          {title ? <p>{title}</p> : ""}
          <Component
            ref={ref}
            InputProps={{ inputProps: { min, max } }}
            {...otherProps}
            placeholder={placeholder}
          />
        </Wrapper>
      )}
    </>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  > p {
    margin: 2px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const ComponentStandard = styled(TextField)`
  & input {
    font-size: 1.5rem;
  }
`;

const Component = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #e3e3e3;
    border-radius: 50px;
    border: none;
  }

  & input {
    height: 0;
    font-size: 1.2rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  }

  input:required:not(:valid):focus {
    border: 2px solid red;
  }

  input::placeholder {
    font-size: 1.2rem;
    text-align: left;
  }

  /* Firefox */
  input[type="number"] {
    font-size: 1.2rem;
    text-align: left;
  }


  @media screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    & .MuiOutlinedInput-input {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    input::placeholder {
      font-size: 1.2rem;
      text-align: left;
      padding-left: 20px;
    }
  }
`;
export { NTextField };
