import React, { useState, useEffect } from "react";
import "../../pages/Causas/Causas.css";
import ModalCausas from "../modal/Modal-Causas";
import data from "../../data/data.json";

import photo from "../../images/causas.jpg";
import parceiros from "../../images/logo-colorido.png";
// import parceiros from "../../images/UBRAFE.jpg";
import partners1 from "../../images/UBRAFE.png";
import partners2 from "../../images/RX.png";
import partners3 from "../../images/world-travel-market-wtm-vector-logo-removebg-preview.png";

import { Link } from "react-router-dom";
import { socialCauses } from "../../api/open";
import { NRoundedButton } from "../../shared-components/NRoundedButton";

const CausasAtuais = (props) => {
  const [clickedImg, setClickedImg] = useState(null);
  const [socialCause, setSocialCause] = useState([]);

  useEffect(() => {
    socialCauses().then((res) => {
      setSocialCause(res);
    });
  }, []);

  return (
    <div id="causas">
      <section className="content-banner">
        <div className="banner-inicial">
          <div className="banner-left">
            <h2>CAUSAS QUE O</h2>

            <h2 className="text-promo">WESTAND</h2>

            <h2>APOIA</h2>
            <a href="#">
              <NRoundedButton
                color={"#ff8400"}
                active={true}
                title={"COMO FUNCIONA"}
              />
            </a>
          </div>
          <div className="Container-text__banner">
            <p>
              Todo projeto e montagem realizado na nossa plataforma garante a
              oportunidade de sua empresa apoiar diretamente uma das causas
              cadastradas na plataforma Westand.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="mobile-container desk-container">
          <div className="causas-content">
            <div className="causas">
              {socialCause.map((cause) => (
                <div key={cause.id} className="evento">
                  <Link to={"/causas-individuais"}>
                    <img className="img-card" src={cause.image} />
                  </Link>
                  <div className="container-causa">
                    <div className="info-causas">
                      <h2>{cause.name}</h2>
                      {/* {cause.instituitions.map((instituition) => (
                        <p>{instituition.name}</p>
                      ))} */}
                    </div>
                    {/* <div className="img-causa">
                      {cause.icons.map((icon) => (
                        <img
                          key={icon.id}
                          className="causas-img_lateral"
                          src={icon.cardIcons}
                        />
                      ))}
                    </div> */}
                    <div className="cause-description">
                      <p style={{ marginBottom: "5rem" }}>
                        {cause.description}
                      </p>
                      <a href={cause.externalLink} target="_blank">
                        <NRoundedButton
                          color="#363636"
                          active={true}
                          title={"conheça mais"}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
              <div>
                {clickedImg && (
                  <ModalCausas
                    clickedImg={clickedImg}
                    setClickedImg={setClickedImg}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mobile-container desk-container content">
        <div className="Container-down">
          <div className="Container-left">
            <img src={photo} alt="photo" />
          </div>
          <div className="Container-right">
            <h3>WE STAND for a cause </h3>
            <p>
              Você sabia que é possível ajudar instituições que promovem
              transformações socioambientais simplesmente participando de feiras
              e eventos? É isso mesmo! WESTAND é um HUB ESG que conecta pessoas
              e recursos financeiros em prol da sustentabilidade.
            </p>
            <p>
              A ideia é apoiar projetos que elevam os índices de
              sustentabilidade de organizações e corporações. Bacana, né?
            </p>
          </div>
        </div>
      </section>

      <div className="parceiros_title">
        <p>
          CONHEÇA OS PARCEIROS <br /> DO WESTAND
        </p>{" "}
      </div>

      <div className="container_parceiros">
        <div className="parceiros-logo">
          <img
            src={partners1}
            alt="parceiro do Nosso Stand"
            style={{ width: "300px", height: "300px" }}
          />
          <img
            src={partners2}
            alt="parceiro do Nosso Stand"
            style={{ width: "200px", height: "125px" }}
          />
          <img
            src={partners3}
            alt="parceiro do Nosso Stand"
            style={{ width: "300px", height: "125px" }}
          />
        </div>
      </div>
    </div>
  );
};
export default CausasAtuais;
