import styled from "@emotion/styled"
import SideBarMontadorNovo from "../components/sidebar/SideBarMontadorNovo"
export default function MontadoraLayoutNovo({ children, sideBarOff }) {

  return (
    <Wrapper sideBarOff={sideBarOff}>
      <SideBarMontadorNovo sideBarOff={sideBarOff} />
      <main>
        {children}
      </main>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #F5F5F9;
  height: 100vh;
  
  & > main {
    background-color: #F5F5F9;
    margin-left: ${({ sideBarOff }) => !sideBarOff && "90px"};
    width: ${({ sideBarOff }) => !sideBarOff && "calc(100% - 90px)"};
    padding: 20px;
  }

  @media only screen and (max-width: 770px) {

   & > main {
     margin: 0;
     width: 100%;
   }
  }
`

