import { useState } from "react";
import { Link } from "react-router-dom";
import ExpoLogo from "../../../images/Frame-122.png";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import BuilderLogo from "../../../images/Frame-124.png";
import DesignerLogo from "../../../images/Frame-126.png";
import PromoterLogo from "../../../images/Frame-123.png";


function MenuAction({
  openMenu,
  handleCloseMenu,
  menuAnchor,
  handleOpenModalUser,
  usersId,
}) {

  return (
    <Menu
      id={`card=${usersId}`}
      key={`card=${usersId}`}
      anchorEl={menuAnchor}
      open={openMenu}
      onClose={handleCloseMenu}
      MenuListProps={{
        sx: {
          "& .MuiMenuItem-root": {
            fontSize: "1.6rem",
            fontWeight: "300",
          },
        },
      }}
    >
      <MenuItem onClick={() => {
        handleCloseMenu()
        handleOpenModalUser()
      }}>
        <Link to={`/criar-pagamento/${usersId}`}>
          Criar Pagamento
        </Link>
      </MenuItem>
    </Menu>
  )
}

function renderAvatar(profile) {

  if (profile.role === 'expositor') {
    return <Avatar alt="Remy Sharp" src={ExpoLogo} />
  }
  if (profile.role === 'montador') {
    return <Avatar alt="Remy Sharp" src={BuilderLogo} />
  }
  if (profile.role === 'projetista') {
    return <Avatar alt="Remy Sharp" src={DesignerLogo} />
  }
  if (profile.role === 'promotor') {
    return <Avatar alt="Remy Sharp" src={PromoterLogo} />
  }
}

const CardProfile = ({
  profile,
  handleAccept
}) => {

  const [openMenu, setOpenMenu] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  function handleOpenMenu(event) {
    setMenuAnchor(event.currentTarget)
    setOpenMenu(true);
  }

  function handleCloseMenu() {
    setOpenMenu(false);
  }

  return (
    <div className="options-profile1" key={profile.id}>
      <div className="div-name1">
        <h3 style={{ marginTop: "15px", color: "black" }}>
          {profile.email}
        </h3>
        <MoreVertSharpIcon onClick={handleOpenMenu} />
        <MenuAction
          key={profile.id}
          openMenu={openMenu}
          handleCloseMenu={handleCloseMenu}
          menuAnchor={menuAnchor}
          usersId={profile.id}
        />
      </div>
      <div className="rating-negoc1"></div>
      <div className="avatar">
        <div>
          <Stack direction="row" gap={1}>
            <span style={{ color: "black", fontWeight: "bold" }}>
              Nome: {' '}
            </span>
            {`${profile.Person?.firstName || 'N/D'} ${profile.Person?.lastName || ''}`}
          </Stack>
          <Stack direction="row" gap={1}>
            <span style={{ color: "black", fontWeight: "bold" }}>
              Nome Fantasia: {' '}
            </span>
            {profile.Person?.nomeFantasia || 'N/D'}
          </Stack>
          <Stack direction="row" gap={1}>
            <span style={{ color: "black", fontWeight: "bold" }}>
              Telefone: {' '}
            </span>
            {profile.Person?.phone || 'N/D'}
          </Stack>
          <Stack direction="row" gap={1}>
            <span style={{ color: "black", fontWeight: "bold" }}>
              Cidade: {' '}
            </span>
            {profile.Person?.city || 'N/D'}
          </Stack>
          <Stack direction="row" gap={1} width={'90%'}>
            <span style={{ color: "black", fontWeight: "bold" }}>
              Endereço: {' '}
            </span>
            {profile.Person?.address || 'N/D'}
          </Stack>
        </div>
        <Stack direction="row" spacing={2}>
          {renderAvatar(profile)}
        </Stack>
      </div>

      <div className="btns-contract">
        <button
          className="btn-aceitar"
          onClick={() => handleAccept(profile.id, "approved")}
        >
          aceitar
        </button>
        <button
          className="btn-n-aceitar"
          onClick={() => handleAccept(profile.id, "rejected")}
        >
          não aceitar
        </button>
      </div>
    </div>
  )
}

export {
  CardProfile,
}