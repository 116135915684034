import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import ExpoLogo from "../../../images/Frame-126.png";
import YellowCircle from "../../../images/yellow-circle.png";
import SideBarPojetista from "../../sidebar/SideBarProjetista";
import { listOpenBriefings } from "../../../api/briefing";
import { ContentWrapper, ActionButton } from "../Styles";
import CardBriefing from "./CardBriefing";
import Loading from "../../../shared-components/Loading";

const EmAberto = (props) => {

  const { data, isLoading } = useQuery('designer-briefins', listOpenBriefings);

  if (isLoading) {
    return (<Loading role={"PROJETISTA"} />)
  }


  return (
    <div className="cont-geral">
      <SideBarPojetista />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="200px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>Hey </span>
              <span style={{ color: "#FDC22A" }}>projetista</span>
              <span style={{ color: "black" }}>
                , veja <br /> os projetos aqui
              </span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={YellowCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <ActionButton>
        <Link to="">
          <button className="btn-open2">solicitações em aberto</button>
        </Link>
        <Link to="/solicit-negociacao-projetista">
          <button className="btn-open-mont">solicitações em andamento</button>
        </Link>
        <Link to="/solicit-aprovado-projetista">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </ActionButton>

      <ContentWrapper>
        {data?.map(briefing => (
          <CardBriefing
            key={briefing.briefingId}
            briefingData={briefing}
          />
        ))}
      </ContentWrapper>

    </div >
  );
};

export default EmAberto;
