import LinearStepper from "../../components/briefing/LinearStepper";
import { CssBaseline, Container, Paper, Box } from "@mui/material";
import { Navigation } from "../../components/home/Navigation";
import Footer from "../../components/home/Footer";
import "./Briefing.css";

function Briefing() {
  return (
    <>
      <CssBaseline />
      <Navigation />
      <Container className="paper" style={{
        marginTop: '100px'
      }}>
        <LinearStepper />
      </Container>
      <Footer />
    </>
  );
}

export default Briefing;
