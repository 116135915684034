import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../components/privateRoute/PrivateRoutes";
import ContratoAprovado from "../components/DashboardExpositorComponents/contratoAprovado/ContratoAprovado";
import Conversas from "../components/DashboardExpositorComponents/conversas/Conversas";


import Montadoras from "../components/DashboardExpositorComponents/empresasMontadoras/EmpresasMontadoras";
import Intercorrencias from "../components/DashboardExpositorComponents/intercorrencias/Intercorrencias";
import Montagem from "../components/DashboardExpositorComponents/montagem/Montagem";
import Gestor from "../components/DashboardExpositorComponents/fluxProjetoEmontagem/Gestor";
import SolicitacaoPlataforma from "../components/DashboardExpositorComponents/solicitacaoPlataforma/SolicitPlataforma";
import SolicitacaoEmAberto from "../components/DashboardExpositorComponents/solicitacaoPlataforma/SolicitEmAberto";
import SolicitacaoEmNegociacao from "../components/DashboardExpositorComponents/solicitacaoPlataforma/SolicitEmNegoc";
import SolicitAprovada from "../components/DashboardExpositorComponents/solicitacaoPlataforma/SolicitAprovada";
import DashboardExpositor from "../pages/Dashboard/DashboardExpositor";
import InformacoesPessoais from "../components/DashboardExpositorComponents/editProfile/InformacoesPessoais";
import DadosEmpresa from "../components/DashboardExpositorComponents/editProfile/DadosEmpresa";
import EntrarSeguranca from "../components/DashboardExpositorComponents/editProfile/EntrarSeguranca";
import ExpositorLayout from "../layouts/ExpositortLayout";
import SolicitEmAbertoDetails from "../components/DashboardExpositorComponents/solicitacaoPlataforma/SolicitEmAbertoDetails";
import { FragmentSecurity } from "../components/dashboardProfile/fragments/FragmentSecurity";
import { ExpositorProfile } from "../components/dashboardProfile/ExpositorProfile";
import { ExpositorProfileForm } from "../components/dashboardProfile/ExpositorProfileForm";
import { FragmentProfileForm } from "../components/dashboardProfile/fragments/FragmentProfileForm";
import { FragmentPersonForm } from "../components/dashboardProfile/fragments/FragmentPersonForm";
import { FragmentEnterpriseForm } from "../components/dashboardProfile/fragments/FragmentEnterpriseForm";
import BriefingManager from "../components/BriefingManager/BriefingManager";
import ListExpositor from "../components/DashboardExpositorComponents/solicitacaoPlataforma/ListExpositor";
import ListBriefing from "../pages/ListBriefing/ListBriefing";
import EditBriefing from "../pages/EditBriefing/EditBriefing";
import FragmentBriefingOpen from "../components/DashboardExpositorComponents/solicitacaoPlataforma/FragmentBriefingOpen";
import Briefing from "../pages/Briefing/Briefing";
import NovoBriefing from "../pages/Briefing/NovoBriefing";
import ListBriefingNovo from "../pages/ListBriefingNovo/ListBriefingNovo";
import TermsOfService from "../pages/TermosDeUso/TermsOfService";
import ExpositorLayoutNovo from "../layouts/ExpositorLayoutNovo";
import FragmentBriefingNegoc from "../components/DashboardExpositorComponents/solicitacaoPlataforma/FragmentBriefingNegoc";
import { BriefingRequestWestand } from "../pages/Briefing/expositor/BriefingRequestWestand";
import BriefingManagerNovo from "../pages/BriefingManagerNovo/BriefingManagerNovo";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route key="expositor-page" element={<PrivateRoutes role="EXPOSITOR" />}>,

    <Route
      path="/expositor/briefing/:briefingId"
      element={
        <ExpositorLayoutNovo>
          <EditBriefing />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="expositor-briefing-card"
    />,
    <Route
      path="/expositor/gestor/:briefingId"
      element={
        <ExpositorLayoutNovo>
          <Gestor />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="expositor-gestor"
    />,
    <Route
      path="/expositor/edit/:briefingId"
      element={
        <ExpositorLayoutNovo>
          <FragmentBriefingNegoc />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="expositor-edit-briefing"
    />,
    <Route
      path="/expositor/briefing-request-westand/:briefingId"
      element={
        <ExpositorLayoutNovo>
          <BriefingRequestWestand />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="expositor-briefing-request"
    />
    <Route
      path="/expositor/edit-briefing/:briefingId"
      element={
        <ExpositorLayout>
          <EditBriefing />
        </ExpositorLayout>
      }
      exact
      strict key="montador-list-briefings"
    />
    <Route
      path="/contrato-expositor"
      element={< ContratoAprovado />}
      exact
      strict key="contrato-expositor"
    />,
    <Route path="/conversas-expositor" element={< Conversas />} exact strict key="conversas-expositor" />,
    <Route
      path="/perfil-expositor"
      element={
        <ExpositorLayoutNovo>
          <ExpositorProfile />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="perfil-expositor"
    />,
    <Route
      path="/edit-pagina-expositor"
      element={
        <ExpositorLayoutNovo>
          <ExpositorProfileForm
            title={<span>Hey <strong>Expositor</strong>, edite seu cadastro aqui</span>}>
            <FragmentProfileForm color="#EA8011" />
          </ExpositorProfileForm>
        </ExpositorLayoutNovo>
      }
      exact
      strict key="edit-pagina-expositor"
    />,
    <Route
      path="/informacoes-pessoais"
      element={
        <ExpositorLayoutNovo>
          <ExpositorProfileForm
            title={<span>Hey <strong>Expositor</strong>, edite seu cadastro aqui</span>}>
            <FragmentPersonForm color="#EA8011" />
          </ExpositorProfileForm>
        </ExpositorLayoutNovo>
      }
      exact
      strict key="informacoes-pessoais"
    />,
    <Route path="/montadoras" element={< Montadoras />} exact strict key="montadoras" />,
    <Route
      path="/intercorrencias-expositor"
      element={< Intercorrencias />}
      exact
      strict key="intercorrencias-expositor"
    />,
    <Route path="/montagem" element={< Montagem />} exact strict key="montagem" />,
    <Route
      path="/expositor/gestor/:briefingId"
      element={
        <ExpositorLayoutNovo>
          <Gestor />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="gestor"
    />,
    <Route
      path="/solicitacao-plataforma"
      element={
        <ExpositorLayout>
          < SolicitacaoPlataforma />
        </ExpositorLayout>
      }
      exact
      strict key="solicitacao-plataforma"
    />,
    {/* <Route
      path="/solicit-aberto-expositor"
      element={
        <ExpositorLayout>
          <SolicitacaoEmAberto />
        </ExpositorLayout>}
      exact
      strict key="solicit-aberto-expositor"
    />, */}
    <Route
      path="/solicit-aberto-expositor/:briefingId"
      element={
        <ExpositorLayout>
          <SolicitEmAbertoDetails />
        </ExpositorLayout>}
      exact
      strict key="solicit-aberto-expositor-briefingId"
    />,
    <Route
      path="/solicit-negociacao-expositor"
      element={
        <ExpositorLayout>
          <SolicitacaoEmNegociacao />
        </ExpositorLayout>}
      exact
      strict key="solicit-negociacao-expositor"
    />,
    < Route
      path="/solicit-aprovada"
      element={
        <ExpositorLayout>
          <SolicitAprovada />
        </ExpositorLayout>
      }
      exact
      strict key="solicit-aprovada"
    />,
    <Route
      path="/expositor/dados-empresa"
      element={
        <ExpositorLayoutNovo>
          <ExpositorProfileForm
            title={<span>Hey <strong>Expositor</strong>, edite seu cadastro aqui</span>}>
            <FragmentEnterpriseForm color="#EA8011" />
          </ExpositorProfileForm>
        </ExpositorLayoutNovo>
      }
      exact
      strict key="dados-empresa"
    />,
    <Route
      path="/entrar-seguranca"
      element={
        <ExpositorLayoutNovo>
          <ExpositorProfileForm
            title={<span>Hey <strong>Expositor</strong>, edite seu cadastro aqui</span>}>
            <FragmentSecurity color="#EA8011" />
          </ExpositorProfileForm>
        </ExpositorLayoutNovo>
      }
      exact
      strict key="entrar-seguranca"
    />,
    <Route
      path="/expositor/novo-briefing"
      element={
        <ExpositorLayoutNovo>
          <NovoBriefing />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="dashboard-expositor"
    />,
    <Route path="/expositor-novo/projeto&montagem" element={<ExpositorLayoutNovo>
      <BriefingManagerNovo />
    </ExpositorLayoutNovo>} exact strict key="projeto&montagem" />
    <Route
      path="/expositor/lista"
      element={
        <ExpositorLayoutNovo>
          <ListBriefing />
        </ExpositorLayoutNovo>
      }
      exact
      strict key="expositor-list-briefing"
    />,
    <Route
      path="/expositor/termos-de-uso"
      element={
        <ExpositorLayout sideBarOff>
          <TermsOfService />
        </ExpositorLayout>
      }
      exact
      strict key="termos-de-uso"
    />


    {/* <Route
      path="/expositor/projeto&montagem"
      element={<ExpositorLayoutNovo>
        <BriefingManagerNovo />
      </ExpositorLayoutNovo>}
      exact
      strict
      key="projeto&montagem" /> */}
  </Route>

]