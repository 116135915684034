import { useUser } from "../../context/UserContext";
import styled from "styled-components";
import { NRoundedButton } from "../../shared-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DialogTerms from "./components/DialogTerms";
import useNotification from "antd/es/notification/useNotification";
import { useQuery } from "react-query";
import { checkRole } from "../../api/login";
import Loading from "../../shared-components/Loading";
import { ROLE } from "../../helpers/types";

export default function TermsOfService() {
  const [filledPersonInfo, setFilledPersonInfo] = useState(false);
  const [filledEnterpriseForm, setFilledEnterpriseForm] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);
  const [moveOn, setMoveOn] = useState(false);

  const { data, isLoading, refetch } = useQuery("terms-of-service", checkRole,
  {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  })
  const navigate = useNavigate();
  const { userData, saveUserData } = useUser();

  function showTerms() {
    setModalTerms(true)
  }

  function closeTermsModal() {
    setModalTerms(false);
  }

  function handleOpenPersonalPage() {


    if (userData?.role?.name === ROLE.MONTADOR) {
      console.log(userData?.role)
      return navigate("/informacoes-pessoais-montador")

    } else {
      return navigate("/informacoes-pessoais")
    }


  }

  const isFilledPersonInfo = userData => {
    const required = [
      "document",
      "firstName",
      "lastName"
    ];

    for (let i = 0; i < required.length; i++) {
      if (!userData?.[required[i]]) {
        return false
      }
    }
    return true;
  }

  const isFilledEnterpriseInfo = userData => {
    const required = [
      "cnpj",
      "razaoSocial",
      // "street",
      // "number",
      // "neighborhood",
      "cep",
      "city",
      "state"
    ];

    for (let i = 0; i < required.length; i++) {
      if (!userData?.[required[i]]) {
        return false
      }
    }
    return true;
  }

  const isAcceptedTerms = userData => userData?.User?.Approval?.terms?.status === "approved";

  useEffect(() => {
    if (isFilledPersonInfo(data)) setFilledPersonInfo(true);
    if (isFilledEnterpriseInfo(data)) setFilledEnterpriseForm(true);
    if (isAcceptedTerms(data)) setAcceptedTerms(true);
  }, [data]);

  useEffect(() => {
    const isAllSet = (filledEnterpriseForm && filledPersonInfo && acceptedTerms) || (acceptedTerms && userData.role.name === "PROJETISTA")
    if (isAllSet) {
      refetch().then(res => {
        saveUserData(res.data);
        setMoveOn(true);
      });
    }
  }, [filledEnterpriseForm, filledPersonInfo, acceptedTerms])

  if (isLoading) {
    return <Loading role={userData?.role?.name} />
  }

  return (
    <Wrapper>
      <DialogTerms
        open={modalTerms}
        handleClose={closeTermsModal}
        title={"Termos de uso"}
        userData={userData}
        setAcceptedTerms={setAcceptedTerms}
      />
      <Title>
        <h2 className="reg-feira">Olá, atualizamos nossos <span style={{ color: "#FF8400" }}>Termos de Uso</span>! Para seguir cumpra os requisitos.</h2>
      </Title>
      <Content>
        <ul>
          {
            data?.role?.name !== "PROJETISTA" &&
            <>
              <li>
                Preencha corretamente seus dados pessoais
                {
                  filledPersonInfo ?
                    <span style={{
                      color: "#22da28",
                      fontWeight: "bold",
                      background: "#f2f2f2",
                      padding: "1rem 0.5rem",
                      borderRadius: '5px',
                      fontSize: "1.5rem",
                      marginLeft: "15px"
                    }}>Preenchido</span>
                    :
                    <NRoundedButton title={"Ir"} active={true} onClick={handleOpenPersonalPage} />
                }
              </li>
              <li>
                Preencha corretamente os dados da sua empresa
                {
                  filledEnterpriseForm ?
                    <span style={{
                      color: "#22da28",
                      fontWeight: "bold",
                      background: "#f2f2f2",
                      padding: "1rem 0.5rem",
                      borderRadius: '5px',
                      fontSize: "1.5rem",
                      marginLeft: "15px"
                    }}>Preenchido</span>
                    :
                    <NRoundedButton title={"Ir"} active={true} onClick={() => navigate(`/${userData?.role?.name.toLowerCase()}/dados-empresa`)} />
                }
              </li>
            </>
          }
          <li>
            Aceite os novos termos e condições de uso da plataforma
            {
              acceptedTerms ?
                <span style={{
                  color: "#22da28",
                  fontWeight: "bold",
                  background: "#f2f2f2",
                  padding: "1rem 0.5rem",
                  borderRadius: '5px',
                  fontSize: "1.5rem",
                  marginLeft: "15px"
                }}>Aceito</span>
                :
                <NRoundedButton title={"Visualizar"} active={true} onClick={showTerms} />
            }
          </li>
          {
            data?.role?.name !== "PROJETISTA" && data?.role?.name !== "MONTADOR" ?
              moveOn &&
              <li>
                Pronto! Agora já pode seguir para a plataforma
                <NRoundedButton title={"Seguir"} active={true} onClick={() => navigate(`/${userData?.role?.name.toLowerCase()}/lista`)} />
              </li>
              :
              moveOn &&
              <li>
                Pronto! Agora já pode seguir para a plataforma
                <NRoundedButton title={"Seguir"} active={true} onClick={() => navigate(`/${userData?.role?.name.toLowerCase()}/lista`)} />
              </li>
          }
        </ul>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
`

const Content = styled.div`
  width: 100%;
  height: 78%;
  margin-top: 1rem;
  padding: 0 2rem;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    min-height: 40px;
    min-width: 50px;
    margin-left: 15px;
  }

  li {
    font-weight: 600;
    margin-bottom: 40px;
    font-size: 2rem;
  }
`

const Title = styled.div`
  width: 100%;
  padding: 0 30px 10px;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);

  > h2 {
    font-size: 4rem;
  }
`