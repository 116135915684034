import { useState } from "react";
import "./PasswordStyle.css";
import { resetPassword } from "../../api/user";
import { Navigation } from "../../components/home/Navigation";
import { useNotification } from "../../context/NotificationContext";
import { useNavigate } from "react-router-dom";
const ForgetPassword = () => {

  const [email, setEmail] = useState('');
  const notification = useNotification();
  const navigate = useNavigate();

  function handleClick() {
    resetPassword(email)
      .then(res => {
        notification.show({
          message: 'Sua nova senha foi enviada para seu email'
        })
        navigate('/login')
      })
      .catch(err => {
        notification.show({
          message: err.message || 'Houve um erro no reset',
          type: 'error'
        })
      })
  }


  return (
    <div className="resetarSenhaDiv">
      <Navigation />
      <div className="frameDiv">
        <div className="eSQUECIASENHASENHA">
          <span>ESQUECI A</span>
          <span style={{ color: "orange" }}> SENHA</span>

        </div>
        <div className="areas-field">
          <input
            placeholder="Digite o seu e-mail"
            className="input-email"
            type="email"
            required
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            onClick={handleClick}
            className="btn-pass">
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
