import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { StepFormProvider } from "./StepFormContext";
import ProjetoEmontagem from "./ProjetoeMontagem";
import { getBriefingManager, getMemorial } from "../../../api/gestor";
import { getBriefing } from "../../../api/briefing";
import Loading from "../../../shared-components/Loading";
import { Error } from "../../../shared-components";

export default function Gestor() {
  const params = useParams();

  let {
    data,
    isLoading,
    error
  } = useQuery(`gestor-briefing-${params.briefingId}`, () => getBriefingManager(params.briefingId));

  let {
    data: dataBriefing,
    isLoading: isLoadingBriefing,
    error: isErrorBriefing
  } = useQuery(`briefing-${params.briefingId}`, () => getBriefing(params.briefingId))

  let {
    data: dataMemorial,
    isLoading: isLoadingMemorial,
    error: isErrorMemorial,
    refetch: refetchMemorial
  } = useQuery(
    `briefing-${params.briefingId}-memorial`,
    () => getMemorial(params.briefingId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  )

  if (isLoading || isLoadingBriefing || isLoadingMemorial) {
    return (<Loading role={"EXPOSITOR"} />)
  }

  if (error || isErrorBriefing || isErrorMemorial) {
    return (<Error />)
  }

  return (
    <StepFormProvider initState={data}>
      <ProjetoEmontagem
        dataBriefing={dataBriefing}
        dataMemorial={dataMemorial}
        refetchMemorial={refetchMemorial}
      />
    </StepFormProvider>
  )
}