import { useState } from "react";

export default function useFilterTable(data) {
  const [filter, setFilter] = useState("all");
  const [filterType, setFilterType] = useState("projeto");
  const [searchTerm, setSearchTerm] = useState("");
  

  const handleFilter = (selectFilter) => {
    setFilter(selectFilter);
  };

  const handleFilterType = (selectType) => {
    setFilterType(selectType);
  };


  //TODO: OPEN
  const filteredBriefingsOpen = data.open.briefings ?
    data?.open?.briefings?.filter((briefing) => {

      const owner = briefing?.User?.Builders[0]?.name
        || briefing?.User?.Exhibitors[0]?.name
        || briefing?.User?.Promoter[0]?.name || '';

      if (filter === "all") {
        return briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase())
      }

      return briefing.Briefing.status === filter &&
        (briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()))
    }) || []
    :
    data?.open?.filter((briefing) => {

      const owner = briefing?.User?.Builders[0]?.name
        || briefing?.User?.Exhibitors[0]?.name
        || briefing?.User?.Promoter[0]?.name || '';

      if (filter === "all") {
        return briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner?.toLowerCase().includes(searchTerm?.toLowerCase())
      }

      return (briefing?.Briefing?.status === filter || briefing.status === filter) &&
      (briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
       briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner.toLowerCase().includes(searchTerm.toLowerCase()))
    }) || [];


  //TODO: EM NEGOCIACAO
  const filteredBriefingsRequest = data?.request?.filter((briefing) => {

    const owner = briefing?.User?.Builders[0]?.name
      || briefing?.User?.Exhibitors[0]?.name
      || briefing?.User?.Promoter[0]?.name || ''
      
      
    
    if (filter === "all") {
      return briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner?.toLowerCase().includes(searchTerm?.toLowerCase())
    }

    return briefing.Briefing.status === filter &&
      (briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
       briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner.toLowerCase().includes(searchTerm.toLowerCase()))
  }) || [];

  //TODO: ACEITO
  const filteredBriefingsAccept = data?.accept?.filter((briefing) => {

     const owner = briefing?.User?.Builders[0]?.name
      || briefing?.User?.Exhibitors[0]?.name
      || briefing?.User?.Promoter[0]?.name || '';
    
    if (filter === "all") {
      return briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner?.toLowerCase().includes(searchTerm?.toLowerCase())
    }
    return briefing.status === filter &&
      (briefing?.Briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
       briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.Briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner.toLowerCase().includes(searchTerm.toLowerCase()))
  }) || [];


  //TODO merge
  const filteredBriefings = [
    ...filteredBriefingsOpen,
    ...filteredBriefingsRequest,
    ...filteredBriefingsAccept]

  return {
    handleFilter,
    handleFilterType,
    searchTerm,
    setSearchTerm,
    filteredBriefings,
    filter,
    filterType,
    setFilter
  }
};