import React from 'react'
import { Wrapper } from './Styles';
import styled from "@emotion/styled";
import { NRoundedButton } from '../../../../shared-components';
import { useQuery } from 'react-query';
import { getContract, sendContract } from '../../../../api/gestor';
import Loading from '../../../../shared-components/Loading';
import { useParams } from 'react-router-dom';
import { useNotification } from '../../../../context/NotificationContext';
import CardFatura from '../components/CardFatura';
import AlertComponent from '../../../../shared-components/AlertComponent';
import jsPDF from 'jspdf';


const Contrato = () => {
  const { briefingId } = useParams()
  const { data, isLoading, error, refetch } = useQuery(`contract-${briefingId}`, () => getContract(briefingId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    });
  const notification = useNotification();

  if (isLoading) {
    return <Loading role="EXPOSITOR" />;
  }

  const handleDownloadPDF = () => {
    try {
      const doc = new jsPDF()
      doc.html(document.querySelector('#contract'), {
        callback: function (pdf) {
          pdf.save('Contrato.pdf')
        },
        x: 19,
        y: 12,
        html2canvas: { scale: 0.25 }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleApprovedContract = async () => {
    try {
      const status = "approved";
      const res = await sendContract(briefingId, status)
      console.log(res);
      notification.show({
        message: "Contrato aprovado"
      });
      await refetch();
    } catch (error) {
      notification.show({
        message: 'Houve um erro ao aprovar o contrato',
        type: 'error'
      });
    }
  };

  if (data.message === "As informações do expositor estão incompletas") {
    return (
      <AlertComponent info={"Suas informações estão incompletas, verifique se preencheu seus dados corretamente"} />
    )
  }

  if (data.message === "As informações do montador estão incompletas") {
    return (
      <AlertComponent info={"O contrato está sendo gerado"} />
    )
  }

  return (
    <Wrapper style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <h2 className="reg-feira">Contrato</h2>
      <Content>
        <div className='contract' id='contract' dangerouslySetInnerHTML={{ __html: data.contract }} />
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "2rem"
        }}>
          {
            data.status === "approved" ?
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '2rem 2rem',
              }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <button onClick={handleDownloadPDF} style={{
                    border: 'none',
                    background: 'red',
                    color: 'white',
                    width: '100%',
                    fontWeight: '600',
                    borderRadius: '20px',
                  }}>Baixar PDF</button>
                  <span style={{
                    color: "#22da28",
                    fontWeight: "bold",
                    background: "#f2f2f2",
                    textAlign: "center",
                    padding: '.5rem',
                    borderRadius: '5px',
                  }}>Contrato aprovado</span>
                </div>
              </div>
              :
              data.status === "disapproved" ?
                <div>
                  <span style={{
                    color: "#ff0000",
                    fontWeight: "bold",
                    background: "#f2f2f2",
                    padding: "2rem 1rem",
                    borderRadius: '5px',
                  }}>Contrato Reprovado</span>
                </div>
                :
                <ActionButtons>
                  {/* <Button color={"#adb5bd"} onClick={handleClose}>Fechar</Button> */}
                  <div style={{ display: 'flex' }}>
                    {/* <Button color={"#adb5bd"} onClick={handleDisapproved}>Reprovar</Button> */}

                    <button onClick={handleDownloadPDF} style={{
                      border: 'none',
                      background: 'red',
                      color: 'white',
                      width: '100%',
                      marginRight: '10px',
                      padding: '0rem 2rem',
                      fontWeight: '600',
                      borderRadius: '20px',
                    }}>Baixar PDF</button>
                    <NRoundedButton title={"Li e concordo"} active={true} onClick={handleApprovedContract} />
                  </div>
                </ActionButtons>
          }
        </div>
      </Content>
      {/* <div style={{ display: "none" }}>
        {data.status === "pending" && (
          <CardFatura status={data.status} />
        )}
      </div> */}
    </Wrapper >
  )
}

const Content = styled.div`
  width: 60%;
  height: 50vh;
  margin-top: 5rem;
  overflow-y: scroll;
  padding: 0 2rem;

  img {
    width: 500px;
  }

  > div {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding-top: 1rem;
  }

  .contract {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    & > p {
      padding: 1rem 0;
    }
  
  }


`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`

export default Contrato