import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "@emotion/styled";
import "../style/styleSolicitPlataforma.css";
import { listRequestBriefings } from "../../../api/briefing";
import FragmentBriefingBudget from "./FragmentBriefingBudget";
import Header from "../Header";
import { FragmentActionsLink } from "./fragments/FragmentActionLinks";
import Loading from "../../../shared-components/Loading";
const SolicitEmNegociacao = ({ flag }, props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    listRequestBriefings()
      .then((response) => {
        if (flag) {
          setData(
            response?.filter(
              (element) => element.finalPriceMax && element.finalPriceMin
            )
          );
        } else {
          setData(
            response?.filter(
              (element) =>
                !element.finalPriceMax &&
                !element.finalPriceMin &&
                !element.deliveryAt
            )
          );
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }, [flag]);

  return (
    <>
      <Header title={"Solicitações em Negocição"} />
      <FragmentActionsLink
        clicked={
          flag ? "em negociação" : "em negociação"
        }
      />
      <ContentWrapper>
        {data ?
          data.map((value) => (
            <FragmentBriefingBudget
              key={`briefing-${value.briefingId}`}
              {...props}
              briefingRequest={value}
            />
          )) : (<>
            <Loading role="MONTADOR" />
          </>)}
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default SolicitEmNegociacao;
