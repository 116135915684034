import { useCallback } from "react";
import { MenuItem } from "@mui/material";
import { DashInput, DashSelect, NTextField } from "../../../../../shared-components";
import { FieldBlock, Title, Row } from "./StyledComponents";
import {
  ELEMENTS_NAME,
  ALTURA_STAND_ITEMS,
  ALTURA_PISO_ITEMS,
  TIPO_PISO_ITEMS,
  TIPO_TESTEIRA_ITEMS,
  TIPO_MEZANINO_ITEMS,
} from "./FormsTypes";
import {
  getValueFromType,
  getValueFromTypeArray,
  getValueObservation,
} from "./formHelper";

export default function FragmentElements({
  elements,
  setStateForm,
  stateForm,
}) {
  const handleElementtState = useCallback(({ target: { name, value } }) => {
    const searchElement = elements.find((e) => e.type === name);
    if (!searchElement) {
      elements = [
        ...elements,
        {
          type: name,
          value,
        },
      ];
    } else {
      Object.values(elements).forEach((struct) => {
        if (struct.type === name) {
          struct.value = value;
        }
      });
    }

    setStateForm({
      ...stateForm,
      BriefingElements: elements,
    });
  });

  const handleElementtStateObs = useCallback(({ target: { name, value } }) => {
    name = name.replace("_observation", "");
    const searchElement = elements.find((e) => e.type === name);
    if (!searchElement) {
      elements = [
        ...elements,
        {
          type: name,
          observation: value,
        },
      ];
    } else {
      Object.values(elements).forEach((struct) => {
        if (struct.type === name) {
          struct.observation = value;
        }
      });
    }

    setStateForm({
      ...stateForm,
      BriefingElements: elements,
    });
  });

  return (
    <>
      <Title>Elementos</Title>
      <Row>
        <FieldBlock>
          <DashSelect
            id={ELEMENTS_NAME.ALTURA_STAND}
            name={ELEMENTS_NAME.ALTURA_STAND}
            key={ELEMENTS_NAME.ALTURA_STAND}
            value={getValueFromType(elements, ELEMENTS_NAME.ALTURA_STAND)}
            onChange={handleElementtState}
            fullWidth
            title={"Altura do stand (m)"}
          >
            {ALTURA_STAND_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${ELEMENTS_NAME.ALTURA_STAND}_observation`}
            name={`${ELEMENTS_NAME.ALTURA_STAND}_observation`}
            key={`${ELEMENTS_NAME.ALTURA_STAND}_observation`}
            value={getValueObservation(elements, ELEMENTS_NAME.ALTURA_STAND)}
            onChange={handleElementtStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={ELEMENTS_NAME.ALTURA_PISO}
            name={ELEMENTS_NAME.ALTURA_PISO}
            key={ELEMENTS_NAME.ALTURA_PISO}
            value={getValueFromType(elements, ELEMENTS_NAME.ALTURA_PISO)}
            onChange={handleElementtState}
            fullWidth
            title={"Altura do piso (cm)"}
          >
            {ALTURA_PISO_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${ELEMENTS_NAME.ALTURA_PISO}_observation`}
            name={`${ELEMENTS_NAME.ALTURA_PISO}_observation`}
            key={`${ELEMENTS_NAME.ALTURA_PISO}_observation`}
            value={getValueObservation(elements, ELEMENTS_NAME.ALTURA_PISO)}
            onChange={handleElementtStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={ELEMENTS_NAME.TIPO_PISO}
            name={ELEMENTS_NAME.TIPO_PISO}
            key={ELEMENTS_NAME.TIPO_PISO}
            value={getValueFromTypeArray(elements, ELEMENTS_NAME.TIPO_PISO)}
            onChange={handleElementtState}
            fullWidth
            multiple
            title={"Tipo do piso"}
          >
            {TIPO_PISO_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${ELEMENTS_NAME.TIPO_PISO}_observation`}
            name={`${ELEMENTS_NAME.TIPO_PISO}_observation`}
            key={`${ELEMENTS_NAME.TIPO_PISO}_observation`}
            value={getValueObservation(elements, ELEMENTS_NAME.TIPO_PISO)}
            onChange={handleElementtStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
      </Row>
      <Row>
        <FieldBlock>
          <DashSelect
            id={ELEMENTS_NAME.TESTEIRA}
            name={ELEMENTS_NAME.TESTEIRA}
            key={ELEMENTS_NAME.TESTEIRA}
            value={getValueFromType(elements, ELEMENTS_NAME.TESTEIRA)}
            onChange={handleElementtState}
            fullWidth
            title={"Testeira"}
          >
            {TIPO_TESTEIRA_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${ELEMENTS_NAME.TESTEIRA}_observation`}
            name={`${ELEMENTS_NAME.TESTEIRA}_observation`}
            key={`${ELEMENTS_NAME.TESTEIRA}_observation`}
            value={getValueObservation(elements, ELEMENTS_NAME.TESTEIRA)}
            onChange={handleElementtStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={ELEMENTS_NAME.MEZANINO}
            name={ELEMENTS_NAME.MEZANINO}
            key={ELEMENTS_NAME.MEZANINO}
            value={getValueFromType(elements, ELEMENTS_NAME.MEZANINO)}
            onChange={handleElementtState}
            fullWidth
            title={"Mezanino"}
          >
            {TIPO_MEZANINO_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${ELEMENTS_NAME.MEZANINO}_observation`}
            name={`${ELEMENTS_NAME.MEZANINO}_observation`}
            key={`${ELEMENTS_NAME.MEZANINO}_observation`}
            value={getValueObservation(elements, ELEMENTS_NAME.MEZANINO)}
            onChange={handleElementtStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashInput
            id={ELEMENTS_NAME.PONTOS_ELETRICOS}
            name={ELEMENTS_NAME.PONTOS_ELETRICOS}
            key={ELEMENTS_NAME.PONTOS_ELETRICOS}
            value={getValueFromType(elements, ELEMENTS_NAME.PONTOS_ELETRICOS)}
            onChange={handleElementtState}
            fullWidth
            title={"Pontos Elétricos (tomadas)"}
            placeholder="0"
            type="number"
          />
          <NTextField
            id={`${ELEMENTS_NAME.PONTOS_ELETRICOS}_observation`}
            name={`${ELEMENTS_NAME.PONTOS_ELETRICOS}_observation`}
            key={`${ELEMENTS_NAME.PONTOS_ELETRICOS}_observation`}
            value={getValueObservation(
              elements,
              ELEMENTS_NAME.PONTOS_ELETRICOS
            )}
            onChange={handleElementtStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
      </Row>
    </>
  );
}
