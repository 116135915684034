import {
  Wrapper,
} from "./Styles";
import "../../../../components/briefing/fireworks.css"

const Final = ({ step, setActiveStep }) => {



  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Tudo certo!</h2>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '40vh',
        fontSize: '2.4rem'
      }}>
        Agora é só aproveitar o seu evento!
      </div>
    </Wrapper>
  );
};


export { Final }