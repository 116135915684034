import styled from "@emotion/styled";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 4%;
  padding: 2% 0;

  > div {
    display: flex;
    flex-basis: 33%;
    flex-direction: column;
    gap: 10px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export {
  Row,
  FormWrapper,
};