import Header from "../DashboardExpositorComponents/Header"
export function ExpositorProfileForm({
  title,
  children,
}) {
  return (
    <>
      <Header title={title} />
      {children}
    </>
  )
}