import { DashTextArea, UploadFileDownload } from "../../../../shared-components";
import {
  Wrapper,
  Row,
  Column,
  Space,
  AvatarMontador,
  AvatarProjetista,
  AvatarExpositor
} from "./Styles";
import SwitchButton from "../switchButton/SwitchButton";
import { useStepActions } from "../useStepActions";
import { useStepForm } from "../StepFormContext";

const PreMontagem = () => {

  const { stateForm, setStateForm } = useStepForm();

  const {
    handleChecked,
    handleGetFile,
    isBuilder,
    isExhibitor,
  } = useStepActions();


  const montador = isBuilder();
  const expositor = isExhibitor();

  const handleChangeText = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setStateForm({
      ...stateForm,
      BriefingManagerUploads: {
        ...stateForm.BriefingManagerUploads,
        [name]: {
          ...stateForm.BriefingManagerUploads[name],
          observations: value,
        },
      },
    });
  };

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Cardeno de Arte e Arquivos</h2>
      </div>
      <Row>
        <Column>
          <span>Arquivos de pré-montagem</span>
          <Row>
            <AvatarMontador />
            <UploadFileDownload
              name="fotosPreMontagem1"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"40%"}
              link={stateForm?.BriefingManagerUploads?.fotosPreMontagem1?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="fotosPreMontagem1"
              checked={stateForm?.BriefingManagerUploads?.fotosPreMontagem1?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <DashTextArea
            id={`fotosPreMontagem1-observations`}
            value={stateForm?.BriefingManagerUploads?.fotosPreMontagem1?.observations || ''}
            onChange={handleChangeText}
            placeholder={"Caso necessário adicione aqui uma observação após o upload do arquivo"}
            name={"fotosPreMontagem1"}
            rows={6}
            disabled={!stateForm?.BriefingManagerUploads?.fotosPreMontagem1?.validated}
          />
        </Column>
        <Space />
        <Column>
          <span>Arquivos de pré-montagem</span>
          <Row>
            <AvatarMontador />
            <UploadFileDownload
              name="fotosPreMontagem2"
              tooltip={"Montador deve enviar o arquivo"}
              link={stateForm?.BriefingManagerUploads?.fotosPreMontagem2?.link}
              width={"40%"}
              getFile={handleGetFile}
              disabled={expositor}

            />
            <SwitchButton
              name="fotosPreMontagem2"
              checked={stateForm?.BriefingManagerUploads?.fotosPreMontagem2?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <DashTextArea
            key={`fotosPreMontagem2-observations`}
            value={stateForm?.BriefingManagerUploads?.fotosPreMontagem2?.observations || ''}
            onChange={handleChangeText}
            name={"fotosPreMontagem2"}
            placeholder={"Caso necessário adicione aqui uma observação após o upload do arquivo"}
            rows={6}
            disabled={!stateForm?.BriefingManagerUploads?.fotosPreMontagem2?.validated}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <span>Caderno de Arte</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="cadernoArte"
              tooltip={"Montador deve enviar o arquivo"}
              width={"40%"}
              link={stateForm?.BriefingManagerUploads?.cadernoArte?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="cadernoArte"
              checked={stateForm?.BriefingManagerUploads?.cadernoArte?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <DashTextArea
            key={`cadernoArte-observations`}
            value={stateForm?.BriefingManagerUploads?.cadernoArte?.observations || ''}
            onChange={handleChangeText}
            name={"cadernoArte"}
            placeholder={"Caso necessário adicione aqui uma observação após o upload do arquivo"}
            rows={6}
            disabled={!stateForm?.BriefingManagerUploads?.cadernoArte?.link}
          />
        </Column>
        <Space />
        <Column>
          <span>Artes</span>
          <Row>
            <AvatarExpositor />
            <UploadFileDownload
              name="artes"
              tooltip={"Expositor deve enviar o arquivo"}
              link={stateForm?.BriefingManagerUploads?.artes?.link}
              width={"40%"}
              getFile={handleGetFile}
            />
            <SwitchButton
              name="artes"
              checked={stateForm?.BriefingManagerUploads?.artes?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <DashTextArea
            key={`artes-observations`}
            value={stateForm?.BriefingManagerUploads?.artes?.observations || ''}
            onChange={handleChangeText}
            name={"artes"}
            placeholder={"Caso necessário adicione aqui uma observação após o upload do arquivo"}
            rows={6}
            disabled={!stateForm?.BriefingManagerUploads?.artes?.link}
          />
        </Column>
      </Row>
    </Wrapper>
  );
};


export { PreMontagem }