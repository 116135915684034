import styled from "@emotion/styled";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {
  generateImageLink
} from "../../../../helpers/generateImageLink"

export function FragmentProjects({
  briefingProject,
  handleClick
}) {
  const projectLink = generateImageLink(briefingProject)

  return (
    <ContainerReference>
      <span onClick={() => handleClick(projectLink)}>
        <ZoomInIcon />
      </span>
      {projectLink.type === 'pdf' ? (
        <object
          data={`${projectLink.image}?#scrollbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ overflow: 'hide' }}
        >
        </object>
      ) :
        (<img
          src={projectLink.image}
          alt=""
        />)
      }

    </ContainerReference>
  )
}



const ContainerReference = styled.div`
  flex-direction: column;
  position: relative;
  
  > span {
    position: absolute;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 1.4rem;
    z-index: 1;
    background-color: black;
    opacity: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.3;
    }

    & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      color: white;
      width: 50px;
      height: 50px;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 100%;
    margin: 10px 0;
  
    p {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  img {
      width: 100%;
      min-width: 250px;
      height: 240px;
      object-fit: cover;
      border-radius: 6px;
    }
`
const WrapperPrice = styled.div`
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  gap: 2px;
  padding: 2px;
  margin: 10px 0;

  > span {
    flex: 0.3;
    font-size: 1.7rem;
    align-items: center;
  }

  > input {
     background-color: #F6F6F6;
     height: 20px;
     font-size: 1.7rem;
  }
`