import {
  Wrapper,
  Row,
  Content,
  Alert,
} from "./Styles";

import { useStepForm } from "../StepFormContext";
import CardPayment from '../components/CardPayment';

function renderPaymentStatus(paymentInit) {
  if (paymentInit[0]?.status === "PAGO") {
    return (
      <Content>
        <Alert>Já recebemos o seu pagamento avance para a <strong>próximo etapa.</strong></Alert>
      </Content>
    )
  }
  return (
    <CardPayment
      price={paymentInit[0]?.value || "Ainda estamos processando o pagamento"}
      dueDate={paymentInit[0]?.dueDate || "Ainda estamos processando o pagamento"}
      paymentLink={paymentInit[0]?.invoiceUrl || ""}
    />
  )
}

const Step2 = () => {

  const {
    stateForm,
  } = useStepForm();

  const payments = stateForm?.BriefingRequest?.Briefing?.Payments;
  const paymentInit = payments?.filter(p => p.type === "briefing") || [];

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Pagamento Taxa de Segurança</h2>

      </div>
      <Row style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '2rem 0'
      }}>
        {renderPaymentStatus(paymentInit)}
      </Row>
    </Wrapper>
  );
};


export { Step2 }