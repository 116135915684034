import React from "react";
import styled from "@emotion/styled";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ExpoLogo from "../../../images/Frame-122.png";
import OrangeCircle from "../../../images/orange-circle.png";
import SideBarExpo1 from "../../../components/sidebar/SideBarExpo1";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import { DashInputPassword } from "../../../shared-components/DashInputPassword";

const EntrarSeguranca = () => {



  return (
    <div className="cont-geral">
      <SideBarExpo1 />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>Hey </span>
              <span style={{ color: "#FF8400" }}>Expositor</span>
              <span style={{ color: "black" }}>
                , edite seu <br /> cadastro aqui
              </span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={OrangeCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="expo1">
        <div className="expositor7">
          <div className="cont-inpus-page2">
            <div className="inputs-pagina">
              <WrapperForm>
                <DashInputPassword
                  title="Senha"
                />
                <DashInputPassword
                  title="Confirmar Senha"
                />
              </WrapperForm>

              <div className="device-dispositives">
                <h2 className="title-social">Histórico de dispositivos</h2>
                <div className="devices">
                  <div className="list-devices">
                    <DnsOutlinedIcon style={{ marginRight: "20px" }} />
                    <p>Windows 10 - Recife, Pernambuco</p>
                  </div>
                  <a href="/" alt="" style={{ color: "blue" }}>
                    sair do dispositivo
                  </a>
                </div>
                <div className="devices">
                  <div className="list-devices">
                    <DnsOutlinedIcon style={{ marginRight: "20px" }} />
                    <p>Windows 10 - Recife, Pernambuco</p>
                  </div>
                  <a href="/" alt="" style={{ color: "blue" }}>
                    sair do dispositivo
                  </a>
                </div>
                <div className="devices">
                  <div className="list-devices">
                    <DnsOutlinedIcon style={{ marginRight: "20px" }} />
                    <p>Windows 10 - Recife, Pernambuco</p>
                  </div>
                  <a href="/" alt="" style={{ color: "blue" }}>
                    sair do dispositivo
                  </a>
                </div>
              </div>
              <div className="share-infos2">
                <VisibilityOffOutlinedIcon
                  style={{
                    color: "black",
                    width: "50px",
                    height: "50px",
                    marginTop: "20px",
                  }}
                />
                <p className="text-info">
                  Que informações são compartilhadas com outras pessoas? <br />
                  <br /> A Westand só disponibiliza informações de contato a
                  expositores e promotores depois da proposta ser confirmada.
                </p>
              </div>
              <div className="btns-register">
                <button className="btn-picture">Gravar</button>
                <button className="btn-remove">Cancelar</button>
              </div>
            </div>
            <div className="share-infos personal-info">
              <VisibilityOffOutlinedIcon
                style={{
                  color: "black",
                  width: "50px",
                  height: "50px",
                  marginTop: "20px",
                }}
              />
              <p className="text-info">
                Que informações são compartilhadas com outras pessoas? <br />
                <br /> A Westand só disponibiliza informações de contato a
                expositores e promotores depois da proposta ser confirmada.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  gap: 10px;

`

export default EntrarSeguranca;
