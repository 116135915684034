import React from "react";
import { Link } from "react-router-dom";
import { IconNameWrapper, IconWrapper, Name } from './sideBarExpoStyle';

const SideBarMenuItem = ({ icon, name, path, isSelected, isOpen }) => {
  return (
    <Link to={path}>
      <IconNameWrapper isSelected={isSelected}>
        <IconWrapper>
          {icon}
        </IconWrapper>
        {isOpen ? (
          <Name isSelected={isSelected}>{name}</Name>
        ) : (
          <></>
        )}
      </IconNameWrapper>
    </Link>
  )
};

export default SideBarMenuItem;