import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import OrangeCircle from "../../../images/orange-circle.png";
import ExpoLogo from "../../../images/Frame-122.png";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import SideBarExpo1 from "../../../components/sidebar/SideBarExpo1";
import "./styleSolicitPlataforma.css";
import Header from "../Header";
import { NRoundedButton } from "../../../shared-components";
import { ActionButton } from "./Styles";

const SolicitPlataforma = () => {
  return (
    <>
      <Header title="Solicitações em aberto" />

      <ActionButton>
        <Link to="/solicit-aberto-expositor">
          <button className="btn-open">solicitação em aberto</button>
        </Link>
        <Link to="/solicit-negociacao-expositor">
          <button className="btn-open">em negociação</button>
        </Link>
        <Link to="/solicit-aprovada">
          <button className="btn-open">aprovadas</button>
        </Link>
      </ActionButton>
      {/* <div className="warning-solicit">
        <ErrorOutlinedIcon style={{ width: "32px", height: "36px" }} />
        <h1 className="status-solicit">Você ainda não tem pedidos</h1>
      </div> */}
      <ContentWrapper>
        <Link to="/cadastro-briefing">
          <NRoundedButton
            title={"+ NOVO PEDIDO"}
            color={"#22DA29"}
            active={true}
          />
        </Link>
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;

  @media screen and (max-width: 898px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
  }
`


export default SolicitPlataforma;
