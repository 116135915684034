import styled from "@emotion/styled";
import { Chip, Stack } from "@mui/material";
import { COLORS } from "../../../helpers/types";
import { listCauses } from "../../../api/promoter";

export default function FragmentCauses({ role, stateForm, handleChipSelectedCauses }) {
  const causes = listCauses();

  return (
    <>
      <span>Causas Sustentáveis</span>
      <p>Adicione as causas sustentáveis que farão parte do seu evento</p>

      <WrapperStack role={role} style={{ width: "60%" }}>
        <Stack direction="row" style={{ flexWrap: 'wrap' }} >
          {causes && causes.map((chip) => (
            <Chip
              key={chip.id}
              variant="outlined"
              label={chip.name}
              clickable
              required
              sx={{
                margin: '5px',
                color: stateForm.selectedCauseList && stateForm.selectedCauseList.includes(chip.id) ? '#ffffff' : 'default',
                background: stateForm.selectedCauseList && stateForm.selectedCauseList.includes(chip.id) ? COLORS[role] : 'default',

              }}
              onClick={() => handleChipSelectedCauses(chip.id)}
            />
          ))}
        </Stack>
      </WrapperStack >
    </>
  );
}

const WrapperStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & .MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium {
    cursor: pointer;

    span {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  & .MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium:active {
    background-color: ${props => COLORS[props.role]};
    color: white;
  }

  & .MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium:hover {
    background-color: ${props => COLORS[props.role]};
    color: white;
  }
`;
