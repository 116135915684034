import styled from "@emotion/styled";
import { DashInput } from "../../../shared-components/DashInput";

export default function FragmentDateTime({ handleChange, stateForm }) {
  return (
    <>
      <span>Data e horário</span>
      <p>Informe aos participantes quando seu evento vai acontecer</p>
      <Row>
        <DashInput
          name="startDate"
          value={stateForm.startDate}
          onChange={handleChange}
          title="Data Início"
          placeholder="dd-mm-yyyy"
          type="date"
        />
        <DashInput
          name="startTime"
          value={stateForm.startTime}
          onChange={handleChange}
          title="Hora de início"
          type="time"
        />
        <DashInput
          name="endDate"
          value={stateForm.endDate}
          onChange={handleChange}
          title="Data de Término"
          type="date"
        />
        <DashInput
          name="endTime"
          value={stateForm.endTime}
          onChange={handleChange}
          title="Hora de Término"
          type="time"
        />
      </Row>
    </>
  );
}

const Row = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 30px;
  margin: 2rem 0;
  > p {
    flex: 2;
  }
`;
