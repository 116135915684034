import { useSimpleForm } from "../../../hooks/useSimpleForm";
import styled from "@emotion/styled";
import EditBriefing from "../editarBriefing/EditBriefing";
import { getBriefing, updateBriefing } from "../../../api/briefing";
import { useNotification } from "../../../context/NotificationContext";
import { Link } from "react-router-dom";
import { useUser } from "../../../context/UserContext";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useQuery } from "react-query";
import Loading from "../../../shared-components/Loading";

function Content ({briefingData}) {
  const { userData } = useUser();
  const notification = useNotification();
  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    BriefingStructs: briefingData?.BriefingStructs || [],
    BriefingElements:briefingData?.BriefingElements || [],
    BriefingMultimedia: briefingData?.BriefingMultimedia || [],
    paymentTypeName: briefingData?.BriefingPaymentType?.name,
    BriefingProjects: briefingData?.BriefingProjects || [],
    BriefingProjectsArte: briefingData?.BriefingProjectsArte || [],
    ...briefingData
  });
  const navigate = useNavigate();

  function submitUpdatedBriefing() {
    const updatedForm = {
      ...stateForm,
      structs: stateForm.BriefingStructs,
      elements: stateForm.BriefingElements,
      multimedias: stateForm.BriefingMultimedia,
      briefingId: briefingData.id,
      briefingTypeId: briefingData.briefingTypeId,
      promoter: stateForm.promoterQuantity,
      projectsArte: stateForm.BriefingProjectsArte,
      references: stateForm.BriefingReferences,
      lastStands: stateForm.BriefingLastStand,
    };

    updateBriefing(updatedForm)
      .then((res) => {
        notification.show({ message: "Briefing atualizado :)" });
      })
      .catch((err) => {
        notification.show({
          message: "Não foi possível atualziar o briefing",
          type: "error",
        });
      });
  };

  function handleCancelClick() {
    navigate(`/expositor/briefing/${briefingData?.id}`);
  }

  return (
    <Wrapper key={briefingData?.id}>
      <EditBriefing
          briefingData={briefingData}
          stateForm={stateForm}
          handleChange={handleChange}
          setStateForm={setStateForm}
            />
      <Action>
        <Button color={"#adb5bd"} onClick={handleCancelClick}>Cancelar</Button>
        {userData.role.name !== "EXPOSITOR" &&
          <Link to={`/admin/memorial-descritivo/${briefingData?.id}`}>
            <Button color={"#FF8400"}>Memorial</Button>
          </Link>
        }
        <Button color={"#FF8400"} onClick={submitUpdatedBriefing}>
          Atualizar
        </Button>
      </Action>
    </Wrapper>
  ) 
};

export default function FragmentBriefingNegoc() {
  const { briefingId } = useParams()
  const { data, loading, error } = useQuery(["briefing", briefingId ], () => getBriefing(briefingId))

  if (loading){
    <Loading />
  }

  return <Content briefingData={data}/>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  min-height: 40rem;
  margin: 10px 0;
  padding: 0;
`;
const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;

  > div {
    display: flex;
    flex: 0.4;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
const Button = styled.button`
  min-width: 130px;
  font-size: 1.4rem;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => (!color ? "#22da28" : color)};
  background: ${({ color }) => (!color ? "#22da28" : color)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }
`;




