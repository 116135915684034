import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { upsertApproval } from '../../../../api/gestor';
import { useParams } from 'react-router-dom';

export default function DialogComment({
  handleClose,
  open,
  title,
  data,
  refetchMemorial,
  handleCloseAll
}) {
  const [comment, setComment] = useState("");
  const [noComment, setNoComment] = useState(false);
  const params = useParams();

  async function handleDisapprove(comment) {
    if (!comment) {
      setNoComment(true);
      return
    }
    await upsertApproval(params.briefingId, "memorial", "disapproved", comment);
    await refetchMemorial();
    setComment("");
    handleCloseAll();
  };

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Column>
            {
              noComment ?
                <p style={{ color: "#ff0000", textDecoration: "underline" }}>
                  Você precisa adicionar um comentário explicando o motivo da reprovação do memorial
                </p>
                :
                <p>
                  Você precisa adicionar um comentário explicando o motivo da reprovação do memorial
                </p>
            }
          </Column>
          <TextArea
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
        </DialogContent>
        <ActionButtons>
          <Button color={"#FCA500"} onClick={() => { setNoComment(false); handleClose()}}>Voltar</Button>
          <Button color={"#adb5bd"} onClick={() => handleDisapprove(comment)}>Reprovar</Button>
        </ActionButtons>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #FDC22A;
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & p {
    font-size: 1.8rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.5rem;
  }
  
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: '100%';
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#FDC22A" : color};
  background: ${({ color }) => !color ? "#FDC22A" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`

const TextArea = styled.textarea`
  width: 100%;
  height: 20rem;
  outline: 0;
  border: 1px solid #aaa;
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`