import React from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import { listOpenBriefings } from "../../../api/briefing";
import Header from "../Header";
import { Link } from "react-router-dom";
import { ActionButton } from "./Styles";
import BriefingTable from "../../../pages/Dashboard/SolicitOrcamento/BriefingTable";
import { ListBriefings } from "../../../pages/Dashboard/SolicitOrcamento/ListBriefings";

function FragmentOpenBriefings({
  data,
  isLoading,
  error,
  handleShowBriefings,
}) {
  if (isLoading) {
    return (
      <>
        <span>carregando...</span>
      </>
    );
  }

  if (data) {
    return (
      <>
        {Object.values(data).map((briefing, index) => (
          <div
            className="solicits-open"
            key={`${briefing.briefings[0].id}-${index}`}
          >
            <h2 className="company-name">
              {briefing.officialName || briefing.briefings[0].eventName}
            </h2>
            {/* <img src={briefing.smallImage} alt="" /> */}
            <Link to={`/solicit-aberto-expositor/${briefing.briefings[0].id}`}>
              <button className="detailes-solicit">DETALHES</button>
            </Link>
          </div>
        ))}
      </>
    );
  }
  if (error) {
    return <span>Algo deu errado, carregue a página novamente...</span>;
  }
}
//TODO: change this name
const SolicitAprovada = () => {
  const { data, isLoading, error } = useQuery(
    "open-briefings",
    listOpenBriefings
  );

  console.log(data)

  return (
    <>
      {/* <Header title="Solicitações em aberto" />
      <ActionButton>
        <Link to="../solicit-aberto-expositor">
          <button className="btn-open2">solicitação em aberto</button>
        </Link>
        <Link to="../solicit-negociacao-expositor">
          <button className="btn-open-mont">em negociação</button>
        </Link>
        <Link to="/solicit-aprovada">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </ActionButton> */}
      <ContentWrapper>
        <FragmentOpenBriefings
          data={data}
          isLoading={isLoading}
          error={error}
        />
        {/* {data && <BriefingTable data={Object.values(data)} />} */}
        {/* <ListBriefings /> */}
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default SolicitAprovada;
