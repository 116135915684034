import { QueryClient, QueryClientProvider } from 'react-query'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes } from 'react-router-dom';
import { NotificationProvider } from './context/NotificationContext';
import ExpositorRoute from "./routes/ExpositorRoute";
import MontadorRoute from "./routes/MontadorRoute";
import ProjetistaRoute from "./routes/ProjetistaRoute";
import PromotorRoute from "./routes/PromotorRoute";
import AdminRoute from "./routes/AdminRoute";
import PublicRoute from "./routes/PublicRoute";
import { UserProvider } from './context/UserContext';
import { useLayoutEffect } from 'react';
function App() {

  const queryClient = new QueryClient();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF8400",
      },
    },
  });

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <UserProvider>
              <Routes>
                {PublicRoute}
                {ExpositorRoute}
                {MontadorRoute}
                {PromotorRoute}
                {ProjetistaRoute}
                {AdminRoute}
              </Routes>
            </UserProvider>
          </NotificationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
