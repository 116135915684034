import React from 'react'
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { listEvents } from '../../../api/promoter';
import { NRoundedButton } from '../../../shared-components/NRoundedButton';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Loading from '../../../shared-components/Loading';
import { COLORS } from '../../../helpers/types';

const GerenciarEventos = ({ role }) => {
  const { data: eventos, isLoading, isError } = useQuery('eventos', listEvents);
  if (isLoading) {
    return <Loading role={role} />
  }

  if (isError) {
    return <p>Ocorreu um erro ao carregar os eventos...</p>;
  }

  function formatDateLocale(value) {
    if (!value) {
      return 'dd/mm/aaaa';
    }
    return new Date(value).toLocaleDateString('pt-Br');
  }

  return (
    <Wrapper>
      <Paper
        sx={{ overflow: "hidden", boxShadow: 'none' }}
      >
        <Box sx={{ overflowX: 'auto' }}>
          <TableContainer component={Paper} className='table-container'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-cell'>Nome do Evento</TableCell>
                  <TableCell align="right" className='table-cell'>Cidade</TableCell>
                  {role === "PROMOTOR" ?
                    <TableCell align="right" className='table-cell'>Qtd. Briefings</TableCell>
                    :
                    <TableCell align="right" className='table-cell'>Nome Promotor</TableCell>
                  }
                  <TableCell align="right" className='table-cell'>Início do Evento</TableCell>
                  <TableCell align="right" className='table-cell'>Fim do Evento</TableCell>
                  <TableCell align="right" className='table-cell'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventos.map((evento) => (
                  <TableRow key={evento.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" className="table-line">
                      {evento.name}
                    </TableCell>
                    <TableCell align="right" className="table-line">{evento.city}</TableCell>
                    {role === "PROMOTOR" ?
                      <TableCell align="center" className='table-line'>{evento.Briefing.length}</TableCell>
                      :
                      < TableCell align="center" className='table-line'>
                        {evento.Promoter?.Promoter[0]?.name || " "}
                      </TableCell>
                    }
                    <TableCell align="right" className="table-line">{formatDateLocale(evento.startDate)}</TableCell>
                    <TableCell align="right" className="table-line">{formatDateLocale(evento.endDate)}</TableCell>
                    <TableCell align="right" className="table-cell">
                      {role === "PROMOTOR" ?
                        <Link to={`/promotor-editar-evento/${evento.id}`}>
                          <NRoundedButton title={'editar evento'} active color={COLORS[role]} />
                        </Link>
                        :
                        <Link to={`/editar-evento/${evento.id}`}>
                          <NRoundedButton title={'editar evento'} active color={COLORS[role]} />
                        </Link>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Wrapper >
  )
}

const Wrapper = styled.div`
  .table-cell {
    font-family: montserrat;
    font-weight: bold;
    font-size: 2rem;
  }

  .table-line {
    font-size: 1.5rem;
    font-family: montserrat;
  }

  @media (max-width: 900px) {
  
    .table-cell {
      font-size: 1.5rem;
    
    }
  }
`;

export default GerenciarEventos