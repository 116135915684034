export function formatDateForInput(value) {
  if (!value) {
    return 'dd/mm/aaaa';
  }
  return new Date(value).toLocaleDateString("pt-br")
}

export function formatDateLocale(value) {
  if (!value) {
    return ('dd/mm/aaaa')

  }
  return new Date(value).toLocaleDateString('pt-Br')
}


export function formatTimeLocale(value) {
  if (!value) {
    return '00:00:00';
  }
  return new Date(value).toLocaleTimeString('pt-Br')
}

export function convertAPIDate(value) {
  if (!value) {
    return 'dd/mm/aaaa';
  }
  let d = new Date(value);
  return new Date(d.getTime() + d.getTimezoneOffset() * 60_000)
    .toLocaleString()
    .split(', ')[0];

} 