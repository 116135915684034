import styled from "@emotion/styled"
import { Link } from "react-router-dom";
import PinkCircle from "../../../images/pink-circle.png";


export default function CadastroEvento() {

  return (
    <Wrapper>
      <img src={PinkCircle} />
      <div>
        <p>Cadastre seu</p>
        <p><strong>evento</strong> e torne-se</p>
        <p>promotor em</p>
        <p>alguns passos</p>

        <span>Junte se a nós. Ajudaremos você
          durante o processo.</span>
      </div>
      <div>
        <div>
          <Link to="/promotor-criar-evento">
            <button>Vamos lá</button>
          </Link>

        </div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 6px;
  height: 60vh;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px;
    width: 520px;
  }

  div:last-of-type {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      color: white;
      border-radius: 6px;
      width: 160px;
      height: 40px;
      border: none;
      font-size: 1.6rem;
      font-weight: 600;
      background-color: #E7054C;
    }
  }

  p {
    z-index: 1;
    font-size: 4.6rem;
    line-height: 5rem;
    font-weight: 800;
    color: #414141;
  }

  strong {
    color: #E7054C;
  }

  span {
    font-size: 2rem;
    font-weight: 800;
  }

  > img {
    z-index: 1;
    height: 50vh;
    transform: rotate(180deg);
    position: absolute;
    filter: opacity(15%);
  }
  
`