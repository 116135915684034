import React from "react";
import { Link } from "react-router-dom";
import ExpoLogo from "../../../images/Frame-124.png";
import GreenCircle from "../../../images/green-circle.png";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import SideBarMontador from "../../sidebar/SideBarMontador";
import "../style/styleSolicitPlataforma.css";

const SolicitPlataformaMont = () => {
  return (
    <div style={{ backgroundColor: "#F5F5F9", height: "120vh" }}>
      <SideBarMontador />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>Hey </span>
              <span style={{ color: "#22da28" }}>Montador</span>
              <span style={{ color: "black" }}>
                , aqui estão <br /> as suas solicitações
              </span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={GreenCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="btns-solict">
        <Link to="/solicit-aberto-montador">
          <button className="btn-open-mont">solicitação em aberto</button>
        </Link>
        <Link to="/solicit-negociacao-sem-proposta-montador">
          <button className="btn-open-mont">em negociação sem proposta</button>
        </Link>
        <Link to="/montador/lista">
          <button className="btn-open-mont">em negociação com proposta</button>
        </Link>
        <Link to="/solicit-aprovada-montador">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </div>
      <div className="warning-solicit">
        <ErrorOutlinedIcon style={{ width: "32px", height: "36px" }} />
        <h1 className="status-solicit">Você ainda não tem pedidos</h1>
      </div>
      <Link to="/">
        <button className="btn-new-order">+ NOVO PEDIDO</button>
      </Link>
    </div>
  );
};

export default SolicitPlataformaMont;
