export const BRIEFING_TYPE = Object.freeze({
  PROJETO: 1,
  PROJETO_MONTADORA: 2,
  MONTAGEM: 3,
  OUTRO: 4,
});

export const BRIEFING_TYPE_ID = Object.freeze({
  1: "PROJETO",
  2: "PROJETO_MONTADORA",
  3: "PROJETO",
  4: "OUTRO",
});

export const ROLE = Object.freeze({
  EXPOSITOR: "EXPOSITOR",
  MONTADOR: "MONTADOR",
  ADMIN: "ADMIN",
  ADMIN_ASSISTENT: "ADMIN_ASSISTENT",
  PROMOTR: "PROMOTOR",
  PROJETISTA: "PROJETISTA",
});

export const COLORS = Object.freeze({
  EXPOSITOR: "#FF8400",
  MONTADOR: "#22da28",
  PROJETISTA: "#FDC22A",
  PROMOTOR: "#E70E4C",
  ADMIN: "#764faa"
});
