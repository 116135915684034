import {
  getUserData,
  privateFetch
} from "./user";

const API = process.env.REACT_APP_API;

export async function acceptDonations(briefingId, status) {

  const token = await privateFetch();

  const res = await fetch(`${API}/briefing-manager-donation/${briefingId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      status
    })
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json()
  throw error;
}