import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { ROLE } from "../../helpers/types";
import useFilterTable from "../ListBriefing/hooks/useFilterTable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { formatDateLocale } from "../../helpers";
import { useUser } from "../../context/UserContext";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { BRIEFING_TYPE } from "../../helpers/BriefingTypes";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormControl from "@mui/material/FormControl";
import PaidIcon from "@mui/icons-material/Paid";

const columns = [
    { id: "eventName", label: "Nome do Evento" },
    { id: "date", label: "Atualizado" },
    { id: "status", label: "Status" },
];

let dates = [];
let eventNames = [];

function renderButtonStatus(status) {
    if (status === "ABERTO") {
        return <OpenButton title={"Não tem propostas recebidas"}> Aberto</OpenButton>;
    }
    if (status === "EM_NEGOCIAÇÃO") {
        return <InNegociationButton title={"Existem propostas recebidas"}>Em negociação</InNegociationButton>;
    }
    if (status === "ACEITO") {
        return <AcceptedButton title={"Existe uma proposta aceita"}>Aceito</AcceptedButton>;
    }
}

function RowCell({ briefing }) {
    const navigate = useNavigate();
    const { userData } = useUser();
    function openBriefing() {
        if (userData.role.name === ROLE.MONTADOR) {
            const briefingId = briefing.briefingId || briefing?.Briefing?.id;
            navigate(`/montador/briefing/${briefingId}`);
        }
        if (userData.role.name === ROLE.PROJETISTA) {
            const briefingId = briefing.briefingId || briefing?.Briefing?.id;
            navigate(`/projetista/briefing/${briefingId}`);
        }
        if (userData.role.name === ROLE.EXPOSITOR) {
            const briefingId = briefing.briefingId || briefing?.id;
            navigate(`/expositor/briefing/${briefingId}`);
        }
    }
    const totalArea = briefing?.length * briefing?.depth || briefing?.Briefing?.length * briefing?.Briefing?.depth;

    const paymentStatus =
        briefing?.Transaction && briefing?.Transaction[0]?.type === "debit"
            ? {
                  color: "#008000",
                  title: "Você usou créditos nesse briefing",
              }
            : {
                  color: "#da0000",
                  title: "Você ainda não usou créditos nesse briefing",
              };

    return (
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={briefing.id}
            style={{
                cursor: "pointer",
            }}
        >
            <TableCell
                key={`eventName-${briefing.id}`}
                onClick={openBriefing}
                sx={{
                    fontSize: "1.5rem",
                }}
            >
                {briefing?.eventName || briefing?.Briefing?.eventName || "Sem nome de evento"} - {totalArea} m² - {briefing.briefingId || briefing?.id}
            </TableCell>
            <TableCell
                key={`date-${briefing.id}`}
                onClick={openBriefing}
                sx={{
                    fontSize: "1.2rem",
                    padding: "5rem",
                }}
            >
                <div>
                    <strong>Criado em:</strong> {formatDateLocale(briefing.createdAt)}
                </div>
                <div>
                    <strong>Atualizado em:</strong> {formatDateLocale(briefing.updatedAt)}
                </div>
            </TableCell>
            <TableCell
                key={`status-${briefing.id}`}
                onClick={openBriefing}
                sx={{
                    fontSize: "1.2rem",
                    padding: "2rem",
                    justifyContent: "flex-start",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {renderButtonStatus(briefing?.status || briefing?.status)}
                    <div title={paymentStatus.title}>
                        <PaidIcon style={{ color: paymentStatus.color, fontSize: "2.5rem" }} />
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
}

const BriefingTableExpositorNovo = ({ data, setFetchType }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("eventName");
    const [visibleRows, setVisibleRows] = React.useState([]);

    const { handleFilter, handleFilterType, searchTerm, setSearchTerm, filteredBriefings, filter, filterType, filterCreatedAt, handleFilterCreatedAt, filterEventNames, setFilterEventNames, clear } = useFilterTable(data);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChange = (event) => {
        const value = event.target.value;

        setFilterEventNames(typeof value === "string" ? value.split(",") : value);
    };

    const getDates = () => {
        if (dates.length === 0) {
            dates = [...new Set(filteredBriefings.map((briefing) => formatDateLocale(briefing.createdAt)))];
            return dates;
        }
        return dates;
    };

    const getEventNames = () => {
        if (eventNames.length === 0) {
            eventNames = [...new Set(filteredBriefings.map((briefing) => briefing.eventName || briefing.Briefing.eventName))];
            return eventNames;
        }
        return eventNames;
    };

    function dateComparator(a, b) {
        const updatedAtA = new Date(a.updatedAt);
        const updatedAtB = new Date(b.updatedAt);

        if (updatedAtB < updatedAtA) {
            return -1;
        }
        if (updatedAtB > updatedAtA) {
            return 1;
        }
        return 0;
    }

    function descendingComparator(a, b, orderBy) {
        if (orderBy === "date") {
            return dateComparator(a, b);
        }

        const getEventName = (briefing) => briefing.eventName || briefing.Briefing.eventName;

        const firstValue = orderBy === "eventName" ? getEventName(a) : a[orderBy];
        const secondValue = orderBy === "eventName" ? getEventName(b) : b[orderBy];

        if (secondValue < firstValue) {
            return -1;
        }
        if (secondValue > firstValue) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(arr, comparator) {
        return arr
            .map((value, index) => ({ value, index }))
            .sort((a, b) => {
                const order = comparator(a.value, b.value);
                return order !== 0 ? order : a.index - b.index;
            })
            .map((item) => item.value);
    }

    React.useEffect(() => {
        const rows = stableSort(filteredBriefings, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        if (!arraysAreEqual(rows, visibleRows)) {
            setVisibleRows(rows);
        }
    }, [order, orderBy, page, rowsPerPage, filteredBriefings, visibleRows]);

    function arraysAreEqual(arr1, arr2) {
        // Check if the arrays have the same length
        if (arr1.length !== arr2.length) {
            return false;
        }
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    const handleRequestSort = (property) => {
        const isAsc = order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
            }}
        >
            <Search>
                <SearchInput type="text" name="eventName" placeholder="Pesquise por nome..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}></SearchInput>
                <StyledSelect name="dateSelect" value={filterCreatedAt} placeholder="data" onChange={(e) => handleFilterCreatedAt(e.target.value)}>
                    <MenuItem value="all" disabled>
                        <IconWrapper>
                            <DateRangeIcon />
                        </IconWrapper>
                        Data
                    </MenuItem>
                    {getDates().map((date) => (
                        <MenuItem key={date} value={date} style={customMenuItemStyle}>
                            {date}
                        </MenuItem>
                    ))}
                </StyledSelect>
                <div>
                    <FormControl>
                        <StyledSelect
                            labelId="demo-multiple-checkbox-label"
                            multiple
                            displayEmpty
                            id="demo-multiple-checkbox"
                            name="eventSelect"
                            label="eventos"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <>
                                            {" "}
                                            <IconWrapper>
                                                <SellOutlinedIcon />
                                            </IconWrapper>{" "}
                                            Eventos{" "}
                                        </>
                                    );
                                } else if (selected.length === 1) {
                                    return selected[0];
                                } else {
                                    return `${selected.length} eventos selecionados`;
                                }
                            }}
                            value={filterEventNames}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={handleChange}
                        >
                            <MenuItem value="all" disabled>
                                Eventos
                            </MenuItem>
                            {getEventNames().map((eventName) => (
                                <MenuItem key={eventName} value={eventName}>
                                    <CustomCheckbox checked={filterEventNames.indexOf(eventName) > -1} />
                                    <CustomCheckboxLabel>{eventName}</CustomCheckboxLabel>
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </FormControl>
                </div>
                <StyledSelect value={filterType} name="projectType" placeholder="Tipo de projeto" onChange={(e) => handleFilterType(e.target.value)}>
                    <MenuItem value="all">
                        <IconWrapper>
                            <BookmarkBorderOutlinedIcon />
                        </IconWrapper>
                        Tipo de projeto
                    </MenuItem>
                    {Object.keys(BRIEFING_TYPE).map((key) => (
                        <MenuItem key={key} value={key} style={customMenuItemStyle}>
                            {BRIEFING_TYPE[key]}
                        </MenuItem>
                    ))}
                </StyledSelect>
                <StyledSelect value={filter} name="statusType" placeholder="status" onChange={(e) => handleFilter(e.target.value)}>
                    <MenuItem value="all">
                        <IconWrapper>
                            <PermIdentitydIcon />
                        </IconWrapper>
                        Status
                    </MenuItem>
                    <MenuItem value="ACEITO" style={customMenuItemStyle}>
                        Aceito
                    </MenuItem>
                    <MenuItem value="ABERTO" style={customMenuItemStyle}>
                        Aberto
                    </MenuItem>
                    <MenuItem value="EM_NEGOCIAÇÃO" style={customMenuItemStyle}>
                        Em negociação
                    </MenuItem>
                </StyledSelect>
                <Button onClick={clear}>Limpar</Button>
            </Search>

            <Paper
                sx={{
                    overflow: "hidden",
                }}
            >
                <Table sx={{ margin: "4rem" }}>
                    <TableHead>
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            {columns.map((column) => (
                                <TableCell key={column.id} style={{ fontSize: "1.4rem", fontWeight: "bold" }} sortDirection={orderBy === column.id ? order : false}>
                                    <TableSortLabel active={orderBy === column.id} direction={orderBy === column.id ? order : "asc"} onClick={() => handleRequestSort(column.id)}>
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                </Table>
            </Paper>
            <Paper
                sx={{
                    overflow: "hidden",
                }}
            >
                <TableContainer sx={{ height: "70vh" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                            {visibleRows.map((briefing, index) => (
                                <RowCell key={`cell-${index}`} briefing={briefing} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={[20, 25, 100]} component="div" count={filteredBriefings.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} labelRowsPerPage={"Linhas por página"} />
            </Paper>
        </div>
    );
};

const customMenuItemStyle = {
    fontSize: "12px",
};

const AcceptedButton = styled.button`
    min-width: 120px;
    height: 30px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    outline: none;
    border-radius: 20px;
    border: 0px solid;
    background: #08da08;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    align-items: center;
    margin: 5px;
`;
const OpenButton = styled.button`
    min-width: 120px;
    height: 30px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    outline: none;
    border-radius: 20px;
    border: 0px solid rgba(218, 34, 45, 0.34);
    background: #009dff;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    align-items: center;
    margin: 5px;
`;
const InNegociationButton = styled.button`
    min-width: 120px;
    height: 30px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    outline: none;
    border-radius: 20px;
    border: 0px solid rgba(218, 34, 45, 0.34);
    background: #ff8400;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    align-items: center;
    margin: 5px;
`;
const Search = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 6vh;
    margin-left: 3vh;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;
const SearchInput = styled.input`
    padding-left: 1.3rem;
    border: 1px solid #ccc;
    outline: none;
    height: 35px;
    width: 200px;
    border-radius: 20px;

    ::placeholder {
        font-size: 13px;
        color: black;
    }
`;
const DateRangeIcon = styled(DateRangeOutlinedIcon)``;
const IconWrapper = styled(ListItemIcon)`
    min-width: 24px;
`;
const PermIdentitydIcon = styled(PermIdentityOutlinedIcon)`
    color: #f68d50;
    font-size: 20px;
`;
const Button = styled.button`
    background-color: #f5f5f9;
    color: #f68d50;
    font-weight: 600;
    border: none;
    margin-left: 10px;
`;
const StyledSelect = styled(Select)`
    width: ${({ width }) => (width ? width : "180px")};

    & fieldset {
        border: none;
    }

    & .MuiSelect-select.MuiSelect-outlined {
        border-radius: 20px;
        height: 24px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        background-color: white;
        white-space: pre-line;
        font-size: 1.4rem;
        padding: 4px 7px;
        display: flex;
        align-items: center;
    }

    & .MuiSelect-select.MuiSelect-outlined:active {
        border: 2px solid #ff8400;
    }

    .MuiSelect-select.MuiSelect-outlined:hover {
        border: 1px solid #ff8400;
    }
    @media (max-width: 768px) {
        margin-top: 3px;
    }
`;

const CustomCheckbox = styled(Checkbox)`
    .MuiSvgIcon-root {
        fill: #ff8400;
    }
`;
const CustomCheckboxLabel = styled.span`
    font-size: 12px;
`;

export default BriefingTableExpositorNovo;
