import { useState } from "react";
import styled from "@emotion/styled";
import { UploadFileBlock } from "../../../shared-components";
import { generateImageLink } from "../../../helpers/generateImageLink";
import { useUser } from "../../../context/UserContext";

export function FragmentUploadReferences({
  briefingReference,
  handleChangeImage,
  ...otherProps
}) {
  const [source, setSource] = useState(() => generateImageLink(briefingReference));

  const { userData } = useUser();

  const download =
    userData.role.name === "ADMIN"
      || userData.role.name === "ADMIN_ASSISTENT" ? true : false;
  const canDelete =
    userData.role.name === "ADMIN"
      || userData.role.name === "ADMIN_ASSISTENT" ? true : false;



  function getFile(e, res) {
    const uploadedImage = {
      type: "upload",
      image: res.name
    }

    setSource(() => generateImageLink(uploadedImage))
    handleChangeImage(e, res);
  }
  function deleteFile(e) {
    e.preventDefault();
    setSource(null);
    const fakeEvent = {
      target: {
        id: otherProps.id,
      }
    }
    handleChangeImage(fakeEvent, undefined);
  }

  return (
    <ContainerReference>
      <UploadFileBlock
        {...otherProps}
        width='100%'
        height='300px'
        imageFile={source?.image || source?.link}
        getFile={getFile}
        download={download}
        canDelete={canDelete}
        deleteFile={deleteFile}
      />

    </ContainerReference>
  )
}


const ContainerReference = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40rem;
  
  img {
      width: 100%;
      min-width: 250px;
      height: 240px;
      object-fit: cover;
      border-radius: 6px;
    }
`
