import React, { useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { NRoundedButton } from './NRoundedButton';
import { uploadFileS3 } from "../libs/s3Client";
import LinearLoading from './LinearLoading';


function renderActionFile({
  imageFile,
  download,
  canDelete,
  removeFile
}) {
  let headerAction = [];

  if (download) {
    headerAction.push(<NRoundedButton
      active={true}
      color="#FF8400"
      title={"Download"}
      onClick={() => {
        window.open(imageFile);
      }} />)
  }
  if (canDelete) {
    headerAction.push(<NRoundedButton
      active={true}
      color="#E70E4C"
      title={"Apagar"}
      onClick={removeFile} />)
  }

  return headerAction;
}

function renderViewFile(imageFile, download, canDelete, removeFile) {
  if (!imageFile) {
    return <></>
  }

  if (
    imageFile.indexOf('.jpeg') > 0 ||
    imageFile.indexOf('.png') > 0 ||
    imageFile.indexOf('.jpg') > 0) {
    return (
      <ContainerFile>
        <img src={imageFile} alt="" />
        <span style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-around',
          padding: '2rem',
        }}>
          {renderActionFile({
            imageFile,
            download,
            canDelete,
            removeFile
          })}
        </span>
      </ContainerFile>

    );
  }
  if (imageFile.indexOf('.pdf')) {
    return (
      <ContainerFile>
        <object
          data={`${imageFile}#toolbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ pointerEvents: 'none' }}
        >
        </object>
        <span style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-around',
          padding: '2rem',
        }}>
          {renderActionFile({
            imageFile,
            download,
            canDelete
          })}
        </span>
      </ContainerFile>)
  }
  if (!imageFile.indexOf('.pdf')) {
    return (
      <ContainerFile>
        <InsertDriveFileIcon />
        <span>Já existe um arquivo aqui</span>
        <span style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-around',
          padding: '2rem',
        }}>
          {renderActionFile({
            imageFile,
            download
          })}
        </span>
      </ContainerFile>)
  }
}

export const UploadFileBlock = forwardRef((props, ref) => {

  const {
    getFile,
    deleteFile,
    canDelete,
    disabled,
    defaultValue,
    imageFile,
    width,
    height,
    download,
    ...otherProps
  } = props;

  const [file, setFile] = useState();
  const [status, setStatus] = useState(null)


  if (defaultValue) {
    setStatus("Arquivo enviado!")
  }

  function handleInput(e) {
    const type = e.target.files[0].type.split("/")[1]
    setFile(`${e.target.files[0].name}.${type}`);
    setStatus('Enviando arquivo...')
    uploadFileS3(e)
      .then(res => {
        setStatus(`Sucesso: ${e.target.files[0].name.substring(0, 20)}...`);
        getFile(e, res);
      })
      .catch(error => {
        setStatus('Tente novamente...')
      })
  }

  function removeFile(e) {
    setStatus(null)
    setFile(null);
    deleteFile(e);
  }

  if (status === 'Enviando arquivo...') {
    return (

      <Wrapper
        disabled={disabled}
        width={width}
        height={height}
      >
        <label style={{
          gap: '2rem'
        }}>
          Enviando o arquivo...
          <input
            type="file"
            onChange={handleInput}
            {...otherProps}
            disabled={disabled}
            ref={ref}
          />
          <LinearLoading />
        </label>
      </Wrapper>

    )
  }

  return (
    <Wrapper
      disabled={disabled}
      width={width}
      height={height}
    >
      <label>
        <CameraAltIcon />
        <p>Clique ou arraste o arquivo aqui</p>
        <input
          type="file"
          onChange={handleInput}
          {...otherProps}
          disabled={disabled}
          ref={ref}
        />
        {status || file}
        {status !== 'Enviando arquivo...' ? renderViewFile(imageFile, download, canDelete, removeFile) : null}
      </label>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  border-radius: 6px;
  opacity: ${props => props.disabled ? '0.6' : '1'};
  width: ${props => props.width ? props.width : '100px'};
  height: ${props => props.height ? props.height : '100px'};
  position: relative;
  

 > label {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color:#E3E3E355;
  font-weight: 700;
  
   button {
      filter: opacity(0%);
    }

  img, object {
    position: absolute;
    z-index: 1;
    width: ${props => props.width ? props.width : '100px'};
    height: ${props => props.height ? props.height : '100px'};
    transition: all 300ms linear;
    object-fit: cover;
  }

  cursor: pointer;

  svg {
    color: black;
    z-index: 1;
    transition: all 300ms linear;
  }

  p {
    width: 80%;
    text-align: center;
    z-index: 1;
    font-weight: 700;
    transition: all 300ms linear;
  }
 }

 > label:hover {
    img, object {
      filter: opacity(30%);
    }
    svg {
        z-index: 3;
    }
    p {
      z-index: 3;
      color: black;
    }

    button {
      z-index: 10;
      filter: opacity(100%);
    }
 }
 
 >div:first-of-type {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  font-size: 1.8rem;
  padding: 0 10px;
  width: 80%;
  height: 30px;
  
 }
 svg {
  color: white;
 }
`

const ContainerFile = styled.div`
  position: absolute;
  z-index: 2;
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
  transition: all 300ms linear;
  background-color: #E3E3E3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  object-fit: cover;

  span {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 700;
  }
`

export default UploadFileBlock;