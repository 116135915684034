//TODO: fix in env
export function generateImageLink(imageData) {

  if (typeof imageData === 'string') {
    return {
      image: `https://bucket-westand-prod.s3.us-east-2.amazonaws.com/${imageData}`
    }
  }

  if (imageData?.type === "link") {
    return {
      image: ""
    }
  }

  if (imageData?.type === "upload" && imageData?.image) {
    return ({
      ...imageData,
      type: imageData?.image.split('.')[1],
      image: `https://bucket-westand-prod.s3.us-east-2.amazonaws.com/${imageData.image}`
    })
  }
  if (imageData?.type === "upload" && imageData?.link) {
    return ({
      ...imageData,
      type: imageData?.link.split('.')[1],
      image: `https://bucket-westand-prod.s3.us-east-2.amazonaws.com/${imageData.link}`
    })
  }


  return imageData;
}
