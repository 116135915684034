import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getBriefing } from "../../api/briefing";
import Loading from "../../shared-components/Loading";
import { useUser } from "../../context/UserContext";
import { ROLE } from "../../helpers/types";
import { Error } from "../../shared-components";
import { BriefingMontador } from "../Briefing/montador/BriefingMontador";
import { BriefingProjetista } from "../Briefing/projetista/BriefingProjetista";
import { BriefingExpositor } from "../Briefing/expositor/BriefingExpositor";

export default function EditBriefing() {
  const params = useParams();
  const { briefingId } = params;
  const { userData } = useUser();
  const { data, isLoading, error } = useQuery(['briefing', briefingId], () => getBriefing(briefingId));


  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  if (data.message === "Você não pode ver esse briefing") {
    return <Error />
  }

  if (userData.role.name === ROLE.MONTADOR) {
    return (
      <>
        <BriefingMontador data={data} userData={userData} />
      </>
    )
  }

  if (userData.role.name === ROLE.PROJETISTA) {
    return (
      <>
        <BriefingProjetista briefingData={data} />
      </>
    )
  }

  if (userData.role.name === ROLE.EXPOSITOR) {

    return (
      <>
        <BriefingExpositor data={data} />
      </>
    )
  }
}