import Header from "../DashboardProjetistaComponents/Header"

export function ProjetistaProfileForm({
  title,
  children,
}) {
  return (
    <>
      <Header title={title} />
      {children}
    </>
  )
}