import { useCallback } from "react";
import { MenuItem } from "@mui/material";
import { DashInput, DashSelect, NTextField } from "../../../shared-components";
import { FieldBlock, Title, Row } from "./StyledComponents";
import {
  MULTIMEDIA_NAME,
  TV_SIZE_ITEMS,
  LIGHT_ITEMS,
  SOUND_BAR_ITEMS,
} from "./FormsTypes";
import {
  getValueFromType,
  getValueFromTypeArray,
  getValueObservation,
} from "./formHelper";

export default function FragmentMultimidia({
  multimedia,
  setStateForm,
  stateForm,
}) {
  const handleMultimediatState = useCallback(({ target: { name, value } }) => {
    const searchElement = multimedia.find((e) => e.type === name);

    if (!searchElement) {
      multimedia = [
        ...multimedia,
        {
          type: name,
          value,
        },
      ];
    } else {
      Object.values(multimedia).forEach((struct) => {
        if (struct.type === name) {
          struct.value = value;
        }
      });
    }

    setStateForm({
      ...stateForm,
      BriefingMultimedia: multimedia,
    });
  });

  const handleMultimediatObs = useCallback(({ target: { name, value } }) => {
    name = name.replace("_observation", "");
    const searchElement = multimedia.find((e) => e.type === name);
    if (!searchElement) {
      multimedia = [
        ...multimedia,
        {
          type: name,
          observation: value,
        },
      ];
    } else {
      Object.values(multimedia).forEach((struct) => {
        if (struct.type === name) {
          struct.observation = value;
        }
      });
    }

    setStateForm({
      ...stateForm,
      BriefingMultimedia: multimedia,
    });
  });

  return (
    <>
      <Title>Material Multimida</Title>
      <Row>
        <FieldBlock>
          <DashInput
            id={MULTIMEDIA_NAME.TV_QUANTIDADE}
            name={MULTIMEDIA_NAME.TV_QUANTIDADE}
            key={MULTIMEDIA_NAME.TV_QUANTIDADE}
            value={getValueFromType(multimedia, MULTIMEDIA_NAME.TV_QUANTIDADE)}
            onChange={handleMultimediatState}
            fullWidth
            title={"TV (quantidade)"}
            placeholder="0"
            type="number"
          />
          <NTextField
            id={`${MULTIMEDIA_NAME.TV_QUANTIDADE}_observation`}
            name={`${MULTIMEDIA_NAME.TV_QUANTIDADE}_observation`}
            key={`${MULTIMEDIA_NAME.TV_QUANTIDADE}_observation`}
            value={getValueObservation(
              multimedia,
              MULTIMEDIA_NAME.TV_QUANTIDADE
            )}
            onChange={handleMultimediatObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={MULTIMEDIA_NAME.TV_TAMANHO}
            name={MULTIMEDIA_NAME.TV_TAMANHO}
            key={MULTIMEDIA_NAME.TV_TAMANHO}
            value={getValueFromTypeArray(
              multimedia,
              MULTIMEDIA_NAME.TV_TAMANHO
            )}
            onChange={handleMultimediatState}
            title={"TV (tamanho)"}
            fullWidth
            multiple
          >
            {TV_SIZE_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${MULTIMEDIA_NAME.TV_TAMANHO}_observation`}
            name={`${MULTIMEDIA_NAME.TV_TAMANHO}_observation`}
            key={`${MULTIMEDIA_NAME.TV_TAMANHO}_observation`}
            value={getValueObservation(multimedia, MULTIMEDIA_NAME.TV_TAMANHO)}
            onChange={handleMultimediatObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashInput
            id={MULTIMEDIA_NAME.PAINEL_LED}
            name={MULTIMEDIA_NAME.PAINEL_LED}
            key={MULTIMEDIA_NAME.PAINEL_LED}
            value={getValueFromType(multimedia, MULTIMEDIA_NAME.PAINEL_LED)}
            onChange={handleMultimediatState}
            title={"Painel de led"}
            placeholder="0"
            fullWidth
            type="number"
          />
          <NTextField
            id={`${MULTIMEDIA_NAME.PAINEL_LED}_observation`}
            name={`${MULTIMEDIA_NAME.PAINEL_LED}_observation`}
            key={`${MULTIMEDIA_NAME.PAINEL_LED}_observation`}
            value={getValueObservation(multimedia, MULTIMEDIA_NAME.PAINEL_LED)}
            onChange={handleMultimediatObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
      </Row>
      <Row>
        <FieldBlock>
          <DashSelect
            id={MULTIMEDIA_NAME.ILUMINAÇÃO}
            name={MULTIMEDIA_NAME.ILUMINAÇÃO}
            key={MULTIMEDIA_NAME.ILUMINAÇÃO}
            value={getValueFromTypeArray(
              multimedia,
              MULTIMEDIA_NAME.ILUMINAÇÃO
            )}
            onChange={handleMultimediatState}
            title={"Iluminação"}
            multiple
            fullWidth
          >
            {LIGHT_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${MULTIMEDIA_NAME.ILUMINAÇÃO}_observation`}
            name={`${MULTIMEDIA_NAME.ILUMINAÇÃO}_observation`}
            key={`${MULTIMEDIA_NAME.ILUMINAÇÃO}_observation`}
            value={getValueObservation(multimedia, MULTIMEDIA_NAME.ILUMINAÇÃO)}
            onChange={handleMultimediatObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={MULTIMEDIA_NAME.CAIXA_SOM}
            name={MULTIMEDIA_NAME.CAIXA_SOM}
            key={MULTIMEDIA_NAME.CAIXA_SOM}
            value={getValueFromType(multimedia, MULTIMEDIA_NAME.CAIXA_SOM)}
            onChange={handleMultimediatState}
            fullWidth
            title={"Caixas de som"}
            placeholder="0"
            type="number"
          >
            {SOUND_BAR_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>
                {v.value}
              </MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${MULTIMEDIA_NAME.CAIXA_SOM}_observation`}
            name={`${MULTIMEDIA_NAME.CAIXA_SOM}_observation`}
            key={`${MULTIMEDIA_NAME.CAIXA_SOM}_observation`}
            value={getValueObservation(multimedia, MULTIMEDIA_NAME.CAIXA_SOM)}
            onChange={handleMultimediatObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashInput
            id={MULTIMEDIA_NAME.LIXEIRAS}
            name={MULTIMEDIA_NAME.LIXEIRAS}
            key={MULTIMEDIA_NAME.LIXEIRAS}
            value={getValueFromType(multimedia, MULTIMEDIA_NAME.LIXEIRAS)}
            onChange={handleMultimediatState}
            title={"Lixeiras (quantidade)"}
            fullWidth
            type="number"
          />
          <NTextField
            id={`${MULTIMEDIA_NAME.LIXEIRAS}_observation`}
            name={`${MULTIMEDIA_NAME.LIXEIRAS}_observation`}
            key={`${MULTIMEDIA_NAME.LIXEIRAS}_observation`}
            value={getValueObservation(multimedia, MULTIMEDIA_NAME.LIXEIRAS)}
            onChange={handleMultimediatObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
      </Row>
    </>
  );
}
