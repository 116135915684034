import styled from "@emotion/styled"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export function CardPersonalInfo() {

  return (
    <>
      <CardInfo>
        <VisibilityOffOutlinedIcon />
        <p>
          Que informações são compartilhadas com outras pessoas? <br />
          <br /> A Westand só disponibiliza informações de contato a
          expositores e promotores depois da proposta ser confirmada.
        </p>
      </CardInfo>
    </>
  )
}

const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 50%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  padding: 10px;

  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      color: black;
      width: 50px;
      height: 50px;
      margin-top: 50px;
  }

  p {
    font-size: 1.8rem;
    color: black;
  }
`