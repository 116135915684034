import styled from '@emotion/styled';
import { forwardRef } from 'react';

import { Stepper, Step, StepLabel } from '@mui/material';

const NStep = forwardRef((props, ref) => {

  const { ...otherProps } = props;
  const { children } = props;

  return (
    <Component ref={ref} {...otherProps}>
      {children}
    </Component>
  )
})

const Component = styled(Stepper)`

   & .Step {
      margin: 20px 0;
      background-color: #fafafa;
    }

  & .MuiStepIcon-root {
    color: #E3E3E3;
    width: 20px;
    height: auto;
  }

  & .MuiStepIcon-active, .MuiStepIcon-completed {
    color: #FF8400;
  }

  & .MuiStepIcon-text {
     font-size: 1.2rem;
     font-weight: 600;
  }

  & .MuiStepLabel-label {
    font-size: 1.3rem; 
  }
`

export default NStep;