import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Stepper, Step, StepLabel, Paper } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { routeStepContent, getSteps } from "./routeStepFragment";
import { saveBriefing } from "../../api/briefing";
import { getUserData } from "../../api/user";
import DialogNewUser from "./DialogNewUser";
import { useBriefing } from "./hooks/useBriefing";
import { useFormBriefing } from "./hooks/useFormBriefing";
import { handleNextBriefingStep } from "./formActions";
import { useNotification } from "../../context/NotificationContext";

const StyledButton = styled.button`
  margin-right: ${(props) => props.theme.spacing(1)};
  background: #FF8400;
  color: #FFFFFF;
  border: none;
  border-radius: 12px;
`;

const ButtonFinish = () => {
  return <a style={{ color: "#FFFFFF" }}>Fim!</a>;
};

const LinearStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [briefing, setBriefing] = useBriefing();
  const [methods] = useFormBriefing();
  const [statusModal, setStatusModal] = useState(false);
  const notification = useNotification();

  const steps = getSteps();

  //TODO: here
  function handleUserSignIn() {
    const token = getUserData();
    if (!token) {
      setStatusModal(true);
      return false;
    }
    return true;
  }

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep(() => activeStep - 1);
  };

  function closeBriefingModal(e) {
    if (e) {
      e.preventDefault();
    }
    setStatusModal(false);
  }

  const handleNext = (data) => {
    window.scrollTo(0, 0);

    const isUserSignIn = handleUserSignIn();
    if (!isUserSignIn) {
      return;
    }
    handleNextBriefingStep({
      data,
      setBriefing,
      activeStep,
      setActiveStep,
      steps,
      saveBriefing,
      notification,
    });
  };

  return (
    <Wrapper>
      <Stepper className="Step" alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <Paper>{routeStepContent(10, briefing, setBriefing)}</Paper>
      ) : (
        <Paper>
          <FormProvider {...methods}>
            <form
              className="FormBox"
              onSubmit={methods.handleSubmit(handleNext)}
            >
              <StepContainer>
                <DialogNewUser
                  open={statusModal}
                  handleClose={closeBriefingModal}
                  title={"Ainda não tem cadastro?"}
                />
                {routeStepContent(activeStep, briefing, setBriefing)}
              </StepContainer>
              <FormAction>
                <StyledButton
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Anterior
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? (
                    "Fim!"
                  ) : (
                    "Próximo"
                  )}
                </StyledButton>
              </FormAction>
            </form>
          </FormProvider>
        </Paper>
      )}
    </Wrapper>
  );
};

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2%;
  gap: 10px;
  justify-content: space-between;

  @media screen and (max-width: 898px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;

    div {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }
`;

const Wrapper = styled.div`
  /* margin-top: 100px; */
  margin-bottom: 80px;

  .form-button-error {
    display: none !important;
  }

  & .Step {
    margin: 20px 0;
    background-color: white !important;
  }

  & .MuiStepIcon-root {
    color: #e3e3e3;
    width: 20px;
    height: auto;
  }

  & .MuiStepIcon-active,
  .MuiStepIcon-completed {
    color: #ff8400 !important;
  }

  & .MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel {
  }

  & .MuiStepIcon-text {
    font-size: 1.2rem;
    font-weight: 600;
  }

  & .MuiStepLabel-label {
    font-size: 1rem;
  }
`;

const FormAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 10%;
  padding: 10px;

  button {
    width: 100px;
    height: 40px;
    font-size: 1rem;
  }

  button:hover {
    background-color: #ff8400 !important;
    filter: brightness(120%);
  }

`;

export default LinearStepper;