import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import { upsertApproval } from '../../../../api/gestor';
import { useParams } from 'react-router-dom';
import { Wrapper } from './Styles';
import AlertComponent from '../../../../shared-components/AlertComponent';

function convertTitle(name) {
  if (name === 'ConsideracoesGerais') {
    return 'Considerações Gerais'
  }
  if (name === 'MontagemItems') {
    return 'Montagem de Itens'
  }
  if (name === 'ResponsavelTecnico') {
    return 'Responsavel Técnico'
  }
  if (name === 'EquipeMontagem') {
    return 'Equipe Montagem'
  }

  return name;
}


export default function FragmentMemorial({
  title,
  data,
}) {
  const params = useParams()

  async function handleApprove() {
    try {
      await upsertApproval(params.briefingId, "memorial", "approved");
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDisapproved() {
    await upsertApproval(params.briefingId, "memorial", "disapproved");

  }


  return (
    <>
      {data.memorial.MontagemItems ? (
        <>
          <h2 className="reg-feira">Memorial</h2>
          <Wrapper style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60%",
            overflowY: "scroll",
            height: "50vh"
          }}>
            <div>
              <Column>
                {Object.keys(data?.memorial).map((v, index) => {
                  return (
                    <div key={`dialog-structs-${index}`}>
                      <SubTitle>{convertTitle(v)}</SubTitle>
                      <div>
                        {data?.memorial[v].map((u, index) => {
                          return (
                            <div key={`dialog-structs-internal-${index}`}>
                              {
                                u.name === u.type ?
                                  <></>
                                  :
                                  <p>
                                    <strong>{u.name}</strong>
                                  </p>
                              }
                              <p style={{ display: 'flex', flexDirection: 'column' }}>
                                {u.children ?
                                  u.children.map((z) => (
                                    <span key={`dialog-structs-${z.id}`}>
                                      {z.name} - {z.qtd}
                                    </span>
                                  ))
                                  :
                                  u.text ?
                                    <span>
                                      {u.text}
                                    </span>
                                    :
                                    <></>
                                }
                              </p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </Column>

              <div style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2rem"
              }}>
                {
                  data.status === "approved" ?
                    <div>
                      <span style={{
                        color: "#22da28",
                        fontWeight: "bold",
                        background: "#f2f2f2",
                        padding: "2rem 1rem",
                        borderRadius: '5px',
                      }}>Memorial aprovado</span>
                    </div>
                    :
                    data.status === "disapproved" ?
                      <div>
                        <span style={{
                          color: "#ff0000",
                          fontWeight: "bold",
                          background: "#f2f2f2",
                          padding: "2rem 1rem",
                          borderRadius: '5px',
                        }}>Memorial reprovado</span>
                      </div>
                      :
                      <ActionButtons>
                        {/* <Button color={"#adb5bd"} onClick={handleClose}>Fechar</Button> */}
                        <div style={{ display: 'flex' }}>
                          <Button color={"#adb5bd"} onClick={handleDisapproved}>Reprovar</Button>
                          <Space />
                          <Button color={"#FCA500"} onClick={handleApprove}>Aprovar</Button>
                        </div>
                      </ActionButtons>
                }
              </div>
            </div>
          </Wrapper>

        </>
      ) : (
        <AlertComponent info={"Este memorial ainda não está pronto"} />
      )}
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & p {
    font-size: 1.8rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.5rem;
  }
  
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: '100%';
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#FDC22A" : color};
  background: ${({ color }) => !color ? "#FDC22A" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`

const Space = styled.div`
  width: 1rem;
`