import React from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getBriefing } from "../../../api/briefing";
import Header from "../Header";
import { Link } from "react-router-dom";
import FragmentBriefingOpen from "./FragmentBriefingOpen";
import { ActionButton } from "./Styles";

function FragmentOpenBriefings({
  data,
  isLoading,
  error,
  handleShowBriefings,
}) {
  if (error) {
    return (
      <>
        <h3>deu ruimm</h3>
      </>
    );
  }

  if (data) {
    return (
      <>
        <FragmentBriefingOpen key={data.id} briefingData={data} />
      </>
    );
  }
  if (error) {
    return <span>Algo deu errado, carregue a página novamente...</span>;
  }
}

const SolicitEmAbertoDetails = () => {
  const params = useParams();
  const { briefingId } = params;

  const { data, isLoading, error } = useQuery(`briefing-${briefingId}`, () =>
    getBriefing(briefingId)
  );

  return (
    <>
      <Header title="Solicitações em aberto" />
      <ActionButton>
        <Link to="/solicit-aberto-expositor">
          <button className="btn-open2">solicitação em aberto</button>
        </Link>
        <Link to="/solicit-negociacao-expositor">
          <button className="btn-open-mont">em negociação</button>
        </Link>
        <Link to="/solicit-aprovada">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </ActionButton>
      <ContentWrapper>
        <FragmentOpenBriefings
          data={data}
          isLoading={isLoading}
          error={error}
        />
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default SolicitEmAbertoDetails;
