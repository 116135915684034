import { useState } from "react";
import styled from "@emotion/styled"
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { checkRole } from "../api/login";
import SideBarAdmin from "../pages/Dashboard/SideBarADM";
import { useUser } from "../context/UserContext";

function TopMenu({ toggle }) {

  const { userData, saveUserData } = useUser();
  const navigate = useNavigate();

  function handleLogout() {
    window.location.assign("https://westand.com.br");
    saveUserData({})
  }

  function handleHomePage() {
    navigate('/')
  }

  function handleNovoPedido() {
    navigate("/admin/novo-briefing");
  }

  return (
    <TopMenuWrapper>
      <MenuIcon onClick={toggle} className="menu-icon" />
      <Link to="/" style={{ textDecoration: "none" }}>
        <span onClick={handleNovoPedido}>Criar um briefing</span>
        {userData ? (<span onClick={handleLogout}>Sair</span>) : ''}
        <Avatar style={{ position: "inherit" }} src={userData.logo} />

      </Link>

    </TopMenuWrapper>
  )
}

export default function AdminLayout({ children }) {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


  return (
    <Wrapper>
      <SideBarAdmin toggle={toggle} isOpen={isOpen} />
      <main>
        {children}
      </main>
    </Wrapper>
  )
}



const TopMenuWrapper = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 7vh;
  margin-bottom: 20px;
  
  .menu-icon {
    opacity: 0;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
  }

  @media only screen and (max-width: 770px) {

    .menu-icon {
      opacity: 1;
    }
  }
`
const Wrapper = styled.div`

  background-color: #F5F5F9;
  
  & > main {
    background-color: #F5F5F9;
    margin-left: 90px;
    width: calc(100% - 90px);
    padding: 30px;
  }

  @media only screen and (max-width: 770px) {

   & > main {
     width: 100%;
     margin-left: -4px;
   }
  }
`

