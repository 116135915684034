import React, { useState } from "react";
import styled from "@emotion/styled";
import { MenuItem } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { NSelect, NTextField } from "../../shared-components";
import { useForm, Controller, useFormContext } from "react-hook-form";
import stand1 from "../../images/stand_basico.jpg";
import stand2 from "../../images/stand_misto.jpg";
import stand3 from "../../images/stand_construido.jpeg";
import stand4 from "../../images/stand_quiosque.jpg";
import stand5 from "../../images/stand_5.png";

const OrcamentoInformation = () => {
  const classes = useState();
  const { control, getValues, setValue } = useFormContext();
  const {
    register,
    formState: { errors },
  } = useForm();

  const [checked, setChecked] = useState(false);
  const [text, setText] = useState("");

  if (!getValues("standType")) {
    setValue("standType", "BÁSICO");
  }

  function calculateTotal() {
    const largeArea = getValues("largeArea");
    const comprimentoArea = getValues("comprimentoArea");
    if (largeArea > 0 && comprimentoArea > 0) {
      setValue("total", largeArea * comprimentoArea);
    }
  }

  function validatNonNegative(e, field) {
    if (e.target.value > 0) {
      field.onChange(e.target.value);
      return;
    }

    field.onChange("");
  }

  return (
    <>
      <h3>Orçamento</h3>
      <Row>
        <div className="measurements">
          <p>Qual a média do seu orçamento (m²)?</p>
          <Row>
            <span>R$</span>
            <Controller
              control={control}
              name="minOrcamento"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <NTextField
                  id="min-orcamento"
                  name="minOrcamento"
                  {...register("minOrcamento")}
                  variant="outlined"
                  margin="normal"
                  placeholder="Min"
                  inputRef={ref}
                  {...field}
                  type="number"
                  onChange={({ target: { value } }) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
            <span> e </span>
            <Controller
              control={control}
              name="maxOrcamento"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <NTextField
                  id="max-orcamento"
                  name="maxOrcamento"
                  {...register("maxOrcamento")}
                  variant="outlined"
                  margin="normal"
                  placeholder="Máx"
                  inputRef={ref}
                  {...field}
                  type="number"
                  onChange={(e) => validatNonNegative(e, field)}
                />
              )}
            />
          </Row>
        </div>
        <div>
          <Controller
            control={control}
            name="prazoDate"
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <NTextField
                id="prazo-date"
                inputRef={ref}
                name="prazoDate"
                {...register("prazoDate")}
                title={"Qual a data do evento?"}
                InputProps={{
                  inputProps: { min: new Date().toISOString().split("T")[0] },
                }}
                {...field}
                type="date"
              />
            )}
          />
        </div>
      </Row>
      <Row>
        <div className="measurements">
          <p>Qual a medida da área?</p>

          <Row>
            <span>m²</span>
            <Controller
              control={control}
              name="largeArea"
              rules={{ required: true, minValue: 1 }}
              render={({ field: { ref, ...field } }) => (
                <NTextField
                  id="large-area"
                  inputRef={ref}
                  name="largeArea"
                  fullWidth
                  {...register("largeArea")}
                  variant="outlined"
                  placeholder="Profundidade"
                  {...field}
                  pattern="^\d*(\.\d{0,2})?$"
                  onChange={(e) => {
                    validatNonNegative(e, field);
                    calculateTotal();
                  }}
                />
              )}
            />
            <span>x</span>

            <Controller
              control={control}
              name="comprimentoArea"
              rules={{ required: true, minValue: 1 }}
              render={({ field: { ref, ...field } }) => (
                <NTextField
                  id="comprimento-area"
                  name="required"
                  inputRef={ref}
                  {...register("comprimentoArea")}
                  variant="outlined"
                  margin="normal"
                  placeholder="Comprimento"
                  {...field}
                  pattern="^\d*(\.\d{0,2})?$"
                  fullWidth
                  onChange={(e) => {
                    validatNonNegative(e, field);
                    calculateTotal();
                  }}
                />
              )}
            />

            <span>=</span>
            <Controller
              control={control}
              name="total"
              rules={{ required: true, minValue: 1 }}
              render={({ field: { ref, ...field } }) => (
                <NTextField
                  id="total-m2"
                  name="total"
                  inputRef={ref}
                  {...register("total")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="M²"
                  {...field}
                  onChange={(e) => validatNonNegative(e, field)}
                />
              )}
            />
          </Row>
        </div>
        <Controller
          control={control}
          name="formaPagamento"
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <NSelect
              id="forma-pagamento"
              inputRef={ref}
              fullWidth
              name="formaPagamento"
              {...register("formaPagamento")}
              title={"Qual melhor forma de pagamento?"}
              placeholder="Pix, Boleto"
              {...field}
            >
              <MenuItem value="PIX">Pix</MenuItem>
              <MenuItem value="BOLETO">Boleto</MenuItem>
            </NSelect>
          )}
        />
      </Row>

      <StandModelsContainer>
        <p>Qual tipo de projeto</p>
        <div>
          <FormControl>
            <Controller
              control={control}
              name="standType"
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  {...field}
                >
                  <StandModel>
                    <FormControlLabel
                      value="BÁSICO"
                      control={<Radio />}
                      label="Stand Básico"
                      onChange={() => {
                        if (checked) {
                          setChecked(!checked);
                        }
                      }}
                    />
                    <img src={stand1} alt="stand-img" />
                  </StandModel>
                  <StandModel>
                    <FormControlLabel
                      value="ECO_PADRÃO"
                      control={<Radio />}
                      label="Eco Padrão"
                      type="checkbox"
                      defaultChecked={checked}
                      onChange={() => {
                        if (checked) {
                          setText("");
                        }
                        setChecked(!checked);
                      }}
                    />
                    <img src={stand5} alt="stand-img" />
                  </StandModel>
                  <StandModel>
                    <FormControlLabel
                      value="MISTO"
                      control={<Radio />}
                      label="Stand Misto"
                      onChange={() => {
                        if (checked) {
                          setChecked(!checked);
                        }
                      }}
                    />
                    <img src={stand2} alt="stand-img" />
                  </StandModel>
                  <StandModel>
                    <FormControlLabel
                      value="CONSTRUÍDO"
                      control={<Radio />}
                      label="Stand Construído"
                      onChange={() => {
                        if (checked) {
                          setChecked(!checked);
                        }
                      }}
                    />
                    <img src={stand3} alt="stand-img" />
                  </StandModel>
                  <StandModel>
                    <FormControlLabel
                      value="QUIOSQUE"
                      control={<Radio />}
                      label="Quiosque"
                      type="checkbox"
                      defaultChecked={checked}
                      onChange={() => {
                        if (checked) {
                          setText("");
                        }
                        setChecked(!checked);
                      }}
                    />
                    <img src={stand4} alt="stand-img" />
                  </StandModel>
                </RadioGroup>
              )}
            />
          </FormControl>
        </div>
      </StandModelsContainer>
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  > span {
    font-size: 1.2rem;
    margin: 4px;
  }


  @media screen and (max-width: 940px) {
    .measurements {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    > span {
      font-size: 1.2rem;
    }
  }
`;

const StandModelsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    margin-top: 1rem;
  }

  > div {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  & .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

const StandModel = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin: 20px 10px;

  img {
    width: 200px;
    height: 130px;
    object-fit: cover;
    padding: 10px;
    border-radius: 20px;

    @media screen and (max-width: 900px) {
      width: 100%;
      height: 300px;
    }
  }

  label {
    padding: 0;
    width: 100%;
    margin: 4px;
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

export default OrcamentoInformation;
