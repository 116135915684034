import styled from "@emotion/styled";
import { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { NRoundedButton } from "./NRoundedButton"
import { COLORS } from "../helpers/types";

const NAlert = forwardRef((props, ref) => {

  const { ...otherProps } = props;
  const {
    title,
    handleCancel,
    handleConfirm,
    children,
    titleConfirm,
    titleCancel,
    role = "EXPOSITOR"
  } = props;

  return (
    <Component {...otherProps}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {titleCancel ? (<NRoundedButton
          onClick={handleCancel}
          title={titleCancel}
        />) : ''}
        <NRoundedButton
          onClick={handleConfirm}
          color={COLORS[role]}
          active={true}
          autofocus
          title={titleConfirm}
        />
      </DialogActions>
    </Component>
  )
})

const Component = styled(Dialog)`

& .MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
  font-size: 2rem;
}

& .MuiDialogContent-root {
  font-size: 1.8rem;
}

`

export {
  NAlert
}