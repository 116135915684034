import RatingStars from "../rating/RatingStars";
import { DashTextArea } from "../../../../shared-components";
import { Column, Wrapper } from "./Styles";
import { useStepForm } from "../StepFormContext";

const Avaliacao = () => {
  const { stateForm, setStateForm } = useStepForm();
  function handleChange(e) {
    setStateForm({
      ...stateForm,
      BriefingManagerRating: [{
        ...stateForm?.BriefingManagerRating[0],
        [e.target.name]: e.target.value,
      }]
    })
  }


  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Avaliação</h2>
      </div>
      <Column>
        <span style={{ fontSize: '1.6rem', fontWeight: '500' }}>Como você avalia o serviço</span>
        <RatingStars
          state={stateForm.BriefingManagerRating[0]?.score || 0}
          handleChange={handleChange}
        />
        <DashTextArea
          id="comments"
          title={"Deixe um comentário"}
          name="comments"
          value={stateForm?.BriefingManagerRating[0]?.comments}
          onChange={handleChange}
        />
        <DashTextArea
          id="positive"
          name="positive"
          title={"O que você gostou?"}
          value={stateForm?.BriefingManagerRating[0]?.positive}
          onChange={handleChange}
        />
        <DashTextArea
          id="negative"
          name="negative"
          title={<span>O que você <strong>não</strong> gostou?</span>}
          value={stateForm?.BriefingManagerRating[0]?.negative}
          onChange={handleChange}
        />
      </Column>

    </Wrapper>

  );
};

export { Avaliacao }