import { getUserData, privateFetch } from "./user";
import axios from "axios";

const API = process.env.REACT_APP_API;

export async function upsertApproval(briefingId, type, status, comment) {
  const token = privateFetch();

  let res;

  if (comment) {
    res = await fetch(
      `${API}/briefing/${briefingId}/approval/${type}/${status}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          comment,
        }),
      }
    );
  } else {
    res = await fetch(
      `${API}/briefing/${briefingId}/approval/${type}/${status}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function upsertTermsApproval(type, status) {
  const token = privateFetch();

  const res = await fetch(
    `${API}/approval/${type}/${status}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
    return res.json;
}

export async function getMemorial(briefingId) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/memorial`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function upsertMemorial(briefingId, memorial) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/memorial`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(memorial),
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function getDonations(id) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing-manager/${id}/donations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function updateDonations(id, donations) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing-manager/${id}/donations`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(donations),
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  console.log(error, "NÃO DEU");

  throw error;
}

export async function getBriefingManager(requestId) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing-manager/${requestId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function updateBriefingManager({
  briefingManagerId,
  uploads,
  rating,
  step,
}) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing-manager/${briefingManagerId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      uploads,
      rating,
      step,
    }),
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function getContract(briefingId) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/contract`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  
    return res.json();
}

export async function sendContract(briefingId, status) {
  const token = await privateFetch();

  const url = `${API}/briefing/${briefingId}/approval/contract/${status}`;

  const res = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: String(status),
    }),
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}

export async function getFatura(briefingId) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/bill`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }

  const error = await res.json();
  throw error;
}
