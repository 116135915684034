import styled from "@emotion/styled";

export function CardContainer({
  children,
  title,
  margin = null,
}) {

  return (
    <>
      <Wrapper margin={margin}>
        <Title>
          <h2>{title}</h2>
        </Title>
        <div>
          {children}
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border-radius: 0 0 0.375rem 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    margin: ${(props) => props.margin ? props.margin : '20px 20px 20px 110px'};

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      width: 100%;
    }

  @media screen and (max-width: 898px) {
      width: 100%;
      margin: 1rem;
  }
`

const Title = styled.section`
    display: flex;
    align-items: center;
    background-color: white;
    background-color: #E3E3E3;
    width: 100%;
    padding: 0 20px;
    height: 80px;

    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
   
   
    > h2 {
      font-size: 3rem;
      font-weight: 900;
    }
`