import React, { useEffect } from "react";
import styled from "@emotion/styled";
import "./fireworks.css"

const FimBriefing = () => {

  useEffect(() => {
    sessionStorage.removeItem('nossostand-briefing')
  })

  return (
    <>
      <Wrapper>
        <div className="pyro">
          <div className="before"></div>
          <div className="after"></div>
        </div>
        <p>Parabéns, você criou um briefing incrível!!!</p>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;


  p {
    font-size: 3rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }

`

export default FimBriefing;