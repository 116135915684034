import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { upsertApproval } from '../../../../api/gestor';
import { useParams } from 'react-router-dom';

function convertTitle(name) {
  if (name === 'ConsideracoesGerais') {
    return 'Considerações Gerais'
  }
  if (name === 'MontagemItems') {
    return 'Montagem de Itens'
  }

  return name;
}


export default function DialogMemorial({
  handleClose,
  open,
  title,
  data,
  showComment,
  refetchMemorial
}) {
  const params = useParams()

  async function handleApprove() {
    await upsertApproval(params.briefingId, "memorial", "approved");
    await refetchMemorial();
    handleClose();
  }

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Column>

            {Object.keys(data?.memorial).map((v, index) => {
              return (
                <div key={`dialog-structs-${index}`}>
                  <SubTitle>{convertTitle(v)}</SubTitle>
                  <div>
                    {data?.memorial[v].map((u, index) => {
                      return (
                        <div key={`dialog-structs-internal-${index}`}>
                          {
                            u.name === u.type ?
                              <></>
                              :
                              <p>
                                <strong>{u.name}</strong>
                              </p>
                          }
                          <p style={{ display: 'flex', flexDirection: 'column' }}>
                            {u.children ?
                              u.children.map((z) => (
                                <span key={`dialog-structs-${z.id}`}>
                                  {z.name} - {z.qtd}
                                </span>
                              ))
                              :
                              u.text ?
                                <span>
                                  {u.text}
                                </span>
                                :
                                <></>
                            }
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </Column>
        </DialogContent>
        {
          data.status === "approved" ?
            <DialogActions>
              <span style={{ color: "#495cff" }}>Memorial aprovado</span>
            </DialogActions>
            :
            data.status === "disapproved" ?
              <DialogActions>
                <span style={{ color: "#c11919" }}>Memorial reprovado</span>
              </DialogActions>
              :
              <ActionButtons>
                <Button color={"#adb5bd"} onClick={handleClose}>Fechar</Button>
                <div style={{ display: 'flex' }}>
                  <Button color={"#adb5bd"} onClick={showComment}>Reprovar</Button>
                  <Space />
                  <Button color={"#FCA500"} onClick={handleApprove}>Aprovar</Button>
                </div>
              </ActionButtons>
        }
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #FDC22A;
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & p {
    font-size: 1.8rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.5rem;
  }
  
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: '100%';
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#FDC22A" : color};
  background: ${({ color }) => !color ? "#FDC22A" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`

const Space = styled.div`
  width: 1rem;
`