import styled from "@emotion/styled"
import { Link } from "react-router-dom"

export function CardProfileSession({
  title, subTitle, Icon, link
}) {

  return (
    <Wrapper>
      <Link to={link}>
        <Icon />
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 15rem;
  width: 20%;
  cursor: pointer;
  transition: all linear 200ms;

  &:hover {
    box-shadow: 0 4px 10px #adb5bd;
    top: 1px;
  }

  @media screen and (max-width: 898px) {
    width: 100%;
  }
`

const Title = styled.h2`
  margin-top: 10px;
  font-weight: 200;
  font-size: 2rem;
  color: black;
`

const SubTitle = styled.p`
  color: black;
  font-weight: 300;
`