export const BRIEFING_TYPE_DB = Object.freeze({
  1: "PROJETO",
  2: "PROJETO_MONTADORA",
  3: "MONTAGEM",
  4: "OUTRO"
})

export const BRIEFING_TYPE_DB_NAME = Object.freeze({
  "PROJETO": 1,
  "PROJETO_MONTADORA": 2,
  "MONTAGEM": 3,
  "OUTRO": 4,
})


export const BRIEFING_TYPE = Object.freeze({
  "PROJETO": "Projeto",
  "PROJETO_MONTADORA": "Projeto + Montagem",
  "MONTAGEM": "Montagem",
  "OUTRO": "Outro"
})