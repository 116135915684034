import { useState } from "react";
import styled from "@emotion/styled";
import { UploadFileBlock } from "../../../../shared-components";
import { generateImageLink } from "../../../../helpers/generateImageLink";

export function FragmentUploadProject({
  briefingProjects,
  handleChangeProject,
  ...otherProps
}) {
  const [source, setSource] = useState(() => generateImageLink(briefingProjects));

  function getFile(e, res) {
    const uploadedImage = {
      type: "upload",
      image: res.name
    }
    setSource(() => generateImageLink(uploadedImage))
    handleChangeProject(e, res);
  }

  return (
    <ContainerReference>
      <UploadFileBlock
        {...otherProps}
        width='100%'
        height='40vh'
        imageFile={source?.image || source?.link}
        getFile={getFile}
        download={false}
      />

    </ContainerReference>
  )
}


const ContainerReference = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
    
`
