export const STRUCTS_NAME = Object.freeze({
  FREEZER: "FREEZER",
  BALCAO: "BALCÃO",
  OBJETOS_SUSPENSOS: "OBJETOS_SUSPENSOS",
  COFFEEBREAK: "COFFEBREAK",
  PRATELEIRAS_PAREDE: "PRATELEIRAS_PAREDE",
  MOBILIA: "MOBILIA",
  MOVEL_EXPOSITOR: "MOVEL_EXPOSITOR",
  PAISAGISMO: "PAISAGISMO",
  SALA_REUNIAO: "SALA_REUNIÃO",
  ESTOQUE: "ESTOQUE"
})

export const ELEMENTS_NAME = Object.freeze({
  ALTURA_PISO: "ALTURA_PISO",
  ALTURA_STAND: "ALTURA_STAND",
  TIPO_PISO: "TIPO_PISO",
  TESTEIRA: "TESTEIRA",
  MEZANINO: "MEZANINO",
  PONTOS_ELETRICOS: "PONTOS_ELETRICOS",
})

export const MULTIMEDIA_NAME = Object.freeze({
  TV_QUANTIDADE: "TV_QUANTIDADE",
  TV_TAMANHO: "TV_TAMANHO",
  PAINEL_LED: "PAINEL_LED",
  ILUMINAÇÃO: "ILUMINAÇÃO",
  CAIXA_SOM: "CAIXA_SOM",
  LIXEIRAS: "LIXEIRAS",
})

export const SALA_REUNIAO_ITEMS = Object.freeze([
  { name: "2_4_PX", value: "2-4 px" },
  { name: "5_7_PX", value: "5-7 px" },
  { name: "8_12_PX", value: "8-12 px" },
])

export const PAISAGISMO_ITEMS = Object.freeze([
  { name: "PLANTAS_VASOS", value: "Plantas com vasos" },
  { name: "JARDINS_VERTICAIS", value: "Jardins Verticais" },
  { name: "PROJETO_ESPECIAL", value: "Projeto especial" },
  { name: "OUTROS", value: "Outros" },
])

export const MOVEL_EXPOSITOR_ITEMS = Object.freeze([
  { name: "VITRINES", value: "Vitrines" },
  { name: "MESAS", value: "Mesas" },
  { name: "PRATELEIRA_PAREDE", value: "Prateleira na parede" },
])

export const COFFEBREAK_ITEMS = Object.freeze([
  { name: "APARADOR", value: "Aparador" },
  { name: "BALCÃO", value: "Balcão" },
  { name: "MESA", value: "Mesa" },
])

export const ALTURA_STAND_ITEMS = Object.freeze([
  { name: "2.5M", value: "2.5m" },
  { name: "3M", value: "3m" },
  { name: "3.5M", value: "3.5m" },
  { name: "4M", value: "4m" },
  { name: "4.5M", value: "4.5m" },
  { name: "MAX", value: "Máximo possível" },
])

export const ALTURA_PISO_ITEMS = Object.freeze([
  { name: "SEM_ELEVAÇÃO", value: "Sem elevação" },
  { name: "5M", value: "5m" },
  { name: "10M", value: "10m" },
  { name: "OUTROS", value: "Outros" },
])

export const TIPO_PISO_ITEMS = Object.freeze([
  { name: "VINÍLICO", value: "Vinílico" },
  { name: "CARPETE", value: "Carpete" },
  { name: "MADEIRA", value: "Madeira" },
  { name: "VIDRO", value: "Vidro" },
  { name: "OUTROS", value: "Outros" },
])

export const TIPO_TESTEIRA_ITEMS = Object.freeze([
  { name: "RETANGULAR", value: "Retangular" },
  { name: "CURVADA", value: "Curvada" },
  { name: "SUSPENSA", value: "Suspensa" },
  { name: "OUTROS", value: "Outros" },
])

export const TIPO_MEZANINO_ITEMS = Object.freeze([
  { name: "MEIO_MEZANINO", value: "Meio Mezanino" },
  { name: "COMPLETO", value: "Mezanino completo" },
])

export const MOBILIA_ITEMS = Object.freeze([
  { name: "LOUNGE", value: "Lounge" },
  { name: "POLTRONAS", value: "Poltronas" },
  { name: "APARADOR", value: "Aparador" },
  { name: "MESAS_CADEIRAS", value: "Mesas + Cadeiras" },
  { name: "MESAS", value: "Mesas" }
])

export const ESTOQUE_ITEMS = Object.freeze([
  { name: "PRATELEIRAS_PAREDE", value: "Com pratelerias na parede" },
  { name: "ESTANTES", value: "Com estantes no chão" },
])

export const TV_SIZE_ITEMS = Object.freeze([
  { name: "32-34pol", value: "32-34pol" },
  { name: "45-55pol", value: "45-55pol" },
  { name: "56-70pol", value: "56-70pol" },
  { name: "MAX", value: "Máx. possível" },
])

export const LIGHT_ITEMS = Object.freeze([
  { name: "REFLETORES_LED", value: "Refletores de led" },
  { name: "COLORAÇÃO_ESPECIAL", value: "Colaração especial" },
  { name: "PROJECT", value: "Projeto Cênico" },
  { name: "OUTROS", value: "Outros" },
])

export const SOUND_BAR_ITEMS = Object.freeze([
  { name: "SIM", value: "Sim" },
  { name: "NÃO", value: "Não" },
])

export const CAUSES_ONGS = Object.freeze([
  { name: "FOME", value: "Fome" },
  { name: "CRIANÇAS", value: "Crianças" },
  { name: "EDUCAÇÃO", value: "Educação" },
  { name: "INTERCÂMBIO_CULTURAL", value: "Intercâmbio Cultural" },
])

