import { useEffect } from "react";
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom";
import FragmentBriefingBudget from "./components/FragmentBriefingBudget";
import {
  listRequestBriefings
} from "../../../api/briefing"
import Loading from "../../../shared-components/Loading";
import { Error } from "../../../shared-components";
import { groupedBriefingsPerPropostals } from "../../../components/DashboardExpositorComponents/solicitacaoPlataforma/SolicitEmNegoc";
import FragmentBriefingOpen from "../../../components/DashboardExpositorComponents/solicitacaoPlataforma/FragmentBriefingOpen";



function BriefingAberto({ briefing }) {

  return (
    <FragmentBriefingOpen
      briefingData={briefing}
    />
  )
}

function BriefingNegociacao({ briefing }) {
  const { data, isLoading} = useQuery(['list', 'briefing'], () => listRequestBriefings());

  if (isLoading) {
    return (
      <Loading />
    )
  }

  const briefingRequest = data.filter(v => v.Briefing.id === briefing.id);

  const groupedBriefingRequest = groupedBriefingsPerPropostals(briefingRequest[0]?.requests || []);

  if (groupedBriefingRequest.length > 0) {
    return (
      <FragmentBriefingBudget
        briefingRequest={groupedBriefingRequest[0]}
      />
    )
  }

  return (
    <Error />
  )
}

function BriefingAceito({ briefing }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/expositor/gestor/${briefing.id}`)
  }, [])

}

export function BriefingExpositor({ data }) {

  if (data.status === 'ABERTO') {
    return (
      <BriefingAberto briefing={data} />
    )
  }

  if (data.status === 'EM_NEGOCIAÇÃO') {
    return (
      <BriefingNegociacao briefing={data} />
    )
  }

  //Deve levar para o GESTOR
  if (data.status === 'ACEITO') {
    return (
      <BriefingAceito briefing={data} />
    )
  }

}