import { useState } from "react";
import styled from "@emotion/styled";
import { UploadFileBlock } from "../../../../../shared-components";
import { generateImageLink } from "../../../../../helpers/generateImageLink";
import { Title } from "./StyledComponents";
import { useUser } from "../../../../../context/UserContext";

function BlockProject({
  projectFile,
  handleChangeProject,
  ...otherProps
}) {

  const [source, setSource] = useState(() => generateImageLink(projectFile));
  const { userData } = useUser();

  const download =
    userData.role.name === "ADMIN"
      || userData.role.name === "ADMIN_ASSISTENT" ? true : false;
  const canDelete =
    userData.role.name === "ADMIN"
      || userData.role.name === "ADMIN_ASSISTENT" ? true : false;

  function getFile(e, res) {
    const uploadedProject = {
      type: "upload",
      link: res.name
    }
    const newUpload = generateImageLink(uploadedProject)
    setSource(() => generateImageLink(newUpload))

    handleChangeProject(e, res);
  }

  function deleteFile(e) {
    e.preventDefault();
    setSource(null);
    const fakeEvent = {
      target: {
        id: otherProps.id,
      }
    }
    handleChangeProject(fakeEvent);
  }

  return (
    <UploadFileBlock
      {...otherProps}
      width='100%'
      height='100%'
      imageFile={source?.image || source?.link}
      getFile={getFile}
      download={download}
      canDelete={canDelete}
      deleteFile={deleteFile}
    />
  )
}

export function FragmentUploadProject({
  briefingData,
  stateForm,
  setStateForm,
}) {
  const [projectFiles, setProjectFiles] = useState(() => createProjectsArray())


  function createProjectsArray() {
    const projects = briefingData?.BriefingProjects || [];
    const projectsLength = projects?.length;

    for (let i = projectsLength; i < 4; i++) {
      projects.push({
        observations: ''
      })
    }
    return projects;
  }

  function handleChangeProject(e, res) {
    const position = e.target.id.split("_")[1]

    let uploadedProject = {
      type: "upload",
      link: res?.name
    }

    const refs = stateForm.BriefingProjects;
    if (!res) {
      uploadedProject = {
        id: refs[position],
        type: "upload",
        link: ''
      }
    }
    refs[position] = uploadedProject;
    setStateForm({
      ...stateForm,
      BriefingProjects: [
        ...refs
      ]
    })
  }


  return (
    <>
      <Title>Projetos</Title>
      <Row style={{
        justifyContent: 'space-between',
        padding: '0 0'
      }}>
        {projectFiles.map((project, index) => (
          <ContainerReference key={`project-${index}`}>
            <BlockProject
              key={`'projects_${index}`}
              id={`'projects_${index}`}
              projectFile={project}
              handleChangeProject={handleChangeProject}
            />
          </ContainerReference>
        ))}
      </Row>
    </>
  )
}


const ContainerReference = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 30rem;
  height: 30rem;
  margin: 1rem 0;

  @media only screen and (max-width: 768px) {
    width: 60vw;
    height: 40vh;
    margin: 1rem 0;

  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0;
  flex-wrap: wrap;
  
  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 4px; 
  }

  @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    > span {
      flex-direction: column;
      align-items: center;
      
    }
  }
`
