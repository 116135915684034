import { useParams } from "react-router-dom"
import { useQuery } from "react-query";
import { MenuItem } from "@mui/material";
import {
  getUserProfile,
  createPaymentForUser,
  updateProfileUser
} from "../../api/admin";
import {
  DashInput,
  DashSelect,
  NRoundedButton
} from "../../shared-components";
import {
  Wrapper,
  Row,
  Title,
} from "./StyledComponents";
import { useSimpleForm } from '../../hooks/useSimpleForm';
import { useNotification } from '../../context/NotificationContext';

function ProfileForm({
  profileData,
}) {

  const notification = useNotification();
  const {
    stateForm,
    handleChange
  } = useSimpleForm({
    name: profileData.name || '',
    email: profileData.email || '',
    document: profileData.document || '',
  })

  const {
    stateForm: stateFormPayment,
    handleChange: handleChangePayment
  } = useSimpleForm({
    value: '',
    method: 'Boleto',
    description: '',
  })


  function handleUpdateDocument() {
    updateProfileUser({
      usersId: profileData.id,
      profile: {
        document: stateForm.document,
      }
    }).then(res => {
      notification.show({
        message: 'Documento atualizado'
      })
    }).catch(error => {
      notification.show({
        message: 'Não foi possível atualizar o documento' || error,
        type: 'error',
      })
    })
  }

  function handleCreatePayment() {
    createPaymentForUser({
      usersId: profileData.id,
      paymentValue: stateFormPayment.value,
      paymentMethod: stateFormPayment.method,
      userDocument: stateForm.document,
      description: stateFormPayment.description,
    }).then(res => {
      notification.show({
        message: 'Pagamento criado'
      })
    }).catch(error => {
      notification.show({
        message: error?.message || 'Não foi possível criar o pagamento',
        type: 'error',
      })
    })

  }


  return (
    <Wrapper>
      <Title>
        Dados do Usuário
      </Title>
      <Row style={{
        justifyContent: 'flex-start'
      }}>
        <DashInput
          title="Nome"
          disabled={true}
          value={stateForm?.name}
        />
        <DashInput
          title="Email"
          disabled={true}
          value={stateForm?.email}
        />
        <DashInput
          title="Documento"
          name="document"
          value={stateForm?.document}
          onChange={handleChange}
        />
        <div style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}>
          <NRoundedButton
            title="Atribuir documento"
            active={true}
            color="#FF8400"
            onClick={handleUpdateDocument}
          />
        </div>
      </Row>
      <Title>Dados do Pagamento</Title>
      <Row style={{
        justifyContent: 'flex-start'
      }}>
        <DashInput
          title="Valor do Pagamento (R$)"
          type="number"
          name="value"
          value={stateFormPayment?.value}
          onChange={handleChangePayment}
        />
        <span>
          <DashSelect
            title="Forma do Pagamento"
            name="method"
            value={stateFormPayment?.method}
            onChange={handleChangePayment}
          >
            <MenuItem value="Boleto" >Boleto</MenuItem>
          </DashSelect>
        </span>
        <DashInput
          title="Descrição"
          name="description"
          value={stateFormPayment?.description}
          onChange={handleChangePayment}
        />
        <div style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}>
          <NRoundedButton
            title="Criar pagamento"
            active={true}
            color="#FF8400"
            onClick={handleCreatePayment}
            disabled={!stateForm?.document}
          />
        </div>
      </Row>
    </Wrapper>
  )

}



const CriarPagamento = () => {

  const { usersId } = useParams();
  const { data, isLoading } = useQuery(`profile-${usersId}`, () => getUserProfile(usersId))



  if (isLoading) {
    return <>carregando...</>
  }

  return (
    <ProfileForm
      profileData={data}
    />
  )

}

export {
  CriarPagamento,
}