//TODO: refactor
const API = process.env.REACT_APP_API;

export async function searchCities(cityName) {

  const res = await fetch(`${API}/open/cities?city=${cityName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.json();
}

export async function searchEvents(eventName) {

  const res = await fetch(`${API}/open/events?event=${eventName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.json();
}


export async function socialCauses() {
  const res = await fetch(`${API}/open/causes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.json();
}

export async function searchCep(CEP) {
  const res = await fetch(`https://viacep.com.br/ws/${CEP}/json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.json();
}