import {
  getUserData,
  privateFetch
} from "./user";

const API = process.env.REACT_APP_API;

export async function registerOng({
  name,
  ownerName,
  externalLink,
  causes,
  email,
  ownerPhone,
}) {
  const res = await fetch(`${API}/open/ong`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      ownerName,
      externalLink,
      causes,
      email,
      ownerPhone,
    }),
  });

  if (res.ok) {
    return await res.json();
  }

  const error = await res.json();
  throw error;
}


export async function getCauses() {

  const res = await fetch(`${API}/open/causes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.json();
}