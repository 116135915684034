import React, { useState, useEffect } from 'react'
import BriefingTable from '../../../pages/Dashboard/SolicitOrcamento/BriefingTable';
import {
  listBriefingAll,
  listBriefingProjects,
  listBriefingBuild,
  listBriefingBuildAndProjects,
  listBriefingArchived,
  listBriefingDeleted
} from "../../../api/admin";
import { ListBriefings } from '../../../pages/Dashboard/SolicitOrcamento/ListBriefings';

async function dispatchFetch(action) {
  if (action === 'projeto') {
    return listBriefingProjects();
  }
  if (action === 'projeto_montagem') {
    return listBriefingBuildAndProjects();
  }
  if (action === 'montagem') {
    return listBriefingBuild();
  }
  if (action === 'apagados') {
    return listBriefingDeleted();
  }
  if (action === 'arquivados') {
    return listBriefingArchived();
  }

  return listBriefingAll();
}

function ListExpositor() {
  const [fetchType, setFetchType] = useState('projeto');
  const [briefings, setBriefings] = useState(null);

  useEffect(() => {
    dispatchFetch(fetchType).then(res => setBriefings(res))
  }, [fetchType])

  return (
    <>
      {/* <BriefingTable
        data={briefings}
        setFetchType={setFetchType}
      /> */}
      <ListBriefings />
    </>
  )
}

export default ListExpositor;