import { useState, useEffect } from "react";
import { useQuery } from "react-query"
import { useParams } from "react-router-dom";
import {
  getBriefing,
  getBriefingRequest
} from "../../../api/briefing";
import SideBarADM from "../SideBarADM";
import FragmentBriefingOpen from "../../../components/DashboardExpositorComponents/solicitacaoPlataforma/FragmentBriefingOpen";
import FragmentBriefingBudget from "../../../components/DashboardExpositorComponents/solicitacaoPlataforma/FragmentBriefingBudget";
import DialogBuilders from "./fragments/DialogBuilder";
import DialogDesigner from "./fragments/DialogDesigner";
import {
  sendBriefingForBuilders,
  sendBriefingForDesigners
} from "../../../api/admin";
import { useBuilder } from "./hooks/useBuilder";
import { useDesigner } from "./hooks/useDesigner";
import Loading from "../../../shared-components/Loading";
import { useNotification } from "../../../context/NotificationContext";

const groupedBriefingsPerPropostals = (requests = [], taxBriefing) =>
  Object.values(
    requests.reduce((acc, request) => {
      const totalArea = (request?.Briefing?.depth * request?.Briefing?.length) || 1;
      const key = JSON.stringify({ briefing: request.Briefing });
      if (!acc[key]) {
        acc[key] = {
          id: request.id,
          builderId: request.builderId,
          briefingId: request.briefingId,
          proposals: [
            {
              requestId: request.id,
              finalPriceMin: request.finalPriceMin * totalArea,
              finalPriceMax: request.finalPriceMax * totalArea,
              finalPrice: request.finalPrice,
              tax: 1 + taxBriefing?.value/100,
              finalPriceExpositor: request.finalPrice && taxBriefing?.value ? request.finalPrice * (1 + taxBriefing.value/100) : null,
              deliveryAt: request.deliveryAt,
              Builder: request?.Builder
            },
          ],
          status: request.status,
          briefingReferencesId: null,
          createdAt: request.createdAt,
          updatedAt: request.updatedAt,
          BriefingRequestReference: request.BriefingRequestReference,
          Briefing: {
            id: request.Briefing.id,
            Approval: request?.Briefing?.Approval,
            Memorial: request?.Briefing?.Memorial,
            eventName: request.Briefing.eventName,
            otherType: request.Briefing.otherType,
            city: request.Briefing.city,
            priceMin: request.Briefing.priceMin,
            priceMax: request.Briefing.priceMax,
            length: request.Briefing.length,
            depth: request.Briefing.depth,
            deadline: request.Briefing.deadline,
            observations: request.Briefing.observations,
            blueprint: request.Briefing.blueprint,
            blueprintType: request.Briefing.blueprintType,
            blueprint_observation: request.Briefing.blueprint_observation,
            promoterQuantity: request.Briefing.promoterQuantity,
            buffet: request.Briefing.buffet,
            status: request.Briefing.status,
            standTypeId: request.Briefing.standTypeId,
            briefingTypeId: request.Briefing.briefingTypeId,
            usersId: request.Briefing.usersId,
            paymentTypeId: request.Briefing.paymentTypeId,
            createdAt: request.Briefing.createdAt,
            updatedAt: request.Briefing.updatedAt,
            eventsId: request.Briefing.eventsId,
            BriefingReferences: request.Briefing.BriefingReferences,
            BriefingProjects: request.Briefing.BriefingProjects,
            BriefingManagerTasks: request.Briefing.BriefingManagerTasks,
            User: {
              id: request.Briefing.User.id,
              rolesId: request.Briefing.User.rolesId,
              status: request.Briefing.User.status,
              Exhibitors: request.Briefing.User.Exhibitors,
              Builders: request.Briefing.User.Builders,
              Promoter: request.Briefing.User.Promoter,
            },
          },
        };
      } else {
        acc[key].proposals.push({
          requestId: request.id,
          finalPriceMin: request.finalPriceMin * totalArea,
          finalPriceMax: request.finalPriceMax * totalArea,
          tax: 1 + taxBriefing?.value/100,
          finalPriceExpositor: request.finalPrice && taxBriefing.value ? request.finalPrice * (1 + taxBriefing.value/100) : null,
          deliveryAt: request.deliveryAt,
          Builder: request?.Builder
        });
      }
      return acc;
    }, {})
  );

function renderCardBriefing({
  briefingData,
  briefingRequest,
  isLoadingBriefing,
  isLoadingRequest,
  newData
}) {

  if (isLoadingBriefing || isLoadingRequest) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
        <Loading role="ADMIN" />
      </div>
    )
  }

  if (briefingData.status === "ABERTO") {
    return <FragmentBriefingOpen
      briefingData={briefingData}
    />
  }
  if (briefingData.status === "EM_NEGOCIAÇÃO" || briefingData.status === "ACEITO") {
    if (Array.isArray(briefingRequest)) {
      return newData?.map((value, id) => (
        <FragmentBriefingBudget
          key={`budget-${id}`}
          briefingRequest={value}
          briefingData={briefingData}
        />))
    }
    return (
      <FragmentBriefingBudget
        briefingRequest={briefingRequest}
      />
    )
  }
}


export default function EditBriefing() {

  const params = useParams();
  const notification = useNotification();
  const { briefingId } = params;

  const {
    data: briefingData,
    isLoading: isLoadingBriefing,
  } = useQuery(
    `briefing-${briefingId}`,
    () => getBriefing(briefingId));

  const {
    data: briefingRequest,
    isLoading: isLoadingRequest
  } = useQuery(
    `request-${briefingId}`, () => getBriefingRequest(briefingId));

  const {
    handleBuilders,
    modalOpenBuilder,
    setModalOpenBuilder,
    handleCloseModalBuilder,
    handleOpenModalBuilder,
    builders
  } = useBuilder({ briefingId })

  const {
    modalOpenDesigner,
    handleDesigners,
    handleCloseModalDesigner,
    handleOpenModalDesigner,
    designers,
    setModalOpenDesigner
  } = useDesigner({ briefingId })


  const [newData, setNewData] = useState(() => groupedBriefingsPerPropostals(briefingRequest)
  );

  useEffect(() => {
    setNewData(() => groupedBriefingsPerPropostals(briefingRequest, briefingData?.TaxBriefing))
  }, [briefingRequest, briefingData]);

  function submitBuilders() {
    sendBriefingForBuilders({
      briefingId,
      buildersId: builders,
    }).then(res => {
      notification.show({
        message: 'Escolha das montadoras atualizada'
      })
    })
      .catch(err => {
        notification.show({
          message: 'Houve um erro ao atualizar a lista'
        })
      })
    setModalOpenBuilder(false)
  }

  function submitDesigners() {
    sendBriefingForDesigners({
      briefingId,
      designersId: designers,
    }).then(res => {
      notification.show({
        message: 'Escolha dos projetista atualizada'
      })
    })
      .catch(err => {
        notification.show({
          message: 'Houve um erro ao atualizar a lista'
        })
      })
    setModalOpenDesigner(false)
  }


  return (
    <div className="cont-geral">
      <SideBarADM />
      <div className="btns-solict2">
        <button
          className="promotor-btn"
          onClick={handleOpenModalBuilder}
          disabled={isLoadingBriefing || isLoadingRequest}
        >Atribuir a montadoras
        </button>
        <button
          className="promotor-btn"
          onClick={handleOpenModalDesigner}
          disabled={isLoadingBriefing || isLoadingRequest}
        >Atribuir a projetistas
        </button>


      </div>
      <div className="main-cont">
        <DialogBuilders
          handleClose={handleCloseModalBuilder}
          handleBuilders={handleBuilders}
          open={modalOpenBuilder}
          title={"Escolha as Montadoras"}
          builders={builders}
          submit={submitBuilders}
        />
        <DialogDesigner
          handleClose={handleCloseModalDesigner}
          handleDesigners={handleDesigners}
          open={modalOpenDesigner}
          title={"Escolha os Projetistas"}
          designers={designers}
          submit={submitDesigners}
        />

        <div className="cont-charts2">
          {renderCardBriefing({
            briefingData,
            briefingRequest,
            isLoadingBriefing,
            isLoadingRequest,
            newData
          })
          }
        </div>
      </div>
    </div>
  )
}


