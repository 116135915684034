import styled from '@emotion/styled';
import { useSimpleForm } from '../../hooks/useSimpleForm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NTextField } from '../../shared-components';
import { createQuickExhibitor } from '../../api/exhibitor';
import { useNotification } from "../../context/NotificationContext";
import { saveToken } from '../../api/login';

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export default function DialogNewUser({
  handleClose,
  open,
  title,
}) {

  const notification = useNotification();

  const { stateForm, handleChange } = useSimpleForm({
    name: "",
    email: ""
  });

  function handleSubmit() {

    const isValidEmail = validateEmail(stateForm.email)
    if (!isValidEmail) {
      notification.show({ message: 'Insira um email válido', type: "error" })
      return;
    }
    createQuickExhibitor(stateForm)
      .then(res => {
        notification.show({ message: 'Cadastro criado' })
        handleClose();
        saveToken(res.token);
      })
      .catch(err => {
        notification.show({ message: err.message, type: "error" })
      })
  }

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <SubTitle>Preencha os campos e continue criando seu <strong>briefing incrível</strong>. É super rápido!</SubTitle>

            <Row>
              <NTextField
                placeholder="Nome"
                name="name"
                value={stateForm.name}
                onChange={handleChange}
                fullWidth
              />
              <NTextField
                placeholder="Email"
                name="email"
                value={stateForm.email}
                onChange={handleChange}
                fullWidth
              />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              color={"#E3E3E3"}
              onClick={handleClose}>
              deixa pra lá
            </Button>
            <Button
              color={"#FF8400"}
              type="submit">
              Continuar
            </Button>
          </DialogActions>
        </form>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  margin: 14px 0;

  strong {
    color: #FF8400;
  }
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;
  gap: 20px;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 6px;
  border: 2px solid ${({ color }) => !color ? "#22da28" : color};
  background: ${({ color }) => !color ? "#22da28" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`