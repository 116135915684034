import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../components/privateRoute/PrivateRoutes";

import SolicitPlataformaMont from "../components/DashboardMontadorComponents/pedidosDeOrcamento/SolicitPlataformaMont";
import SolicitEmAbertoMontador from "../components/DashboardMontadorComponents/pedidosDeOrcamento/SolicitEmAbertoMontador";
import DetalheSolicitEmAbertoMontador from "../components/DashboardMontadorComponents/pedidosDeOrcamento/DetalheSolicitEmAbertoMontador";
import SolicitEmNegocMont from "../components/DashboardMontadorComponents/pedidosDeOrcamento/SolicitEmNegocMont";
import SolicitAprovadaMont from "../components/DashboardMontadorComponents/pedidosDeOrcamento/SolicitAprovadaMont";
import DadosEmpresaMontador from "../components/DashboardMontadorComponents/editarPerfil/DadosEmpresaMontador";
import EntrarSegurancaMontador from "../components/DashboardMontadorComponents/editarPerfil/EntrarSegurancaMontador";
import ServicosRegioesMMontador from "../components/DashboardMontadorComponents/editarPerfil/ServicosEregioes";
import ContratoAprovadoMontador from "../components/DashboardMontadorComponents/contratoAprovado/contratoAprovadoMontador";
import DashboardMontador from "../pages/Dashboard/DashboardMontador";
import MontadoraLayout from "../layouts/MontadoraLayout";
import SolicitEmAbertoDetails from "../components/DashboardMontadorComponents/pedidosDeOrcamento/SolicitEmAbertoDetails";
import { MontadorProfile } from "../components/dashboardProfile/MontadorProfile";
import { MontadorProfileForm } from "../components/dashboardProfile/MontadorProfileForm";
import { FragmentProfileForm } from "../components/dashboardProfile/fragments/FragmentProfileForm";
import { FragmentPersonForm } from "../components/dashboardProfile/fragments/FragmentPersonForm";
import { FragmentSecurity } from "../components/dashboardProfile/fragments/FragmentSecurity";
import Gestor from "../components/DashboardExpositorComponents/fluxProjetoEmontagem/Gestor";
import BriefingManager from "../components/BriefingManager/BriefingManager";
import { FragmentEnterpriseForm } from "../components/dashboardProfile/fragments/FragmentEnterpriseForm";
import ListBriefing from "../pages/ListBriefing/ListBriefing";
import EditBriefing from "../pages/EditBriefing/EditBriefing";
import NovoBriefing from "../pages/Briefing/NovoBriefing";
import TermsOfService from "../pages/TermosDeUso/TermsOfService";
import MontadorLayoutNovo from "../layouts/MontadorLayoutNovo";
import MontadoraLayoutNovo from "../layouts/MontadorLayoutNovo";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route element={<PrivateRoutes role="MONTADOR" />}>
    <Route
      path="/dashboard-montador"
      element={
        <DashboardMontador />
       }
      exact
      strict key="dashboard-montador"
    />
    <Route
      path="/montador/novo-briefing"
      element={
        <MontadoraLayout>
          <NovoBriefing />
        </MontadoraLayout>
      }
      exact
      strict key="novo-briefing"
    />
    <Route
      path="/montador/lista"
      element={
        <MontadoraLayoutNovo>
          <ListBriefing />
        </MontadoraLayoutNovo>
      }
      exact
      strict key="montador-list-briefings"
    />
    <Route
      path="/montador/briefing/:briefingId"
      element={
        <MontadoraLayoutNovo>
          <EditBriefing />
        </MontadoraLayoutNovo>
      }
      exact
      strict key="montador-edit-briefings"
    />
    <Route
      path="/orcamento-montador"
      element={<SolicitPlataformaMont />}
      exact
      strict key="orcamento-montador"
    />
    <Route
      path="/solicit-aberto-montador"
      element={
        <MontadoraLayout>
          <SolicitEmAbertoMontador />
        </MontadoraLayout>
      }
      exact
      strict key="solicit-aberto-montador"
    />
    <Route
      path="/solicit-aberto-montador/:briefingId"
      element={
        <MontadoraLayout>
          <SolicitEmAbertoDetails />
        </MontadoraLayout>
      }
      strict key="solicit-aberto-montador-briefingId"
    />
    <Route
      path="/solicit-aberto-montador/:briefingId"
      element={<DetalheSolicitEmAbertoMontador />}
      exact
    />
    <Route
      path="/solicit-negociacao-sem-proposta-montador"
      element={
        <MontadoraLayout>
          <SolicitEmNegocMont flag={false} />
        </MontadoraLayout>
      }
      exact
    />
    <Route
      path="/solicit-negociacao-com-proposta-montador"
      element={
        <MontadoraLayout>
          <SolicitEmNegocMont flag={true} />
        </MontadoraLayout>
      }
      exact
      strict key="solicit-negociacao-com-proposta-montador"
    />
    <Route
      path="/solicit-aprovada-montador"
      element={
        <MontadoraLayout>
          <SolicitAprovadaMont />
        </MontadoraLayout>
      }
      exact
      strict key="solicit-aprovada-montador"
    />
    <Route
      path="/montador/gestor/:briefingId"
      element={
        <MontadoraLayout>
          <Gestor />
        </MontadoraLayout>
      }
      exact
      strict key="gestor-montador"
    />
    <Route
      path="/perfil-montador"
      element={
        <MontadoraLayoutNovo>
          <MontadorProfile />
        </MontadoraLayoutNovo>
      }
      exact
      strict key="perfil-montado"
    />
    <Route
      path="/edit-pagina-montador"
      element={
        <MontadoraLayout>
          <MontadorProfileForm
            title={
              <span>
                Hey <strong>Montador</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentProfileForm color="#22DA29" />
          </MontadorProfileForm>
        </MontadoraLayout>
      }
      exact
      strict key="edit-pagina-montador"
    />
    <Route
      path="/montador/dados-empresa"
      element={
        <MontadoraLayout>
          <MontadorProfileForm
            title={
              <span>
                Hey <strong>Montador</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentEnterpriseForm color="#22DA29" />
          </MontadorProfileForm>
        </MontadoraLayout>
      }
      exact
      strict key="dados-empresa-motador"
    />

    <Route
      path="/informacoes-pessoais-montador"
      element={
        <MontadoraLayout>
          <MontadorProfileForm
            title={
              <span>
                Hey <strong>Montador</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentPersonForm color="#22DA29" />
          </MontadorProfileForm>
        </MontadoraLayout>
      }
      exact
      strict key="informacoes-pessoais-montado"
    />
    <Route
      path="/entrar-seguranca-montador"
      element={
        <MontadoraLayout>
          <MontadorProfileForm
            title={
              <span>
                Hey <strong>Montador</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentSecurity color="#22DA29" />
          </MontadorProfileForm>
        </MontadoraLayout>
      }
      exact
      strict key="entrar-seguranca-montador"
    />
    <Route
      path="/servico&regioes-montador"
      element={<ServicosRegioesMMontador />}
      exact
      strict key="servico&regioes-montador"
    />
    <Route
      path="/contrato-aprovado-montador"
      element={<ContratoAprovadoMontador />}
      exact
      strict key="contrato-aprovado-montador"
    />
    <Route
      path="/montador/projeto&montagem"
      element={
        <MontadoraLayoutNovo>
          <BriefingManager />
        </MontadoraLayoutNovo>
      }
      exact
      strict key="projeto&montagem"
    />
    <Route
      path="/montador/termos-de-uso"
      element={
        <MontadoraLayout sideBarOff>
          <TermsOfService />
        </MontadoraLayout>
      }
      exact
      strict key="termos-de-uso"
    />
  </Route>
]

