import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { DashInput } from '../../shared-components';
import { upsertFinalPrice } from '../../api/admin';
import { useNotification } from '../../context/NotificationContext';

function convertTitle(name) {
  if (name === 'ConsideracoesGerais') {
    return 'Considerações Gerais'
  }
  if (name === 'MontagemItems') {
    return 'Montagem de Itens'
  }

  return name;
}


export default function DialogMemorial({
  handleClose,
  open,
  priceFinal,
  requestId,
  tax
}) {
  const [finalPrice, setFinalPrice] = useState('');
  const [initialFinalPrice, setInitialFinalPrice] = useState(priceFinal ?? 0);
  const notification = useNotification();

  function handleChange(e) {
    setFinalPrice(e.target.value);
    setInitialFinalPrice(e.target.value);
  }

  async function handleSend() {
    upsertFinalPrice(requestId, finalPrice)
      .then(res => {
        notification.show({
          message: "Sucesso"
        });
        handleClose();
      })
  }

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title" style={{
          textAlign: "center"
        }}>
          ATENÇÃO: Essa é a Proposta FINAL que será paga à MONTADORA!
        </DialogTitle>
        <DialogContent>
          <Column>
            <Row>
                <span>R$</span>
                <DashInput
                  className="price-plan"
                  borderless={"true"}
                  background={"#F6F6F6"}
                  placeholder="Valor mínimo"
                  width={"40%"}
                  type="number"
                  name={`finalPriceMin`}
                  value={finalPrice ? finalPrice : initialFinalPrice}
                  onChange={handleChange}
                />
            </Row>
            <Row>o valor final pago pelo EXPOSITOR será R$ {finalPrice ? finalPrice * tax : initialFinalPrice * tax}</Row>
          </Column>
        </DialogContent>
          <ActionButtons>
            <Button color={"#adb5bd"} onClick={handleClose}>Fechar</Button>
            <Button color={"#FCA500"} onClick={handleSend}>Enviar</Button>
          </ActionButtons>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #FDC22A;
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & p {
    font-size: 1.8rem;
    padding-bottom: 10px;
  }
  
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.8rem;
  
  span {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 40px;
    padding-left: 1rem;
    margin-right: 1rem;
    font-size: 1.7rem;
    align-items: center;
  }

  input {
     background-color: #F6F6F6;
     font-size: 1.7rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#FDC22A" : color};
  background: ${({ color }) => !color ? "#FDC22A" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`

const Space = styled.div`
  width: 1rem;
`