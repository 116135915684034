import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import {
  ArchiveIcon, ArrowIcon, BoxIcon, Container1, ContainerHeader, FeedIcon, Header,
  HomeIcon, IconsContainer, Line, ListIcon, LogoContainer,
  LogoImage, NotificationsIcon, PaidIcon, PhotoIcon,
  ScheduleIcon, SideBar, Text, Section, UserAvatar, ButtonCreateBrienfing
} from './sideBarExpoStyle';
import SideBarMenuItem from "./sideBarMenuItem";
import { deleteToken } from "../../api/login";

const menuItems = [
  { id: 1, icon: <HomeIcon />, name: 'Dashboard', path: "/perfil-expositor" },
  { id: 2, icon: <BoxIcon />, name: 'Novo Pedido', path: "/expositor/novo-briefing" },
  { id: 3, icon: <ListIcon />, name: 'Briefings', path: "/expositor/lista" },
  { id: 4, icon: <PhotoIcon />, name: 'Projetos', path: "/expositor-novo/projeto&montagem" },
  { id: 5, icon: <PaidIcon />, name: 'Financeiro', path: "" },
  { id: 6, icon: <ScheduleIcon />, name: 'Relatórios', path: "" },
  { id: 7, icon: <FeedIcon />, name: 'Documentos', path: "" },
];

const SideBarExpo = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const { userData, saveUserData } = useUser();
  const navigate = useNavigate();
  const location = useLocation()

  const newMenuItems = menuItems.map(value => {
    if (value.path === location.pathname) {
      return ({
        ...value,
        active: true,
      })
    }
    return ({
      ...value
    })
  }
  );

  const handleArrowClick = () => {
    setIsSideBarOpen(prevOpen => !prevOpen);
  };

  function handleNovoPedido() {
    navigate("/expositor/novo-briefing");
  };

  function handleLogout() {
    window.location.assign("https://www.westand.com.br")
    saveUserData({})
    deleteToken()
  }

  return (
    <>
      <Container1>
        {/* ----- INICIO: Bloco DESKTOP ------ */}
        <SideBar open={isSideBarOpen}>
          <LogoContainer>
            <LogoImage src="img/favicon_32x32.png" alt="Logo"></LogoImage>
          </LogoContainer>
          <Line />
          <ArrowIcon onClick={handleArrowClick} />
          {!isSideBarOpen ? (
            <IconsContainer>
              {newMenuItems.map(item => (
                <SideBarMenuItem
                  key={item.id}
                  isSelected={location.pathname === item.path}
                  icon={item.icon}
                  isOpen={false}
                  path={item.path}>
                </SideBarMenuItem>
              ))}
            </IconsContainer>
          ) : (
            <>
              <ArrowIcon Open={isSideBarOpen} onClick={handleArrowClick} />
              <IconsContainer>
                {newMenuItems.map(item => (
                  <SideBarMenuItem
                    key={item.id}
                    isSelected={location.pathname === item.path}
                    icon={item.icon}
                    name={item.name}
                    isOpen={true}
                    path={item.path}>
                  </SideBarMenuItem>
                ))}
              </IconsContainer>
            </>

          )}
        </SideBar>
        <ContainerHeader>
          <Header>
            <Text></Text>
            <Section>
              <ButtonCreateBrienfing onClick={handleNovoPedido}>Criar Brienfing</ButtonCreateBrienfing>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <UserAvatar alt="" src={userData.avatar} />
                <NotificationsIcon />
                <ArchiveIcon onClick={handleLogout} />
              </div>
            </Section>
          </Header>
        </ContainerHeader>
      </Container1>
    </>
  );
}

export default SideBarExpo;