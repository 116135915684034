import styled from "@emotion/styled";
import {
  Wrapper,
} from "./Styles";
import {
  Content,
  Alert
} from "./Styles";
import { useStepActions } from "../useStepActions";



const Step3Builder = () => {


  const {
    handleChecked,
    handleGetFile,
  } = useStepActions();


  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Pagamento</h2>
      </div>
      <Content>
        <Alert>Aguarde até o <strong>Expositor</strong> efetuar o pagamento.</Alert>
      </Content>

    </Wrapper>
  );
};



export { Step3Builder }