import {
  getUserData,
  privateFetch
} from "./user";

const API = process.env.REACT_APP_API;

const BRIEFING_TYPE = Object.freeze({
  PROJETO: 1,
  PROJETO_E_MONTAGEM: 2,
  MONTAGEM: 3,
  OUTRO: 4,
  TODOS: 5,
  ARQUIVADOS: 100,
  APAGADOS: 7,
});

export async function listBriefingProjects() {
  return listBriefingsByType(BRIEFING_TYPE.PROJETO);
}

export async function listBriefingBuild() {
  return listBriefingsByType(BRIEFING_TYPE.MONTAGEM);
}

export async function listBriefingBuildAndProjects() {
  return listBriefingsByType(BRIEFING_TYPE.PROJETO_E_MONTAGEM);
}

export async function listBriefingAll() {
  return listBriefingsByType(BRIEFING_TYPE.TODOS);
}

export async function listBriefingArchived() {
  return listBriefingsByType(BRIEFING_TYPE.ARQUIVADOS);
}

export async function listBriefingDeleted() {
  return listBriefingsByType(BRIEFING_TYPE.APAGADOS);
}

export async function listBriefingsByType(typeId) {

  const token = await privateFetch();

  const res = await fetch(`${API}/briefings/${typeId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function listBuilders() {

  const token = await privateFetch();

  const res = await fetch(`${API}/builders`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function listDesigners() {

  const token = await privateFetch();

  const res = await fetch(`${API}/designers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function listUsers() {

  const token = await privateFetch();

  const res = await fetch(`${API}/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function changeOwnerBriefing({ briefingId, usersId }) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/change-owner`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      usersId,
    })
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function archiveBriefing(briefingId) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/admin-control/${briefingId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: "archived",
    })
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function listBuildersFitBriefing({
  briefingId,
}) {

  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/builders-fit`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function listDesignersFitBriefing({
  briefingId,
}) {

  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/designers-fit`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function sendBriefingToGestor({ briefingId }) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing-manager/create/${briefingId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}


export async function sendBriefingForBuilders({ briefingId, buildersId }) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/builders-fit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      buildersId,
    })
  });

  return res.json();
}

export async function sendBriefingForDesigners({ briefingId, designersId }) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/designers-fit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      designersId,
    }),
  });

  return res.json();
}

export async function getExpositoresUsers() {
  const token = await privateFetch();

  const res = await fetch(`${API}/users-access?roleName=expositor`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },

  });

  return res.json();

}

export async function getPromoterUsers() {
  const token = await privateFetch();

  const res = await fetch(`${API}/users-access?roleName=promotor`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },

  });

  return res.json();
}


export async function getAssemblerUsers() {
  const token = await privateFetch();

  const res = await fetch(`${API}/users-access?roleName=montador`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },

  });

  return res.json();
}

export async function getDraftsmanUsers() {
  const token = await privateFetch();

  const res = await fetch(`${API}/users-access?roleName=projetista`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },

  });


  return res.json();
}

export async function sendUsersProfiles(userId, status) {
  const token = await privateFetch();
  const res = await fetch(`${API}/users-access/${userId}/${status}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId,
    })
  });
  return res.json();
}


export async function getUserProfile(usersId) {

  const token = await privateFetch();

  const res = await fetch(`${API}/user-profile/${usersId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function updateProfileUser({
  usersId,
  profile
}) {

  const token = await privateFetch();

  const res = await fetch(`${API}/user-profile/${usersId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      ...profile,
    })
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function createPaymentForUser({
  usersId,
  paymentValue,
  paymentMethod,
  userDocument,
}) {

  const token = await privateFetch();

  const res = await fetch(`${API}/payment/create-payment-user/${usersId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      value: paymentValue,
      method: paymentMethod,
      userDocument,
    })
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function listPayments() {

  const token = await privateFetch();

  const res = await fetch(`${API}/list-payments`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function upsertFinalPrice(briefingRequestId, finalPrice) {
  const token = await privateFetch();
  const res = await fetch(`${API}/briefing-request/${briefingRequestId}/final-price`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      finalPrice,
    })
  });
  return res.json();
}