import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { BRIEFING_TYPE, BRIEFING_TYPE_DB_NAME } from "../helpers/BriefingTypes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { Link } from "react-router-dom";
import BriefingListCompleted from "./BrienfingListCompleted";
import { formatDateLocale } from "../helpers";

const BriefingManagerListNovo = ({ data }) => {
    const [briefings, setBriefings] = useState(data);
    const [filters, setFilters] = useState({
        eventName: "",
        eventSelect: "eventos",
        projectType: "tipo de projeto",
        statusType: "status",
        dateSelect: "data",
    });

    useEffect(() => {
        const filtered = data.filter((briefing) => {
            const eventNameMatch = filters.eventName === "" || briefing.Briefing.eventName.toLowerCase().includes(filters.eventName.toLowerCase());
            const eventMatch = filters.eventSelect === "eventos" || briefing.Briefing.eventName === filters.eventSelect;
            const projectTypeMatch = filters.projectType === "tipo de projeto" || briefing.Briefing.briefingTypeId === BRIEFING_TYPE_DB_NAME[filters.projectType];
            const statusMatch = filters.statusType === "status" || briefing.Briefing.status === filters.statusType;
            const dateMatch = filters.dateSelect === "data" || formatDateLocale(briefing.Briefing.createdAt) === filters.dateSelect;

            return eventNameMatch && eventMatch && projectTypeMatch && statusMatch && dateMatch;
        });

        setBriefings(filtered);
    }, [data, filters]);

    const handleSelectChange = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };

    const handleCleanClick = () => {
        setFilters({
            eventName: "",
            eventSelect: "eventos",
            projectType: "tipo de projeto",
            statusType: "status",
            dateSelect: "data",
        });
    };

    const getDates = () => {
        const dates = data.map((briefing) => formatDateLocale(briefing.Briefing.createdAt));
        return [...new Set(dates)];
    };

    return (
        <Container>
            <BoxHeader>
                <div>
                    <Title>Confira o andamento de seus projetos</Title>
                    <SubTitle>Aqui você encontra todos os seus briefings aprovados</SubTitle>
                </div>
            </BoxHeader>
            <Search>
                <SearchInput type="text" name="eventName" placeholder="Pesquise por nome..." value={filters.eventName} onChange={(e) => handleSelectChange(e.target.name, e.target.value)}></SearchInput>
                <StyledSelect name="dateSelect" value={filters.dateSelect} placeholder="data" onChange={(e) => handleSelectChange(e.target.name, e.target.value)}>
                    <MenuItem value="data" disabled>
                        <IconWrapper>
                            <DateRangeIcon />
                        </IconWrapper>
                        Data
                    </MenuItem>
                    {getDates().map((date) => (
                        <MenuItem key={date} value={date}>
                            {date}
                        </MenuItem>
                    ))}
                </StyledSelect>
                <StyledSelect name="eventSelect" label="eventos" placeholder="eventos" value={filters.eventSelect} onChange={(e) => handleSelectChange(e.target.name, e.target.value)}>
                    <MenuItem value="eventos">
                        <IconWrapper>
                            <SellOutlinedIcon />
                        </IconWrapper>
                        Eventos
                    </MenuItem>
                    {data.map((briefing) => (
                        <MenuItem key={briefing.id} value={briefing.Briefing.eventName}>
                            {briefing.Briefing.eventName}
                        </MenuItem>
                    ))}
                </StyledSelect>
                <StyledSelect value={filters.projectType} name="projectType" placeholder="Tipo de projeto" onChange={(e) => handleSelectChange(e.target.name, e.target.value)}>
                    <MenuItem value="tipo de projeto">
                        <IconWrapper>
                            <BookmarkBorderOutlinedIcon />
                        </IconWrapper>
                        Tipo de projeto
                    </MenuItem>
                    {Object.keys(BRIEFING_TYPE).map((key) => (
                        <MenuItem key={key} value={key}>
                            {BRIEFING_TYPE[key]}
                        </MenuItem>
                    ))}
                </StyledSelect>
                <StyledSelect value={filters.statusType} name="statusType" placeholder="status" onChange={(e) => handleSelectChange(e.target.name, e.target.value)}>
                    <MenuItem value="status">
                        <IconWrapper>
                            <PermIdentitydIcon />
                        </IconWrapper>
                        Status
                    </MenuItem>
                    <MenuItem value="ACEITO">Aceito</MenuItem>
                    <MenuItem value="ABERTO">Aberto</MenuItem>
                    <MenuItem value="EM NEGOCIAÇÃO">Em negociação</MenuItem>
                </StyledSelect>
                <Button onClick={handleCleanClick}>Limpar </Button>
            </Search>
            <Wrapper>
                {briefings.map((briefing) =>
                    briefing.step !== "8" ? (
                        <Box key={`box-${briefing.id}`}>
                            <Link to={`/expositor/gestor/${briefing.briefingId}`} style={{ textDecoration: "none", color: "inherit" }}>
                                <Top>
                                    <DateText>
                                        <CalendarIcon></CalendarIcon>
                                        <span>
                                            Iniciado:
                                            <DateLine>{formatDateLocale(briefing.Briefing.createdAt)}</DateLine>
                                        </span>
                                        <span>
                                            Atualizado:
                                            <DateLine>{formatDateLocale(briefing.Briefing.updatedAt)}</DateLine>
                                        </span>
                                        <MoreIcon/>
                                    </DateText>
                                    <ProjectText>{briefing.Briefing.eventName}</ProjectText>
                                </Top>
                                <Container1>
                                    <BoxProgress>
                                        <CircleIconWrapper>
                                            <SyncIcon></SyncIcon>
                                        </CircleIconWrapper>
                                        <Info>
                                            <h4>Em andamento</h4>
                                            <h6>
                                                Etapa <strong> {briefing.step} </strong> de <strong> 8 </strong>{" "}
                                            </h6>
                                        </Info>
                                    </BoxProgress>
                                    <StyledLinearProgress variant="determinate" value={(briefing.step / 8) * 100}></StyledLinearProgress>
                                </Container1>
                            </Link>
                        </Box>
                    ) : (
                        <Link to={`/expositor/gestor/${briefing.briefingId}`} style={{ textDecoration: "none", color: "inherit" }}>
                            <BriefingListCompleted briefing={briefing} />
                        </Link>
                    )
                )}
            </Wrapper>
        </Container>
    );
};
const BoxHeader = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
`;
const DateLine = styled.div`
    margin: 1px;
`;
const Container = styled.div`
    margin-left: 30px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;
const Title = styled.div`
    color: black;
    font-weight: 500;
    font-size: 27px;
    padding: 10px;
    margin-left: 20px;
`;
const SubTitle = styled.div`
    font-size: 19px;
    margin-left: 34px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 60vh;
`;
const Box = styled.div`
    background-color: white;
    width: 290px;
    height: 420px;
    border-radius: 20px;
    margin: 4rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        padding: 2px;
        margin: 10px;
    }
`;
const Top = styled.div`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 290px;
    height: 40%;
    background-color: #f68d50;
    border-radius: 20px 20px 20px 20px;

    @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
    }
`;
const DateText = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    left: 10px;
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
`;
const ProjectText = styled.div`
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 2.6rem;
    padding: 1rem;
`;
const Search = styled.div`
    display: flex;
    padding: 2rem 0;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;
const SearchInput = styled.input`
    padding-left: 3rem;
    border: 1px solid #ccc;
    outline: none;
    height: 35px;
    width: 200px;
    border-radius: 20px;

    ::placeholder {
        font-size: 13px;
        color: black;
    }

    @media (max-width: 768px) {
        margin-left: 1rem;
    }
`;
const CalendarIcon = styled(CalendarMonthOutlinedIcon)`
    color: white;
    font-size: 20px;
    left: 35%;
    top: 10px;
`;
const MoreIcon = styled(MoreHorizIcon)`
    font-size: 20px;
`;
const DateRangeIcon = styled(DateRangeOutlinedIcon)``;
const SyncIcon = styled(SyncOutlinedIcon)`
    font-size: 39px;
    color: #ff5e00;
`;
const Container1 = styled.div`
    margin-top: 200px;
    margin-left: 15px;
`;
const CircleIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border: 1px solid #ddd;
    box-sizing: box;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 3, 0.4);
    margin-bottom: 18px;
`;
const IconWrapper = styled(ListItemIcon)`
    min-width: 24px;
`;
const PermIdentitydIcon = styled(PermIdentityOutlinedIcon)`
    color: #f68d50;
    font-size: 20px;
`;
const Button = styled.button`
    background-color: #f5f5f9;
    color: #f68d50;
    font-weight: 600;
    border: none;
    margin-left: 10px;
`;
const BoxProgress = styled.div`
    display: flex;
`;
const Info = styled.div`
    margin-left: 15px;
    width: 70%;
`;
const StyledSelect = styled(Select)`
    width: ${({ width }) => (width ? width : "200px")};
    margin-left: 10px;
    & fieldset {
        border: none;
    }

    @media (max-width: 768px) {
        padding: 3px;
    }

    & .MuiSelect-select.MuiSelect-outlined {
        border-radius: 20px;
        height: 24px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        background-color: white;
        white-space: pre-line;
        font-size: 1.4rem;
        padding: 4px 10px;
        display: flex;
        align-items: center;
    }

    & .MuiSelect-select.MuiSelect-outlined:active {
        border: 2px solid #ff8400;
    }

    .MuiSelect-select.MuiSelect-outlined:hover {
        border: 1px solid black;
    }
`;
const StyledLinearProgress = styled(LinearProgress)`
    width: 90%;
    height: 8px;
    border-radius: 10px;
    margin-left: 2%;

    .MuiLinearProgress-bar {
        background-color: green;
    }

    .MuiLinearProgress-colorPrimary {
        background-color: #ccc;
    }

    .MuiLinearProgress-colorSecondary {
        background-color: #ccc;
    }
`;
export default BriefingManagerListNovo;
