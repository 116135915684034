import {
  Wrapper,
  Row,
} from "./Styles";
import { useState } from "react";
import { useStepActions } from "../useStepActions";
import { useStepForm } from "../StepFormContext";
import { useNotification } from "../../../../context/NotificationContext";
import {
  NRoundedButton,
  ToDoTasks
} from "../../../../shared-components";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from '@mui/material/Button';
import { updateBriefingTodo } from "../../../../api/briefing";
import FragmentProjects from "../fragments/FragmentProjects";


const Step1Projetista = ({ version, versionTitle }) => {
  const { stateForm, setStateForm } = useStepForm();
  const notification = useNotification();


  const [tasks, setTasks] = useState(() => {
    if (stateForm?.Briefing?.BriefingManagerTasks.length > 0) {

      if (version === 'v1') {
        let taskList = stateForm?.Briefing?.BriefingManagerTasks
          .filter(v => v.type === version);

        if (taskList.length === 0) {
          taskList = stateForm?.Briefing?.BriefingManagerTasks
            .filter(v => v.type === 'v0');
        }

        return JSON.parse(taskList[0].tasks);
      }

      if (version === 'v2') {
        let taskList = stateForm?.Briefing?.BriefingManagerTasks
          .filter(v => v.type === version);

        if (taskList.length === 0) {
          return []
        }
        return JSON.parse(taskList[0]?.tasks);
      }



    }
    return [];
  });

  const [open, setOpen] = useState(false);

  const {
    isBuilder,
    isDraftsman,
    role,
  } = useStepActions();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleUpdateTasks() {

    updateBriefingTodo(
      stateForm?.Briefing?.id,
      tasks,
      version,
      stateForm?.BriefingProjectsVersion
    )
      .then((res) => {
        notification.show({
          message: "As alterações foram enviadas para o solicitante.",
        });
      })
      .catch((error) => {
        console.log(error)
        notification.show({
          message: error.response?.data?.message || "Houve um erro ao enviar as alterações",
          type: "error"
        });
      });
  }

  function toggleTask(taskId) {
    const newTasks = tasks.map(v => {
      if (v.id === taskId) {
        return ({
          ...v,
          done: !v.done
        })
      } else {
        return v;
      }
    })

    setTasks(newTasks)

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: newTasks,
    });
  }

  function handleAddTask(taskName) {
    if (isBuilder()) {
      return;
    }
    const newTask = {
      id: +new Date(),
      description: taskName,
      done: false,
    };

    setTasks([
      ...tasks,
      newTask
    ])

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: [
        ...tasks,
        newTask
      ],
    });

  };

  function handleRemoveTask(taskId) {
    const newTasks = tasks.filter(v => v.id !== taskId);
    setTasks(newTasks);
    setStateForm({
      ...stateForm,
      BriefingManagerTasks: newTasks,
    });
  }

  const projetista = isDraftsman();


  return (
    <Wrapper>
      <div style={{
        textAlign: 'center',
      }}>
        <h2 className="reg-feira">{`Projeto - ${versionTitle}`}</h2>
        <h4>Nessa etapa você recebe as solicitações de alteração do solicitante e poderá subir as novas versões. Lembre-se de atualizar o checklist de atualizações.</h4>
      </div>
      <Row>
        <FragmentProjects briefingData={stateForm} />
      </Row>
      <Row style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

      }}>
        <div className="project-wrapper">
          <div className="release-project">
            <ToDoTasks
              name="checklistCorrecoes"
              title={`Alterações solicitadas na ${versionTitle}`}
              color={"#FFA500"}
              tasks={tasks}
              handleAddTask={handleAddTask}
              onToggle={toggleTask}
              onRemove={handleRemoveTask}
              disableRemove={projetista}
              disableTitle={projetista}
              noTaskText={'O expositor ainda não solicitou alterações, volte mais tarde.'}
            />
            <div>
              <NRoundedButton
                title="Atualizar versão do projeto"
                color="#ff8400"
                active={true}
                onClick={handleClickOpen}
              />
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Atenção, ao atualizar a versão do projeto não será possível modifica-las! Deseja atualizar mesmo assim?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} style={{
                  fontFamily: "Montserrat",
                  fontSize: "1.5rem",
                  color: "#92939c",
                }}
                >
                  Revisar
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                    handleUpdateTasks();
                  }}
                  autoFocus
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "1.5rem",
                    color: "#ff8400",
                    fontWeight: "bold"
                  }}>
                  Atualizar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Row>
    </Wrapper>
  );
};


export { Step1Projetista }