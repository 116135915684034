import { useState } from "react";

function useCustomForm(initValue = []) {
  const [form, setForm] = useState(initValue)
  function handleForm({ target: { value, name } }, key) {

    const values = form?.map(v => {
      if (v?.referenceId === key) {
        return ({
          ...v,
          [name]: value,
        })
      }
      return ({
        ...v
      })
    })
    setForm(values)
  }

  return [form, handleForm]
}

export {
  useCustomForm
}
