import styled from "@emotion/styled";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { checkRole } from "../../../../api/login";
import { ActionButton } from "../Styles";

const actions = {
  MONTADOR: [
    {
      title: "em aberto",
      link: "/solicit-aberto-montador",
      active: true,
    },
    {
      title: "em negociação",
      link: "/montador/lista",
    },
    { title: "aprovadas", link: "/solicit-aprovada-montador" },
  ],
  EXPOSITOR: [
    {
      title: "em aberto",
      link: "/solicit-aberto-expositor",
      active: true,
    },
    { title: "em negociação", link: "/solicit-negociacao-expositor" },
    { title: "aprovadas", link: "/solicit-aprovada-expositor" },
  ],
};

function getClass(button, clicked) {
  if (button.title === clicked) {
    return "btn-open6";
  }

  return "btn-open-mont";
}

export function FragmentActionsLink({ clicked }) {
  const { data } = useQuery("user-role", checkRole);

  return (
    <ActionButton>
      {data &&
        actions[data?.role.name].map((button) => (
          <Link to={button.link} key={`action-button-${button.title}`}>
            <button className={getClass(button, clicked)}>
              {button.title}
            </button>
          </Link>
        ))}
    </ActionButton>
  );
}
