export function RenderTermText({ briefing }) {

  return (
    <>
      <p>Após o aceite deste termo de serviço você se compromente com o pagamento da taxa inicial de acordo com a tabela a seguir em até 10 dias corridos.</p>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <p>
          <ul style={{
            listStyleType: 'circle'
          }}>
            <li>0m² a 30m² - R$350,00</li>
            <li>31m² a 60m² - R$450,00</li>
            <li>61m² a 80m² - R$ 550,00</li>
            <li>81m² a 100m² - R$700,00</li>
            <li>101m² a 120m² - R$850,00</li>
            <li>Acima de 120m² - R$950,00</li>
          </ul>
        </p>
      </div>
      <p>A <strong>WeStand</strong> possibilita a gestão das informações, controle de qualidade e o suporte necessário para que empresas de montagem executem os eventos com transparência, qualidade e responsabilidade socio-ambiental. A WeStand é responsável pela co-criação do conceito, stands e projetos cenográficos, não sendo responsável direto pela fabriação e montagem dos eventos.</p>
      <p>Cada m² de stand montado a WeStand regenerará a mesma área na floresta Amazonica. Expositor, Montador e Promotor receberão o selo <strong>WeStand for a Cause</strong>.</p>
    </>
  )
}