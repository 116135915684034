import { useState, useEffect } from "react";
import { useSimpleForm, useCustomForm } from "../../../../hooks";
import styled from "@emotion/styled";
import { makeBudgetForOpenBriefing, rejectBriefing } from "../../../../api/briefing";
import { useNotification } from "../../../../context/NotificationContext";
import DialogBriefing from "./DialogBriefing";
import FragmentReferences from "./FragmentReferences";
import { FragmentProjects } from "./FragmentProjects";
import {
  formatDateLocale,
} from "../../../../helpers/formatDate";
import { DialogProject } from "../../../DashboardExpositorComponents/solicitacaoPlataforma/fragments/DialogProject";
import { useNavigate } from "react-router-dom";
import { DashInput, NAlert } from "../../../../shared-components";
import { WrapperPrice } from "../StyledComponent";
import { NRoundedButton } from "../../../../shared-components";

function ReferencesOrProjects({ briefingData, handleForm }) {
  const [openModalProject, setOpenModalProject] = useState(false);
  const [projectViewZoom, setProjectViewZoom] = useState();

  function handleModalProject(projectLink) {
    setProjectViewZoom(projectLink);
    setOpenModalProject(true);
  }

  function closeModalProject() {
    setOpenModalProject(false);
  }

  if (briefingData?.BriefingProjects?.length === 0) {
    return (
      <Row>
        {briefingData?.BriefingReferences.map((briefing, index) => {
          if (index >= 4) {
            return "";
          }
          return (
            <span key={`briefing-${briefing.id}`}>
              <FragmentReferences
                BriefingRequestReference={[]}
                makeChoose={false}
                handleChangeInput={handleForm}
                briefingReference={briefing}
                briefingDataReference={briefingData?.briefingDataReference}
                position={index + 1}
              />
            </span>
          );
        })}
      </Row>
    );
  }

  return (
    <Row>
      <DialogProject
        open={openModalProject}
        handleClose={closeModalProject}
        projectViewZoom={projectViewZoom}
      />
      {briefingData?.BriefingProjects.map((project, index) => (
        <>
          <FragmentProjects
            key={`upload-${index}`}
            id={`project_${index}`}
            briefingProject={project}
            handleClick={handleModalProject}
          />
        </>
      ))}
    </Row>
  );
}

export default function FragmentBriefingOpen({ briefingData, ...otherProps }) {
  const [modalBriefing, setModalBriefing] = useState(false);
  const notification = useNotification();

  const [openAlert, setOpenAlert] = useState(false);
  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    BriefingStructs: briefingData?.BriefingStructs || [],
    BriefingElements: briefingData?.BriefingElements || [],
    BriefingMultimedia: briefingData?.BriefingMultimedia || [],
    finalPriceMax: "",
    finalPriceMin: "",
    observation: "",
    deliveryAt: "",
    ...briefingData,
  });

  const area = briefingData.length * briefingData.depth || 1;
  const navigate = useNavigate();
  const [form, handleForm] = useCustomForm(
    briefingData?.BriefingReferences.map((v) => ({
      referenceId: v.id,
      image: v.image,
      priceMax: "",
      priceMin: "",
    }))
  );



  function acceptBriefing() {

    makeBudgetForOpenBriefing({
      briefingId: briefingData.id,
      budgets: form,
      proposal: {
        finalPriceMax: stateForm?.finalPriceMax,
        finalPriceMin: stateForm?.finalPriceMin,
        observation: stateForm?.observation,
        deliveryAt: stateForm?.deliveryAt,
      }
    })
      .then((res) => {
        notification.show({
          message: "Enviamos seu orçamento para o expositor",
        });
        navigate("/montador/lista");
      })
      .catch((error) => {
        notification.show({
          message: error.message || "Houve um erro",
          type: "error",
        });
      });
  }

  function showBriefing() {
    setModalBriefing(true);
  }

  function closeBriefingModal() {
    setModalBriefing(false);
  }

  function handleGiveUp() {
    rejectBriefing(briefingData.id)
      .then(res => {
        notification.show({
          message: 'Briefing rejeitado',
        })

        setOpenAlert(false);
        navigate(-1);
      })
      .catch(error => {
        notification.show({
          message: 'Houve um erro ao rejeitar o Briefing',
          type: 'error'
        })
      })
  }

  const isButtonDisabled = !stateForm?.finalPriceMin || !stateForm?.finalPriceMax;

  return (
    <Wrapper key={briefingData.id}>
      <NAlert
        handleCancel={() => setOpenAlert(false)}
        handleConfirm={handleGiveUp}
        title={"Atenção!"}
        titleCancel={"Manter"}
        titleConfirm={"Rejeitar"}
        open={openAlert}
        role={"MONTADOR"}
      >
        <span>Tem certeza que deseja rejeitar esse Briefing?</span>

      </NAlert>
      <DialogBriefing
        open={modalBriefing}
        handleClose={closeBriefingModal}
        title={"Briefing"}
        briefingId={briefingData.id}
      />
      <Title>
        <h2 className="title-company">
          {briefingData?.id} - {briefingData?.eventName} - {area} m²{" "}
        </h2>
      </Title>
      <ReferencesOrProjects
        briefingData={briefingData}
        handleForm={handleForm}
      />
      <Row>
        <Column>
          <SubTitle>Resumo do Briefing</SubTitle>
          <div>
            <strong>Prazo</strong>: {formatDateLocale(briefingData.deadline)}
          </div>
          <div>
            <strong>Preço Mínimo:</strong>: {briefingData.priceMin}
            <span> e </span>
            <strong>Preço Máximo:</strong>: {briefingData.priceMax}
          </div>
          <div>
            <strong>Tamanho: </strong>
            {briefingData.length}m (altura)
            <span> x </span>
            {briefingData.depth}m (largura)
            <span> = </span>
            {briefingData.length * briefingData.depth}m²
          </div>
        </Column>
        <Column>
          <h2 className="title-deadline">
            Quando será a entrega
          </h2>
          <DashInput
            width={"80%"}
            borderless={"true"}
            background={"#F6F6F6"}
            className="input-date"
            name="deliveryAt"
            value={stateForm?.deliveryAt}
            onChange={handleChange}
            type="date"
          />
        </Column>
        <Column>
          <div>
            <h2 className="title-deadline">Valor final estimado por m²</h2>
            <span
              style={{
                fontWeight: 400,
              }}
            >Para enviar um orçamento você precisa preencer os valores.</span>
          </div>
          <span style={{
            width: '100%',
          }}>
            <WrapperPrice>
              <span>R$</span>
              <DashInput
                className="price-plan"
                borderless={"true"}
                background={"#F6F6F6"}
                placeholder="Valor mínimo"
                type="number"
                name={`finalPriceMin`}
                value={stateForm?.finalPriceMin}
                onChange={handleChange}
              />
              <span>até</span>
              <DashInput
                className="price-plan"
                borderless={"true"}
                background={"#F6F6F6"}
                placeholder="Valor máximo"
                value={stateForm?.finalPriceMax}
                onChange={handleChange}
                type="number"
                name={`finalPriceMax`}
              />
            </WrapperPrice>
          </span>
          <textarea
            placeholder="Caso queria você pode explicar o porquê entre as diferenças dos valores." className="resume-briefing-text-area"
            rows="3"
            name={"observation"}
            value={stateForm?.observation}
            onChange={handleChange}
            style={{
              resize: 'none'
            }}
          />
        </Column>


      </Row>
      <Action>
        <NRoundedButton
          color={"#adb5bd"}
          title={"não tenho interesse"}
          active={true}
          onClick={() => setOpenAlert(true)}
        />
        <NRoundedButton
          onClick={showBriefing}
          title={"ver briefing completo"}
          active={true}
          color={"#22da28"}
        />
        {isButtonDisabled ? (
          <NRoundedButton
            onClick={acceptBriefing}
            disabled={true}
            active={true}
            color={"#adb5bd"}
            title="Enviar um orçamento"
          />) : (
          <NRoundedButton
            onClick={acceptBriefing}
            active={true}
            color={"#22da28"}
            title="Enviar um orçamento"
          />)}

      </Action>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  min-height: 40rem;
  margin: 10px 0;
  padding: 0;

  .required-message {
    color: red;
    align-self: flex-end;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
`;

const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #22da28;
  margin: 14px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: "40%";
  margin: 10px 0;
  font-size: 1.6rem;

  & p {
    font-size: 1.6rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  .resume-briefing-text-area {
    width: 100%;
    font-size: 1.5rem;
    background-color: #f6f6f6;
    padding: 1rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid #adb5bd;
    
    &:focus {
      border: 1px solid #ccc;
    }

    &:hover {
      border: 1px solid #ccc;
    }
  }
`;
const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;

  > div {
    display: flex;
    flex: 0.4;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 898px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;



const Button = styled.button`
  min-width: 130px;
  font-size: 1.4rem;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => (!color ? "#22da28" : color)};
  background: ${({ color }) => (!color ? "#22da28" : color)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e3e3e3;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin: 5px;
    padding: 0 10px;
  }

  > span {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    align-items: center;
    flex-direction: column;

    > span {
      img {
        min-width: 380px;
        height: 240px;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    > span {
      flex-direction: column;

      img {
        min-width: 280px;
        height: 240px;
      }
    }
  }
`;
