import { useState } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { checkRole, deleteToken } from "../api/login";
import SideBarPromotor from "../components/sidebar/SideBarPromotor";
import { useUser } from "../context/UserContext";

function TopMenu({ toggle, userData, data, handleLogout }) {

  const navigate = useNavigate();

  function handleNovoPedido() {
    navigate("/promotor/novo-briefing");
  }

  return (
    <TopMenuWrapper>
      <MenuIcon onClick={toggle} className="menu-icon" />
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}>
        <span style={{ cursor: 'pointer' }} onClick={handleNovoPedido}>Criar um briefing</span>
        {userData ? <span style={{ cursor: 'pointer' }} onClick={handleLogout}>Sair</span> : ""}
        <Avatar style={{ position: "inherit" }} src={data.avatar} />
      </div>
    </TopMenuWrapper>
  )
}

const TopMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 5.875rem;
  margin-bottom: 20px;
  
  .menu-icon {
    opacity: 0;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
  }

  @media only screen and (max-width: 770px) {

    .menu-icon {
      opacity: 1;
    }
  }
`

export default function PromotorLayout({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { data, isLoading } = useQuery('account', checkRole);

  const { userData, saveUserData } = useUser();

  function handleLogout() {
    saveUserData({});
    deleteToken();
  }

  if (isLoading) {
    return (
      <span>carregando...</span>
    )
  }

  return (
    <Wrapper>
      <SideBarPromotor toggle={toggle} isOpen={isOpen} />
      <main>
        <TopMenu toggle={toggle} userData={userData} data={data} handleLogout={handleLogout} />
        {children}
      </main>
    </Wrapper>
  )
}

const Wrapper = styled.div`

  background-color: #F5F5F9;
  
  & > main {
    background-color: #F5F5F9;
    margin-left: 90px;
    width: calc(100% - 90px);
    padding: 30px;
  }

  @media only screen and (max-width: 770px) {

   & > main {
     width: 100%;
     margin-left: -4px;
   }
  }
`

