import styled from "@emotion/styled";
import { useQuery } from "react-query";
import {
  NRoundedButton,
  DashInputPassword
} from "../../../shared-components";
import { CardPersonalInfo } from "./CardPersonalInfo";
import { useSimpleForm } from "../../../hooks";
import { useState } from "react";
import {
  changePassword,
  listDevices,
  disconnectDevice,
} from "../../../api/user";
import { useNotification } from "../../../context/NotificationContext";

const ERROR_STATUS = Object.freeze({
  NOT_MATCH: 'not_match',
  WEAK_PASS: 'weak_pass',
  OK: 'ok',
})

const strogPassRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

function RenderErrorMessage({ errorMessage }) {
  if (errorMessage === ERROR_STATUS.NOT_MATCH) {
    return (<span>As senhas devem ser iguais</span>)
  }
  if (errorMessage === ERROR_STATUS.WEAK_PASS) {
    return (<span>Sua senha deve ter pelo menos  8 caracteres, 1 letra maiúscula e um caracter especial ($, #, @)</span>)
  }
  return '';
}

export function FragmentSecurity({ color }) {

  const { data } = useQuery('list-devices', listDevices);

  const notification = useNotification();
  const [errorMessage, setErrorMessage] = useState(null);

  const { stateForm, handleChange } = useSimpleForm({
    password: '',
    confirmPassword: '',
  })

  function submitForm() {

    if (stateForm?.password !== stateForm?.confirmPassword) {
      setErrorMessage(ERROR_STATUS.NOT_MATCH)
      return;
    }

    if (!strogPassRegex.test(stateForm?.password)) {
      setErrorMessage(ERROR_STATUS.WEAK_PASS)
      return;
    }

    changePassword({
      password: stateForm?.password,
      confirmPassword: stateForm?.password,
    })
      .then(res => {
        notification.show({ message: "Senha alterada" })
      })
      .catch(err => {

        notification.show({ message: "Briefing atualizado :)", type: "error" })
      })



  }

  return (
    <Wrapper>
      <Content>
        <div>
          <DashInputPassword
            title="Nova senha"
            name="password"
            value={stateForm?.password}
            onChange={handleChange}
          />
          <DashInputPassword
            title="Confirmar nova senha"
            name="confirmPassword"
            value={stateForm?.confirmPassword}
            onChange={(e) => {
              setErrorMessage(ERROR_STATUS.OK)
              handleChange(e)
            }}
          />

          <RenderErrorMessage
            errorMessage={errorMessage}
          />

        </div>
        <div>
          <CardPersonalInfo />
        </div>
      </Content>

      <Actions>
        <NRoundedButton
          title={"Cancelar"}
        />
        <NRoundedButton
          title={"Gravar"}
          color={color}
          active={true}
          onClick={submitForm}
        />
      </Actions>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin: 10px 0;
  padding: 10px;
  gap: 20px;
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-of-type(1) {
    flex: 0.4;
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    flex: 0.2;
    padding: 10px 0;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
  @media screen and (max-width: 898px) {
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`
const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 4vh;
  padding: 2vh 0;
`