import {
  Wrapper,
} from "./Styles";
import FragmentMontadorTransferList from '../fragments/FragmentMontadorTransferList';

const DoacaoMontador = () => {

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Doações</h2>
        <FragmentMontadorTransferList />
      </div >
    </Wrapper >
  );
};



export { DoacaoMontador }