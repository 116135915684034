import { useState } from "react";
import { listBuilders } from "../../../api/promoter";

const builders = listBuilders();

export default function FragmentBuilder({ handleChange, stateForm }) {
  const [builderChips, setBuilderChips] = useState(builders);

  function handleClickBuiders(e) {
    const newBuilders = builderChips.map((v) => {
      if (v.id === 1) {
        return { ...v, active: true };
      }
      return { ...v };
    });
    setBuilderChips(newBuilders);
  }

  return (
    <>
      {/* <span>Empresas de montagem</span>
      <p>Oficiais</p>

      <WrapperStack>
        <Stack direction="row" spacing={1}>
          {builderChips.map((v) => (
            <NChip
              key={v.id}
              name={v.name}
              clickable={true}
              active={v.active}
              onClick={handleClickBuiders}
              variant="outlined"
              label={v.name}
            />
          ))}
        </Stack>
      </WrapperStack> */}
    </>
  );
}