import React, { useState } from "react";
import styled from "@emotion/styled";
import { generateImageLink } from "../../../../helpers/generateImageLink";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { WrapperPrice } from "./StyledComponent";


function brightnessImage(props) {
  const { makeChoose, active } = props;
  if (makeChoose && !active) {
    return 'brightness(50%);';
  }
  if (active) {
    return 'brightness(100%);';
  }

}
//TOOD: Merge this component with FragmentReferences from Expositor
export default function FragmentReferences({
  briefingReference,
  position,
  handleChangeInput,
  BriefingRequestReference,
  makeChoose,
}) {

  const prices = BriefingRequestReference
    .find(e => e.briefingReferencesId === briefingReference.id);

  const [pricesForm, setPricesForm] = useState(prices);

  let active = false;

  if (briefingReference?.id === makeChoose) {
    active = true;
  }

  return (
    <ContainerReference
      makeChoose={makeChoose}
      active={active}
    >
      <span>{position}</span>
      <img src={generateImageLink(briefingReference).image} alt="" />
      <div className="price-tag">
        <LocalOfferOutlinedIcon
          style={{ color: "black", width: "20px", height: "20px" }}
        />
        <p>Preço Estimado por m²</p>
      </div>
      <WrapperPrice>
        <span>R$</span>
        <input
          className="price-plan"
          placeholder="____"
          type="number"
          name={`minPrice`}
          onChange={(e) => {
            handleChangeInput(e, briefingReference.id);
            setPricesForm({
              ...pricesForm,
              minPrice: e.target.value
            })
          }}
          value={pricesForm?.minPrice || ""}
        />
        <span>até</span>
        <input
          className="price-plan"
          placeholder="____"
          type="number"
          onChange={(e) => {
            handleChangeInput(e, briefingReference.id);
            setPricesForm({
              ...pricesForm,
              maxPrice: e.target.value
            })
          }}
          name={`maxPrice`}
          value={pricesForm?.maxPrice || ""}
        />
      </WrapperPrice>
    </ContainerReference>
  )
}


const ContainerReference = styled.div`
  flex-direction: column;
  position: relative;
  
  > span {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: 500;
    font-size: 1.4rem;
    right: 20px;
    top: 10px;
    z-index: 1;
  }

  > div {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 100%;
    margin: 10px 0;
  
    p {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  img {
      width: 100%;
      min-width: 250px;
      height: 240px;
      object-fit: cover;
      border-radius: 6px;
      filter: ${(props) => brightnessImage(props)};
    }
`
