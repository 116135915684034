import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useEditBriefingForm } from "../hooks/useEditBriefingForm";
import styled from "@emotion/styled";
import Collapse from "@mui/material/Collapse";
import FragmentEditBriefing from "./editarBriefing/EditBriefing";
import { updateBriefing } from "../../../../api/briefing";
import { useNotification } from "../../../../context/NotificationContext";
import { FragmentReferences } from "./FragmentReferences"
import { ModalChoseReferences } from "./ModalChoseReferences"
import { useUser } from "../../../../context/UserContext";
import { AlertWarningProposal } from "./AlertWarningProposal";
import { FragmentProjects } from "./FragmentProjects";
import { DialogProject } from "./DialogProject";
import { ROLE } from "../../../../helpers/types";
import { NRoundedButton, ToDoTasks } from "../../../../shared-components";
import { Toggle } from "../../../../shared-components/NToggleButton";
import ProposalToggle from "../../../../shared-components/ProposalToggle";
import { sendBriefingToGestor } from "../../../../api/admin";
import { updateBriefingTodo } from "../../../../api/briefing";
import { Column, SubTitle } from "../../shared/components/Styles";
import { formatDateLocale } from '../../../../helpers/formatDate';
import Loading from "../../../../shared-components/Loading";
import BriefingTaxa from "../../../../shared-components/BriefingTaxa";

function renderMemorialStatus(data) {


  if (!data.Memorial || data?.Memorial.length === 0) {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial ainda não foi preenchido
      </span>
    )
  }
  if (data?.Memorial.length !== 0) {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial preenchido porém o expositor ainda não aprovou
      </span>
    )
  }
  if (data?.Approval[0]?.status === 'approved') {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial aprovado pelo expositor
      </span>
    )
  }
  if (data?.Approval[0]?.status === 'disapproved') {

    return (
      <div>
        <span style={{ fontWeight: '500' }}>
          Memorial rejeitado pelo expositor
        </span>
        <div>
          <span style={{ fontWeight: '500' }}>Motivo: </span> {data.Approval[0].comment}
        </div>

      </div>
    )
  }

}

function actionAdmin(userData, handleSendGestor, briefingRequest) {

  if (userData.role.name === ROLE.ADMIN) {
    return (
      <Row>
        <Action style={{
          alignItems: 'flex-end',
          flexDirection: 'row'
        }}>
          <Link to={`/memorial-descritivo/${briefingRequest?.Briefing?.id}`}>
            <NRoundedButton
              active={true}
              title={"Memorial"}
              color={"#764faa"}
              onClick={() => { }}
            />
          </Link>
          <NRoundedButton
            active={true}
            title={"Enviar para Gestor"}
            color={"#764faa"}
            onClick={handleSendGestor}
          ></NRoundedButton>
        </Action>
      </Row>
    )
  }

}


function renderTitle(briefingRequest, totalArea, userData) {
  if (userData.role.name === ROLE.EXPOSITOR) {
    return (
      <h2 className="title-company">
        {briefingRequest?.Briefing.eventName || "Sem nome"} - {totalArea} m² -{" "}
        {briefingRequest?.Briefing.id}
      </h2>
    );
  }

  return (
    <h2 className="title-company">
      {briefingRequest?.Briefing?.User?.Exhibitors[0]?.name ||
        briefingRequest?.Briefing?.User?.Builders[0]?.name ||
        briefingRequest?.Briefing?.User?.Promoter[0]?.name}{" "}
      - {briefingRequest?.Briefing.eventName || "Sem nome"} - {totalArea} m² -{" "}
      {briefingRequest?.Builder?.Builders[0]?.name} -{" "}
      {briefingRequest?.Briefing.id}
    </h2>
  );
}

function renderReferencesOrProjects({ briefingRequest, handleModalProject }) {
  if (briefingRequest?.Briefing?.BriefingProjects?.length !== 0) {
    return (
      <>
        {briefingRequest?.Briefing?.BriefingProjects?.map((project) => (
          <FragmentProjects
            key={project?.id}
            briefingProject={project}
            handleClick={handleModalProject}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {briefingRequest?.Briefing?.BriefingReferences.map((reference, index) => {
        if (reference.type === "link") {
          return "";
        }
        return (
          <FragmentReferences
            key={reference?.id}
            briefingReference={reference}
            BriefingRequestReference={briefingRequest?.BriefingRequestReference}
            position={index + 1}
            makeChoose={briefingRequest?.briefingReferencesId}
            active={false}
          />
        );
      })}
    </>
  );
}

export default function FragmentBriefingBudget({
  briefingRequest,
  onRemoveBriefingRequest,
}) {
  const [showBriefingDetails, setShowBriefingDetails] = useState(false);
  const [openWarningProposal, setOpenWarningProposal] = useState(false);
  const [openModalReferences, setOpenModalReferences] = useState(false);
  const [openModalProject, setOpenModalProject] = useState(false);
  const [projectViewZoom, setProjectViewZoom] = useState();
  const [dateInput, setDateInput] = useState("");
  const [priceMin, setPriceMin] = useState("");
  const [priceMax, setPriceMax] = useState("");
  const { userData } = useUser();


  //TODO: Pagamento
  const totalArea =
    briefingRequest?.Briefing?.depth * briefingRequest?.Briefing?.length;


  useEffect(() => {
    setDateInput(() => briefingRequest.deliveryAt?.split("T")[0] || "");
    setPriceMin(() => totalArea * briefingRequest?.finalPriceMin || "");
    setPriceMax(() => totalArea * briefingRequest?.finalPriceMax || "");
  }, []);

  const notification = useNotification();
  const {
    stateForm,
    handleChange,
    setStateForm,
    briefingData,
    imagesReferences,
    setImagesReferences,
    refetch,
    isLoading,
  } = useEditBriefingForm({ briefingId: briefingRequest?.briefingId });



  const [tasks, setTasks] = useState(() => {
    if (briefingRequest?.Briefing?.BriefingManagerTasks?.length !== 0) {
      const taskList = briefingRequest?.Briefing?.BriefingManagerTasks
        .filter(v => v.type === 'v0');
      if (taskList.length === 0) return [];

      return JSON.parse(taskList[0].tasks);
    }
    return []
  });


  function toggleTask(taskId) {
    const newTasks = tasks.map(v => {
      if (v.id === taskId) {
        return ({
          ...v,
          done: !v.done
        })
      } else {
        return v;
      }
    })

    setTasks(newTasks)

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: newTasks,
    });
  }

  function handleAddTask(taskName) {

    const newTask = {
      id: +new Date(),
      description: taskName,
      done: false,
    };

    setTasks([
      ...tasks,
      newTask
    ])

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: [
        ...tasks,
        newTask
      ],
    });

  };

  function handleRemoveTask(taskId) {
    const newTasks = tasks.filter(v => v.id !== taskId);

    setTasks(newTasks);

    setStateForm({
      ...stateForm,
      BriefingManagerTasks: newTasks,
    });
  }

  function submitBudget(index, setOpenAlertProposal) {
    if (
      (!briefingRequest?.proposals?.[index]?.finalPriceMax ||
        !briefingRequest?.proposals?.[index]?.finalPriceMin) &&
      userData.role.name !== "ADMIN"
    ) {
      setOpenWarningProposal(true);
      return;
    }
    setOpenAlertProposal(true);
  }

  function toggleShowBriefingDetails() {
    setShowBriefingDetails(() => !showBriefingDetails);
  }
  function toggleShowChoseReferences() {
    setOpenModalReferences(() => !openModalReferences);
  }

  function handleModalProject(projectLink) {
    setProjectViewZoom(projectLink);
    setOpenModalProject(true);
  }

  function closeModalProject() {
    setOpenModalProject(false);
  }

  function submitUpdatedBriefing() {
    const updatedForm = {
      ...stateForm,
      structs: stateForm?.BriefingStructs,
      elements: stateForm?.BriefingElements,
      multimedias: stateForm?.BriefingMultimedia,
      references: stateForm?.BriefingReferences,
      lastStands: stateForm?.BriefingLastStand,
      briefingId: briefingData?.id,
      briefingTypeId: briefingData?.briefingTypeId,
      promoter: stateForm?.promoterQuantity,
      projects: stateForm?.BriefingProjects,
      freeInformation: stateForm?.observations,
      projectsArte: stateForm?.BriefingProjectsArte,
    };

    updateBriefing(updatedForm)
      .then((res) => {
        notification.show({ message: "Briefing atualizado :)" });
      })
      .catch((err) => {
        notification.show({
          message: "Não foi possível atualziar o briefing",
          type: "error",
        });
      });
  }


  function sendTodo() {
    const briefingId = briefingRequest?.briefingId;
    updateBriefingTodo(briefingId, tasks, 'v0')
      .then((res) => {
        notification.show({
          message: "Todo atualizado com sucesso"
        })
      })
      .catch((error) => {
        notification.show({
          message: error.message || "Erro ao atualizar o todo",
          type: "error"
        })
      });
  }


  function handleSendGestor() {
    sendBriefingToGestor({
      briefingId: briefingRequest?.briefingId
    })
      .then(res => {
        notification.show({ message: "Briefing enviado para o Gestor" });
      })
      .catch(err => {
        notification.show({
          message: err.message || "Não foi possível enviar o briefing para o Gestor",
          type: "error",
        });
      })
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper key={briefingRequest.id}>
      <Title>{renderTitle(briefingRequest, totalArea, userData)}</Title>
      <Column>
        <SubTitle>Resumo do Briefing</SubTitle>
        <div>
          <strong>Prazo</strong>: {formatDateLocale(briefingRequest?.Briefing?.deadline)}
        </div>
        <div>
          <strong>Tamanho: </strong>
          {briefingRequest?.Briefing?.length}m (altura)
          <span> x </span>
          {briefingRequest?.Briefing?.depth}m (largura)
          <span> = </span>
          {briefingRequest?.Briefing?.length * briefingRequest?.Briefing?.depth}m²
        </div>
        <div>
          <strong>Status do Memorial:</strong> {renderMemorialStatus(briefingRequest?.Briefing)}
        </div>
      </Column>
      <DialogProject
        open={openModalProject}
        handleClose={closeModalProject}
        projectViewZoom={projectViewZoom}
      />
      <Row>
        {renderReferencesOrProjects({
          briefingRequest,
          handleModalProject,
          setProjectViewZoom,
        })}
      </Row>
      <Row>
        <NRoundedButton
          active={true}
          color={"#FF8400"}
          onClick={toggleShowBriefingDetails}
          title={"Editar Briefing"}
        />
      </Row>
      <Row>
        {briefingData && stateForm.id ? (
          <Collapse
            in={showBriefingDetails}
            timeout={{
              appear: 300,
              enter: 300,
              exit: 300,
            }}
          >
            <FragmentEditBriefing
              briefingData={briefingData}
              stateForm={stateForm}
              handleChange={handleChange}
              setStateForm={setStateForm}
            />
            <NRoundedButton
              active={true}
              color={"#FF8400"}
              onClick={submitUpdatedBriefing}
              title={"Atualizar Briefing"}
            ></NRoundedButton>
          </Collapse>
        ) : (
          ''
        )}
      </Row>
      <Action style={{
        justifyContent: 'flex-start'
      }}>
        {briefingRequest?.Briefing?.BriefingProjects.length === 0 ? (
          <NRoundedButton
            active={true}
            title={"Escolher Referência"}
            color={"#FF8400"}
            onClick={toggleShowChoseReferences}
          ></NRoundedButton>
        ) : (
          ""
        )}
        {imagesReferences ? (
          <ModalChoseReferences
            open={openModalReferences}
            setOpen={setOpenModalReferences}
            imagesReferences={imagesReferences}
            setImagesReferences={setImagesReferences}
            briefingRequestId={briefingRequest.id}
            refetch={refetch}
          />
        ) : (
          ""
        )}
        <AlertWarningProposal
          open={openWarningProposal}
          setOpen={setOpenWarningProposal}
        />
      </Action>
      <Row>
        <div>
          <ToDoTasks
            name="checklistCorrecoes"
            title={"Adicionar checklist de correção"}
            color={"#FFA500"}
            tasks={tasks}
            handleAddTask={handleAddTask}
            onToggle={toggleTask}
            onRemove={handleRemoveTask}
          />
          <NRoundedButton
            active={true}
            title={"Enviar alterações"}
            color={"#FF8400"}
            onClick={sendTodo}
          />
        </div>
        {userData.role.name === 'ADMIN' ? (<div>
          <BriefingTaxa briefingData={briefingData} />
        </div>) : null}
      </Row>
      <Row>
        <ContainerToggle>
          <Toggle
            initState={true}
            title={`Ver propostas para esse Briefing (${briefingRequest?.proposals?.length ?? 0
              })`}
          >
            {briefingRequest?.proposals
              ? briefingRequest.proposals.map((proposal, index) => {
                return (
                  <ProposalToggle
                    key={index}
                    index={index}
                    dateInput={proposal.deliveryAt}
                    priceMin={proposal.finalPriceMin}
                    priceMax={proposal.finalPriceMax}
                    priceFinal={proposal.finalPrice}
                    observation={proposal.observation}
                    submitBudget={submitBudget}
                    id={proposal.requestId}
                    briefingId={briefingRequest.Briefing.id}
                    removeBriefingAceept={onRemoveBriefingRequest}
                    builder={proposal.Builder}
                  />

                );
              })
              : "Nenhuma proposta disponível para esse briefing"}
          </Toggle>
        </ContainerToggle>
      </Row>
      {actionAdmin(userData, handleSendGestor, briefingRequest)}
    </Wrapper>
  );
}

const ContainerToggle = styled.div`
  display: flex;
  justify-content: start;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin: 10px 0;
  padding: 0;
`;

const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;

  > div {
    display: flex;
    flex: 0.4;
    align-items: flex-start;
    justify-content: space-between;

    > span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 898px) {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e3e3e3;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin: 5px;
    padding: 0 10px;
  }

  > span {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    align-items: center;
    flex-direction: column;

    > span {
      img,
      object {
        min-width: 380px;
        height: 140px;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    h2 {
      width: 100%;
    }

    > span {
      width: 100%;
      flex-direction: column;
      align-items: center;

      img,
      object {
        min-width: 280px;
        height: 240px;
      }
    }
  }
`;
