import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { useBudgetForm } from "./hooks/useBudgetForm";
import DialogBriefing from "./fragments/DialogBriefing";
import { DialogProject } from "../../DashboardExpositorComponents/solicitacaoPlataforma/fragments/DialogProject";
import {
  ActionContainer,
  Title,
  Row,
  WrapperPrice,
  InfoText,
} from "./StyledComponent";
import { DashInput, NRoundedButton } from "../../../shared-components";
import { FragmentReferencesOrProjects } from "./fragments/FragmentReferencesOrProjects";
import { BRIEFING_TYPE, COLORS } from "../../../helpers/types";


function shouldSubmitProject(briefingRequest) {
  if (
    briefingRequest?.Briefing?.briefingTypeId === BRIEFING_TYPE.PROJETO ||
    briefingRequest?.Briefing?.briefingTypeId ===
    BRIEFING_TYPE.PROJETO_MONTADORA
  ) {
    return true;
  }

  return false;
}

function renderInfoText(briefingRequest) {
  if (
    briefingRequest?.Briefing.briefingTypeId === BRIEFING_TYPE.MONTAGEM ||
    briefingRequest?.Briefing.briefingTypeId === BRIEFING_TYPE.OUTRO
  ) {
    return (
      <InfoText>
        {briefingRequest?.briefingReferencesId ? (
          <p>
            O expositor já escolheu a referência, agora diga o melhor valor
            possível com base na referência escolhida.
          </p>
        ) : (
          <p>
            Somente após o expositor escolher o modelo de referência você poderá
            dar o seu melhor valor estimado.
          </p>
        )}
      </InfoText>
    );
  }

  return (
    // <InfoText>

    // </InfoText>
    ""
  );
}

function renderTitle(briefingRequest) {
  const area =
    briefingRequest?.Briefing?.length * briefingRequest?.Briefing?.depth || 1;
  const notification = {
    icon: "",
    alert: "",
  };
  if (!briefingRequest.finalPriceMax || !briefingRequest.finalPriceMin) {
    notification.icon = "⚠️";
    notification.alert =
      "Você ainda não fez nenhuma oferta para esse Briefing.";
  }
  return (
    <Title>
      <h2
        className="title-company"
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "2rem",
          width: "100%",
        }}
      >
        Briefing {briefingRequest?.Briefing?.id} -{" "}
        {briefingRequest?.Briefing?.eventName} - {area} m²
        <span title={notification.alert}>{notification.icon}</span>
      </h2>
    </Title>
  );
}

function renderButtonSubmit({ briefingRequest, submitBudget, submitProposal }) {
  if (shouldSubmitProject(briefingRequest)) {
    let buttonText = "Enviar Proposta ou Projeto";
    if (briefingRequest?.finalPriceMax || briefingRequest?.finalPriceMin) {
      buttonText = "Atualizar Proposta ou Projeto";
    }

    return (
      <NRoundedButton
        onClick={submitProposal}
        active={true}
        color={"#22da28"}
        title={buttonText}
      />
    );
  }

  return (
    <NRoundedButton
      onClick={submitBudget}
      active={true}
      color={"#22da28"}
      title={"Enviar Proposta"}
    />
  );
}

function renderDateText(briefingRequest) {
  if (briefingRequest?.Briefing?.briefingTypeId === BRIEFING_TYPE.MONTAGEM) {
    return "Quando será a entrega do projeto técnico";
  }

  if (
    briefingRequest?.Briefing?.briefingTypeId ===
    BRIEFING_TYPE.PROJETO_MONTADORA
  ) {
    return "Quando será a entrega do projeto 3D";
  }

  return "Quando será a entrega";
}

export default function FragmentBriefingOpen({
  briefingRequest,
}) {
  const inputDateRef = useRef();
  const [modalBriefing, setModalBriefing] = useState(false);
  const [openModalProject, setOpenModalProject] = useState(false);
  const [projectViewZoom, setProjectViewZoom] = useState();

  const {
    stateForm,
    handleChange,
    form,
    handleForm,
    submitBudget,
    submitProposal,
    projectFiles,
    setProjectFiles,
    handleChangeProject,
  } = useBudgetForm({ briefingRequest });

  function showBriefing() {
    setModalBriefing(true);
  }

  function closeBriefingModal() {
    setModalBriefing(false);
  }

  function handleModalProject(projectLink) {
    setProjectViewZoom(projectLink);
    setOpenModalProject(true);
  }

  function closeModalProject() {
    setOpenModalProject(false);
  }

  return (
    <Wrapper key={briefingRequest.id}>
      <DialogBriefing
        open={modalBriefing}
        handleClose={closeBriefingModal}
        title={"Briefing"}
        briefingId={briefingRequest.briefingId}
      />
      <DialogProject
        open={openModalProject}
        handleClose={closeModalProject}
        projectViewZoom={projectViewZoom}
      />
      <Title>{renderTitle(briefingRequest)}</Title>
      <Row>
        <FragmentReferencesOrProjects
          briefingRequest={briefingRequest}
          handleModalProject={handleModalProject}
          setProjectViewZoom={setProjectViewZoom}
          projectFiles={projectFiles}
          handleChangeProject={handleChangeProject}
          handleForm={handleForm}
        />
      </Row>
      <Row>
        <Column>
          <div>
            <h2 className="title-deadline">
              {renderDateText(briefingRequest)}
            </h2>
          </div>

          <DashInput
            ref={inputDateRef}
            width={"80%"}
            borderless={"true"}
            background={"#F6F6F6"}
            className="input-date"
            name="deliveryAt"
            InputProps={{
              inputProps: { min: new Date().toISOString().split("T")[0] },
            }}
            onChange={(e) => {
              inputDateRef.current.type = "date";
              handleChange(e);
            }}
            value={stateForm?.deliveryAt}
            type="date"
          />

        </Column>
        <Column>
          <div>
            <h2 className="title-deadline">Preço final estimado por m² </h2>
          </div>
          <span >
            <WrapperPrice>
              <span>R$</span>
              <DashInput
                className="price-plan"
                borderless={"true"}
                background={"#F6F6F6"}
                placeholder="Valor mínimo"
                width={"40%"}
                type="number"
                name={`finalPriceMin`}
                value={stateForm?.finalPriceMin}
                onChange={handleChange}
              />
              <span>até</span>
              <DashInput
                className="price-plan"
                borderless={"true"}
                width={"40%"}
                background={"#F6F6F6"}
                placeholder="Valor máximo"
                value={stateForm?.finalPriceMax}
                onChange={handleChange}
                type="number"
                name={`finalPriceMax`}
              />
            </WrapperPrice>
            <textarea
              placeholder="Caso queria você pode explicar o porquê entre as diferenças dos valores." className="resume-briefing-text-area"
              rows="3"
              name={"observation"}
              value={stateForm?.observation}
              onChange={handleChange}
              style={{
                resize: 'none'
              }}
            />
          </span>

        </Column>
      </Row>
      <Row>
        <Row>
          <NRoundedButton
            active={true}
            color={COLORS.MONTADOR}
            onClick={showBriefing}
            title={"Ver Briefing"}
          ></NRoundedButton>
          {/* <button className="btn-noint">
              NÃO TENHO
              <br /> INTERESSE
            </button> */}
        </Row>
        {renderInfoText(briefingRequest)}
      </Row>
      <ActionContainer>
        <NRoundedButton
          color={"#adb5bd"}
          active={true}
          title={"Não tenho interesse"}
        />
        {renderButtonSubmit({
          briefingRequest,
          submitBudget,
          submitProposal,
        })}
      </ActionContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  min-height: 70rem;
  margin: 10px 0;
  padding: 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: "40%";
  margin: 10px 0;
  font-size: 1.6rem;

  & p {
    font-size: 1.6rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  .resume-briefing-text-area {
    width: 100%;
    font-size: 1.5rem;
    background-color: #f6f6f6;
    padding: 1rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid #adb5bd;
    
    ::placeholder {
      font-weight: 500;
    }

    &:focus {
      border: 1px solid #ccc;
    }

    &:hover {
      border: 1px solid #ccc;
    }
  }
`;

export const ProjectBlock = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 10px;
  width: 100%;
`;
