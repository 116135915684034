import React, { useState } from "react";
import ExpoLogo from "../../../images/Frame-126.png";
import Image1 from "../../../images/Rectangle735.png";
import Image2 from "../../../images/Rectangle739.png";
import Image3 from "../../../images/Rectangle740.png";
import Image4 from "../../../images/Rectangle741.png";
import YellowCircle from "../../../images/yellow-circle.png";
import "./styleProjetista.css";
import SideBarProjetista from "../../sidebar/SideBarProjetista";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

const ServicosProjetista = () => {
  const [task, setTask] = useState(""); //
  const [data, setData] = useState([]);

  const changeData = (e) => {
    setTask(e.target.value);
  };

  const change = () => {
    setData([...data, task]);
    setTask("");
  };

  const deleteService = (task) => {
    setData(data.filter((todo) => todo !== task));
  };

  return (
    <div className="cont-geral">
      <SideBarProjetista />
      <div className="expo1">
        <div className="expositor8">
          <div className="cont-inpus-page2">
            <div className="inputs-pagina">
              <div
                className="div-name"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="">
                  <h2 className="title-social" style={{ color: "black" }}>
                    Portfólio
                  </h2>
                  <div className="cont-companies2">
                    <div className="cont-company">
                      <img src={Image1} alt="" className="img-company" />
                    </div>
                    <div className="cont-company">
                      <img src={Image2} alt="" className="img-company" />
                    </div>
                    <div className="cont-company">
                      <img src={Image3} alt="" className="img-company" />
                    </div>
                    <div className="cont-company">
                      <img src={Image4} alt="" className="img-company" />
                    </div>
                  </div>
                </label>
                <label htmlFor="">
                  <h2 className="title-social" style={{ color: "black" }}>
                    Informações Básicas
                  </h2>
                  <div className="google">
                    <p>Nome</p>
                    <a
                      href=""
                      style={{
                        textDecoration: "underline",
                        color: "FDC22A",
                        fontSize: "16px",
                      }}
                    >
                      editar
                    </a>
                  </div>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <input
                      type="text"
                      className="form input-name"
                      style={{ marginTop: "0px" }}
                    />
                  </div>
                  <div className="google" style={{ marginTop: "20px" }}>
                    <p>Descrição</p>
                    <a
                      href=""
                      style={{
                        textDecoration: "underline",
                        color: "FDC22A",
                        fontSize: "16px",
                      }}
                    >
                      editar
                    </a>
                  </div>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <input
                      type="text"
                      className="form input-name"
                      style={{ marginTop: "0px" }}
                    />
                  </div>
                </label>
                <label htmlFor="">
                  <h2 className="title-social" style={{ color: "black" }}>
                    Serviços
                  </h2>
                  <div className="google">
                    <p>Adicone mais opções de serviços</p>
                  </div>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <input
                      type="text"
                      className="form input-name"
                      style={{ marginTop: "0px" }}
                      value={task}
                      onChange={(e) => changeData(e)}
                    />
                    <button
                      className="btn-picture"
                      style={{ marginLeft: "10px" }}
                      onClick={change}
                    >
                      Adicionar
                    </button>
                  </div>
                </label>
              </div>
              <div className="services-opt">
                {data.map((data, index) => (
                  <button
                    onClick={() => deleteService(data)}
                    key={index}
                    className="add-service"
                  >
                    {data}
                  </button>
                ))}
              </div>
              {/* <div className="share-infos2">
                <VisibilityOffOutlinedIcon
                  style={{
                    color: "black",
                    width: "50px",
                    height: "50px",
                    marginTop: "20px",
                  }}
                />
                <p className="text-info">
                  Que informações são compartilhadas com outras pessoas? <br />
                  <br /> A Westand só disponibiliza informações de contato a
                  expositores e promotores depois da proposta ser confirmada.
                </p>
              </div> */}
            </div>
            {/* <div className="share-infos personal-info">
              <VisibilityOffOutlinedIcon
                style={{
                  color: "black",
                  width: "50px",
                  height: "50px",
                  marginTop: "20px",
                }}
              />
              <p className="text-info">
                Que informações são compartilhadas com outras pessoas? <br />
                <br /> A Westand só disponibiliza informações de contato a
                expositores e promotores depois da proposta ser confirmada.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicosProjetista;
