import {useState} from "react";

export default function useFilter (data) {
  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  

  const handleFilter = (selectFilter) => {
    setFilter(selectFilter);
  };

  const filteredProfiles = data?.filter((profile) => {
    if(filter === "all") {
      return profile?.status.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
             profile?.role.indexOf(searchTerm) >= 0 ||
             profile?.email.indexOf(searchTerm) >= 0;
    }
      return profile?.status === filter && (
             profile?.role.indexOf(searchTerm) >= 0 ||
             profile?.email.indexOf(searchTerm)>= 0);
             
  })



  return {
    handleFilter, 
    searchTerm, 
    setSearchTerm,
    filteredProfiles,
    filter,
    setFilter
  }
};