import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";
import Eventos from "../../components/eventos/EventosAtuais";
import { Navigation } from "../../components/home/Navigation";
import { Footer } from "../../components/home/Footer";

import "../../App.css";
/*
import eventsDataJson from "../../data/data.json";
import { getEvents } from "../../api/events"; */

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const EventosAtuais = () => {
/*
  const [landingPageData, setLandingPageData] = useState({});
  const [eventsData, setEventsData] = useState({});

  useEffect(() => {   
    setEventsData(eventsDataJson); 
    console.log("dados do json", eventsData.Eventos);

    getEvents().then((response) => {
      setLandingPageData(response); 
    });
    console.log("dados da api", landingPageData);
  }, []);*/

  // efeito para carregar a página com scroll no alto
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navigation /> 
      <Eventos/>
      <Footer />
    </div>
  );
};

export default EventosAtuais;
