import { useState } from "react";

export default function useFilter(data) {
  const [filter, setFilter] = useState("all");
  const [filterType, setFilterType] = useState("projeto");
  const [searchTerm, setSearchTerm] = useState("");

  const handleFilter = (selectFilter) => {
    setFilter(selectFilter);
  };

  const handleFilterType = (selectType) => {
    setFilterType(selectType);
  };


  const filteredBriefings = data?.filter((briefing) => {

    const owner = briefing?.User?.Builders[0]?.name
      || briefing?.User?.Exhibitors[0]?.name
      || briefing?.User?.Promoter[0]?.name || '';

    if (filter === "all") {
      return briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner?.toLowerCase().includes(searchTerm?.toLowerCase())
    }
    return briefing.status === filter &&
      (briefing?.eventName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.city?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        briefing?.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        owner.toLowerCase().includes(searchTerm.toLowerCase()));
  });

  return {
    handleFilter,
    handleFilterType,
    searchTerm,
    setSearchTerm,
    filteredBriefings,
    filter,
    filterType,
    setFilter
  }
};