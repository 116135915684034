import React, { useState } from "react";
import {
  MenuItem,
  FormControl,
} from "@mui/material";

import {
  useForm,
  Controller,
  useFormContext,
} from "react-hook-form";
import { NSelect } from "../../shared-components/NSelect";
import { NTextField } from "../../shared-components";
import { Row, FormWrapper } from "./FormLayout";

const ElementosInformation = () => {
  const { control } = useFormContext();
  const { register } = useForm({
    defaultValues: {
      testeira: "",
      alturaPiso: "",
      alturaStand: ""
    }
  });
  return (
    <>
      <FormWrapper>
        <h3>Escolha os elementos presentes no seu stand:</h3>
        <Row>
          <div>
            <p>Altura de stand <span style={{ color: '#ff8800' }}>m²</span></p>
            <Controller
              control={control}
              name="alturaStand"
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="alturaStand"
                >
                  <MenuItem value={'2.5M'}>2.5m</MenuItem>
                  <MenuItem value={'3M'}>3m</MenuItem>
                  <MenuItem value={'3.5M'}>3.5m</MenuItem>
                  <MenuItem value={'4M'}>4m</MenuItem>
                  <MenuItem value={'4.5M'}>4.5m</MenuItem>
                  <MenuItem value={'MÁX'}>Máx. possível</MenuItem>
                </NSelect>)}

            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="alturaStand_obs"
              render={({ field }) => (
                <NTextField
                  {...register("alturaStand_obs")}
                  {...field}
                  id="alturaStand_obs"
                  name="alturaStand_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Altura do Piso <span style={{ color: '#ff8800' }}>cm</span></p>
            <Controller
              control={control}
              name="alturaPiso"
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="alturaPiso"
                >
                  <MenuItem value={'SEM_ELEVAÇÃO'}>Sem elevação</MenuItem>
                  <MenuItem value={'5M'}>5m</MenuItem>
                  <MenuItem value={'10M'}>10m</MenuItem>
                  <MenuItem value={'OUTROS'}>Outros</MenuItem>
                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="alturaPiso_obs"
              render={({ field }) => (
                <NTextField
                  {...register("alturaPiso_obs")}
                  {...field}
                  id="alturaPiso_obs"
                  name="alturaPiso_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Tipo do Piso</p>
            <Controller
              control={control}
              name="tipoPiso"
              defaultValue={[]}
              {...register("tipoPiso")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="tipoPiso"
                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={'VINÍLICO'}>Vinílico</MenuItem>
                  <MenuItem value={'CARPETE'}>Carpete</MenuItem>
                  <MenuItem value={'MADEIRA'}>Madeira</MenuItem>
                  <MenuItem value={'VIDRO'}>Vidro</MenuItem>
                  <MenuItem value={'OUTROS'}>Outros</MenuItem>
                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="tipoPiso_obs"
              render={({ field }) => (
                <NTextField
                  {...register("tipoPiso_obs")}
                  {...field}
                  id="tipoPiso_obs"
                  name="tipoPiso_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
        <Row>
          <div>
            <p>Testeira</p>
            <Controller
              control={control}
              name="testeira"
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="testeira"
                >
                  <MenuItem value={'RETANGULAR'}>Retangular</MenuItem>
                  <MenuItem value={'CURVADA'}>Curvada</MenuItem>
                  <MenuItem value={'SUSPENSA'}>Suspensa</MenuItem>
                  <MenuItem value={'OUTROS'}>Outros</MenuItem>
                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="testeira_obs"
              render={({ field }) => (
                <NTextField
                  {...register("testeira_obs")}
                  {...field}
                  id="testeira_obs"
                  name="testeira_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />

          </div>
          <div>
            <p>Mezanino</p>
            <Controller
              control={control}
              name="mezanino"
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("mezanino")}
                  {...field}
                  label="mezanino"
                >
                  <MenuItem value={'SEM_MEZANINO'}>Sem mezanino</MenuItem>
                  <MenuItem value={'MEIO_MEZANINO'}>Meio mezanino</MenuItem>
                  <MenuItem value={'MEZANINO_COMPLETO'}>Mezanino completo</MenuItem>

                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="mezanino_obs"
              render={({ field }) => (
                <NTextField
                  {...register("mezanino_obs")}
                  {...field}
                  id="mezanino_obs"
                  name="mezanino_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />

          </div>
          <div>
            <p>Pontos Elétricos (tomadas)</p>
            <Controller
              control={control}
              name="pontosEletricos"
              render={({ field }) => (
                <NTextField
                  className="form__medium"
                  id="pontos-eletricos"
                  name="pontosEletricos"
                  {...register("pontosEletricos")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="pontosEletricos_obs"
              render={({ field }) => (
                <NTextField
                  {...register("pontosEletricos_obs")}
                  {...field}
                  id="pontosEletricos_obs"
                  name="pontosEletricos_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
      </FormWrapper>
    </>
  );
};

export default ElementosInformation;