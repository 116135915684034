import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import Slide from "./Slide";
import "../../App.css";
import { searchCities } from "../../api/open";

export const Header = (props) => {

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState();

  async function handleSearchCity(e) {
    const result = await searchCities(e.target.value)
    setCities(result.map(v => ({
      label: v.name
    })))
  }

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="main-page">
                <div className="col-md-8 intro-text" id="main-text">
                  <h2 className="header-title2">
                    <span>O </span>
                    <span style={{ color: "#FF8400" }}>melhor </span>
                    <span>stand em 5 minutos</span>
                  </h2>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <p className="second-p">
                    {props.data ? props.data.question : "Loading"}
                  </p>
                  <InputContainer className="input-group mb-3" id="search-input">

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      freeSolo
                      options={cities}
                      onInputChange={handleSearchCity}
                      onChange={(event, value) => {
                        if (value) {
                          setCity(value.label)
                          sessionStorage.setItem('city', value.label)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="text"
                          className="form-control"
                          placeholder="Local"
                          aria-label="Local"
                          aria-describedby="basic-addon2"
                          onChange={(e) => {
                            setCity(e.target.value)
                            sessionStorage.setItem('city', e.target.value)
                          }}

                        />
                      )}
                    />
                    <Link to={`/cadastro-briefing?city=${city}`} >
                      <button className="search-btn">BUSCAR</button>
                    </Link>
                  </InputContainer>
                </div>
                <div className="imagem-banner" style={{ opacity: "1" }}>
                  <Slide />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const InputContainer = styled.div`

  & .MuiAutocomplete-root {
    padding: 0 !important;
    margin: 0 !important;
    width: 200px !important;
  }

  & .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
    width: 200px !important;
    height: 40px !important;
  }

  & .MuiAutocomplete-listbox {
    font-size: 1.4rem ;
  }

  & input {
    height: 10px !important;
    font-size: 1.6rem;
  }

  & svg {
    display: none;
  }

`