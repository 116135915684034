import {
  getUserData,
  privateFetch
} from "./user";

const API = process.env.REACT_APP_API;


export async function simulateCheckout({
  eventId,
  briefingRequestId,
}) {
  const token = await privateFetch();

  const res = await fetch(`${API}/payment/simulate-checkout/${eventId}/${briefingRequestId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function createPayment({
  briefingId,
  installments,
  eventId,
}) {

  const token = await privateFetch();
  const res = await fetch(`${API}/payment/create-payment/${briefingId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      installments,
      eventId,
    }),
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function createPaymentTax({
  briefingId,
}) {

  const token = await privateFetch();
  const res = await fetch(`${API}/payment/create-payment-tax/${briefingId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}