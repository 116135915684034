import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { listOpenBriefings } from "../../../api/briefing";
import Header from "../Header";
import { Link } from "react-router-dom";
import { ActionButton } from "./Styles";
import Loading from "../../../shared-components/Loading";
import { Error } from "../../../shared-components";


function FragmentOpenBriefings({ data, error }) {

  if (data) {
    return (
      <>
        {Object.values(data).map((group) => (
          group.briefings.map((briefing, index) => (
            <div className="solicits-open" key={index}>
              <h2 className="company-name">
                {briefing.eventName ||
                  briefing.Briefing?.eventName} - {briefing.Briefing?.depth * briefing.Briefing?.length} m²
              </h2>
              <img src={briefing.smallImage} alt="" />
              <Link
                to={`/solicit-aberto-montador/${briefing.Briefing?.id || briefing.id
                  }`}
              >
                <button className="detailes-solicit"> DETALHES</button>
              </Link>
            </div>
          ))
        ))}
      </>
    );
  }
  if (error) {
    <Error />
  }
}

const SolicitEmAbertoMontador = () => {
  const [openBriefingData, setOpenBriefingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    listOpenBriefings()
      .then((response) => {
        setOpenBriefingData(response);
      })
      .catch((response) => {
        setError(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);


  if (isLoading) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '40rem',
      }}>
        <Loading role="MONTADOR" />
      </div>
    );
  }


  return (
    <>
      <Header title="Solicitações em aberto" />
      <ActionButton>
        <Link to="/solicit-aberto-montador">
          <button className="btn-open5">em aberto</button>
        </Link>
        <Link to="/montador/lista">
          <button className="btn-open-mont">em negociação</button>
        </Link>
        <Link to="/solicit-aprovada-montador">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </ActionButton>
      <ContentWrapper>
        {openBriefingData && (
          <FragmentOpenBriefings
            data={openBriefingData}
            isLoading={isLoading}
            error={error}
          />
        )}
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default SolicitEmAbertoMontador;
