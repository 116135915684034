import {
  Wrapper,
  Row,
} from "./Styles";
import { useStepActions } from "../useStepActions";
import { ROLE, COLORS } from "../../../../helpers/types";


function renderText(role) {
  if (role.name === ROLE.EXPOSITOR) {
    return (
      <div style={{
        fontSize: '2rem',
      }}>
        <strong style={{
          color: `${COLORS.EXPOSITOR}`
        }}>Expositor</strong>, esse é o fluxo gestor. Aqui você poderá interagir durante as etapas do seu projeto, irá receber fotos pré-montagem, poderá solicitar alterações no projeto e por fim avaliar o serviço de montagem. Clique no botão <em>"próximo"</em> para começar.
      </div>
    )
  }
  if (role.name === ROLE.MONTADOR) {
    return (
      <div style={{
        fontSize: '2rem',
      }}>
        <strong style={{
          color: `${COLORS.MONTADOR}`
        }}>Montador</strong>, esse é o fluxo gestor. Aqui você poderá interagir com o solicitante do projeto, enviar fotos da fase de montagem e por fim irá avaliar o solicitante. Clique no botão <em>"próximo"</em> para começar.
      </div>
    )
  }
  if (role.name === ROLE.PROJETISTA) {
    return (
      <div style={{
        fontSize: '2rem',
      }}>
        <strong style={{
          color: `${COLORS.PROJETISTA}`
        }}>Projetista</strong>, esse é o fluxo gestor. Aqui você poderá receber as alterações de projeto do solicitante e subir novas versões do projeto. Clique no botão <em>"próximo"</em> para começar.
      </div>
    )
  }
}


const Step0 = () => {

  const {
    role,
  } = useStepActions();


  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Fluxo Gestor</h2>
      </div>
      <Row>
        {renderText(role)}
      </Row>

    </Wrapper>
  );
};


export { Step0 }