import React from "react";
import ExpoLogo from "../../../images/Frame-124.png";
import GreenCircle from "../../../images/green-circle.png";
import "../style/styleSolicitPlataforma.css";
import SideBarMontador from "../../sidebar/SideBarMontador";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const contratoAprovado = () => {
  return (
    <div className="cont-geral">
      <SideBarMontador />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>Hey </span>
              <span style={{ color: "#22da28" }}>Montador</span>
              <span style={{ color: "black" }}>
                , aqui estão <br /> seus contratos
              </span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={GreenCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="multiple-options">
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
        <div className="options-profile1">
          <div className="div-name1">
            <h3 style={{ marginTop: "15px", color: "black" }}>Fulano de tal</h3>
            <h2
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "10px",
                color: "black",
              }}
            >
              ...
            </h2>
          </div>
          <div className="rating-negoc1">
            <StarOutlinedIcon
              style={{ color: "#FDC22A", width: "20px", height: "20px" }}
              className="star-rating"
            />
            <p style={{ margin: "0px", fontWeight: "bold", color: "black" }}>
              &nbsp;4.5
            </p>
          </div>
          <div className="avatar">
            <p style={{ color: "black" }}>
              2/10 atividades de cadastro concluídas
            </p>
            <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={ExpoLogo} />
            </Stack>
          </div>
          <div className="btns-contract">
            <button className="btn-aceitar">aceitar</button>
            <button className="btn-n-aceitar">não aceitar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default contratoAprovado;
