import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useSimpleForm } from "../../../../hooks/useSimpleForm";
import { getBriefing } from "../../../../api/briefing";


export function useEditBriefingForm({ briefingId }) {

  const {
    isLoading,
    data: briefingData,
    refetch,
  } = useQuery(
    `briefing-request-${briefingId}`,
    () => getBriefing(briefingId), {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  });

  const { stateForm, handleChange, setStateForm } = useSimpleForm({});
  const [imagesReferences, setImagesReferences] = useState(null);
  const [projects, setProjects] = useState(null);


  useEffect(() => {

    setStateForm({
      ...briefingData,
      BriefingStructs: briefingData?.BriefingStructs || [],
      BriefingElements: briefingData?.BriefingElements || [],
      BriefingMultimedia: briefingData?.BriefingMultimedia || [],
      BriefingProjects: stateForm?.BriefingProjects?.length > 0 ? stateForm.BriefingProjects : briefingData?.BriefingProjects || [],
      BriefingProjectsArte: stateForm?.BriefingProjectsArte?.length > 0 ? stateForm.BriefingProjectsArte : briefingData?.BriefingProjectsArte || [],
      BriefingReferences: stateForm?.BriefingReferences?.length > 0 ? stateForm.BriefingReferences : briefingData?.BriefingReferences || []
    })
    setImagesReferences(briefingData?.BriefingReferences);
    setProjects(briefingData?.BriefingProjects);

  }, [briefingData])

  return {
    stateForm,
    handleChange,
    setStateForm,
    briefingData,
    imagesReferences,
    setImagesReferences,
    projects,
    setProjects,
    refetch,
    isLoading,
  }
}