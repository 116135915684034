import { createTheme, ThemeProvider } from "@mui/material/styles";
import EventoInformation from "./EventoInformation";
import OrcamentoInformation from "./OrcamentoInformation";
import EstruturaInformation from "./EstruturaInformation";
import ElementosInformation from "./ElementosInformation";
import MultimidiaInformation from "./MultimidiaInformation";
import BuffetInformation from "./BuffetInformation";
import ReferenceInformation from "./ReferenceInformation";
import Blueprint from "./Blueprint";
import FilePhoto from "./FilePhoto";
import FreeInformation from "./FreeInformation";
import FimBriefing from "./FimBriefing";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400 !important",
    },
  },
});
export function routeStepContent(step, briefing, setBriefing) {
  switch (step) {
    case 0:
      return <EventoInformation theme={theme} briefing={briefing} setBriefing={setBriefing} />;
    case 1:
      return <OrcamentoInformation />;
    case 2:
      return <FilePhoto />;
    case 3:
      return <EstruturaInformation />;
    case 4:
      return <ElementosInformation />;
    case 5:
      return <MultimidiaInformation />;
    case 6:
      return <BuffetInformation />;
    case 7:
      return <ReferenceInformation />;
    case 8:
      return <Blueprint />;
    case 9:
      return <FreeInformation />;
    case 10:
      return <FimBriefing />;
    default:
      return "unknown step";
  }
}


export function getSteps() {
  return ["Evento", "Orçamento", "Referências", "Estrutura", "Elementos", "Multimidia", "Promoter & Buffet", "Stand Passados", "Planta Baixa", "Etapa Livre"];
}