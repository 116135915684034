import React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

const RatingStars = ({
  state,
  handleChange
}) => {

  return (
    <div>
      <Box
        sx={{
          "& > legend": { mt: 2 },
        }}
      >
        <Rating
          name="score"
          value={Number(state)}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
};

export default RatingStars;
