export const formatCurrency = (numStr) => {
  if (numStr === "") return "";
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 0,
  }).format(numStr);
};

export const formatCurrencyDecimal = (numStr) => {
  if (numStr === "") return "";
  if (isNaN(numStr)) return null;

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 2,
  }).format(numStr);
};
