const API = process.env.REACT_APP_API;


export function getUserData() {
  return localStorage.getItem('nossostand');
}

export function privateFetch() {
  const token = getUserData("nossostand");

  if (!token) {
    return new Error("there is no token");
  }
  return token;
}

export async function updateProfileInfo({ profileInfo }) {
  const token = privateFetch();

  const res = await fetch(`${API}/user/profile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(profileInfo),
  });

  return res.json();
}

export async function changePassword({ password, confirmPassword }) {
  const token = privateFetch();

  const res = await fetch(`${API}/user/change-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password,
      confirmPassword,
    }),
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json();
  throw error;
}

export async function disconnectDevice({ deviceId }) {
  const token = privateFetch();

  const res = await fetch(`${API}/user/disconnet-device`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      sessionId: deviceId,
    }),
  });

  return res.json();
}

export async function listDevices() {
  const token = privateFetch();

  const res = await fetch(`${API}/user/list-devices`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function getProfileInfo() {
  const token = privateFetch();

  const res = await fetch(`${API}/user/profile`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function resetPassword(email) {
  const res = await fetch(`${API}/user/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
  if (res.ok) {
    return await res.json();
  }
  const error = await res.json();
  throw error;
}

export async function registerUser({
  name,
  email,
  phone,
  password,
  reEnterPassword,
  profession,
}) {
  const res = await fetch(`${API}/sign-up`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      password,
      confirmPassword: reEnterPassword,
      role: profession,
    }),
  });
  if (res.ok) {
    return await res.json();
  }
  const error = await res.json();
  throw error;
}
