import React from "react";
import errorImage from '../images/error-404-mob.png'

export const Error = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src={errorImage} alt="imagem_error" />
    </div>
  );
};


