import React, { useState } from "react";
import styled from "@emotion/styled";
import { MenuItem, ListItem } from "@mui/material";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { NSelect } from "../../shared-components/NSelect";
import { NTextField } from "../../shared-components";
import { Row, FormWrapper } from "./FormLayout";

const EstruturaInformation = () => {
  const { control, getValues } = useFormContext();
  const { register } = useForm();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <h3>Escolha os elementos presentes no seu stand:</h3>
      <FormWrapper>
        <Row>
          <div>
            <p>Balcão</p>
            <Controller
              control={control}
              name="balcao"
              render={({ field }) => (
                <NTextField
                  className="form__medium"
                  id="balcao"
                  name="balcao"
                  {...register("balcao")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="balcao_obs"
              render={({ field }) => (
                <NTextField
                  {...register("balcao_obs")}
                  {...field}
                  id="balcao_obs"
                  name="balcao_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Objetos Suspensos</p>
            <Controller
              control={control}
              name="suspenso"
              render={({ field }) => (
                <NTextField
                  id="suspenso"
                  name="suspenso"
                  {...register("suspenso")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="suspenso_obs"
              render={({ field }) => (
                <NTextField
                  {...register("suspenso_obs")}
                  {...field}
                  id="suspenso_obs"

                  name="suspenso_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Estoque</p>
            <Controller
              control={control}
              defaultValue={[]}
              name="estoque"
              {...register("estoque")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="estoque"

                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={"SEM_PRATELEIRAS"}>Sem prateleiras</MenuItem>
                  <MenuItem value={"PRATELEIRAS_PAREDE"}>
                    Com prateleiras na parede
                  </MenuItem>
                  <MenuItem value={"ESTANTES_CHÃO"}>
                    Com estantes no chão
                  </MenuItem>
                </NSelect>
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="estoque_obs"
              render={({ field }) => (
                <NTextField
                  {...register("estoque_obs")}
                  {...field}
                  id="estoque_obs"
                  name="estoque_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
        <Row>
          <div>
            <p>Mobília</p>
            <Controller
              control={control}
              name="mobilia"
              defaultValue={[]}
              {...register("mobilia")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="mobilia"
                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={"LOUNGE"}>Lounge</MenuItem>
                  <MenuItem value={"POLTRONAS"}>Poltronas</MenuItem>
                  <MenuItem value={"APARADOR"}>Aparador</MenuItem>
                  <MenuItem value={"MESAS_CADEIRAS"}>Mesas + Cadeiras</MenuItem>
                  <MenuItem value={"MESAS"}>Mesas</MenuItem>
                </NSelect>
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="mobilia_obs"
              render={({ field }) => (
                <NTextField
                  {...register("mobilia_obs")}
                  {...field}
                  id="mobilia_obs"
                  name="mobilia_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Área para coffee break</p>
            <Controller
              control={control}
              name="coffeeBreak"
              defaultValue={[]}
              {...register("coffeeBreak")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="coffeeBreak"
                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={"APARADOR"}>Aparador</MenuItem>
                  <MenuItem value={"BALCÃO"}>Balcão</MenuItem>
                  <MenuItem value={"MESA"}>Mesa</MenuItem>
                </NSelect>
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="coffeeBreak_obs"
              render={({ field }) => (
                <NTextField
                  {...register("coffeeBreak_obs")}
                  {...field}
                  id="coffeeBreak_obs"
                  name="coffeeBreak_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Móvel expositor</p>
            <Controller
              control={control}
              name="movelExpositor"
              defaultValue={[]}
              {...register("movelExpositor")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  defaultValue={[]}
                  label="movelExpositor"
                  multiple
                >
                  <MenuItem value={"VITRINES"}>Vitrines</MenuItem>
                  <MenuItem value={"MESAS"}>Mesas</MenuItem>
                  <MenuItem value={"PRATELEIRA_PAREDE"}>
                    Prateleiras de parede
                  </MenuItem>
                </NSelect>
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="movelExpositor_obs"
              render={({ field }) => (
                <NTextField
                  {...register("movelExpositor_obs")}
                  {...field}
                  id="movelExpositor_obs"
                  name="movelExpositor_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
        <Row>
          <div>
            <p>Freezer</p>
            <Controller
              control={control}
              name="freezer"
              render={({ field }) => (
                <NTextField
                  className="form__medium"
                  id="freezer"
                  name="freezer"
                  {...register("freezer")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="freezer_obs"
              render={({ field }) => (
                <NTextField
                  {...register("freezer_obs")}
                  {...field}
                  id="freezer_obs"
                  name="freezer_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Sala de Reunião</p>
            <Controller
              control={control}
              name="salaReuniao"
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="salaReuniao"
                >
                  <MenuItem value={"2_4_PX"}>2-4 px</MenuItem>
                  <MenuItem value={"5_7_PX"}>5-7 px</MenuItem>
                  <MenuItem value={"8_12_PX"}>8-12 px</MenuItem>
                </NSelect>
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="salaReuniao_obs"
              render={({ field }) => (
                <NTextField
                  {...register("salaReuniao_obs")}
                  {...field}
                  id="salaReuniao_obs"
                  name="salaReuniao_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Paisagismo</p>
            <Controller
              control={control}
              name="paisagismo"
              defaultValue={[]}
              {...register("paisagismo")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="paisagismo"
                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={"PLANTAS_VASOS"}>Plantas com vasos</MenuItem>
                  <MenuItem value={"JARDINS_VERTICAIS"}>
                    Jardins Verticais
                  </MenuItem>
                  <MenuItem value={"PROJETO_ESPECIAL"}>
                    Projeto especial
                  </MenuItem>
                  <MenuItem value={"OUTROS"}>Outros</MenuItem>
                </NSelect>
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="paisagismo_obs"
              render={({ field }) => (
                <NTextField
                  {...register("paisagismo_obs")}
                  {...field}
                  id="paisagismo_obs"
                  name="paisagismo_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
      </FormWrapper>
    </>
  );
};

export default EstruturaInformation;
