import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onValue,
  push,
  set
} from "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyDlkk5kO5keka9juf6QQ16GmwRfhQy5Jto",
  appId: "1:655029350230:web:a194da504b257220ccf0f7",
  databaseURL: "https://nossostand-ff2f6-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

const mainNode = ref(database, '/chat');

function listenRoom(node) {
  return ref(database, `/chat/${node}`)
}

function sendMessage(node, message) {
  const key = push(ref(database, `/chat/${node}`)).key;

  set(ref(database, `/chat/${node}/${key}`), message)
}

export {
  mainNode,
  onValue,
  listenRoom,
  sendMessage,
}