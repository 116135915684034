import { useState } from "react";
import styled from "@emotion/styled";
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { formatDateLocale } from "../../../../helpers/formatDate";
import exhibitorAvatar from "../../../../images/Frame-122.png";
import adminAvatar from "../../../../images/Frame-125.png";
import builderAvatar from "../../../../images/Frame-124.png";

import DialogUser from "./DialogUser";
import { changeOwnerBriefing } from "../../../../api/admin";
import { useNotification } from "../../../../context/NotificationContext";

function MenuAction({
  openMenu,
  handleCloseMenu,
  menuAnchor,
  handleOpenModalUser
}) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={menuAnchor}
      open={openMenu}
      onClose={handleCloseMenu}
      MenuListProps={{
        sx: {
          "& .MuiMenuItem-root": {
            fontSize: "1.6rem",
            fontWeight: "300",
          },
        },
      }}
    >
      <MenuItem onClick={() => {
        handleCloseMenu()
        handleOpenModalUser()
      }}>Atribuir para Usuário</MenuItem>
    </Menu>
  )
}

function renderButtonStatus(status) {
  if (status === "ABERTO") {
    return <button className="btn-aberto">aberto</button>
  }
  if (status === "EM_NEGOCIAÇÃO") {
    return <button className="btn-andamento">andamento</button>
  }
  if (status === "ACEITO") {
    return <button className="btn-aprovado">aprovado</button>
  }
}

function renderButtonPayment(payment) {
  if (payment[0]?.status === "PAGO") {
    return <button className="btn-aprovado">Pago</button>
  }
  if (payment[0]?.status === "FATURADO") {
    return <button className="btn-andamento">Pendente</button>
  }
}

function renderRoleBriefing({ briefingData }) {

  if (briefingData?.User?.Exhibitors[0]?.name) {
    return (
      <>
        <img
          alt="expositor"
          src={exhibitorAvatar}

        />
        <strong>{briefingData?.User?.Exhibitors[0]?.name}</strong>
      </>
    )
  }

  if (briefingData?.User?.Builders[0]?.name) {
    return (
      <>
        <img
          alt="montador"
          src={builderAvatar}
        />
        <strong>{briefingData?.User?.Builders[0]?.name}</strong>
      </>
    )
  }

  if (briefingData?.User?.Promoter[0]?.name) {
    return (
      <>
        <img
          alt="admin"
          src={adminAvatar}
        />
        <strong>{briefingData?.User?.Promoter[0]?.name}</strong>
      </>
    )
  }
}

export function FragmentBriefing({ briefingData }) {

  const [modalExhibitor, setModalExhibitor] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(briefingData?.usersId || "");

  const navigate = useNavigate();
  const notifcation = useNotification();

  const totalArea = briefingData.length * briefingData.depth;

  function handleClick(briefingId) {
    navigate(`/editar-briefing/${briefingId}`)
  }

  function handleCloseModalUser() {
    setModalExhibitor(false);
  }

  function handleOpenModalUser() {
    setModalExhibitor(true);
  }

  function handleOpenMenu(event) {
    setMenuAnchor(event.currentTarget)
    setOpenMenu(true);
  }

  function handleCloseMenu() {
    setOpenMenu(false);
  }


  function handleUser({ target: { value } }) {
    setSelectedUser(value);
  }

  function submitUser() {
    changeOwnerBriefing({
      briefingId: briefingData.id,
      usersId: selectedUser,
    }).then(res => {
      notifcation.show({
        message: "Briefing atribuido com sucesso",
      })
    }).catch(err => {
      notifcation.show({
        message: err.message || "Houve um erro",
        type: "error"
      })
    })
    handleCloseModalUser();
  }

  return (
    <Wrapper
    >
      <DialogUser
        title={"Para quem você quer enviar esse briefing"}
        open={modalExhibitor}
        handleClose={handleCloseModalUser}
        handleUser={handleUser}
        selectedUser={selectedUser}
        submitUser={submitUser}
      />
      <div onClick={() => handleClick(briefingData.id)}>
        <h2>
          {
            briefingData.User?.Exhibitors[0]?.name ||
            briefingData.User?.Builders[0]?.name ||
            briefingData.User?.Promoter[0]?.name
          } - {briefingData.eventName || "Sem nome"} - {totalArea} m² - {briefingData.id}
        </h2>
      </div>
      <SectionRow onClick={() => handleClick(briefingData.id)}>
        {renderRoleBriefing({ briefingData })}
      </SectionRow>
      <Section onClick={() => handleClick(briefingData.id)}>
        <p><strong>Criado:</strong> {formatDateLocale(briefingData.createdAt)}</p>
        <p><strong>Atualizado:</strong> {formatDateLocale(briefingData.updatedAt)}</p>
      </Section>
      <ActionArea>
        {renderButtonStatus(briefingData.status)}
        {renderButtonPayment(briefingData?.Payments)}
        <MoreVertSharpIcon onClick={handleOpenMenu} />
        <MenuAction
          openMenu={openMenu}
          handleCloseMenu={handleCloseMenu}
          menuAnchor={menuAnchor}
          handleOpenModalUser={handleOpenModalUser}
        />
      </ActionArea>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 0.375rem;
  padding: 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 10rem;
  cursor: pointer;
  width: 98%;
  align-items: center;
  gap: 2vw;
  margin: 0.6vh 0;
  transition: all linear 200ms;

  & .MuiList-root{
    background-color: red;
  }

  &:hover {
    box-shadow: 0 0 0.6rem 0.25rem rgb(161 172 184 / 65%);
  }
  h2 {
    font-size: 2rem;
    font-weight: 800;
  }
 
  div:nth-of-type(1) {
    flex: 2;
  }
  div:nth-of-type(2) {
    flex: 1;
  }
   div:nth-of-type(3) {
    flex: 2;
  }


  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
  }

`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const SectionRow = styled(Section)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  img {
    width: 40px;
    height: 40px;
  }

`

const ActionArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`