import React, { useState } from "react";
import ExpoLogo from "../../../images/Frame-125.png";
import OrangeCircle from "../../../images/purple-circle.png";
import SideBarADM from "../SideBarADM";
import { Link } from "react-router-dom";
import "../ADM.css";
import { SwitchControlls } from "../../../shared-components/SwitchControlls";
import { ToDoTasks } from "../../../shared-components/ToDoTasks";

const MainDash = () => {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      label: "dormir",
      checked: false,
    },
    {
      id: 2,
      label: "comer",
      checked: false,
    },
  ]);

  const handleToggleTask = (taskId) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          checked: !task.checked,
        };
      } else {
        return task;
      }
    });
    setTasks(updatedTasks);
  };

  return (
    <div className="cont-geral">
      <SideBarADM />
      <div className="btns-solict2">
        <Link to="">
          <button className="promotor-btn1">configurações</button>
        </Link>
        <Link to="">
          <button className="promotor-btn1">performance</button>
        </Link>
        <Link to="">
          <button className="promotor-btn1">contatos</button>
        </Link>
      </div>
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>
                O que você gostaria <br /> de{" "}
              </span>
              <span style={{ color: "#764FAA" }}>gerenciar</span>
              <span style={{ color: "black" }}>?</span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={OrangeCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="main-cont">
        <div className="cont-charts">
          <div className="finance-chart">
            <h2 style={{ color: "black", fontSize: "30px" }}>Financeiro</h2>
          </div>
        </div>
        <div className="main-cont2">
          <div className="cont-contacts">
            <div className="finance-chart2">
              <h2 style={{ color: "black", fontSize: "30px" }}>Contatos</h2>
            </div>
            <div className="finance-chart2">
              <h2 style={{ color: "black", fontSize: "30px" }}>Contratos</h2>
            </div>
          </div>
          <div className="finance-chart3">
            <h2 style={{ color: "black", fontSize: "30px" }}>
              Editor do jornada Briefing
            </h2>
          </div>
        </div>
      </div>
      <div className="cont-adm">
        <div className="finance-chart4">
          <h2 style={{ color: "black", fontSize: "20px" }}>Administrativo</h2>
        </div>
        <div className="finance-chart4">
          <h2 style={{ color: "black", fontSize: "20px" }}>MArketing e SEO</h2>
        </div>
        <div className="finance-chart4">
          <h2 style={{ color: "black", fontSize: "20px" }}>Performance</h2>
        </div>
      </div>
    </div>
  );
};

export default MainDash;
