import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { listDesignersFitBriefing } from "../../../../api/admin";

export function useDesigner({
  briefingId,
}) {
  const [modalOpenDesigner, setModalOpenDesigner] = useState(false)
  const [designers, setDesigners] = useState([]);

  const { data } = useQuery(`designerfit-${briefingId}`,
    () => listDesignersFitBriefing({ briefingId, }))


  useEffect(() => {
    const designersId = data?.map(v => v.Designer?.Designers[0]?.usersId)
    setDesigners(designersId);
  }, [data])

  function handleDesigners({ target: { value } }) {
    if (!value) return;
    setDesigners([
      ...value,
    ])
  }

  function handleCloseModalDesigner() {
    setModalOpenDesigner(false)
  }

  function handleOpenModalDesigner() {
    setModalOpenDesigner(true);
  }

  return {
    handleDesigners,
    modalOpenDesigner,
    setModalOpenDesigner,
    handleCloseModalDesigner,
    handleOpenModalDesigner,
    designers
  }

}