import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import {
  useForm,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { listStandImages } from "../../api/briefing";
import UploadFile from "../../shared-components/UploadFile";

const FragmentImages = ({ data, isLoading, handleSelect }) => {

  if (isLoading) {
    return (
      <span>carregando...</span>
    )
  }
  if (data) {
    return (
      <StandExamples>
        {data.map((v, index) => (
          
          <ImageContainer
            key={index}
            selected={v.selected}
          >
            <span>{index + 1}</span>
            <img key={v.id} src={v.image} alt={v.type}
              onClick={() => {

                handleSelect(v);
              }}
            />
          </ImageContainer>
        ))}
      </StandExamples>
    )
  }
}

const FilePhoto = () => {
  const { control, setValue, getValues } = useFormContext();
  const { data, isLoading } = useQuery('stand-images', listStandImages, {
    staleTime: Infinity,
    cacheTime: Infinity
  });
  const persistedForm = getValues('references');
  const [imagesSelected, setImagesSelected] = useState(persistedForm);
  
  const imageSelectedDom = imagesSelected.map(image => {
    if (image.image.startsWith("https://") && image.id) {
      return {
        id: image.id,
        image: image.image,
        type: image.type,
        selected: image.selected,
      };
    } else {
      return {
        image: image.image,
        type: image.type,
        selected: image.selected,
      };
    }
  });

  const [disableUpload, setDisableUpload] = useState(false);


  const { register, methods } = useForm();

  setValue('references', imageSelectedDom)

  function handleSelect(clicked) {

    if (clicked.selected) {
      setImagesSelected(() => imageSelectedDom.filter(v => v.image !== clicked.image));
      clicked.selected = false;
      return;
    }
    if (imageSelectedDom.length < 4) {
      clicked.selected = true;
      setImagesSelected([...imageSelectedDom, clicked]);
    }

  }

  const handleRemoveFile = (event) => {
    setImagesSelected(imageSelectedDom.filter(v => v.id !== event.target.id))
  }

  const handleGetFile = (file, result) => {

    const type = file.target.files[0].type.split("/")[1]
    setImagesSelected([
      ...imageSelectedDom,
      {
        type: 'upload',
        image: `${result.key}.${type}`,
        id: file.target.id,
      }
    ])
  }


  return (
    <Wrapper>
      <div>
        <h3>Referências</h3>
        <p>Escolha até 4 fotos como referências ou também envie as suas próprias:</p>
        <FormProvider {...methods}>
          <ContentUpload >
            <UploadFile
              id=""
              getFile={handleGetFile}
              deleteFile={(e) => handleRemoveFile(e)}
              disabled={imageSelectedDom.length >= 4 ? true : false}
            />
            <UploadFile
              id=""
              getFile={handleGetFile}
              deleteFile={(e) => handleRemoveFile(e)}
              disabled={imageSelectedDom.length >= 4 ? true : false}
            />
            <UploadFile
              id=""
              getFile={handleGetFile}
              deleteFile={(e) => handleRemoveFile(e)}
              disabled={imageSelectedDom.length >= 4 ? true : false}
            />
            <UploadFile
              id=""
              getFile={handleGetFile}
              deleteFile={(e) => handleRemoveFile(e)}
              disabled={imageSelectedDom.length >= 4 ? true : false}
            />
          </ContentUpload>
          <FragmentImages data={data} isLoading={isLoading} handleSelect={handleSelect} />
        </FormProvider>
      </div>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  
`

const ContentUpload = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px 0;

  >div {
    width: 46%;
    min-width: 200px;
  }

`

const StandExamples = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: .8rem;
`

const ImageContainer = styled.div`

  position: relative;
  background-color: ${props => props.selected ? '#585858' : ''};
  transform: ${props => props.selected ? 'scale(0.90)' : 'scale(1)'};
  filter: ${props => props.selected ? 'brightness(50%)' : 'brightness(100%)'};
  transition: all 100ms linear;

  img {
    width: 280px;
    height: 200px;
    object-fit: cover;
    flex-basis: 20%;
    margin: 10px 0;
    border: 4px solid #58585860;
  }

  img:hover {
    cursor: pointer;
    filter: brightness(110%);
    
    border: 4px solid #585858;
  }

  span {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10px;
    top: 20px;
    width: 40px;
    height: 40px;
    background-color: white;
    font-size: 2rem;
    border-radius: 50%;
  }
`

export default FilePhoto;