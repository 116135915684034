import * as React from 'react';
import styled from '@emotion/styled';
import { useUser } from '../context/UserContext';
import { COLORS } from '../helpers/types';

export default function LinearLoading({ role = 'EXPOSITOR' }) {

  const { userData } = useUser();


  return (
    <Wrapper role={userData.role.name}>
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>

      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 0px 6px;
  border-radius: 50%;
  background-color: ${props => props.role ? COLORS[props.role] : '#a3a1a1'};
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}


`