import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  gap: 20px;
`

export const FieldBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  display: flex;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  color: #FF8400;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 0 10px;


  > div {
    display: flex;
    flex: 1;
  }

  > span {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 1200px) {
   flex-direction: column;
  }


`