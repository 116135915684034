import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { donationItems, sendDonations } from '../../../../api/exhibitor';
import { NRoundedButton } from '../../../../shared-components';
import Loading from '../../../../shared-components/Loading';
import { useNotification } from '../../../../context/NotificationContext';
import { Typography } from '@mui/material';

const colors = [
  'type-item color1',
  'type-item color2',
  'type-item color3',
  'type-item color4',
  'type-item pendency-color',
  'type-item approved-color',
  'type-item reject-color'
]

function renderStatusItemDonation({ data }) {
  if (data.expositorQuerDoacao && data.MontadorAceitaDoarExpositor === undefined) {
    return (
      <span className='type-item pendency-color' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        Pendente
      </span>
    )
  }
  if (data.expositorQuerDoacao && !data.MontadorAceitaDoarExpositor) {
    return (
      <span className='type-item reject-color' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        Rejeitado
      </span>
    )
  }
  return (
    <span className='type-item approved-color' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      Aprovado
    </span>
  )
}

function ListItemDonation({ data, labelId, parent, handleToggle, index }) {
  const [checked, setChecked] = useState(data.checked);

  return (
    <ListItem
      key={`list-${data.id}`}
      role="listitem"
      onClick={() => {
        setChecked(() => !checked)
        handleToggle(data)
      }}
    >
      <ListItemIcon>
        <Checkbox
          checked={checked || false}
          tabIndex={-1}
          disableRipple
          inputProps={{
            'aria-labelledby': labelId,
          }}

        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={data.name}
        className="list-text" />
      <span>
        {data.expositorQuerDoacao ? renderStatusItemDonation({ data }) : (<span className={colors[index % colors.length]}>
          {parent.name}
        </span>)}
      </span>

    </ListItem>
  )
}

function renderLeftList(left, handleToggle) {

  return (
    <>
      {left && left.filter(value => value.children.length).map((value, index) => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return value.children.map(child =>
          <ListItemDonation
            key={child.id}
            index={index}
            data={child}
            parent={value}
            labelId={labelId}
            handleToggle={handleToggle}
          />
        )
      })}
    </>
  )

}

function renderRightList(right, checked, handleToggle) {

  return (
    <>
      {right && right.filter(value => value.children.length).map((value, index) => {
        const labelId = `transfer-list-all-item-${value}-label`;

        return value.children.map(child =>
          <ListItemDonation
            key={child.id}
            index={index}
            data={child}
            parent={value}
            labelId={labelId}
            handleToggle={handleToggle}
          />
        )
      })}
    </>
  )
}

const FragmentDoacao = ({ data }) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [donations, setDonations] = useState(data);
  const notification = useNotification();
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    const leftItems = donations.map(value => ({
      ...value,
      children: value.children.filter(v => !v.expositorQuerDoacao)
    }))
    setLeft(leftItems);

    const rightItems = donations.map(value => ({
      ...value,
      children: value.children.filter(v => v.expositorQuerDoacao)
    }))
    setRight(rightItems);

    setShowContent(donations.length > 0)
  }, [donations]);

  //Vitor: Atenção quando for chamar função pelo onClick
  const sendMontador = async () => {

    try {
      await sendDonations(data[0].briefingId, donations);
      notification.show({
        message: 'Itens de interesse enviados para o montador'
      })
    } catch (error) {
      notification.show({
        message: 'Houve um erro ao enviar os itens...',
        type: 'error'
      })
    }
  };



  // Dados do componente MUI
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => {
    value.checked = !value.checked;
  };

  const numberOfChecked = (items) => intersection(checked, items).length;
  const handleCheckedRight = () => {

    const newDonation = donations.map(parent => ({
      ...parent,
      children: parent.children.map(child => {
        if (child.checked || child.expositorQuerDoacao) {
          return ({
            ...child,
            checked: false,
            expositorQuerDoacao: true,
          })
        }
        return {
          ...child,
          checked: false,
          expositorQuerDoacao: false,
        }
      })
    }))

    setDonations(newDonation)
  };

  const handleCheckedLeft = () => {
    setLeft((prevLeft) => {
      const updatedLeft = [...prevLeft, ...rightChecked];
      return updatedLeft;
    });

    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    const updatedDonations = donations.map((parent) => ({
      ...parent,
      children: parent.children.map((child) => {
        if (child.checked) {
          return {
            ...child,
            checked: false,
            expositorQuerDoacao: false, // Define expositorQuerDoacao como false ao retornar para "Memorial"
          };
        }
        return child;
      }),
    }));

    setDonations(updatedDonations);
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }
  //Vitor: Se não for usar apaga depois
  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 230,
          bgcolor: '#ffff',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
        className='list'
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          const matchingItem = data.find((item) => item.name === value);

          return (
            <ListItem
              key={value}
              role="listitem"
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  style={{ fontSize: '3rem' }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} className="list-text" />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <ListWrapper>
      <div style={{
        textAlign: "center",
        width: "65%",
        margin: "auto",
      }}>
        <p style={{
          fontSize: '1.8rem'
        }}>
          <b>Doações: </b>Selecione os itens que você tem interesse no botão <strong>"Tenho Interesse"</strong> e aguarde até que a empresa Montadora aceite ou rejeite o pedido de doação.
          <br />
          <span style={{
            fontSize: '1.8rem'
          }}>
            Você também pode desistir de um pedido de doação no botão <strong>"Desistir"</strong>.</span>
          <br />
          <br />
        </p>
      </div>
      {showContent ? (
        <>
          <Grid container spacing={2} justifyContent="center" alignItems="center" className="grid-container">
            <Grid item className="memorial-grid">
              <Card>
                <CardHeader
                  title="Itens do stand"
                />

                <Divider />
                <List
                  sx={{
                    minHeight: '50rem',
                    maxHeight: '50rem',
                    bgcolor: '#ffff',
                    overflow: 'auto',
                  }}
                  dense
                  component="div"
                  role="list"
                  className='list'
                >
                  {renderLeftList(left, handleToggle)}
                </List>
              </Card>
            </Grid>
            <Grid item className="grid-item">
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  aria-label="move selected right"
                >
                  <Typography component="span" variant="body2" style={{ fontSize: '16px', fontFamily: "Montserrat", fontWeight: "400" }}>
                    Tenho Interesse
                  </Typography>
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  aria-label="move selected left"
                >
                  <Typography component="span" variant="body2" style={{ fontSize: '16px', fontFamily: "Montserrat", fontWeight: "400" }}>
                    Desistir
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader
                  title="Tenho interesse"
                />
                <Divider />
                <List
                  sx={{
                    minHeight: '50rem',
                    maxHeight: '50rem',
                    bgcolor: '#ffff',
                    overflow: 'auto',
                  }}
                  dense
                  component="div"
                  role="list"
                  className='list'
                >
                  {renderRightList(right, checked, handleToggle)}
                </List>
              </Card>
            </Grid>
          </Grid>
          <div className='list-submit'>
            <NRoundedButton
              title={'enviar para montador'}
              color={'#ff8400'}
              active={true}
              onClick={sendMontador}
            />
          </div>
        </>
      ) : (
        <div className='empty-donation'>
          <span>Aguarde a finalização do Memorial Descritivo</span>
        </div>
      )}
    </ListWrapper>
  );

}


const DoacaoListExpositor = () => {
  const { briefingId } = useParams();
  const { data, isLoading, error } = useQuery(`donations-${briefingId}`, () => donationItems(briefingId));


  if (isLoading) {
    return <Loading role="EXPOSITOR" />;
  }

  return (
    <>
      <FragmentDoacao data={data} />
    </>
  )
}

const ListWrapper = styled.div`
  .list {
    width: 450px;
  }

  .list-submit {
    width: 20%;
    position: relative;
    left: 75%;
    padding: 2rem 0;
  }

  .MuiTypography-body2 {
    font-size: 2rem;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }


  .MuiTypography-h5 {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  }

  .css-9mgopn-MuiDivider-root {
    display: none;
  }


  .css-185gdzj-MuiCardHeader-root  {
    border-bottom: 2px solid #ff8400;
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif !important;
    margin-bottom: 3rem;

    .MuiTypography-h5 {
      font-size: 2rem;
    }
  }

  .type-item {
    padding: 5px 20px;
    border-radius: 2px;
    font-weight: bold;
    margin-right: 1rem;
  }

  .type-item.color1 {
    background-color: #edc69c;
    color: #414141;
  }

  .type-item.color2 {
    background-color: #ed9e9c;
    color: #414141;
  }

  .type-item.color3 {
    background-color: #c69ced;
    color: #414141;
  }

  .type-item.color4 {
    background-color: #9cedc6;
    color: #414141;
  }

  .type-item.pendency-color {
    background-color: #f2f2f2;
    color: #414141;
  }

  .type-item.approved-color {
    background-color: #00ff21;
    color: #fff;
  }

  .type-item.reject-color {
    background-color: #eb3535;
    color: #fff;
  }

  .empty-donation {
    font-size: 2.5rem;
    text-align: center;
    padding-top: 5rem;
    
  }

  @media (max-width: 1220px){
    display: flex;
    flex-direction: column;
    width: 100%;

    .list-submit {
      width: 100%;
      position: relative;
      left: 0;
      right: 0;
      padding: 2rem 0;
      text-align: center;

      > button {
        width: 200px;
        
      }
    }
    

    .grid-container {
      width: 100%;
      padding: 0;
      margin: 0;
      flex-direction: column !important;
      align-items: center;
      justify-content: center;
    }

    .list{
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
    }

    button {
      width: 300px;
      flex-direction: column;
    }

    .css-6s1xft-MuiGrid-root>.MuiGrid-item  {
      padding: 2rem 0;
    }
  }
    
`;

export default DoacaoListExpositor;