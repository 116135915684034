import { useState, useEffect } from "react";
import BriefingTable from "./BriefingTable";
import {
  listBriefingAll,
  listBriefingProjects,
  listBriefingBuild,
  listBriefingBuildAndProjects,
  listBriefingArchived,
  listBriefingDeleted
} from "../../../api/admin";
import Loading from "../../../shared-components/Loading";

async function dispatchFetch(action) {
  if (action === 'projeto') {
    return listBriefingProjects();
  }
  if (action === 'projeto_montagem') {
    return listBriefingBuildAndProjects();
  }
  if (action === 'montagem') {
    return listBriefingBuild();
  }
  if (action === 'apagados') {
    return listBriefingDeleted();
  }
  if (action === 'arquivados') {
    return listBriefingArchived();
  }

  return listBriefingAll();
}

const ListBriefings = () => {

  const [fetchType, setFetchType] = useState('projeto');
  const [briefings, setBriefings] = useState(null)

  useEffect(() => {
    dispatchFetch(fetchType).then(res => setBriefings(res))
  }, [fetchType])

  return (
    <>{briefings ? <BriefingTable
      data={briefings}
      setFetchType={setFetchType}
    /> : (
      <>
        <Loading role="ADMIN" />
      </>
    )}

    </>
  )
}

export {
  ListBriefings,
}