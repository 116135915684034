import { useStepForm } from "./StepFormContext";
import { useUser } from "../../../context/UserContext";

export function useStepActions() {
  const { stateForm, setStateForm } = useStepForm();
  const { userData } = useUser();

  function handleChecked(e, name) {
    setStateForm({
      ...stateForm,
      BriefingManagerUploads: {
        ...stateForm.BriefingManagerUploads,
        [name]: {
          ...stateForm.BriefingManagerUploads[name],
          validated: e,
        },
      },
    });
  }

  const handleGetFile = (file, result) => {
    const type = file.target.files[0].type.split("/")[1];
    const name = file.target.name;

    setStateForm({
      ...stateForm,
      BriefingManagerUploads: {
        ...stateForm.BriefingManagerUploads,
        [name]: {
          ...stateForm[name],
          link: `${result.key}.${type}`,
        },
      },
    });
  };

  function isExhibitor() {
    if (userData?.role?.name === "EXPOSITOR") {
      return true;
    }
    return false;
  }

  function isBuilder() {
    if (userData?.role?.name === "MONTADOR") {
      return true;
    }
    return false;
  }

  function isDraftsman() {
    if (userData?.role?.name === "PROJETISTA") {
      return true;
    }

    return false;
  }

  return {
    handleChecked,
    handleGetFile,
    isExhibitor,
    isBuilder,
    isDraftsman,
    role: userData?.role,
  };
}
