import { Route, Routes, BrowserRouter } from "react-router-dom";
import PrivateRoutes from "../components/privateRoute/PrivateRoutes";
import MontagemOrcamento from "../pages/Dashboard/SolicitOrcamento/Montagem";
import ProjetoEmontagem from "../pages/Dashboard/SolicitOrcamento/ProjetoEmontagem";
import Todos from "../pages/Dashboard/SolicitOrcamento/Todos";
import SolicitCadastroADM from "../pages/Dashboard/SolicitCadastroADM/SolicitCadastroADM";
import SolicitCadPromotor from "../pages/Dashboard/SolicitCadastroADM/SolicitCadPromotor";
import SolicitCadMontador from "../pages/Dashboard/SolicitCadastroADM/SolicitCadMontador";
import SolicitCadProjetista from "../pages/Dashboard/SolicitCadastroADM/SolicitCadProjetista";
import MainDash from "../pages/Dashboard/MainDash/MainDash";
import EditBriefing from "../pages/Dashboard/SolicitOrcamento/EditBriefing";
import AdminLayout from "../layouts/AdminLayout";
import Chat from "../pages/Chat/Chat"
import UserSolicitInfo from "../pages/Dashboard/SolicitCadastroADM/UserSolicitInfo";
import BriefingManager from "../components/BriefingManager/BriefingManager";
import Gestor from "../components/DashboardExpositorComponents/fluxProjetoEmontagem/Gestor";
import { CriarPagamento } from "../components/DashboardADM/CriarPagamento";
import { Pagamentos } from "../components/DashboardADM/Pagamentos";
import { ListBriefings } from "../pages/Dashboard/SolicitOrcamento/ListBriefings";
import EncontradoErro from "../pages/EncontradoErro/EncontradoErro";
import MemorialDesc from "../components/DashboardProjetistaComponents/SolicitProjeto/MemorialDescritivo/MemorialDesc";
import EditarEvento from "../components/DashboardPromotorComponents/editarEvento/EditarEvento";
import CriarEvento from "../components/DashboardPromotorComponents/criarEvento/CriarEvento";
import GerenciarEventos from "../components/DashboardPromotorComponents/gerenciarEventos/GerenciarEventos";
import NovoBriefing from "../pages/Briefing/NovoBriefing";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route element={<PrivateRoutes role="ADMIN" />}>,
    <Route path="/admin" element={<MainDash />} exact />,

    <Route
      path="/solicitacao-cadastro-admin"
      element={<SolicitCadastroADM />}
      exact
      strict key="solicitacao-cadastro-admin"
    />,
    <Route
      path="/admin/novo-briefing"
      element={
        <AdminLayout>
          <NovoBriefing />
        </AdminLayout>
      }
      exact
      strict key="solicitacao-cadastro-admin"
    />,
    <Route
      path="/solicitacao-cadastro-promotor"
      element={<SolicitCadPromotor />}
      exact
      strict key="solicitacao-cadastro-promotor"
    />,
    <Route
      path="/solicitacao-cadastro-montador"
      element={<SolicitCadMontador />}
      exact
      strict key="solicitacao-cadastro-montador"
    />,
    <Route
      path="/solicitacao-cadastro-projetista"
      element={<SolicitCadProjetista />}
      exact
      strict key="solicitacao-cadastro-projetista"
    />,

    <Route
      path="/pagamentos"
      element={
        <AdminLayout>
          <Pagamentos />
        </AdminLayout>
      }
      exact
      strict key="pagamentos"
    />,
    <Route
      path="/solicitacao-orcamento-admin"
      element={
        <AdminLayout>
          <ListBriefings />
        </AdminLayout>
      }
      exact
      strict key="solicitacao-orcamento-admin"
    />,
    <Route
      path="/solicitacao-orcamento-montagem"
      element={<MontagemOrcamento />}
      exact
      strict key="solicitacao-orcamento-montagem"
    />,
    <Route
      path="/solicitacao-orcamento-projeto&montagem"
      element={<ProjetoEmontagem />}
      exact
      strict key="solicitacao-orcamento-projeto&montagem"
    />,
    <Route
      path="/solicitacao-orcamento-todos"
      element={<Todos />}
      exact
      strict key="solicitacao-orcamento-todos"
    />,
    <Route
      path="/editar-briefing/:briefingId"
      element={
        <EditBriefing />}
      exact
      strict key="editar-briefing"
    />,
    <Route
      path="/admin/memorial-descritivo/:briefingId"
      element={
        <AdminLayout>
          <MemorialDesc />
        </AdminLayout>
      }
      exact
      strict key="memorial-descritivo-admin"
    />,
    <Route
      path="/criar-pagamento/:usersId"
      element={
        <AdminLayout>
          <CriarPagamento />
        </AdminLayout>
      }
      exact
      strict key="criar-pagamento"
    />,
    <Route
      path="/admin/gestor/:briefingId"
      element={
        <AdminLayout>
          < Gestor />
        </AdminLayout>
      }
      exact strict key="gestor" />,
    <Route
      path="/chat"
      element={
        <AdminLayout>
          <Chat />
        </AdminLayout>
      }
      exact
      strict key="chat"
    />,
    <Route
      path="/cadastro-info/:id"
      element={
        <AdminLayout><UserSolicitInfo /></AdminLayout>
      }
      exact
      strict key="cadastro-info"
    />,
    <Route path="/admin/projeto&montagem" element={<AdminLayout>
      <BriefingManager />
    </AdminLayout>} exact strict key="projeto&montagem" />,

    <Route path="/criar-evento" element={<AdminLayout>
      <CriarEvento role={"ADMIN"} />
    </AdminLayout>} exact strict key="criar-evento" />,

    <Route path="/gerenciar-eventos" element={<AdminLayout>
      <GerenciarEventos role={"ADMIN"} />
    </AdminLayout>} exact strict key="gerenciar-eventos" />,

    <Route path="/editar-evento/:id" element={<AdminLayout>
      <EditarEvento role={"ADMIN"} />
    </AdminLayout>} exact strict key="editar-evento" />,

  </Route>,

]

