import React from "react";
import SideBarADM from "../SideBarADM";
import { Link } from "react-router-dom";
import "../sidebarADM.css";
import { getPromoterUsers } from "../../../api/admin.js";
import { useQuery } from "react-query";
import { NRoundedButton, DashInput } from "../../../shared-components";
import useFilter from "./useFilter";
import { CardProfile } from "./CardProfile";

const SolicitCadPromotor = () => {
  const { data: promoters, isLoading } = useQuery(
    "profiles-builds",
    getPromoterUsers
  );

  const {
    handleFilter,
    searchTerm,
    setSearchTerm,
    filteredProfiles,
    filter,
    setFilter,
  } = useFilter(promoters);

  if (isLoading) {
    return <></>;
  }

  const handleClearFilter = () => {
    setFilter("all");
  };

  return (
    <div className="cont-geral">
      <SideBarADM />
      <div className="btns-solict2">
        <Link to="/solicitacao-cadastro-admin">
          <button className="expo-btn1">expositor</button>
        </Link>
        <Link to="/solicitacao-cadastro-promotor">
          <button className="promotor-btn">promotor</button>
        </Link>
        <Link to="/solicitacao-cadastro-montador">
          <button className="montador-btn1">montador</button>
        </Link>
        <Link to="/solicitacao-cadastro-projetista">
          <button className="proj-btn1">projetistas</button>
        </Link>
        <div className="search-input-admin">
          <DashInput
            value={searchTerm}
            placeholder={"Busque por um termo..."}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <NRoundedButton
            title={"aprovado"}
            active={filter === "APROVADO"}
            color={"#764FAA"}
            onClick={() => handleFilter("APROVADO")}
          />
          <NRoundedButton
            title={"pendente"}
            active={filter === "PENDENTE"}
            color={"#764FAA"}
            onClick={() => handleFilter("PENDENTE")}
          />
          <NRoundedButton
            title={"reprovado"}
            active={filter === "REPROVADO"}
            color={"#764FAA"}
            onClick={() => handleFilter("REPROVADO")}
          />
          <NRoundedButton
            title={"mostrar todos"}
            color={"#764FAA"}
            onClick={handleClearFilter}
            active={filter === "all"}
          />
        </div>
      </div>
      <div className="multiple-options">
        {filteredProfiles.map((promoter) => (
          <CardProfile
            key={promoter.id}
            profile={promoter}
            handleAccept={promoter}
          />
        ))}
      </div>
    </div>
  );
};

export default SolicitCadPromotor;
