import { useQuery } from "react-query";
import {
  listPayments
} from "../../api/admin";
import {
  NRoundedButton
} from "../../shared-components";
import {
  CardRow,
  CardSection,
} from "./StyledComponents";
import { formatDateLocale } from "../../helpers/formatDate";
import { formatCurrency } from "../../helpers/formatNumber";
import exhibitorAvatar from "../../images/Frame-122.png";
import adminAvatar from "../../images/Frame-125.png";
import builderAvatar from "../../images/Frame-124.png";

function renderUser(data) {
  const user = data?.Builders[0] || data?.Exhibitors[0] || data?.Designers[0] || data?.Promoter[0]
  let avatar = exhibitorAvatar;

  if (data?.Users?.Role.name === "montador") {
    avatar = builderAvatar;
  }

  if (data?.Users?.Role.name === "admin-promotor") {
    avatar = adminAvatar;
  }

  return {
    ...user,
    avatar,
  }
}


const Pagamentos = () => {

  const { data, isLoanding } = useQuery('list-paymenys', listPayments);

  if (isLoanding) {
    return (
      <>carregando...</>
    )
  }

  function handlePaymentLink(link) {
    window.open(link, '__blank')
  }


  return (
    <>
      {data?.map(payment => (
        <CardRow key={payment.id}>
          <CardSection>
            <img src={renderUser(payment?.Users).avatar} alt="avatar" />
          </CardSection>
          <CardSection>
            <span><strong>Pagador:</strong>: {renderUser(payment?.Users).name}</span>
            <span><strong>Criado em:</strong>: {formatDateLocale(payment.createdAt)}</span>

          </CardSection>
          <CardSection>
            <span><strong>Vencimento:</strong>: {formatDateLocale(payment.dueDate)}</span>
            <span><strong>Método:</strong>: {payment.billingType}</span>
            <span><strong>Valor:</strong>: {formatCurrency(payment.value)}</span>
          </CardSection>

          <CardSection>
            <NRoundedButton
              title={"Ver boleto"}
              active={true}
              color={"#764FAA"}
              onClick={() => handlePaymentLink(payment.bankSlipUrl)}
            />
          </CardSection>
        </CardRow>
      ))}
    </>
  )
}

export {
  Pagamentos,
}