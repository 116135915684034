import React, { useState } from "react";
import styled from "@emotion/styled";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import logo from "../../images/logo-colorido.png";
import { Link } from "react-router-dom";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import useMatchMedia from "use-match-media-hook";

const size = ["(max-width: 768px)", "(min-width: 769px)"];

const SideBarPromotor = ({ isOpen, toggle }) => {
  const [mobile, desktop] = useMatchMedia(size);

  return (
    <Wrapper isOpen={isOpen}>
      <Title isOpen={isOpen}>
        <img
          src={logo}
          alt="nossostand-logo"
          style={{ display: isOpen ? "block" : "none" }}
        />
        <ArrowBackIosOutlinedIcon onClick={toggle} />
      </Title>
      <Content isOpen={isOpen}>
        <span>- DASH</span>
        <Link to="/dashboard-promotor">
          <Item>
            <div className="icon">
              <HomeOutlinedIcon style={{ width: "30px", height: "40px" }} />
            </div>
            <div
              className="link_text"
              style={{
                display: isOpen ? "block" : "none",
              }}
            >
              ADM Promotor
            </div>
          </Item>
        </Link>
        <span>- FLUXOS</span>
        <Link to="/promotor-criar-evento">
          <Item>
            <div className="icon">
              <CalendarMonthOutlinedIcon
                style={{ width: "30px", height: "40px" }}
              />
            </div>
            <div
              className="link_text"
              style={{
                display: isOpen ? "block" : "none",
              }}
            >
              Cadastrar Evento
            </div>
          </Item>
        </Link>
        <Link to="/promotor-gerenciar-eventos">
          <Item>
            <div className="icon">
              <ManageHistoryOutlinedIcon
                style={{ width: "30px", height: "40px" }}
              />
            </div>
            <div
              className="link_text"
              style={{
                display: isOpen ? "block" : "none",
              }}
            >
              Gerenciar Eventos
            </div>
          </Item>
        </Link>
        <Link to="/">
          <Item>
            <div className="icon">
              <OtherHousesOutlinedIcon
                style={{ width: "30px", height: "40px" }}
              />
            </div>
            <div
              className="link_text"
              style={{
                display: isOpen ? "block" : "none",
              }}
            >
              Solicitar Stand
            </div>
          </Item>
        </Link>
      </Content>
    </Wrapper>
  );
};

const Content = styled.div`
  span {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 10px 20px;
    display: ${({ isOpen }) => (isOpen ? "init" : "none")};
  }
`;

const Item = styled.div`
  padding: 5px 27px;
  gap: 15px;
  align-items: center;
  transition: all 0.5s;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 20px 0;

  &:hover {
    background: #e7054c10;
    color: #e7054c;
    transition: all 0.5s;
    border-radius: 5px;
    margin-left: 15px;
    text-decoration: none;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  width: ${({ isOpen }) => (isOpen ? "300px" : "100px")};
  transition: all 0.3s;
  position: fixed;
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 20%),
    0 12px 24px -4px rgb(145 158 171 / 12%);
  z-index: 10;

  @media only screen and (max-width: 770px) {
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    width: ${({ isOpen }) => (isOpen ? "300px" : "0px")};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  position: relative;

  img {
    width: 180px;
    height: auto;
  }

  & svg {
    cursor: pointer;
    background-color: #e7054c;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: white;
    position: absolute;
    right: -10px;
    transition: all 1s;
    transform: rotate(${({ isOpen }) => (isOpen ? "0deg" : "180deg")});
  }
`;

export default SideBarPromotor;
