import styled from "@emotion/styled";
import { useUser } from "../context/UserContext";
import { COLORS } from "../helpers/types";

export function NRoundedButton({ title, active, color, ...otherProps }) {
  const { userData } = useUser();

  const roleData = userData?.role?.name;
  const activeColor = roleData ? COLORS[roleData] : color;

  return (
    <Wrapper
      {...otherProps}
      active={active}
      color={activeColor}
    >
      {title}
    </Wrapper>
  )
}


const Wrapper = styled.button`
  min-width: 130px;
  min-height: 40px;
  max-height: 46px;
  color: ${({ color, active }) => active ? "white" : "#aaaaaa"};
  padding: 0rem 2rem;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #f1f1f1;
  background-color: ${({ color, active }) => active ? color : "#fff"};
  font-family: "Montserrat", sans-serif;

  &:hover {
    background-color: ${({ color }) => `${color}CC`};
    color: white;
  }

  &:active {
     transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
  }
`