import React, { useState } from "react";
import styled from "@emotion/styled";
import { DashInput } from "./DashInput";
import { NRoundedButton } from "./NRoundedButton";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from '@mui/icons-material/Delete';


export function ToDoTasks({
  tasks,
  handleAddTask,
  handleRemoveTask,
  onRemove,
  onToggle,
  color,
  title,
  subTitle,
  disableTitle = false,
  disableRemove = false,
  noTaskText = '',
}) {
  const [newTasks, setNewTasks] = useState("");

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleClick(event);
    }
  }

  function handleClick(e) {
    e.preventDefault();
    if (!newTasks) return;
    handleAddTask(newTasks);
    setNewTasks("");
  }

  function handleRemoveClick(e, id) {
    e.preventDefault();
    handleRemoveTask(id);
  }

  return (
    <Wrapper>
      <div className="content-wrapper">
        <h3>{title}</h3>
        <h6 style={{ fontSize: '1.4rem' }}>{subTitle}</h6>
        {!disableTitle ? (
          <>
            <div style={{ display: "flex", gap: "1rem" }} className="add-task">
              <DashInput
                width="100%"
                value={newTasks}
                onChange={(value) => setNewTasks(value.target.value)}
                onKeyDown={handleKeyDown}
              />
              <NRoundedButton
                key="button-todo-tasks"
                title={"Adicionar"}
                color={color}
                active={true}
                onClick={handleClick}
              />
            </div>
          </>
        ) : ''}
      </div>

      <div
        className="content-wrapper"
        style={{ overflow: "auto", height: "200px" }}
      >
        {tasks?.length !== 0 ? (
          <ul>
            {tasks.map((task, index) => (
              <li key={task?.id + index}>
                <p>
                  <span>
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                      checked={task?.done}
                      onChange={() => onToggle(task?.id)} />
                    {task?.description}
                  </span>
                  {!disableRemove ? (
                    <DeleteIcon
                      title={"X"}
                      color={color}
                      active={true}
                      onClick={(e) => onRemove(task?.id)}
                      style={{ color: "#ff8400" }}
                    />
                  ) : ''}
                </p>
              </li>
            ))}
          </ul>
        ) : (<span style={{
          fontSize: '2rem',
          fontWeight: 700,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}>{noTaskText}</span>)}

      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-right: 20px;
  margin-bottom: 25px;

  svg {
    font-size: 2.2rem;
  }

  .content-wrapper {
    width: 100%;
    background-color: white;
    margin: 1rem 0;
    border-radius: 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    padding: 1rem 1.5rem;
    cursor: pointer;

    > h3 {
      margin: 20px 0;
    }

    p {
      font-size: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      background-color: #F3F3F3;
      border-radius: 5px;
      font-weight: bold;
      margin: .5rem 0;
    }
  }

  @media (max-width: 900px) {
    width: 100%;

    .add-task {
      flex-direction: column;
    }
  }
`;
