import {
  Wrapper,
} from "./StyledComponents";
import FragmentStructs from "./FragmentStructus";
import FragmentBudget from "./FragmentBudget";
import FragmentElements from "./FragmentElements";
import FragmentMultimidia from "./FragmentMuiltimidia";
import FragmentPromoter from "./FragmentPromoter";
import FragmentLastStandsBlueprint from "./FragmentLastStandsBlueprint";
import FragmentReferences from "./FragmentReferences";
import { FragmentUploadProject } from "./FragmentUploadProject";
import { FragmentUploadArte } from "./FragmentUploadArte";

export default function EditBriefing({
  briefingData,
  stateForm,
  handleChange,
  setStateForm,
}) {

  return (
    <Wrapper>
      <FragmentUploadProject
        key="form-projects"
        briefingData={briefingData}
        handleChange={handleChange}
        stateForm={stateForm}
        setStateForm={setStateForm}
      />
      <FragmentReferences
        key="form-references"
        briefingData={briefingData}
        stateForm={stateForm}
        handleChange={handleChange}
        setStateForm={setStateForm}
      />
      <FragmentUploadArte
        key="form-projects-arte"
        briefingData={briefingData}
        handleChange={handleChange}
        stateForm={stateForm}
        setStateForm={setStateForm}
      />
      <FragmentBudget
        key="form-budget"
        briefingData={briefingData}
        stateForm={stateForm}
        handleChange={handleChange}
      />
      <FragmentStructs
        key="form-structs"
        structs={stateForm.BriefingStructs}
        setStateForm={setStateForm}
        stateForm={stateForm}
        handleChange={handleChange}
      />
      <FragmentElements
        key="form-elements"
        elements={stateForm.BriefingElements}
        setStateForm={setStateForm}
        stateForm={stateForm}
        handleChange={handleChange}
      />
      <FragmentMultimidia
        key="form-multimidia"
        multimedia={stateForm.BriefingMultimedia}
        setStateForm={setStateForm}
        stateForm={stateForm}
        handleChange={handleChange}
      />
      <FragmentPromoter
        key="form-promoter"
        setStateForm={setStateForm}
        stateForm={stateForm}
        handleChange={handleChange}
      />
      <FragmentLastStandsBlueprint
        key="form-laststands"
        briefingData={briefingData}
        setStateForm={setStateForm}
        stateForm={stateForm}
        handleChange={handleChange}
      />
    </Wrapper>
  )
}
