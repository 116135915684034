import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getBriefing } from "../../../api/briefing";
import Loading from "../../../shared-components/Loading";
import { Error } from "../../../shared-components";
import { useUser } from "../../../context/UserContext";
// import { HeadBriefing } from "./components/HeadBriefing";
// import CarouselImages from "../../../shared-components/CarouselDefault";
import ProposalToggle from "../../../shared-components/ProposalToggle";

function Content({ briefing, onRemoveBriefingRequest }) {

  const [projectViewZoom, setProjectViewZoom] = useState();
  const [openModalProject, setOpenModalProject] = useState(false);
  const [openWarningProposal, setOpenWarningProposal] = useState(false);
  const { userData } = useUser();



  //TODO: REFATOR NO FUTURO - MOTIVO HEADBRIEFING
  const fakeBriefingRequest = {
    briefingRequest: briefing,
  }
  console.log(fakeBriefingRequest)

  function handleModalProject(projectLink) {
    setProjectViewZoom(projectLink);
    setOpenModalProject(true);
  };

  function submitBudget(index, setOpenAlertProposal) {
    if (
      (!briefing?.proposals?.[index]?.finalPriceMax ||
        !briefing?.proposals?.[index]?.finalPriceMin) &&
      userData.role.name !== "ADMIN"
    ) {
      setOpenWarningProposal(true);
      return;
    }
    setOpenAlertProposal(true);
  };

  return (

    <>
      
      <>
        {briefing.BriefingRequest.map((briefingRequest, index) => <ProposalToggle
          index={index}
          key={index}
          dateInput={briefingRequest.deliveryAt}
          priceMin={briefingRequest.finalPriceMin}
          priceMax={briefingRequest.finalPriceMax}
          priceFinal={briefingRequest.finalPrice}
          observation={briefingRequest.observation}
          submitBudget={submitBudget}
          id={briefingRequest.id}
          briefingId={briefing.briefingId}
          removeBriefingAceept={onRemoveBriefingRequest}
          builder={briefingRequest.builderId}
          createdAt={briefingRequest.createdAt}
          updatedAt={briefingRequest.updatedAt}
          deadline={briefing.deadline}
          projectName={briefing.eventName}
        />)}

      </>
    </>
  )

}

export function BriefingRequestWestand() {

  const { briefingId } = useParams();
  const { data, isLoading, error } = useQuery(['request-westand', briefingId], () => getBriefing(briefingId));


  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Error />
  }



  return (
    <Content briefing={data} />
  )

}