import { DashTextArea } from "../../../shared-components/DashTextArea";

export default function FragmentDescription({ handleChange, stateForm }) {
  return (
    <>
      <span>Descrição do evento</span>
      <p>
        Conte todos os detalhes do seu evento, como a programação e os
        diferenciais da sua produção!
      </p>
      <DashTextArea
        name="description"
        value={stateForm?.description}
        onChange={handleChange}
        placeholder="Adicione aqui a descrição do seu evento"
      />
    </>
  );
}
