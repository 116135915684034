import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import { Navigation } from "../../components/home/Navigation";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./LoginStyle.css";
import { signIn, saveToken } from "../../api/login";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/home/Footer";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../../context/UserContext";
import logoColorido from "../../images/logo-colorido.png";

const Validation = yup
  .object({
    email: yup
      .string("Preencha o Email corretamente")
      .required("campo obrigatorio"),
    senha: yup.string().required("campo obrigatorio"),
  })
  .required();

function redirectPage(name) {
  if (name === "expositor") {
    return "/expositor/lista";
  }
  if (name === "montador") {
    return "/montador/lista";
  }
  if (name === "promotor") {
    return "/dashboard-promotor";
  }
  if (name === "promotor-admin") {
    return "/dashboard-promotor";
  }
  if (name === "projetista") {
    return "/projetista/lista";
  }
  if (name === "admin_assistent") {
    return "/admin";
  }
  if (name === "admin") {
    return "/admin";
  }
}

const Login = () => {
  const [hasError, setHasError] = useState(false);

  const [disable, setDisable] = useState(true);
  const { userData } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.role) {
      const page = redirectPage(userData?.role?.name.toLowerCase());
      navigate(page);
    }
  }, []);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Validation),
  });

  const onSubmit = (data) => {
    signIn({ email: data.email, password: data.senha })
      .then((res) => {
        saveToken(res.token);
        const page = redirectPage(res.role.name);
        navigate(page);
      })
      .catch((error) => {
        console.log(error);
        setHasError(error.message);
      });
  };

  return (
    <div id="login">
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img src={logoColorido} alt="" width="230px" />
      </div>
      <div className="main-login">
        <div className="form-login">
          <h2>Entrar</h2>
          <Form
            className="form__input--login"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Control
              name="email"
              placeholder="E-mail"
              {...register("email")}
            />
            <p>{errors.email?.message}</p>

            <Form.Control
              name="senha"
              placeholder="Senha"
              type="password"
              {...register("senha")}
            />
            <p>{errors.senha?.message}</p>
            {hasError ? <p>Login inválido</p> : ""}
            <div className="button-login">
              <Button className="btn-cad" type="submit">
                Entrar
              </Button>
            </div>
          </Form>
          <div className="cadastre">
            <h3 className="cadastre">
              <a
                style={{ color: "black", fontWeight: "500" }}
                href="/forget-password"
              >
                Esqueci a senha
              </a>
            </h3>
          </div>
        </div>
        <div className="right-side">
          <h2>
            É novo aqui? <br /> Cadastre-se
          </h2>
          <div>
            <h3>Faça seu cadastro?&nbsp;</h3>
            <div className="btn-cadastro">
              <Button
                href="https://westand.com.br/register"
                className="btn-cad"
                type="submit"
              >
                AQUI
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
