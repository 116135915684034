import {
  getUserData,
  privateFetch
} from "./user";

const API = process.env.REACT_APP_API;

export async function getFluxBriefingManager() {

  const token = await privateFetch();

  const res = await fetch(`${API}/briefing-manager`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();

}