import React from "react";
import styled from "@emotion/styled";
import ExpoLogo from "../../images/Frame-124.png";
import GreenCircle from "../../images/green-circle.png";
import "./style/styleSolicitPlataforma.css";

export default function Header({ title }) {

  return (
    <Wrapper>
      <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
      <span style={{ color: "black" }}>
        {title}
      </span>
      <img
        className="orange-circle"
        src={GreenCircle}
        alt=""
        width="220px"
      />
    </Wrapper>

  )

}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 22rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 0.375rem;
  
  & >span {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
  }

  strong {
    color: #22DA29;
    font-weight: 700;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;

    & >span {
    font-size: 3rem;
    }
  }

`