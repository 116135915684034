import { useContext, createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UserContext = createContext();

function UserProvider({ children }) {

  const [userData, setUserData] = useState({})

  function saveUserData(data) {
    return setUserData({
      ...data,
    })
  }

  return (
    <UserContext.Provider value={{ userData, saveUserData }}>
      {children}
    </UserContext.Provider>
  )
}

const isMissingPersonInformation = userData => (
  !userData.document ||
  !userData.firstName ||
  !userData.lastName
);

const isMissingEnterpriseInformation = userData => (
  !userData.cnpj ||
  !userData.razaoSocial ||
  // !userData.street ||
  // !userData.number ||
  // !userData.neighborhood ||
  !userData.cep ||
  !userData.city ||
  !userData.state
);

const isMissingTermsApproval = userData => !userData?.User?.Approval?.terms;

const isMissingSomething = (userData) => (
  isMissingEnterpriseInformation(userData) ||
  isMissingPersonInformation(userData) ||
  isMissingTermsApproval(userData)
);

const isNotLocationForNavigate = (userData, location) => (
  location.pathname === `/${userData.role.name.toLowerCase()}/termos-de-uso` ||
  location.pathname === `/${userData.role.name.toLowerCase()}/dados-empresa` ||
  location.pathname === `/informacoes-pessoais` ||
  location.pathname === `/informacoes-pessoais-montador` ||
  location.pathname === `/info-pessoais-projetista`
)

function useUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("Esse contexto não existe");
  }

  if (context.userData.id) {
    if (context.userData.role.name === "ADMIN" || context.userData.role.name === "ADMIN_ASSISTENT" || context.userData.role.name === "PROMOTOR") {
      return context;
    }

    if (context.userData.role.name !== "PROJETISTA") {
      if (isMissingSomething(context.userData) && !isNotLocationForNavigate(context.userData, location)) {
        navigate(`/${context.userData.role.name.toLowerCase()}/termos-de-uso`);
      }
    }
    if (isMissingTermsApproval(context.userData) && !isNotLocationForNavigate(context.userData, location)) {
      navigate(`/${context.userData.role.name.toLowerCase()}/termos-de-uso`)
    }
  }
  return context;
}

export {
  UserProvider,
  useUser,
}