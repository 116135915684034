import styled from "@emotion/styled";
import { DashSelect } from "../../../shared-components/DashSelect";
import { MenuItem } from "@mui/material";

export default function FragmentType({ handleChange, stateForm }) {
  return (
    <>
      <p>Classifique seu evento</p>
      <Row>
        <DashSelect
          required
          title="Assunto"
          placeholder="Assunto"
          name="matter"
          onChange={handleChange}
          value={stateForm.matter ? stateForm.matter : ""}
        >
          <MenuItem value={"Acadêmico e científico"}>
            Acadêmico e científico
          </MenuItem>
          <MenuItem value={"Artesanato"}>Artesanato</MenuItem>
          <MenuItem value={"Casa e estilo de vida"}>
            Casa e estilo de vida
          </MenuItem>
          <MenuItem value={"Desenvolvimento pessoal"}>
            Desenvolvimento pessoal
          </MenuItem>
          <MenuItem value={"Desing, métricas e produtos digitais"}>
            Desing, métricas e produtos digitais
          </MenuItem>
        </DashSelect>

        <DashSelect
          required
          title="Categoria (opcional)"
          placeholder="Categoria (opcional)"
          fullWidth
          name="category"
          onChange={handleChange}
          value={stateForm.category ? stateForm.category : ""}

        >
          <MenuItem value={"Corrida"}>Corrida</MenuItem>
          <MenuItem value={"Competição ou torneio"}>
            Competição ou torneio
          </MenuItem>
          <MenuItem value={"Feira, exposições"}>Feira, exposições</MenuItem>
          <MenuItem value={"Outro"}>Outro</MenuItem>
        </DashSelect>
      </Row>
    </>
  );
}

const Row = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 30px;
  margin: 2rem 0;
  > p {
    flex: 2;
  }
`;
