import React from "react";
import styled from "@emotion/styled";
import PromoLogo from "../../images/Frame-123.png";
import AdmLogo from "../../images/Frame-125.png";
import PinkCircle from "../../images/pink-circle.png";
import PurpleCircle from "../../images/purple-circle.png";
import { COLORS } from "../../helpers/types";

function FragmentTitle({ title }) {
  if (title) {
    return title
  }
  return (
    <>
      Este é o dashboard <strong>Promotor</strong>
    </>
  )
}

export default function Header({ role, title }) {

  return (
    <Wrapper role={role}>
      {role === "PROMOTOR" ? (
        <>
          <img className="expo-logo" src={PromoLogo} alt="" width="120px" />
          <span style={{ color: "black" }}>
            <FragmentTitle title={title} />
          </span>
          <img
            className="pink-circle"
            src={PinkCircle}
            alt=""
            width="220px"
          />
        </>
      ) : role === "ADMIN" ? (
        <>
          <img className="expo-logo" src={AdmLogo} alt="" width="120px" />
          <span style={{ color: "black" }}>
            <FragmentTitle title={title} />
          </span>
          <img
            className="purple-circle"
            src={PurpleCircle}
            alt=""
            width="220px"
          />
        </>
      ) : (
        <div>
          <span>{title}</span>
        </div>
      )}
    </Wrapper>
  );


}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 22rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 0.375rem;
  padding: 0 20px;

  & >span {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;

    >strong {
      color:  ${props => COLORS[props.role]};
    }
  }


  @media only screen and (max-width: 900px) {
    flex-direction: column;
    & >span {
    font-size: 3rem;
    }
  }


`