import React, { useState, useImperativeHandle, forwardRef } from 'react';
import styled from '@emotion/styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { uploadFileS3 } from "../libs/s3Client";

export const bucketParams = {
  Bucket: "bucket-westand-prod",
};

export const UploadAvatar = forwardRef((props, ref) => {

  const {
    getFile,
    deleteFile,
    disabled,
    defaultValue,
    imageFile,
    ...otherProps } = props;

  const [file, setFile] = useState();
  const [status, setStatus] = useState(null)

  if (defaultValue) {
    setStatus("Arquivo enviado!")
  }


  function handleInput(e) {
    const type = e.target.files[0].type.split("/")[1]
    setFile(`${e.target.files[0].name}.${type}`);
    setStatus('Enviando...')
    uploadFileS3(e)
      .then(res => {
        setStatus(null);
        getFile(e, res);
      })
      .catch(error => {
        console.error(error)
        setStatus('Tente novamente...')
      })
  }

  function removeFile(e) {
    setStatus(null)
    setFile(null);
    deleteFile(e);
  }


  return (
    <Wrapper disabled={disabled}>
      <label>
        <AddCircleOutlineIcon />
        <input
          type="file"
          onChange={handleInput}
          {...otherProps}
          disabled={disabled}
          ref={ref}
        />
        {status}
        {imageFile ? <img src={imageFile} /> : ''}
      </label>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  border-radius: 50%;
  opacity: ${props => props.disabled ? '0.6' : '1'};
  width: 100px;
  height: 100px;

 > label {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color:#E3E3E355;
  font-weight: 700;
  border-radius: 50%;

    img {
      position: absolute;
      z-index: 1;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    img:hover {
      opacity: 0.8;
      z-index: 0;
    }

  cursor: pointer;

  svg {  
    color: black;
    z-index: 1;
    opacity: 1;
  }


  p {
    width: 80%;
    text-align: center;
    font-weight: 600;
    z-index: 2;
    font-weight: 700;
  }
 }
 
 >div:first-of-type {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  font-size: 1.8rem;
  padding: 0 10px;
  width: 80%;
  height: 30px;
 }
 svg {
  color: white;
 }


`
