import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ExpoLogo from "../../../images/Frame-124.png";
import GreenCircle from "../../../images/green-circle.png";
import Company from "../../../images/logo_wvegan.png";
import SideBarMontador from "../../sidebar/SideBarMontador";
import "../style/styleSolicitPlataforma.css";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { listOpenBriefings } from "../../../api/briefing";

function FragmentOpenBriefingRequest({ data, isLoading, error }) {
  if (isLoading) {
    return <>carregando...</>;
  }

  if (error) {
    return <>Algo deu errado!</>;
  }

  if (data) {
    return (
      <>
        {Object.values(data).map((value) => (
          <div className="solicits-open" key={value.id}>
            <h2 className="company-name">{value.eventName}</h2>
            <img src={Company} alt="" />
            <button className="detailes-solicit">
              Visualizar solicitações
            </button>
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Rating name="simple-controlled" value={4.5} readOnly />
              <Typography component="legend">5</Typography>
            </Box>
          </div>
        ))}
      </>
    );
  }
}

const DetalheSolicitEmAbertoMontador = () => {
  const { data, isLoading, error } = useQuery(
    "briefing-request",
    listOpenBriefings
  );
  const params = useParams();

  return (
    <div className="cont-geral">
      <SideBarMontador />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>Solicitações em aberto </span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={GreenCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="btns-solict">
        <button className="btn-open4">solicitação em aberto</button>
        <Link to="/solicit-negociacao-sem-proposta-montador">
          <button className="btn-open-mont">em negociação sem proposta</button>
        </Link>
        <Link to="/montador/lista">
          <button className="btn-open-mont">em negociação com proposta</button>
        </Link>
        <Link to="/solicit-aprovada-montador">
          <button className="btn-open-mont">aprovadas</button>
        </Link>
      </div>
      <div className="cont-solicit-aberto">
        <FragmentOpenBriefingRequest
          data={data}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </div>
  );
};

export default DetalheSolicitEmAbertoMontador;
