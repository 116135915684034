import { useEffect } from "react";
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom";
import FragmentBriefingBudget from './components/FragmentBriefingBudget'
import FragmentBriefingOpen from './components/FragmentOpenBriefings';
import {
  listRequestBriefings
} from "../../../api/briefing"
import Loading from "../../../shared-components/Loading";
import { Error } from "../../../shared-components";


function BriefingAberto({ briefing }) {

  return (
    <FragmentBriefingOpen
      briefingData={briefing}
    />
  )
}

function BriefingNegociacao({ briefing }) {
  const { data, isLoading, error } = useQuery(['list', 'briefing'], () => listRequestBriefings());

  if (isLoading) {
    return (
      <Loading />
    )
  }

  const briefingRequest = data.filter(v => v.Briefing.id === briefing.id);
  if (briefingRequest.length > 0) {
    return (
      <FragmentBriefingBudget
        briefingData={briefing}
        briefingRequest={briefingRequest[0]}
      />
    )
  }

  return (
    <Error />
  )
}

function BriefingAceito({ briefing }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/montador/gestor/${briefing.id}`)
  }, [])

}


export function BriefingMontador({ data, userData }) {

  if (data.status === 'ABERTO') {
    return (
      <BriefingAberto briefing={data} />
    )
  }
  if (data.status === 'EM_NEGOCIAÇÃO') {
    return (
      <BriefingNegociacao briefing={data} />
    )
  }

  //Deve levar para o GESTOR
  if (data.status === 'ACEITO') {
    return (
      <BriefingAceito briefing={data} />
    )
  }



}