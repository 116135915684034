import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import {
  Wrapper,
  Row,
  Column,
  Space,
} from "./Styles";
import { MenuItem } from "@mui/material";
import { listEvents } from "../../../../api/exhibitor";
import { simulateCheckout } from "../../../../api/payment";
import { useStepForm } from "../StepFormContext";
import { DashSelect, NRoundedButton } from "../../../../shared-components";
import { formatDateForInput, formatCurrencyDecimal } from "../../../../helpers";
import { createPayment } from "../../../../api/payment";
import { useNotification } from "../../../../context/NotificationContext";
import CardPaymentFinal from "../components/CardPaymentFinal";
import CardFatura from '../components/CardFatura';


function FragmentPayment({
  events,
  setSelectedInstallment,
  paymentInstallments,
  setEventId,
  handleCreatePayment
}) {

  return (
    <>
      <Title>Condição de Pagamento</Title>
      <Row>
        <DashSelect
          title={"Parcelamento"}
          fullWidth
          onChange={(e) => setSelectedInstallment(e.target.value)}
        >
          <MenuItem value="1">Pagamento a vista</MenuItem>
          {paymentInstallments && paymentInstallments.map((value, index) =>
            <MenuItem
              key={`installment-${index}`}
              value={value.installment}>
              {value.label}
            </MenuItem>
          )}

        </DashSelect>
        <DashSelect
          title={"Confirme o evento"}
          fullWidth
          onChange={(e) => setEventId(e.target.value)}
        >
          {events && events.map(e => (
            <MenuItem
              key={`event-${e.id}`}
              value={e.id}>
              {e.name} - {formatDateForInput(e.startDate)}
            </MenuItem>
          ))}
        </DashSelect>
      </Row>
      <NRoundedButton
        title={"Gerar Boleto"}
        active={true}
        color={"#FCA500"}
        onClick={handleCreatePayment}
      />
    </>
  )
}



const Pagamento = ({ showMemorial, closeMemorialModal, dataMemorial }) => {

  const { data: events, isLoading, error } = useQuery('events', listEvents);
  const { stateForm, setStateForm } = useStepForm();
  const [eventId, setEventId] = useState(null);
  const [paymentInstallments, setPaymentInstallments] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const notification = useNotification();
  const finalPayment = stateForm?.Briefing?.Payments?.filter(v => v.type === "final");
  const finalPaymentTax = stateForm?.Briefing?.Payments?.filter(v => v.type === "briefing");


  useEffect(() => {
    if (eventId) {
      simulateCheckout({
        eventId,
        briefingRequestId: stateForm?.briefingRequestId,
      }).then(res => {
        setPaymentInstallments(res.installments);
      })
    }
  }, [eventId])

  function calculateFinalPrice() {

    if (finalPayment.length > 0) {
      return formatCurrencyDecimal(finalPayment[0].value)
    }

    if (finalPaymentTax.length > 0) {
      const priceTax = finalPaymentTax[0].value;
      const finalPrice = stateForm.finalPrice - priceTax;
      return formatCurrencyDecimal(finalPrice);
    }

    return 'Valor não gerado!'
  }


  function handleCreatePayment(e) {
    e.preventDefault();
    createPayment({
      briefingId: stateForm.Briefing.id,
      installments: selectedInstallment,
      eventId,
    }).then(res => {
      notification.show({
        message: "Estamos gerando seu boleto, aguarde alguns segundos ou atualize a página."
      })
    }).catch(error => {
      notification.show({
        message: error.message || "Houve um erro",
        type: "error"
      })
    })
  }

  function handleOpenPayment(e) {
    e.preventDefault();
    window.open(finalPayment[0].invoiceUrl)
  }

  if (isLoading || error) {
    return;
  }

  if (!stateForm.briefingRequestId) {
    return (
      <Wrapper>
        <div>
          <h2 className="reg-feira">Pagamento</h2>
        </div>
        <Row style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <p style={{
            fontSize: '2rem',
          }}>Você ainda não escolheu uma proposta para o seu projeto!</p>
        </Row>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Pagamento</h2>
      </div>
      <Row>
        <Column>
          <CardPaymentFinal price={calculateFinalPrice()} />
        </Column>
        <Space />
        <Column style={{ justifyContent: 'flex-start' }}>
          {finalPayment && finalPayment[0] ? (
            <>
              <NRoundedButton
                title={"Ver Boleto de Pagamento"}
                active={true}
                color={"#FCA500"}
                onClick={handleOpenPayment}
              />
            </>
          ) :
            (<FragmentPayment
              events={events}
              setSelectedInstallment={setSelectedInstallment}
              paymentInstallments={paymentInstallments}
              stateForm={stateForm}
              setEventId={setEventId}
              handleCreatePayment={handleCreatePayment}
            />)}
        </Column>
      </Row>
      <Row>
        <Column>
          <CardFatura />
        </Column>
        <Space />
        <Column />
      </Row>
    </Wrapper>
  );
};


const Title = styled.span`
  font-size: 2rem;

`


export { Pagamento }