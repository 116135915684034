import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NRoundedButton } from './NRoundedButton';
import { forwardRef } from 'react';


const NDialog = forwardRef((props, ref) => {

  const {
    handleClose,
    handleConfirm,
    open,
    setOpen,
    title,
    titleConfirm,
    activeColor,
    children,
    ...otherProps
  } = props;

  return (
    <>
      <Component
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...otherProps}
      >
        <DialogTitle style={{
          fontSize: '2rem'
        }}>
          {title}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <NRoundedButton
            onClick={handleClose}
            title={"cancelar"}
          />
          <NRoundedButton
            active={true}
            color={activeColor ? activeColor : "#FF8400"}
            title={titleConfirm ? titleConfirm : "salvar"}
            onClick={handleConfirm}
          />
        </DialogActions>
      </Component>
    </>
  )
})

const Component = styled(Dialog)`
  & .MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    font-size: 1.2rem;
}
`

export {
  NDialog
}