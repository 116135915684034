import React from "react";
import { Link } from "react-router-dom";
import { IconNameWrapper, IconWrapper, Name, IconNameWrapperDisabled } from './sideBarMontadorStyle';

const SideBarMenuItem = ({ icon, name, path, isSelected, isOpen }) => {

  return (
    path ? (
      <Link to={path}>
        <IconNameWrapper isSelected={isSelected} open={isOpen}>
          <IconWrapper open={isOpen} isSelected={isSelected}>
            {icon}
          </IconWrapper>
          {isOpen ? (
            <Name isSelected={isSelected}>{name}</Name>
          ) : (
            <></>
          )}
        </IconNameWrapper>
      </Link>
    ) : (
      <IconNameWrapperDisabled>
      <IconWrapper>
       <span title={"EM TESTE"}>{icon}</span> 
      </IconWrapper>
      {isOpen ? (
        <Name isSelected={isSelected} title={"EM TESTE"}>{name}</Name>
      ) : (
        <></>
      )}
    </IconNameWrapperDisabled>
    )
  );
};

export default SideBarMenuItem;