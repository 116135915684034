import LinearStepper from "../../components/briefing/LinearStepper";
import "./Briefing.css";

function NovoBriefing() {
  return (
    <>
      <LinearStepper />
    </>
  );
}

export default NovoBriefing;
