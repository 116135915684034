import { registerUser } from "../../api/user";
import { registerOng } from "../../api/ong";

export function createUser({
  event,
  stateForm,
  setHasError,
  setErrorMessage,
  notification,
  setStateForm,
  navigate,
}) {
  event.preventDefault();
  registerUser(stateForm)
    .then((res) => {
      setHasError(false);
      notification.show({
        message: "Usuário criado",
      });
      setStateForm({
        name: "",
        company: "",
        email: "",
        phone: "",
        password: "",
        reEnterPassword: "",
        profession: "",
      });
      navigate("/login");
    })
    .catch((error) => {
      setHasError(true);
      let msgError = error.message;
      if (msgError) return setErrorMessage(msgError);
    });
}

export function createOng({
  event,
  stateForm,
  setHasError,
  setErrorMessage,
  notification,
  setStateForm,
}) {
  event.preventDefault();
  registerOng(stateForm)
    .then((res) => {
      setHasError(false);
      notification.show({
        message: "Ong criado",
      });
      setStateForm({
        name: "",
        ownerName: "",
        externalLink: "",
        causes: "",
        email: "",
        ownerPhone: "",
      });
    })
    .catch((error) => {
      setHasError(true);
      let msgError = error.message;
      if (msgError) return setErrorMessage(msgError);
    });
}
