import { useState } from 'react';
import styled from '@emotion/styled';
import { NRoundedButton } from '../../../../shared-components';
import "../StyleProjeto&Montagem.css";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { COLORS } from '../../../../helpers/types';
import { useQuery } from 'react-query';
import { getBriefingManager } from '../../../../api/gestor';
import { useParams } from 'react-router-dom';
import Loading from '../../../../shared-components/Loading';
import { useNotification } from 'antd/es/notification/useNotification';

const CardFatura = ({ role, status }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const params = useParams();
  const [faturaInfo, setFaturaInfo] = useState(null);
  const [error, setError] = useState(null);


  const { data, isLoading } = useQuery(`gestor-briefing-${params.briefingId}`, () => getBriefingManager(params.briefingId));

  if (isLoading) {
    return <Loading role='EXPOSITOR' />
  }

  const handlePaymentLink = (link) => {
    window.open(link, '__blank');
  };

  const hasPayments = data?.Briefing?.Payments && data.Briefing.Payments.length > 0;

  return (
    <>
      {hasPayments ? (
        <Wrapper className="options-profile">
          <div>
            <ReceiptLongIcon style={{ width: "30px", height: "31px" }} />
            <h2 className="title-option">Clique aqui para visualizar sua fatura de contratação.</h2>
          </div>

          <NRoundedButton
            title={"Ver Fatura"}
            active={true}
            onClick={() => handlePaymentLink(data?.Briefing?.Payments?.[0]?.invoiceUrl)}
          />
        </Wrapper>
      ) : (
        <div className="options-profile" style={{ background: '#EEEE' }}>
          <p>Sua fatura está sendo gerada, volte daqui uns minutos ou atualize a página.</p>
        </div>
      )}
    </>
  )
}

const Wrapper = styled.div`
  background-color: #EEEE;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
`;

export default CardFatura;