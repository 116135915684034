import { MenuItem } from "@mui/material";
import {
  DashInput,
  DashSelect,
} from "../../../shared-components";
import {
  Title,
  Row
} from "./StyledComponents";

export default function FragmentBudget({
  stateForm,
  handleChange
}) {

  return (
    <>
      <Title>Orçamento</Title>
      <Row>
        <DashInput
          title={"Mínimo (R$)"}
          type="number"
          id={"priceMin"}
          key={"priceMin"}
          name={"priceMin"}
          fullWidth
          value={stateForm.priceMin}
          onChange={handleChange}
          min="1"
        />
        <DashInput
          title={"Máximo (R$)"}
          type="number"
          id={"priceMax"}
          key={"priceMax"}
          name={"priceMax"}
          fullWidth
          value={stateForm.priceMax}
          onChange={handleChange}
          min="1"
        />
        <DashInput
          title={"Prazo de entrega"}
          id={"deadline"}
          key={"deadline"}
          name={"deadline"}
          value={stateForm?.deadline?.split('T')[0]}
          onChange={handleChange}
          fullWidth
          type="date"
          InputProps={{ inputProps: { min: new Date().toISOString().split('T')[0] } }}
        />
        <DashSelect
          title={"Forma de pagamento"}
          type="number"
          id={"paymentTypeName"}
          key={"paymentTypeName"}
          name={"paymentTypeName"}
          fullWidth
          value={stateForm.paymentTypeName}
          onChange={handleChange}
        >
          <MenuItem value="PIX">Pix</MenuItem>
          <MenuItem value="BOLETO">Boleto</MenuItem>
        </DashSelect>
      </Row>
      <Row>
        <DashInput
          title={"Profundidade (m)"}
          type="number"
          id={"depth"}
          key={"depth"}
          name={"depth"}
          fullWidth
          value={stateForm.depth}
          onChange={handleChange}
          min="1"
        />
        <DashInput
          title={"Comprimento (m)"}
          type="number"
          id={"length"}
          key={"length"}
          name={"length"}
          fullWidth
          value={stateForm.length}
          onChange={handleChange}
          min="1"
        />
        <DashInput
          title={"Total (m²)"}
          key={"Total"}
          id={"Total"}
          type="number"
          fullWidth
          value={stateForm.depth * stateForm.length}
        />
      </Row>

    </>
  )
}
