import { useState } from "react";
import styled from "@emotion/styled";
import { NRoundedButton } from "./NRoundedButton";
import { DashInput } from "./DashInput";
import { AlertSendProposal } from "../components/DashboardExpositorComponents/solicitacaoPlataforma/fragments";
import { useUser } from "../context/UserContext";
import { ROLE } from "../helpers/types";
import DialogFinalPrice from "../components/ADMcomponents/DialogFinalPrice";


function titleButton(userData, builder) {
  if (userData.role.name === ROLE.ADMIN || userData.role.name === ROLE.ADMIN_ASSISTENT) {
    return `Contratar ${builder?.Builders[0]?.name}`;
  }
  return "Contratar Montadora";
}

function titleRequest(userData, builder, index, id) {
  if (userData.role.name === ROLE.ADMIN) {
    return `Montadora: ${builder?.Builders[0]?.name}`;
  }
  return `Montador ${index + 1} - Proposta ${id}`
}

const isThereFinalPrice = (userData, priceFinal, priceFinalWithTax) => {
  if (userData?.role?.name === "ADMIN" && !isNaN(priceFinalWithTax)) {
    return true;
  }

  if (userData?.role?.name === "EXPOSITOR" && priceFinal) {
    return true;
  }

  return false;
}


function ProposalToggle({
  dateInput,
  priceMin,
  priceMax,
  priceFinal,
  priceFinalWithTax,
  observation,
  submitBudget,
  index,
  id,
  briefingId,
  removeBriefingAceept,
  builder,
  briefingRequestId,
  tax
}) {
  const [modalFinalPrice, setModalFinalPrice] = useState(false)
  const [openAlertProposal, setOpenAlertProposal] = useState(false);
  const { userData } = useUser();

  function showModalFinalPrice() {
    setModalFinalPrice(true);
  }

  function closeModalFinalPrice() {
    setModalFinalPrice(false);
  }

  return (
    <ToggleWrapper>
      <div>
        <h3>{titleRequest(userData, builder, index, id)}</h3>
        <h2 className="title-deadline">
          Prazo de entrega do primeiro conceito de projeto
        </h2>
        <div className="div-input-date">
          <DashInput
            width={"80%"}
            borderless={"true"}
            background={"#F6F6F6"}
            className="input-date"
            name="deliveryAt"
            value={dateInput ? dateInput.split("T")[0] : ""}
            disabled={true}
            type="date"
          />
        </div>
      </div>
      <div>
        {isThereFinalPrice(userData, priceFinal, priceFinalWithTax) ? ('') : (
          <>
            <h2 className="title-deadline">Valor estimado</h2>
            <span>
              <WrapperPrice>
                <span>R$</span>
                <DashInput
                  key={"price-plan-min"}
                  className="price-plan"
                  borderless={"true"}
                  background={"#F6F6F6"}
                  placeholder="Valor mínimo"
                  width={"auto"}
                  type="number"
                  name={`finalPriceMin`}
                  value={priceMin || ""}
                  disabled={true}
                />
                <span>até</span>
                <DashInput
                  key={"price-plan-max"}
                  className="price-plan"
                  borderless={"true"}
                  width={"auto"}
                  background={"#F6F6F6"}
                  placeholder="Valor máximo"
                  type="number"
                  name={`finalPriceMax`}
                  value={priceMax || ""}
                  disabled={true}
                />
              </WrapperPrice>
            </span>
          </>
        )}
        {isThereFinalPrice(userData, priceFinal, priceFinalWithTax) ? (
          <>
            <h2 className="title-deadline">Proposta final</h2>
            <span>
              <WrapperPrice style={{
                width: '20rem'
              }}>
                <span>R$</span>
                <DashInput
                  key={"price-plan-final"}
                  className="price-plan"
                  borderless={"true"}
                  background={"#F6F6F6"}
                  placeholder="Proposta Final"
                  width={"auto"}
                  type="number"
                  name={`priceFinal`}
                  value={priceFinalWithTax ?? priceFinal}
                  disabled={true}
                />
              </WrapperPrice>
            </span>
          </>
        ) : <span style={{ fontSize: '1.5rem' }}>* Montador ainda não enviou sua proposta final</span>}
      </div>
      <span style={{ display: 'none' }}>
        <p>Justificativa da Montadora referente a diferença entre os valores</p>
        <textarea
          className="resume-briefing-text-area"
          value={observation}
          disabled={true}
        />
      </span>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1rem 0',
      }}>
        <DialogFinalPrice
          priceFinal={priceFinal}
          priceFinalWithTax={priceFinalWithTax}
          open={modalFinalPrice}
          handleClose={closeModalFinalPrice}
          requestId={briefingRequestId}
          tax={tax}
        />
        {
          (userData?.role?.name === "ADMIN" || userData?.role?.name === "ADMIN_ASSISTENT") ?
            <ActionButtons>
              <NRoundedButton
                active={true}
                color={"#FF8400"}
                title={priceFinalWithTax ? "Editar Proposta Final" : "Adicionar Proposta Final"}
                onClick={showModalFinalPrice}
              />
              <NRoundedButton
                active={priceFinalWithTax ? true : false}
                disabled={priceFinalWithTax ? false : true}
                color={"#FF8400"}
                title={titleButton(userData, builder)}
                onClick={() => submitBudget(index, setOpenAlertProposal)}
              />
            </ActionButtons>
            :
            <NRoundedButton
              active={priceFinal}
              disabled={!priceFinal}
              color={"#FF8400"}
              title={titleButton(userData, builder)}
              onClick={() => submitBudget(index, setOpenAlertProposal)}
            />
        }
      </div>
      <AlertSendProposal
        open={openAlertProposal}
        setOpen={setOpenAlertProposal}
        requestId={id}
        briefingId={briefingId}
        removeBriefingAceept={removeBriefingAceept}
      />
    </ToggleWrapper>
  );
}

export default ProposalToggle;

const WrapperPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  span {
    margin: 0 1rem;
  }
`;

const ToggleWrapper = styled.div`
  background-color: #f7f8fa;
  padding: 1rem;
  margin: .3rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .resume-briefing-text-area {
    width: 100%;
    font-size: 1.5rem;
    background-color: #f6f6f6;
    padding: 1rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid #adb5bd;
    resize: none;
    
    &:focus {
      border: 1px solid #ccc;
    }

    &:hover {
      border: 1px solid #ccc;
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0;
  width: 100%;
`;
