import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { COLORS } from "../../../helpers/types";

export default function FragmentVisibility({ role, stateForm, handleVisibilityChange }) {
  return (
    <>
      <span>Visibilidade do evento: </span>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        style={{ flexDirection: "row" }}
        required
      >
        <FormControlLabel
          value="publico"
          control={
            <Radio
              checked={stateForm.visibility === 'publico'}
              onChange={handleVisibilityChange}
              sx={{
                '&.Mui-checked': {
                  color: COLORS[role],
                },
              }}
            />
          } label={<Typography style={{ fontSize: '15px' }}>Público
          </Typography>} />
        <FormControlLabel
          value="privado"
          control={
            <Radio
              checked={stateForm.visibility === 'privado'}
              onChange={handleVisibilityChange}
              sx={{
                '&.Mui-checked': {
                  color: COLORS[role],
                },
              }}
            />
          } label={<Typography style={{ fontSize: '15px' }}>Privado
          </Typography>}
        />
      </RadioGroup >
    </>
  );
}
