import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import {
    ArchiveIcon,
    ArrowIcon,
    BoxIcon,
    Container1,
    ContainerHeader,
    FeedIcon,
    Header,
    HomeIcon,
    IconsContainer,
    Line,
    ListIcon,
    LogoContainer,
    LogoImage,
    NotificationsIcon,
    PaidIcon,
    PhotoIcon,
    ScheduleIcon,
    SideBar,
    Text,
    Section,
    UserAvatar,
    Button,
    HamburgerMenu,
    MenuMobile,
    BoxHeader,
    ContainerMobile,
    IconClose,
    Box,
    RequestQuoteIcon,
    ManageAccountsIcon,
    PlaylistIcon,
} from "../SidebarHeader/sideBarMontadorStyle";
import SideBarMenuItem from "../SidebarHeader/sideBarMenuItemMontador";
import { useMatchMedia } from "../../hooks/useMatchMedia";
import { deleteToken } from "../../api/login";

const queries = ["(max-width: 768px)", "(min-width: 769px)"];

const menuItems = [
    { id: 1, icon: <HomeIcon />, name: "Dashboard", path: "/dashboard-montador" },
    { id: 2, icon: <RequestQuoteIcon />, name: "Pedidos de orçamento ", path: "/montador/lista" },
    { id: 3, icon: <ManageAccountsIcon />, name: "Editar Perfil", path: "/perfil-montador" },
    { id: 4, icon: <PlaylistIcon />, name: "Projeto + Montagem", path: "/montador/projeto&montagem" },
];

const textHeader = [
    { id: 1, name: "Briefings", path: /^\/montador\/lista$/ },
    { id: 2, name: "Briefing", path: /^\/montador\/briefing\/\d+$/ },
    { id: 3, name: "Dashboard", path: /^\/dashboard-montador$/ },
    { id: 4, name: "Perfil", path: /^\/perfil-montador$/ },
    { id: 5, name: "Projeto e Montagem", path: /^\/montador\/projeto&montagem$/ },
];

const SideBarMontadorNovo = (active) => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const { userData, saveUserData } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const [mobile] = useMatchMedia(queries);

    const newMenuItems = menuItems.map((value) => {
        if (value.path === location.pathname) {
            return {
                ...value,
                active: true,
            };
        }
        return {
            ...value,
        };
    });

    const newTextHeader = textHeader.filter((value) => value.path.test(location.pathname));

    const handleArrowClick = () => {
        setIsSideBarOpen((prevOpen) => !prevOpen);
    };

    const handleArrowClickMobile = () => {
        setIsSideBarOpen(!isSideBarOpen);
    };
    function handleNovoPedido() {
        navigate("/expositor/novo-briefing");
    }

    function handleChoosePlans() {
        navigate("/expositor/planos");
    }

    function handleLogout() {
        window.location.assign(window.location.origin);
        saveUserData({});
        deleteToken();
    }

    return (
        <>
            <Container1>
                {mobile ? (
                    <ContainerMobile>
                        <MenuMobile onClick={handleArrowClickMobile} />
                        <HamburgerMenu open={isSideBarOpen}>
                            {isSideBarOpen && (
                                <>
                                    <IconClose onClick={handleArrowClickMobile} />
                                    <IconsContainer onClick={handleArrowClickMobile}>
                                        {newMenuItems.map((item) => (
                                            <SideBarMenuItem key={item.id} isSelected={location.pathname === item.path} icon={item.icon} name={item.name} isOpen={true} path={item.path}></SideBarMenuItem>
                                        ))}
                                    </IconsContainer>
                                </>
                            )}
                        </HamburgerMenu>
                    </ContainerMobile>
                ) : (
                    <SideBar open={isSideBarOpen}>
                        <LogoContainer>
                            <LogoImage />
                        </LogoContainer>
                        <Line />
                        <ArrowIcon onClick={handleArrowClick} />
                        {!isSideBarOpen ? (
                            <IconsContainer>
                                {newMenuItems.map((item) => (
                                    <SideBarMenuItem key={item.id} isSelected={location.pathname === item.path} icon={item.icon} isOpen={false} path={item.path}></SideBarMenuItem>
                                ))}
                            </IconsContainer>
                        ) : (
                            <>
                                <ArrowIcon Open={isSideBarOpen} onClick={handleArrowClick} />
                                <IconsContainer>
                                    {newMenuItems.map((item) => (
                                        <SideBarMenuItem key={item.id} isSelected={location.pathname === item.path} icon={item.icon} name={item.name} isOpen={true} path={item.path}></SideBarMenuItem>
                                    ))}
                                </IconsContainer>
                            </>
                        )}
                    </SideBar>
                )}
                <ContainerHeader>
                    <Header>
                        <Text>{newTextHeader[0].name}</Text>
                        <Section>
                            <BoxHeader>
                                <UserAvatar alt="" src={userData.avatar} />
                                <NotificationsIcon />
                                <ArchiveIcon onClick={handleLogout} />
                            </BoxHeader>
                        </Section>
                    </Header>
                </ContainerHeader>
            </Container1>
        </>
    );
};

export default SideBarMontadorNovo;
