import { Outlet, Route, Routes } from "react-router-dom";

import Briefing from "../pages/Briefing/Briefing";
import Expositor from "../pages/Cadastro/Expositor";
// import Expositor2 from "./pages/Eventos/Expositor";
import Montador from "../pages/Cadastro/Montador";
import Promotor from "../pages/Cadastro/Promotor";
import Projetista from "../pages/Cadastro/Projetista";

import SignupSuccess from "../pages/Cadastro/SignupSuccess";
import ComoFunciona from "../pages/ComoFunciona/ComoFunciona";
import EventosAtuais from "../pages/Eventos/EventosAtuais";
import EventosIndividuais from "../pages/Eventos/EventosIndividuais";
import HomePage from "../pages/Home/HomePage";
import Login from "../pages/Login/Login";
import EsqueciSenha from "../pages/Senha/ForgetPassword";
import ResetSenha from "../pages/Senha/ResetPassword";
import SuccessPassword from "../pages/Senha/SuccessPassword";
import EncontradoErro from "../pages/EncontradoErro/EncontradoErro";
import AcessoErro from "../pages/AcessoErro/AcessoErro";
import Planos from "../pages/Planos/Planos";
import Cookies from "../components/cookies/cookies";
import Blog from "../pages/Blog/Blog";
import FAQ from "../pages/FAQ/FAQ";

import Causas from "../pages/Causas/Causas";
import CausasIndividuais from "../pages/Causas/CausasIndividuais";
import BriefingEnviado from "../pages/Sucesso/BriefingEnviado";
import CadastroSucesso from "../pages/Cadastro/CadastroSuccess";
import OngRegister from "../pages/Cadastro/OngRegister";

export default [
  <Route path="/" element={<HomePage />} exact strict key="home-page" />,

      <Route path="/cadastro-expositor" element={<Expositor />} exact strict key="cadastro-expositor" />,
      <Route path="/cadastro-promotor" element={<Promotor />} exact strict key="cadastro-promotor" />,
      <Route path="/cadastro-montador" element={<Montador />} exact strict key="cadastro-montador"  />,
      <Route path="/cadastro-projetista" element={<Projetista />} exact strict key="cadastro-projetista" />,
      <Route path="/cadastro-sucesso" element={<CadastroSucesso />} exact strict key="cadastro-sucesso" />,
      <Route path="/login" element={<Login />} exact strict key="login" />,
      <Route path="/cadastro-realizado" element={<SignupSuccess />} exact strict key="cadastro-realizado" />,
      <Route
        path="/eventos-individuais"
        element={<EventosIndividuais />}
        exact
        strict key="eventos-individuais"
      />,
      <Route path="/como-funciona" element={<ComoFunciona />} exact strict key="como-funciona" />,
      <Route path="/forget-password" element={<EsqueciSenha />} exact strict key="forget-password"/>,
      <Route path="/reset-password" element={<ResetSenha />} exact strict key="reset-password"/>,
      <Route path="/success-password" element={<SuccessPassword />} exact strict key="success-password"/>,
      <Route path="/cadastro-briefing" element={<Briefing />} exact strict key="cadastro-briefing"/>,
      <Route path="/briefing-enviado" element={<BriefingEnviado />} exact strict key="briefing-enviado"/>,
      <Route path="/cookies" element={<Cookies />} exact strict key="cookies"/>,
      <Route path="/planos" element={<Planos />} exact strict key="planos"/>,
      <Route path="/blog" element={<Blog />} exact strict key="blog"/>,
      <Route path="/faq" element={<FAQ />} exact strict key="faq"/>,
      <Route path="/causas" element={<Causas />} exact strict key="causas"/>,
      <Route
        path="/causas-individuais"
        element={<CausasIndividuais />}
        exact
        strict key="causas-individuais"
      />,
      <Route path="/nao-autorizado" element={<AcessoErro />} exact strict key="nao-autorizado"/>,
      <Route path="*" element={<EncontradoErro />} exact strict key="page-error" />,
      <Route path="/cadastro-ong" element={<OngRegister />} exact strict key="cadastro-ong"/>
]