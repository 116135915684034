import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { NRoundedButton } from "../../../../shared-components";


function renderContent(projectViewZoom) {
  if (!projectViewZoom) {
    return ''
  }

  if (projectViewZoom.type === 'pdf') {
    return (
      <object
        data={`${projectViewZoom.image}#toolbar=0&scrollbar=1&view=FitH`}
        type="application/pdf"
        width="100%"
        height="920%"
        style={{ pointerEvents: 'none' }}
      >
      </object>
    )
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}>
      <img
        src={projectViewZoom.image}
        width="80%"
        alt=""
      />
    </div >
  )

}


export function DialogProject({
  open,
  handleClose,
  projectViewZoom,
}) {

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogContent
        sx={{
          height: '80vh',
        }}
      >
        {renderContent(projectViewZoom)}
      </DialogContent>
      <DialogActions>
        <NRoundedButton
          active={true}
          color={"#FF8400"}
          title={"Fechar"}
          onClick={handleClose}
        />
      </DialogActions>

    </Dialog>
  )
}