import { useState } from "react";
import { getIncompleteBriefing } from "../../../api/briefing";
export function useBriefing() {

  const city = sessionStorage.getItem('city');

  const { briefingData } = getIncompleteBriefing()


  const [briefing, setBriefing] = useState({
    evento: briefingData?.eventName || "",
    precisa: "",
    cidade: city ? city : "",
    local: "",
    email: "",
    phone: "",
    alternatephone: "",
    address1: "",
    address2: "",
    country: "",
    cardnumber: "",
    cardmonth: "",
    cardyear: "",
    minOrcamento: "",
    maxOrcamento: "",
    prazoDate: "",
    largeArea: "",
    comprimentoArea: "",
    total: "",
    formaPagamento: "",
    balcao: "",
    estoque: [""],
    objetosSuspensos: "",
    freezer: "",
    pontosEletricos: "",
    tvQuantidade: "",
    painelLed: "",
    lixeiras: "",
    promoter: "",
    link: "",
    buffet: "",
    blueprint: "",
    freeInformation: "",
    paisagismo: "",
    references: [],
    lastStands: [],
    orcamento: {},
  });


  return [briefing, setBriefing]
}