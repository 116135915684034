import React from "react";
import styled from "@emotion/styled";
import ProjetistaLogo from "../../images/Frame-126.png";
import YellowCircle from "../../images/yellow-circle.png";

export default function Header({ title }) {

  return (
    <Wrapper>
      <img className="expo-logo" src={ProjetistaLogo} alt="" width="200px" />
      <span style={{ color: "black" }}>
        {title}
      </span>
      <img
        className="orange-circle"
        src={YellowCircle}
        alt=""
        width="220px"
      />
    </Wrapper>

  )

}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 22rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 0.375rem;

  & >span {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    flex-basis: 40%;
  }

  strong {
    font-weight: 700;
    color: #FDC22A;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;

    & >span {
    font-size: 3rem;
    }
  }

`