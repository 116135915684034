import styled from "@emotion/styled"

export const WrapperPrice = styled.div`
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  gap: 2px;
  padding: 2px;
  margin: 10px 0;

  > span {
    flex: 0.3;
    font-size: 1.7rem;
    align-items: center;
  }

  > input {
     background-color: #F6F6F6;
     height: 20px;
     font-size: 1.7rem;
  }
`
export const WrapperFinalPrice = styled.div`
 
  display: flex;
  align-items: center;
  justify-content: start;
  height: 40px;

  > span {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #F6F6F6;
    border-radius: 6px 0 0 6px;
    height: 40px;
    padding-left: 2rem;
    font-size: 1.7rem;
    align-items: center;
  }

  > span:nth-of-type(2) {
    background-color: white;
    font-size: 1.2rem;
  }

  > input {
     background-color: #F6F6F6;
     height: 20px;
     font-size: 1.7rem;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;

  > div {
    display: flex;
    flex: 0.4;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }


  @media screen and (max-width: 898px) {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
    
    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }
`

export const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #e3e3e3;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;

  input:disabled {
    cursor: not-allowed;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin: 5px;
    padding: 0 10px;
  }

  > span {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    align-items: center;
    flex-direction: column;

    > span {
      img {
        min-width: 380px;
        height: 240px;
      }
    }
  }

    @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    h2 {
      width: 100%;
    }
    
    > span {
      width: 100%;
      flex-direction: column;
      align-items: center;
      

      img {
        min-width: 280px;
        height: 240px;
      }
    }
  }

`

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #09b001;
  border-radius: 50px;
  background-color: transparent;
  border: 2px solid #09b009;

  > p {
    padding: 6px;
    font-size: 1.6rem;
  }

  @media screen and (max-width: 898px) {
    border-radius: 20px;
    > p {
      padding: 2rem;
      font-size: 1.6rem;
    }
  }
`