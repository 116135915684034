import { useUser } from "../../context/UserContext";

export function useBriefingManager() {

  const { userData } = useUser();

  function isExhibitor() {
    if (userData?.role?.name === "EXPOSITOR") {
      return true;
    }
    return false;
  }

  function isBuilder() {

    if (userData?.role?.name === "MONTADOR") {
      return true;
    }
    return false;
  }

  function isProjetist() {

    if (userData?.role?.name === "PROJETISTA") {
      return true;
    }
    return false;
  }

  
  function isProfileColor() {
  if (isExhibitor()) {
      return "#FF8400"; 
    } else if (isBuilder()) {
      return "#22DA29"; 
    } else {
      return "#764FAA"; 
    }
  }

  return {
    isBuilder,
    isExhibitor,
    isProjetist,
    isProfileColor
  }

}