import { useState } from "react";
import { useStepForm } from "../StepFormContext";
import {
  Row,
  ProjectBlock,
} from "../../../DashboardProjetistaComponents/SolicitProjeto/Styles";
import { FragmentUploadProject } from "../../../DashboardProjetistaComponents/SolicitProjeto/fragments/FragmentUploadProject";

export default function FragmentProjects() {

  const { stateForm, setStateForm } = useStepForm();
  const [projectFiles, setProjectFiles] = useState(createProjectsArray)

  function createProjectsArray() {
    const projects = stateForm?.Briefing.BriefingProjects || [];
    const projectsLength = projects?.length;

    for (let i = projectsLength; i < 4; i++) {
      projects.push({
        observations: ''
      })
    }

    return projects;
  }

  function handleChangeProject(e, res) {
    const position = e.target.id.split("_")[1]

    const uploadedImage = {
      link: res?.name
    }

    const refs = projectFiles;
    refs[position] = uploadedImage;
    setProjectFiles([
      ...refs,
    ])

    stateForm['BriefingProjectsVersion'] = refs;
  }

  return (
    <Row>
      {projectFiles.map((project, index) => (
        <ProjectBlock
          key={index}
        >
          <FragmentUploadProject
            key={`upload-${index}`}
            id={`project_${index}`}
            briefingProjects={project}
            handleChangeProject={handleChangeProject}
          />
        </ProjectBlock>
      ))}
    </Row>
  )
}