import {
  getUserData,
  privateFetch
} from "./user";


//TODO: refactor
const API = process.env.REACT_APP_API;

export async function signIn({ email, password }) {

  const res = await fetch(`${API}/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      device: navigator.userAgent,
    }),
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function checkRole() {
  const token = await privateFetch();

  const res = await fetch(`${API}/check-role`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;

}

export function saveToken(token) {
  localStorage.setItem('nossostand', token)
}

export function deleteToken() {
  localStorage.removeItem('nossostand')
}