import React from "react";
import { Switch } from "antd";

const SwitchButton = ({
  checked,
  handleChecked,
  name,
  disabled,
}) => {

  return (
    <Switch
      checked={checked || false}
      checkedChildren="aprovado"
      unCheckedChildren="pendente"
      onChange={(e) => handleChecked(e, name)}
      disabled={disabled}
    />
  );
};




export default SwitchButton;
