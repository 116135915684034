import styled from "@emotion/styled";
import { Title } from "./StyledComponents";
import { FragmentUploadReferences } from "./FragmentUploadReferences";
import { DashTextArea } from "../../../shared-components";
export default function FragmentLastStandsBlueprint({
  briefingData,
  stateForm,
  setStateForm,
  handleChange,
}) {
  function handleInput(e) {
    const position = e.target.id.split("_")[1];

    stateForm.BriefingLastStand[position] = {
      ...stateForm.BriefingLastStand[position],
      observation: e.target.value,
    };

    setStateForm({
      ...stateForm,
    });
  }

  function handleChangeImageLastStand(e, res) {
    const position = e.target.id.split("_")[1];

    const uploadedImage = {
      type: "upload",
      link: res?.name,
    };

    stateForm.BriefingLastStand[position] = uploadedImage;

    setStateForm({
      ...stateForm,
    });
  }

  function handleChangeBlueprint(e, res) {
    setStateForm({
      ...stateForm,
      blueprint: {
        image: res ? res.name : null,
      },
    });
  }

  return (
    <>
      <Title>Últimos Stands</Title>
      <Row style={{
        justifyContent: 'space-between',
        padding: '0 0',
      }}>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingLastStand[0]?.id}
            id={"laststand_0"}
            briefingReference={briefingData?.BriefingLastStand[0]}
            handleChangeImage={handleChangeImageLastStand}
            title="Último Stand 1"
          />
          <DashTextArea
            id={`lastStandObjservation_0`}
            key={`lastStandObjservation_0`}
            fullWidth
            placeholder="Observações"
            name="lastStand0"
            value={briefingData?.BriefingLastStand[0]?.observation}
            onChange={handleInput}
          />
        </div>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingLastStand[1]?.id}
            id={"laststand_1"}
            briefingReference={briefingData?.BriefingLastStand[1]}
            handleChangeImage={handleChangeImageLastStand}
            title="Último Stand 2"
          />
          <DashTextArea
            id={`lastStandObjservation_1`}
            key={`lastStandObjservation_1`}
            fullWidth
            placeholder="Observações"
            name="lastStand1"
            value={briefingData?.BriefingLastStand[1]?.observation}
            onChange={handleInput}
          />
        </div>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingLastStand[2]?.id}
            id={"laststand_2"}
            briefingReference={briefingData?.BriefingLastStand[2]}
            handleChangeImage={handleChangeImageLastStand}
            title="Último Stand 3"
          />
          <DashTextArea
            id={`lastStandObjservation_2`}
            key={`lastStandObjservation_2`}
            fullWidth
            placeholder="Observações"
            name="lastStand2"
            value={briefingData?.BriefingLastStand[2]?.observation}
            onChange={handleInput}
          />
        </div>
      </Row>
      <Title>Planta Baixa</Title>
      <Row>
        <div>
          <FragmentUploadReferences
            key={briefingData?.blueprint}
            id={"blueprint"}
            briefingReference={briefingData?.blueprint}
            handleChangeImage={handleChangeBlueprint}
            title="Planta Baixa"
          />
          <DashTextArea
            id={"blueprint_observation"}
            key={"blueprint_observation"}
            fullWidth
            placeholder="Observações"
            name="blueprint_observation"
            value={stateForm?.blueprint_observation || ""}
            onChange={handleChange}
          />
        </div>
      </Row>
    </>
  );
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0;
  flex-wrap: wrap;


  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    > span {
      flex-direction: column;
      align-items: center;
    }
  }
`;
