import styled from "@emotion/styled";
import { forwardRef } from 'react';

const DashTextArea = forwardRef((props, ref) => {

  const { title, ...otherProps } = props;

  return (
    <Wrapper>
      {title ? <p>{title}</p> : ''}
      <Component {...otherProps}></Component>
    </Wrapper>

  )

})

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    

    > p {
      margin: 2px;
      font-size: 1.6rem;
      font-weight: 500;
    }
`

const Component = styled.textarea`

    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: ${({ height }) => height ? height : '100%'};
    width: ${({ width }) => width ? width : '100%'};
    background-color: #F3F3F3;
    font-size: 1.8rem;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    resize: none;
    outline: none;
    white-space: pre-line;
    overflow-wrap: break-word;

  &:hover {
    outline: none;
    border: 1px solid black;
  }
  &:active, &:focus {
    outline: none;
    border: 1px solid #FF9E00;
  }

`

export {
  DashTextArea,
}