import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  Step0,
  Step1,
  ProjetoTecnicoArt,
  ART,
  PreMontagem,
  Doacao,
  Pagamento,
  Avaliacao,
  Step1Projetista,
  Step3Builder,
  PreDoacao,
  Final,
  DoacaoMontador,
  PagamentoTaxa
} from './stepsComponents'
import { useStepForm } from "./StepFormContext";
import { useStepActions } from "./useStepActions";
import { useNotification } from "../../../context/NotificationContext";
import { Step1Builder } from "./stepsComponents/Step1Builder";
import { NRoundedButton } from "../../../shared-components";
import DialogBriefing from "../../DashboardProjetistaComponents/SolicitProjeto/fragments/DialogBriefing";
import {
  updateBriefingManager
} from "../../../api/gestor";
import { Alert } from "./components/Alert";
import DialogMemorial from './components/DialogMemorial';
import DialogComment from './components/DialogComment';
import { useUser } from "../../../context/UserContext";
import { COLORS } from "../../../helpers/types";
import Contrato from "./stepsComponents/Contrato";
import MemorialGestor from "./stepsComponents/MemorialGestor";
import AlertComponent from "../../../shared-components/AlertComponent";

function getSteps(
  isBuilder,
  isDraftsman,
  isExhibitor
) {
  if (isBuilder()) {
    return [
      "Apresentação",
      "Pagamento Taxa Inicial",
      "Projeto Técnico Final + 3D",
      "Doações",
      "Doações Itens",
      "Contrato",
      "Pagamento Final",
      "RRT de Projeto + Execução",
      "Cardeno de Arte e Arquivos",
      "Avaliação",
      "Fim",
    ];
  }
  if (isDraftsman()) {
    return [
      "Apresentação",
      "Projeto - Versão 1",
      "Projeto - Versão 2",
      "Projeto Técnico Final + 3D",
      "Doações",
      "Doações Itens",
      "RRT de Projeto + Execução",
      "Cardeno de Arte e Arquivos",
      "Avaliação",
      "Fim",
    ];
  }


  //Expositor e Admin
  return [
    "Apresentação",
    "Pagamento de Contratação",
    "Projeto - Versão 1",
    "Projeto - Versão 2",
    "Projeto Técnico Final + 3D Final",
    "Doações",
    "Memorial",
    "Contrato",
    "Pagamento Final",
    "RRT de Projeto + Execução",
    "Cardeno de Arte e Arquivos",
    "Avaliação",
    "Fim",
  ];
}


function stepsBuilder(
  step,
  setActiveStep,
  stateForm,
  buttonClicked
) {

  const { hasDonation } = stateForm;
  switch (step) {
    case 0: {
      return <Step0 />
    }
    case 1: {
      return <Step1Builder />
    }
    case 2:
      return <ProjetoTecnicoArt />;
    case 3:
      {
        if (hasDonation) {
          return <PreDoacao step={step} setActiveStep={setActiveStep} />;
        }
        if (!hasDonation) {
          if (buttonClicked === "next") {
            setActiveStep(() => step + 1)
          } else {
            setActiveStep(() => step - 1)
          }
        }
      }
    // eslint-disable-next-line no-fallthrough
    case 4: {
      if (hasDonation) {
        return <DoacaoMontador />;
      }
      if (buttonClicked === "next") {
        setActiveStep(() => step + 2)
      } else {
        setActiveStep(() => step - 2)
      }
      break;
    }
    case 5:
      return <Contrato />;
    case 6:
      return <Step3Builder />;
    case 7:
      return <ART />;
    case 8:
      return <PreMontagem />;
    case 9:
      return <Avaliacao />
    default:
      return <Final />;
  }
}

function stepsDraftsman(
  step,
  isBuilder,
  isDraftsman,
  setActiveStep,
  showMemorial,
  closeMemorialModal,
  dataMemorial,
  buttonClicked
) {


  switch (step) {
    case 0: {
      return <Step0 />
    }
    case 1: {
      return <Step1Projetista
        key="projetista-v1"
        version="v1"
        versionTitle="Versão 1"
      />
    }
    case 2: {
      return <Step1Projetista
        key="projetista-v2"
        version="v2"
        versionTitle="Versão 2"
      />
    }
    case 3: return <ProjetoTecnicoArt />;
    case 4:
      {
        if (buttonClicked === "next") {
          setActiveStep(() => step + 1)
        } else {
          setActiveStep(() => step - 1)
        }
      }
    // eslint-disable-next-line no-fallthrough
    case 5:
      {
        if (buttonClicked === "next") {
          setActiveStep(() => step + 1)
        } else {
          setActiveStep(() => step - 1)
        }
      }
    case 6:
      return <ART />;
    case 7:
      return <PreMontagem />;
    case 8:
      {
        if (buttonClicked === "next") {
          setActiveStep(() => step + 1)
        } else {
          setActiveStep(() => step - 1)
        }
      }
    default:
      return <Final />;
  }
}


function getStepContent(
  step,
  isBuilder,
  isDraftsman,
  isExhibitor,
  setActiveStep,
  showMemorial,
  closeMemorialModal,
  dataMemorial,
  stateForm,
  buttonClicked
) {

  if (isBuilder()) {
    return stepsBuilder(
      step,
      setActiveStep,
      stateForm,
      buttonClicked
    )
  }

  if (isDraftsman()) {
    return stepsDraftsman(
      step,
      isBuilder,
      isDraftsman,
      setActiveStep,
      showMemorial,
      closeMemorialModal,
      dataMemorial,
      buttonClicked
    )
  }

  //Expositor e Admin
  switch (step) {
    case 0: {
      return <Step0 />
    }
    case 1: {
      return <PagamentoTaxa />
    }
    case 2: {
      return <Step1
        key="expositor-v1"
        version="v1"
        versionTitle="Versão 1"
      />;
    }
    case 3: {
      return <Step1
        key="expositor-v2"
        version="v2"
        versionTitle="Versão 2"
      />;
    }
    case 4: return <ProjetoTecnicoArt />;
    case 5:
      return <Doacao />;
    case 6:
      return <MemorialGestor />;
    case 7: {
      if (dataMemorial.status === 'pending') {
        return <AlertComponent info={"Você precisa aprovar o memorial para visualizar a etapa de contrato. Atualize a página ou volte quando o memorial estiver aprovado."} />
      } else if (dataMemorial.status === 'disapproved') {
        return <AlertComponent info={"O memorial foi reprovado. Você não pode prosseguir para a etapa de contrato."} />;
      } else
        return <Contrato />;
    }

    case 8: {
      if (dataMemorial.status === 'pending') {
        return <AlertComponent info={"Você precisa aprovar o memorial para visualizar a etapa de pagamento. Atualize a página ou volte quando o memorial estiver aprovado. "} />
      } else if (dataMemorial.status === 'disapproved') {
        return <AlertComponent info={"O memorial foi reprovado. Você não pode prosseguir para a etapa de contrato."} />;
      } else
        return <Pagamento
          showMemorial={showMemorial}
          closeMemorialModal={closeMemorialModal}
          dataMemorial={dataMemorial}
        />;
    }
    case 9:
      return <ART />;
    case 10:
      return <PreMontagem />;
    case 11:
      return <Avaliacao />;
    default:
      return <Final />;
  }
}

const ProjetoEmontagem = ({ dataBriefing, dataMemorial, refetchMemorial }) => {

  const [skippedSteps, setSkippedSteps] = useState([]);
  const [modalBriefing, setModalBriefing] = useState(false);
  const [modalMemorial, setModalMemorial] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const { stateForm, setStateForm } = useStepForm();
  const notification = useNotification();
  const [activeStep, setActiveStep] = useState(+stateForm?.step || 0);
  const [openAlert, setOpenAlert] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(null);
  const {
    isBuilder,
    isDraftsman,
    isExhibitor,
  } = useStepActions()
  const steps = getSteps(isBuilder, isDraftsman, isExhibitor);

  const methods = useForm({
    defaultValues: {},
  });

  const { userData } = useUser();
  const activeColor = COLORS[userData?.role?.name] || COLORS['EXPOSITOR'];



  function showBriefing() {
    setModalBriefing(true)
  }

  function closeBriefingModal() {
    setModalBriefing(false);
  }

  function showMemorial() {
    setModalMemorial(true)
  }

  function closeMemorialModal() {
    setModalMemorial(false);
  }

  function showComment() {
    setModalComment(true)
  }

  function closeCommentModal() {
    setModalComment(false);
  }

  function closeAllModals() {
    setModalBriefing(false);
    setModalComment(false);
    setModalMemorial(false);
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleBack = () => {
    setButtonClicked("back");
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setButtonClicked("next");
    updateBriefingManager({
      briefingManagerId: stateForm.Briefing.id,
      uploads: stateForm?.BriefingManagerUploads,
      rating: stateForm?.BriefingManagerRating[0] || undefined,
      step: activeStep,
    }).then(res => {
      notification.show({
        message: "Gestor atualizado"
      })
      setStateForm({ ...stateForm })
    }).catch(err => {
      notification.show({
        message: "Houve um erro ao atualizar o gestor",
        type: "error"
      })
    })

    if (activeStep === steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };


  return (
    <Wrapper
      style={{ marginTop: "0px" }}
      activeColor={activeColor}
    >
      <DialogBriefing
        open={modalBriefing}
        handleClose={closeBriefingModal}
        title={"Briefing"}
        data={dataBriefing}
      />
      <DialogMemorial
        open={modalMemorial}
        handleClose={closeMemorialModal}
        title={"Memorial"}
        data={dataMemorial}
        showComment={showComment}
        refetchMemorial={refetchMemorial}
      />
      <DialogComment
        open={modalComment}
        handleClose={closeCommentModal}
        title={"Comentário"}
        data={dataMemorial}
        refetchMemorial={refetchMemorial}
        handleCloseAll={closeAllModals}
      />
      <Alert
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
        text="Antes de avançar para a próxima etapa verifique se todas as solicitações de alteração do projeto foram realizadas."
      />
      <Stepper className="Step" alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ backgroundColor: "#fafafa" }}
              ></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel
                {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center"></Typography>
      ) : (
        <ContentForm>
          <FormProvider {...methods}>
            <form
              // onSubmit={methods.handleSubmit(handleNext)}
              onSubmit={(e) => e.preventDefault()}
              style={{
                minHeight: '50vh',
              }}
            >
              <ActionButtons>
                <NRoundedButton
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  color="#92939c"
                  active={true}
                  title="anterior"
                />
                <NRoundedButton
                  onClick={showBriefing}
                  color={"#ff8400"}
                  active={true}
                  title="ver briefing"
                />
                <NRoundedButton
                  color={"#ff8400"}
                  active={true}
                  onClick={handleNext}
                  type="submit"
                  title={"salvar e avançar"}
                />
              </ActionButtons>
              {getStepContent(
                activeStep,
                isBuilder,
                isDraftsman,
                isExhibitor,
                setActiveStep,
                showMemorial,
                closeMemorialModal,
                dataMemorial,
                stateForm,
                buttonClicked,
              )}

            </form>
          </FormProvider>
        </ContentForm>
      )}
    </Wrapper>
  );
};


const Wrapper = styled.div`

  & .Step {
    background-color: white !important;
  }

  & .MuiStepIcon-root {
    color: #e3e3e3;
    width: 24px;
    height: auto;
  }

  & .MuiStepIcon-active,
  .MuiStepIcon-completed {
    color: ${props => `${props.activeColor} !important;`};
  }
  
  & .MuiStepIcon-text {
    font-size: 1.4rem;
    font-weight: 600;
  }

  & .MuiStepLabel-label {
    font-size: 1.3rem;
  }

  & .MuiStepIcon-completed {
    width: 20px;
    color: ${props => `${props.activeColor}AA !important;`};
  }


`

const ContentForm = styled.div`
  margin-top: 20px;
  margin-left: 0;

  form {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: white;
    width: 100%;
    border-radius: 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    min-height: 40vh;
  }

  @media screen and (max-width: 898px) {
    width: 90%;
    margin: 20px;
    display: flex;
    flex-direction: column;
  }

`

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 2rem;

`


export default ProjetoEmontagem;