import ExpoLogo from "../../images/Frame-124.png";
import GreenCircle from "../../images/green-circle.png";
import SideBarMontador from "../../components/sidebar/SideBarMontador";
import SideBarMontadorNovo from "../../components/sidebar/SideBarMontadorNovo";

const DashboardExpositor = () => {
  return (
    <div style={{ backgroundColor: "#F5F5F9", height: "120vh" }}>
      <SideBarMontadorNovo />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>
                Este é o dashboard <br />
              </span>
              <span style={{ color: "#22DA29" }}>Montador</span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={GreenCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="expo1">
        <div className="expositor2">
          <h2 className="warnings">AVISOS</h2>
          <p className="messages">
            Você preencheu 30% do cadastro. Check seu perfil para terminar
          </p>
        </div>
      </div>
      <div className="expo1">
        <div className="expositor3">
          <h2 className="warnings">VIDEO</h2>
          <button className="cookie-modal2">COOKIE modal</button>
        </div>
      </div>
    </div>
  );
};

export default DashboardExpositor;
