import { NAlert } from "../../../../shared-components";
import { useNotification } from "../../../../context/NotificationContext";
import { acceptProposal } from "../../../../api/briefing";

export function AlertSendProposal({
  open,
  setOpen,
  requestId,
}) {
  const notification = useNotification();
  
  function handleCancel() {
    setOpen(false);
  }

  function handleConfirm() {
    try {
      acceptProposal(requestId)
      notification.show({
        message: "Orçamento aceito :)",
      })
      console.log(requestId)
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
    }
  }

  return (
    <NAlert
      title="Tem certeza?"
      open={open}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      titleConfirm={"Aceitar proposta"}
      titleCancel={"deixa pra lá"}
    >
      <span>Ao aceitar essa proposta você não poderá mais edita-la.</span>
    </NAlert>
  );
}
