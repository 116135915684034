import React, { useState } from "react";
import styled from "@emotion/styled";
//import "./BriefingStyle.css";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form";

const FreeInformation = () => {
  const { control, setValue } = useFormContext();
  const { register } = useForm();
  const [textarea, setTextarea] = useState(
  );


  setValue('freeInformation', textarea)

  const handleChange = (event) => {
    setTextarea(event.target.value)
  }

  return (
    <>
      <Wrapper>
        <h3>Etapa Livre</h3>
        <p>Existe detalhes que você precisa esclarecer e não teve oportunidade? Aproveite agora.</p>
        <Controller
          id="freeinformation"
          title="freeinformation"
          name="freeinformation"
          {...register("freeinformation")}
          render={({ field }) => (
            <textarea
              value={textarea}
              onChange={handleChange}
            />
          )}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`

  display: flex;
  flex-direction: column;
  gap: 30px;

  > textarea {
    width: 90%;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 30rem;
    background-color: #E3E3E3;
    font-size: 1.8rem;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    resize: vertical;
    outline: none;
  }

  > textarea:hover {
    outline: none;
    border: 1px solid black;
  }
  > textarea:active, textarea:focus {
    outline: none;
    border: 2px solid #FF9E00;
  }


`

export default FreeInformation;