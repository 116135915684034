import React, { useState } from "react";
import styled from "@emotion/styled";

export const Toggle = ({ title, children, initState = false }) => {
  const [open, setOpen] = useState(initState);

  return (
    <div>
      <ToggleArrow onClick={() => setOpen(!open)} open={open}>
        {title}
      </ToggleArrow>
      <ContentWrapper open={open}>{children}</ContentWrapper>
    </div>
  );
};

const ToggleArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #555;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  color: #414141;

  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-top: 6px solid #555;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    transition: transform 0.2s ease;
    transform-origin: center center;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

const ContentWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
`;
