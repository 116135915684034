import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import SideBarADM from "../SideBarADM";
import { FragmentBriefing } from "./fragments/FragmentBriefing";
import { listBriefingBuild } from "../../../api/admin";
import { DashInput, NRoundedButton } from "../../../shared-components";
import useFilter from "./hooks/useFilter";

const Montagem = () => {
  const { data, isLoading } = useQuery("briefings-builds", listBriefingBuild);
  const {
    handleFilter,
    searchTerm,
    setSearchTerm,
    filteredBriefings,
    filter,
    setFilter,
  } = useFilter(data);

  if (isLoading) {
    return <></>;
  }

  const handleClearFilter = () => {
    setFilter("all");
  };

  return (
    <div className="cont-geral">
      <SideBarADM />
      <div className="btns-solict2">
        <Link to="/solicitacao-orcamento-projetista">
          <button className="promotor-btn1">projeto</button>
        </Link>
        <Link to="/solicitacao-orcamento-montagem">
          <button className="promotor-btn">montagem</button>
        </Link>
        <Link to="/solicitacao-orcamento-projeto&montagem">
          <button className="promotor-btn1">projeto + montagem</button>
        </Link>
        <Link to="/solicitacao-orcamento-todos">
          <button className="promotor-btn1">todos</button>
        </Link>
        <div className="search-input-admin">
          <DashInput
            value={searchTerm}
            placeholder={"Busque por um termo..."}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <NRoundedButton
            title={"aberto"}
            active={filter === "ABERTO"}
            color={"#764FAA"}
            onClick={() => handleFilter("ABERTO")}
          />
          <NRoundedButton
            title={"em negociação"}
            active={filter === "EM_NEGOCIAÇÃO"}
            color={"#764FAA"}
            onClick={() => handleFilter("EM_NEGOCIAÇÃO")}
          />
          <NRoundedButton
            title={"aceito"}
            active={filter === "ACEITO"}
            color={"#764FAA"}
            onClick={() => handleFilter("ACEITO")}
          />
          <NRoundedButton
            title={"mostrar todos"}
            color={"#764FAA"}
            onClick={handleClearFilter}
            active={filter === "all"}
          />
        </div>
      </div>
      <div className="main-cont">
        <div className="cont-charts2">
          {filteredBriefings.map((briefing) => (
            <FragmentBriefing
              key={`${briefing.eventName}-${briefing.id}`}
              briefingData={briefing}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Montagem;
