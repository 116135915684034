import { useState } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { NRoundedButton } from "../../../shared-components";
import Header from "../Header";
import { listAcceptBriefings } from "../../../api/briefing";
import { DialogTerms } from "./fragments";
import { ActionButton } from "./Styles";

function FragmentAccetBriefings({ data, isLoading, error }) {
  const [clickBriefing, setClickBriefing] = useState("");
  const [open, setOpen] = useState(false);

  function handleModal() {
    setOpen(() => !open);
  }
  if (isLoading) {
    return <>carregando...</>;
  }

  if (data) {
    return (
      <>
        <DialogTerms
          title={"Termos de Serviço"}
          open={open}
          handleModal={handleModal}
          briefing={clickBriefing}
        />
        {data.map((briefing, index) => {


          return (
            <div className="solicits-open" key={`${briefing.id}`}>
              <h2 className="company-name">{briefing.eventName}</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <NRoundedButton
                  title="Pagar"
                  active={true}
                  color="#EFA316"
                  onClick={() => {
                    setClickBriefing(briefing);
                    setOpen(true);
                  }}
                />
                <Link to={`/gestor/${briefing.id}`}>
                  <NRoundedButton
                    title="Ir para o gestor"
                    active={true}
                    color="#EFA316"
                  />
                </Link>
              </div>
            </div>
          )
        })}
      </>
    );
  }
}

const SolicitAprovada = () => {
  const { data, isLoading, error } = useQuery(
    "accept-briefings",
    listAcceptBriefings
  );

  return (
    <>
      <Header title="Solicitações em aprovadas" />
      <ActionButton>
        <Link to="/solicit-aberto-expositor">
          <button className="btn-open-mont">solicitação em aberto</button>
        </Link>
        <Link to="/solicit-negociacao-expositor">
          <button className="btn-open-mont">em negociação</button>
        </Link>
        <Link to="/solicit-aprovada">
          <button className="btn-open2">aprovadas</button>
        </Link>
      </ActionButton>
      <ContentWrapper>
        {data && (
          <FragmentAccetBriefings
            data={data}
            isLoading={isLoading}
            error={error}
          />
        )}
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default SolicitAprovada;
