import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../components/privateRoute/PrivateRoutes";
import PromotorLayout from "../layouts/PromotorLayout";
import DashboardPromotor from "../pages/Dashboard/DashboardPromotor";
import CadastroEvento from "../components/DashboardPromotorComponents/cadastroEvento/CadastroEvento";
import CriarEvento from "../components/DashboardPromotorComponents/criarEvento/CriarEvento";
import EditarEvento from "../components/DashboardPromotorComponents/editarEvento/EditarEvento";
import GerenciarEventos from "../components/DashboardPromotorComponents/gerenciarEventos/GerenciarEventos";
import NovoBriefing from "../pages/Briefing/NovoBriefing";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route element={<PrivateRoutes role="PROMOTOR" />} strict key="promotor-page">,
    <Route
      path="/dashboard-promotor"
      element={
        <PromotorLayout>
          <DashboardPromotor />
        </PromotorLayout>
      }
      exact
      strict key="dashboard-promotor"
    ></Route>,
    <Route
      path="/promotor/novo-briefing"
      element={
        <PromotorLayout>
          <NovoBriefing />
        </PromotorLayout>
      }
      exact
      strict key="dashboard-promotor"
    ></Route>,
    <Route
      path="/promotor-cadastro-evento"
      element={
        <PromotorLayout>
          <CadastroEvento />
        </PromotorLayout>
      }
      exact
      strict key="promotor-cadastro-evento"
    ></Route>,
    <Route
      path="/promotor-criar-evento"
      element={
        <PromotorLayout>
          <CriarEvento role={"PROMOTOR"} />
        </PromotorLayout>
      }
      exact
      strict key="promotor-criar-evento"
    >
    </Route>,
    <Route
      path="/promotor-editar-evento/:id"
      element={<PromotorLayout><EditarEvento role={"PROMOTOR"} /></PromotorLayout>}
      exact
      strict key="promotor-editar-evento"
    >
    </Route>,
    <Route
      path="/promotor-gerenciar-eventos"
      element={
        <PromotorLayout>
          <GerenciarEventos role={"PROMOTOR"} />
        </PromotorLayout>
      }
      exact
      strict key="promotor-gerenciar-eventos"
    ></Route>,
  </Route>,
]

