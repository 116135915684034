import React from "react";
import styled from "@emotion/styled";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { formatDateLocale } from "../helpers";

const BriefingListCompleted = ({ briefing }) => {

  return (
    <Wrapper>
      <MiniBox>
        <FinishedBox>
          <DateText>
            <CalendarIcon></CalendarIcon>
            <span>
              Iniciado:
              <DateLine>
                {formatDateLocale(briefing.createdAt)}
              </DateLine>
            </span>
            <span>
              Atualizado:
              <DateLine>
                {formatDateLocale(briefing.updatedAt)}
              </DateLine>
            </span>
            <MoreIcon></MoreIcon>
          </DateText>
          <Text>{briefing.Briefing.eventName}</Text>
        </FinishedBox>
        <Check>
          <CircleIconWrapper>
            <FactCheckOutIcon />
          </CircleIconWrapper>
          <Info>
            <h4>Concluído</h4>
            <h6>Etapa <strong> {briefing.step} </strong> de <strong> 8 </strong> </h6>
          </Info>
        </Check>
        <StyledLinearProgress variant="determinate" value={(briefing.step / 8) * 100}></StyledLinearProgress>
      </MiniBox>
    </Wrapper>
  );
};

const DateLine = styled.div`
  margin: 1px;
`;
const Wrapper = styled.div`
  display:flex;
  flex-wrap: wrap;
  height: 60vh; 
`;
const MiniBox = styled.div`
background-color: white;
margin: 40px;
width: 290px;
height: 350px;
border-radius: 20px;
box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
position: relative;
`;
const DateText = styled.div`
display:flex;
justify-content: space-around;
padding: 10px;
left: 10px;
font-size: 11px;
color: white;
gap: 5px;

`;
const CalendarIcon = styled(CalendarMonthOutlinedIcon)`
 color: white;
 font-size: 20px;
 left: 35%;
 top: 10px;
`;
const MoreIcon = styled(MoreHorizIcon)`
  font-size: 20px;   
`;
const CircleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 1px solid #ddd;
  box-sizing: box;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 3, 0.4);
  margin-bottom: 18px;
`;
const Info = styled.div`
 margin-left:15px;
 width: 70%;
`;
const FinishedBox = styled.div`
  background-color: #1997d6;
  top: 0;
  left: 0;
  width: 100%;
  height: 180px; 
  color: white;
  border-radius: 20px 20px 20px 20px;
`;
const Check = styled.div`
 display:flex;
 margin: 25px;
`
const Text = styled.div`
 display:flex;
 justify-content: center;
 margin-top: 20px;
 margin-left: 10px;
 color: white;
 font-weight: 500;
 font-size: 27px;
`;
const FactCheckOutIcon = styled(FactCheckOutlinedIcon)`
  color: #1997d6;
  font-size: 30px;
`
const StyledLinearProgress = styled(LinearProgress)`
  width: 90%;
  height: 8px;
  border-radius: 10px;
  margin-left:2%;

  .MuiLinearProgress-bar {
    background-color: green;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #ccc;
  }

  .MuiLinearProgress-colorSecondary {
    background-color: #ccc;
  }
  
`;
export default BriefingListCompleted;