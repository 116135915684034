import styled from "@emotion/styled";
import { useState } from "react";
import { useQuery } from 'react-query';
import { useParams } from "react-router-dom";
import { FragmentBriefing, FragmentBuilder, FragmentCauses, FragmentDateTime, FragmentDescription, FragmentImage, FragmentLocation, FragmentTickets, FragmentType, FragmentVisibility } from "../fragments";
import { getEventById, updateEvent } from "../../../api/promoter";
import { NRoundedButton } from "../../../shared-components/NRoundedButton";
import { useSimpleForm } from "../../../hooks/useSimpleForm";
import Header from "../Header";
import { useNotification } from "../../../context/NotificationContext";
import Loading from "../../../shared-components/Loading";
import { COLORS } from "../../../helpers/types";

const FragmnetEdit = ({ role, id, data }) => {
  const [modalBriefing, setModalBriefing] = useState(false);
  const { stateForm, handleChange, setStateForm } = useSimpleForm(data);
  const notification = useNotification();

  if (!stateForm.selectedCauseList) {
    const eventCauses = data.EventsCauses.map((cause) => cause.causesId);
    eventCauses ?
      setStateForm((prevState) => ({
        ...prevState,
        selectedCauseList: eventCauses,
      }))
      :
      setStateForm((prevState) => ({
        ...prevState,
        selectedCauseList: [],
      }))
  }

  const handleOpenCloseBriefing = () => {
    setModalBriefing(modalBriefing ? false : true);
  }

  const handleChipSelectedCauses = (chip) => {
    if (stateForm.selectedCauseList && stateForm.selectedCauseList.includes(chip)) {
      let filteredList = stateForm.selectedCauseList.filter((selectedChip) => selectedChip !== chip);
      setStateForm({
        ...stateForm,
        selectedCauseList: filteredList,
      });
    } else {
      setStateForm({
        ...stateForm,
        selectedCauseList: stateForm.selectedCauseList ? [...stateForm.selectedCauseList, chip] : [chip],
      });
    }
  };

  const handleChipTicket = (type) => {
    if (stateForm.ticketType === type) {
      setStateForm({
        ...stateForm,
        ticketType: "",
      });
    } else {
      setStateForm({
        ...stateForm,
        ticketType: type,
      });
    }
  };

  const handleVisibilityChange = (event) => {
    setStateForm({
      ...stateForm,
      visibility: event.target.value
    });
  };

  function handleSubmit(event) {
    event.preventDefault();

    // ARRUMAR API (NÃO ATUALIZA OS DADOS SE NÃO TIVER ESSES DADOS, COM VALORES, ABAIXO)

    const updateStateForm = stateForm;
    updateStateForm.externalLink = "";
    updateStateForm.price = 0;
    updateStateForm.smallImage = stateForm.largeImage;

    updateEvent(id, updateStateForm)
      .then(res => {
        notification.show({ message: 'Evento atualizado com sucesso.' })
      })
      .catch(err => {
        notification.show({ message: 'Não foi possível atualizar o evento.', type: "error" })
      })
  }

  return (
    <>
      <Header role={role} title="Vamos atualizar o seu evento" />

      <WrapperForm role={role} onSubmit={handleSubmit}>
        <FragmentBriefing role={role} stateForm={stateForm} isOpen={modalBriefing} handleOpenCloseBriefing={handleOpenCloseBriefing} />
        <FragmentLocation role={role} handleChange={handleChange} stateForm={stateForm} setStateForm={setStateForm} />
        <FragmentImage handleChange={handleChange} stateForm={stateForm} />
        <FragmentType handleChange={handleChange} stateForm={stateForm} />
        <FragmentDescription handleChange={handleChange} stateForm={stateForm} />
        <FragmentBuilder handleChange={handleChange} stateForm={stateForm} />
        <FragmentCauses role={role} handleChange={handleChange} stateForm={stateForm} handleChipSelectedCauses={handleChipSelectedCauses} />
        <FragmentDateTime handleChange={handleChange} stateForm={stateForm} />
        <FragmentTickets role={role} handleChange={handleChange} stateForm={stateForm} handleChipTicket={handleChipTicket} />
        <FragmentVisibility role={role} handleChange={handleChange} stateForm={stateForm} handleVisibilityChange={handleVisibilityChange} />

        <ActionForm>
          <NRoundedButton
            title={"Editar evento"}
            color={COLORS[role]}
            active={true}
            type="submit"
          />
        </ActionForm>
      </WrapperForm>
    </>
  );
}

export default function EditarEvento({ role }) {
  const { id } = useParams();
  const { data, isLoading } = useQuery('event', () => getEventById(id));

  if (isLoading) {
    return <Loading role={role} />
  }

  return (
    <>
      <FragmnetEdit role={role} id={id} data={data} />
    </>
  );
}

const WrapperForm = styled.form`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  border-radius: 0.375rem;
  padding: 30px;

  span {
    font-size: 2rem;
    font-weight: 800;
  }

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }

  @media screen and (max-width: 898px) {
    flex-direction: column;

    div {
      flex-direction: column;
    }
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:  ${props => COLORS[props.role]};
  }
`;

const ActionForm = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  height: 10vh;
`;
