import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  DashInput,
  NRoundedButton,
} from "../../../shared-components"
import { useSimpleForm } from "../../../hooks";
import { CardPersonalInfo } from "./CardPersonalInfo";
import {
  Row,
  Wrapper,
  Actions,
  Content
} from "./StyledComponents";
import { updateProfileInfo, getProfileInfo } from "../../../api/user";
import { useNotification } from "../../../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";

export function FragmentEnterpriseForm({ color }) {

  const { data, isLoading } = useQuery('profile-data', getProfileInfo);
  const notification = useNotification();
  const navigate = useNavigate();
  const { userData, refetchUser } = useUser();

  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    createDate: "",
    owner: "",
    // street: "",
    // number: "",
    // neighborhood: "",
    complement: "",
    cep: "",
    city: "",
    state: ""
  });


  useEffect(() => {
    setStateForm(data);
  }, [data])

  function verifyRequiredInputs(form) {
    let verified = true
    const required = [
      "cnpj",
      "razaoSocial",
      "street",
      "cep",
      "city",
      "state"
    ]
    required.forEach(input => {
      if (!form[input]) {
        verified = false
      }
    })
    return verified;
  }

  const isAcceptedTerms = user => user.Approval?.terms && user.Approval.terms.status === "approved";

  const isFilledPersonForm = data => (
    !!data.firstName &&
    !!data.lastName &&
    !!data.document
  )

  function submitForm() {
    const verifiedRequiredInputs = verifyRequiredInputs(stateForm);
    if (verifiedRequiredInputs) {
      updateProfileInfo({ profileInfo: stateForm })
        .then(res => {
          notification.show({
            message: 'Dados atualizados'
          })
          if (isAcceptedTerms(data.User) && isFilledPersonForm(data)) {
            navigate(`/${userData?.role?.name?.toLowerCase()}/lista`)
          } else {
            navigate(`/${userData?.role?.name?.toLowerCase()}/termos-de-uso`)
          }
        })
        .catch(err => {
          notification.show({
            message: 'Não foi possível atualizar os dados',
            type: 'error'
          })
        })
    }
  }


  return (
    <Wrapper>
      <Content>
        <div>
          <Row>
            <DashInput
              width="100%"
              title="CNPJ"
              name="cnpj"
              value={stateForm?.cnpj}
              onChange={handleChange}
              required
              error={!stateForm?.cnpj}
            />
            <DashInput
              width="100%"
              title="Razão Social"
              name="razaoSocial"
              value={stateForm?.razaoSocial}
              onChange={handleChange}
              required
              error={!stateForm?.razaoSocial}
            />
          </Row>
          <Row>
            <DashInput
              width="100%"
              title="Nome Fantasia"
              name="nomeFantasia"
              value={stateForm?.nomeFantasia}
              onChange={handleChange}
            />
            <DashInput
              width="100%"
              title="Responsável"
              name="owner"
              value={stateForm?.owner}
              onChange={handleChange}
            />
            <DashInput
              width="100%"
              title="Data Abertura"
              name="createDate"
              type="date"
              value={stateForm?.createDate}
              onChange={handleChange}
            />
          </Row>
          <Row>
            <div className="div-line2">
              <p className="address-line">Endereço</p>
              <div className="line"></div>
            </div>
          </Row>
          <Row>
            <DashInput
              width="100%"
              title="Rua"
              name="street"
              value={stateForm?.street}
              onChange={handleChange}
              required
              error={!stateForm?.street}
            />
          </Row>
          <Row>
            <DashInput
              width="100%"
              title="Número"
              name="number"
              value={stateForm?.number}
              onChange={handleChange}
              required
              error={!stateForm?.number}
              type={`number`}
            />
            <DashInput
              width="100%"
              title="Bairro"
              name="neighborhood"
              value={stateForm?.neighborhood}
              onChange={handleChange}
              required
              error={!stateForm?.neighborhood}
            />
            <DashInput
              width="100%"
              title="Complemento"
              name="complement"
              value={stateForm?.complement}
              onChange={handleChange}
            />
          </Row>
          <Row>
            <DashInput
              width="100%"
              title="CEP"
              name="cep"
              value={stateForm?.cep}
              onChange={handleChange}
              required
              error={!stateForm?.cep}
            />
            <DashInput
              width="100%"
              title="Cidade"
              name="city"
              value={stateForm?.city}
              onChange={handleChange}
              required
              error={!stateForm?.city}
            />
            <DashInput
              width="100%"
              title="Estado"
              name="state"
              value={stateForm?.state}
              onChange={handleChange}
              required
              error={!stateForm?.state}
            />
          </Row>
        </div>
        <div>
          <CardPersonalInfo />
        </div>
      </Content>
      <Actions>
        <NRoundedButton
          title={"Cancelar"}
        />
        <NRoundedButton
          title={"Gravar"}
          color={color}
          active={true}
          onClick={submitForm}
        />
      </Actions>
    </Wrapper >
  )
}

