import styled from "@emotion/styled";
import { NDialog } from "../../../../shared-components";
import { chooseReference } from "../../../../api/briefing";
import { useNotification } from "../../../../context/NotificationContext";
import { useState } from "react";
import { generateImageLink } from "../../../../helpers";

export function ModalChoseReferences({
  open,
  setOpen,
  imagesReferences,
  setImagesReferences,
  briefingRequestId,
  refetch
}) {

  const [referenceSeleted, setReferenceSeleted] = useState();
  const notification = useNotification();
  function handleConfirm() {
    chooseReference({
      requestId: briefingRequestId,
      referenceId: referenceSeleted,
    }).then(res => {
      notification.show({ message: "Referência atualizada! Essa modificação pode demorar alguns segundos" })
      refetch();
    })
    setOpen(false)
  }

  function handleClick({ target: { id } }) {
    const selected = imagesReferences.map(value => {
      if (value.id === Number(id)) {
        return ({
          ...value,
          selected: true,
        })
      }
      return ({
        ...value,
        selected: false,
      })
    })
    setReferenceSeleted(id);
    setImagesReferences(selected);
  }

  return (
    <NDialog
      open={open}
      handleClose={() => setOpen(false)}
      handleConfirm={handleConfirm}
      title="Esolha uma referência"
      maxWidth={'lg'}
    >
      <ModalReferenceContent>
        {imagesReferences.map((reference, index) => {
          if (reference.type === "link") {
            return ''
          }
          const referenceImage = generateImageLink(reference)
          return (
            <span key={`reference-span-${index}`} className={reference.selected ? 'select' : ''}>
              <img
                key={`reference-image-${index}`}
                id={reference.id}
                src={referenceImage.image}
                alt={`reference-${reference.id}`}

                onClick={handleClick}
              />
              {<span>{index + 1}</span>}
            </span>
          )
        })}
      </ModalReferenceContent>
    </NDialog>
  )
}


const ModalReferenceContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > span {
    position: relative;
  }

  > span span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
  }

  > span.select > span {
    background-color: #FF8400;
  }

  > span img {
    width: 240px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
    transition: all linear 200ms;
     border-radius: 6px;
  }

  > span img:hover {
    filter: brightness(80%);
  }

  .select {
    border: 2px solid #FF8400;
    border-radius: 6px;
  }
`