import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import React from "react";
import "../StyleProjeto&Montagem.css";
import { formatCurrencyDecimal } from "../../../../helpers";

const CardPaymentFinal = ({ price }) => {
  return (
    <div className="">
      <div className="options-profile" style={{ backgroundColor: "#EFEEEE" }}>
        <AddCardOutlinedIcon
          className="icons"
          style={{ width: "30px", height: "31px" }}
        />
        <h2 className="title-option">Pagamento do projeto</h2>
        <p className="text-option">
          Aqui você irá efetuar o pagamento total do projeto. <strong>{price}</strong>
        </p>
      </div>
    </div>
  );
};

export default CardPaymentFinal;
