import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { COLORS } from "../../../helpers/types";

export default function FragmentResponsibility({ role, stateForm, handleResponsibilityChange }) {
  return (
    <>
      <span>Responsabilidade</span>
      <FormControlLabel
        style={{ width: "90%" }}
        value={stateForm.responsibility}
        control={
          <Checkbox
            checked={stateForm.responsibility}
            onChange={handleResponsibilityChange}
            required
            style={{
              color: COLORS[role],
            }}
          />}
        label={
          <Typography style={{ fontSize: '15px' }}>
            Ao publicar este evento, estou de acordo com os Termos de uso, com as Diretrizes de Comunidade e com o
            Acordo de Processamento de Dados, bem como declaro estar ciente da Política de Privacidade, da Política de
            Cookies e das Obrigatoriedades Legais.
          </Typography>
        }
      />
    </>
  );
}
