import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { forwardRef } from "react";
import { TextField } from "@mui/material";

const DashInput = forwardRef((props, ref) => {
  const {
    title,
    placeholder,
    variant,
    min = 0,
    max,
    width,
    borderless,
    background,
    required,
    ...otherProps
  } = props;

  return (
    <>
      {variant === "standard" ? (
        <ComponentStandard
          ref={ref}
          InputProps={{ inputProps: { min, max } }}
          {...otherProps}
          placeholder={placeholder}
          variant="standard"
        />
      ) : (
        <Wrapper width={width} error={otherProps.error}>
          {title ? <p>{`${title} ${required ? '*' : ''}`}</p> : ""}
          <Component
            ref={ref}
            width={width}
            borderless={borderless}
            background={background}
            InputProps={{ inputProps: { min, max } }}
            {...otherProps}
            placeholder={placeholder}
          />
        </Wrapper>
      )}
    </>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: ${({ width }) => (width ? width : "parent")};

  > p {
    ${({ error }) => (error ? "color: #de6a6a;" : "")}
    margin: 2px;
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const ComponentStandard = styled(TextField)`
  & input {
    font-size: 1.6rem;
  }
`;

const Component = styled(TextField)`
  width: 100%;

  border: none;
  outline: none;

  ${({ borderless }) => (borderless ? "& fieldset { border: none };" : "")}

  & input {
    height: 10px;
    width: 100%;
    border-radius: 6px;
    font-size: 1.6rem;
    padding-left: 0px;
    padding-right: 0px;
  }

  & input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  & input[type=number] {
    -moz-appearance: textfield;
  }

  & .MuiInputBase-root.MuiOutlinedInput-root {
    border: none;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    background-color: ${({ background }) =>
    background ? background : "#F3F3F3"};
  }

  input::placeholder {
    font-size: 1.6rem;
    text-align: left;
  }

  /* Firefox */
  input[type="number"] {
    font-size: 1.6rem;
    text-align: left;
  }

  @media screen and (max-width: 898px) {
    /* input[type="date"] {
      width: calc(80% - 30px);
      
    } */

    & .MuiOutlinedInput-input {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;
export { DashInput };
