import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import { NTextField } from "../../shared-components";

const BuffetInformation = () => {
  const { control } = useFormContext();
  const { register } = useForm();
  const [textarea, setTextarea] = useState();

  const handleChange = (event) => {
    setTextarea(event.target.value)
  }
  return (
    <>
      <Wrapper>
        <div>
          <h3>Promoter & Buffet</h3>
          <Content>
            <div>
              <Controller
                control={control}
                name="promoter"
                render={({ field }) => (
                  <NTextField
                    id="promoter"
                    title="Promoter"
                    name="promoter"
                    {...register("promoter")}
                    variant="outlined"
                    placeholder="0"
                    {...field}
                    type="number"

                  />
                )}
              />
            </div>
            <Form>
              <p>Buffet</p>
              <Controller
                id="buffet"
                title="buffet"
                name="buffet"
                {...register("buffet")}
                render={({ field }) => (
                  <textarea value={textarea} onChange={handleChange} {...field} />
                )}

              />


            </Form>
          </Content>
        </div>
      </Wrapper>

    </>
  );
};

const Form = styled.div`

  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 60%;

  > textarea {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 100px;
    background-color: #E3E3E3;
    font-size: 1.8rem;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    resize: none;
    outline: none;
  }

  > textarea:hover {
    outline: none;
    border: 1px solid black;
  }
  > textarea:active, textarea:focus {
    outline: none;
    border: 2px solid #FF9E00;
  }


`

const Wrapper = styled.div`
  
  >div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

`

const Content = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;
  gap: 20px;

  > div:first-of-type {
    flex: 1;
  }
  form {
    flex: 2;
  }

  textarea {
    width: 100%;
  }
  

`


export default BuffetInformation;