import { useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate, Outlet } from "react-router-dom";
import { checkRole } from "../../api/login";
import { useUser } from "../../context/UserContext";

const PrivateRoutes = ({ children, role }) => {

  const { data, isLoading, error, refetch } = useQuery(
    'account',
    checkRole,
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );
  const { userData, saveUserData } = useUser();

  useEffect(() => {
    saveUserData(data);
  }, [data])

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (error) {
    return <Navigate to="/login" replace />
  }

  if (data && (data.role.name === role || data?.role?.name?.split("_")[0] === role)) {
    return (<Outlet />);
  }

}

export default PrivateRoutes;