import axios from "axios";
import { getUserData, privateFetch } from "./user";

const API = process.env.REACT_APP_API;

export async function saveBriefing(data) {
  const briefing = getIncompleteBriefing();
  if (briefing) {
    return updateBriefing({ ...data, briefingId: briefing.briefingId });
  }

  return createBriefing(data);
}

export async function getBriefing(briefingId) {
  const token = privateFetch();

  if (!briefingId) return;
  const res = await fetch(`${API}/briefing/${briefingId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}
export async function updateBriefingTaxa(briefingId, taxaValue) {
  const token = await privateFetch();

  const url = `${API}/briefing-tax/${briefingId}`;

  const response = await axios.put(url, { value: taxaValue }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return response.data;
}


export async function getBriefingRequest(briefingId) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/request`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function createBriefing({
  eventName,
  eventId,
  city,
  briefingTypeName,
  paymentTypeName,
  standTypeName,
  priceMin,
  priceMax,
  length,
  depth,
  deadline,
  observations,
  lastStand1,
  lastStand2,
  lastStand3,
  structs,
  elements,
  multimedias,
  promoter,
  buffet,
  BriefingElements,
  projects,
}) {
  const token = privateFetch();

  const data = {
    eventName,
    eventId,
    city,
    briefingTypeName,
    paymentTypeName,
    standTypeName,
    priceMin,
    priceMax,
    length,
    depth,
    deadline,
    observations,
    lastStand1,
    lastStand2,
    lastStand3,
    structs,
    elements,
    multimedias,
    promoter,
    buffet,
    BriefingElements,
    projects,
  };

  try {
    const res = await axios.post(`${API}/briefing`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    saveIncompleteBriefing({
      briefingId: res.data.id,
      briefingData: data,
    });

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateBriefing({
  briefingId,
  eventName,
  eventId,
  city,
  briefingTypeName,
  paymentTypeName,
  standTypeName,
  priceMin,
  priceMax,
  length,
  depth,
  deadline,
  observations,
  structs,
  elements,
  multimedias,
  references,
  promoter,
  buffet,
  lastStands,
  blueprint,
  blueprint_observation,
  projects,
  freeInformation,
  projectsArte,
}) {
  const token = privateFetch();
  return axios.put(
    `${API}/briefing/${briefingId}`,
    {
      eventName,
      eventId,
      city,
      briefingTypeName,
      paymentTypeName,
      standTypeName,
      priceMin: Number(priceMin) || undefined,
      priceMax: Number(priceMax) || undefined,
      length,
      depth,
      deadline,
      observations,
      structs,
      elements,
      multimedias,
      references,
      promoter,
      buffet,
      lastStands,
      blueprint,
      blueprint_observation,
      projects,
      freeInformation: observations,
      projectsArte,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

//TODO: São todos
export async function listOpenBriefings() {
  const token = privateFetch();

  const res = await fetch(`${API}/briefings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

//TODO: Apenas os briefings abertos
export async function listBriefingsOpen() {
  const token = privateFetch();

  const res = await fetch(`${API}/briefings/open`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

//TODO: Apenas os briefings aceitos
export async function listBriefingsAccept() {
  const token = privateFetch();

  const res = await fetch(`${API}/briefings/accept`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function listStandImages() {
  const res = await fetch(`${API}/briefing/stands-images`, {
    method: "GET",
  });

  return res.json();
}

export async function listRequestBriefings() {
  const token = privateFetch();
  const res = await fetch(`${API}/briefings/request`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function listAcceptBriefings() {
  const token = privateFetch();

  const res = await fetch(`${API}/briefings/accept`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

function saveIncompleteBriefing({ briefingId, briefingData }) {
  if (briefingId) {
    sessionStorage.setItem(
      "nossostand-briefing",
      JSON.stringify({
        briefingId,
        briefingData,
      })
    );
  }
}

export function getIncompleteBriefing() {
  const briefing = sessionStorage.getItem("nossostand-briefing");
  if (!briefing) {
    return false;
  }

  return JSON.parse(briefing);
}

export async function makeBudgetForReferences({
  requestId,
  budgets,
  projects,
}) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${requestId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      budgets,
      projects,
    }),
  });

  return res.json();
}

export async function makeBudgetForOpenBriefing({ briefingId, budgets, proposal }) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/make-budget`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      budgets,
      proposal,
    })
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json();
  throw error;
}

export async function chooseReference({ requestId, referenceId }) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${requestId}/choose-reference`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      referenceId: Number(referenceId),
    }),
  });

  return res.json();
}

export async function makeProposal({
  requestId,
  finalPriceMin,
  finalPriceMax,
  deliveryAt,
  projects,
  observation,
  finalPrice,
}) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${requestId}/make-proposal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      finalPriceMin: Number(finalPriceMin),
      finalPriceMax: Number(finalPriceMax),
      deliveryAt,
      projects,
      observation,
      finalPrice: Number(finalPrice),
    })
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json();
  throw error;
}

export async function acceptProposal(requestId) {
  const token = privateFetch();
  const res = await fetch(`${API}/briefing/${requestId}/accept`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function updateBriefingTodo(briefingId, todoData, type, projects) {
  const token = privateFetch();

  try {
    const res = await axios.put(
      `${API}/briefing/${briefingId}/todo`,
      {
        todo: todoData,
        type,
        projects,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getBriefingTodo(briefingId) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}


export async function rejectBriefing(briefingId) {
  const token = privateFetch();

  const res = await fetch(`${API}/briefing/${briefingId}/reject`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (res.ok) {
    return res.json();
  }
  const error = await res.json();
  throw error;
}
