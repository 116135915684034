import styled from 'styled-components';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { COLORS } from '../../helpers/types';

const IconStyles = `
  width: 28px !important;
  height: 28px !important;
  color: #e9e9e9;
  margin: 20px 0;
  transition: all 0.3s;

  &:hover {
    cursor: pointer; 
    color: black;
    border-left: ${props => props.open ? '' : '3px solid orange'};
  }
`;

export const HamburgerMenu = styled.div`
  width: ${props => props.open ? '300px' : '0px'};
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0rem;
  left: 0px;
  z-index:20;
  animation: showSidebar .4s;
`;
export const MenuMobile = styled(MenuIcon)`
  width: 40px !important;
  height: 40px !important;
  color: black;
  display: flex;
  align-items: center;
  margin: 20px 0;
`;
export const ContainerMobile = styled.div`
  background-color: white;
  height: 13.2rem;
  display: flex;
  align-items: center;
  position: relative;
`;
export const Container1 = styled.div`
  background-color: #F5F5F9; 
  display: flex;
`;
export const SideBar = styled.div`
  width: ${props => props.open ? '210px' : '90px'};
  height: 100vh;
  transition: width 0.3s ease;
  background-color: white; 
  padding: 0px;
  transition: all 0.3s;
  position: fixed;
  z-index: 20;

`;
export const LogoContainer = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
`;

export const LogoImage = styled.div`
  background-image: url(img/favicon_32x32.png);
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(161 172 184 / 25%);
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  margin: 15px 0;
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  padding: 0px;
  margin-top: 40px;
`;
export const IconsAndName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.open ? 'margin-left: 30px' : ''};

  &:hover{
    color: black;
  }
`;

export const IconNameWrapper = styled.div`
  position: relative;
  display: ${props => props.open ? 'flex' : 'inline-block'};
  width: ${props => props.open ? '100%' : '90px'};
  ${props => props.open ? 'align-items: center' : ''};

${props => (props.isSelected
    ? ` & * {
          color: black;
        }
      `
    : '')}

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 100%;
      border-radius: 100vh;
      left: 0;
      bottom: 0;
      background-color: #2ada31;
      visibility: ${props => props.isSelected ? 'visible' : 'hidden'};
    }
    
    &:hover::before {
      visibility: visible;
      margin: 0px!important;    
    }

    &:hover{
      & * {
        color: black;
      }
    }
`;

export const IconNameWrapperDisabled = styled.div`
display: flex;
width: ${props => props.open ? '0px' : '90px'};
align-items: center;

&:hover {
    cursor: not-allowed;
    border-radius: 0.4rem;
    color: #e9e9e9;
  }
`;
export const Name = styled.p`
 width: 100%;
 color: ${props => props.isSelected ? '#000000' : '#ccc'};
`;
export const ArrowIcon = styled(ArrowBackIosOutlinedIcon)`
  cursor: pointer;
  background-color: ${COLORS.MONTADOR};
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  color: white;
  position: absolute;
  right: -10px;
  transform: rotate(${props => (props.Open ? '0deg' : '180deg')});
  transition: all 2s linear;
  padding: 3px;
`;
export const Button = styled.button`
 width:120px !important;
 height: 25px;
 background-color: #FF8400;
 color: white;
 font-weight: 600;
 border-radius: 10px;
 border-color: orange;
 font-size: 12px;
 margin-right: 15px;
 
 &:hover {
  transform: scale(1.2);
}
@media (max-width: 768px) {
  margin-top: 10px;
  }
`;

export const HomeIcon = styled(HomeOutlinedIcon)`
  width: 30px !important;
  height:30px !important;
  color: #e9e9e9;
  margin: 20px 0; 
  
  &:hover {
    color: black;
  }
`;

export const RequestQuoteIcon = styled(RequestQuoteOutlinedIcon)`
  width: 30px !important;
  height:30px !important;
  color: #e9e9e9;
  margin: 20px 0; 
  
  &:hover {
    color: black;
  }
`;

export const ManageAccountsIcon = styled(ManageAccountsOutlinedIcon)`
  width: 30px !important;
  height:30px !important;
  color: #e9e9e9;
  margin: 20px 0; 
  
  &:hover {
    color: black;
  }
`;

export const PlaylistIcon = styled(PlaylistAddIcon)`
  width: 30px !important;
  height:30px !important;
  color: #e9e9e9;
  margin: 20px 0; 
  
  &:hover {
    color: black;
  }
`;
export const BoxIcon = styled(AddBoxOutlinedIcon)`
 ${IconStyles}
`;
export const ListIcon = styled(MenuIcon)`
   ${IconStyles}
  
`;
export const PhotoIcon = styled(PhotoOutlinedIcon)`
 ${IconStyles}
`;
export const PaidIcon = styled(PaidOutlinedIcon)`
  ${IconStyles}
 `;
export const ScheduleIcon = styled(ScheduleOutlinedIcon)`
   ${IconStyles}
    
   &:hover {
    cursor: not-allowed;
    color: #e9e9e9;
  }
`;
export const FeedIcon = styled(FeedOutlinedIcon)`
   ${IconStyles}
  
  &:hover {
    cursor: not-allowed;
    color: #e9e9e9;
  }
`;
export const ContainerHeader = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 5px;
  padding: 0;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-top: 0px;
  margin-left:calc(3rem + 9rem);
  height: 128px;
  padding: 20px;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 3px;
  }
 `;
export const Header = styled.div`
  display: flex;
  alignItems: center;
  height: 100%;
  justify-content: space-between;
 
 @media (max-width: 768px) {
    display: flex; /* alteração para display block */
    align-items: center;
    padding: 0;
  }
`;
export const Section = styled.div`
 display:flex;
 align-items:center;
 justify-content: space-between;

 @media (max-width: 768px) {
    flex-direction: column;
}
`;
export const BoxHeader = styled.div`
 display:flex;
 align-items:center;
 
 @media (max-width: 768px) {
  padding: 4px;
}
 `;
export const UserAvatar = styled(Avatar)`
    border-radius: 20%;
`;
export const Text = styled.h3`
 padding: 20px;
`;
export const NotificationsIcon = styled(NotificationsNoneOutlinedIcon)`
  width: 22px !important;
  height: 22px !important;
  margin-right:10px;
  margin-left:10px;

  &:hover {
  cursor: not-allowed;
 }
`;
export const ArchiveIcon = styled(ArchiveOutlinedIcon)`
  width: 22px !important;
  height: 22px !important;
`;
export const IconClose = styled(CloseIcon)`
  color: black;
  width: 28px !important;
  height: 28px !important;
  margin: 1rem;

`;
export const Box = styled.div`
 display:flex;
 flex-direction:column;
 gap: 10px;
`;
