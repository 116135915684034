import React, { useState } from "react";
import styled from "@emotion/styled";
//import "./BriefingStyle.css";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import UploadFile from "../../shared-components/UploadFile";
import { NTextField } from "../../shared-components";

const Blueprint = () => {
  const { control, setValue } = useFormContext();
  const { register } = useForm();
  const [blueprint, setBlueprint] = useState(null);
  const [blueprintUrl, setBlueprintUrl] = useState(null);


  setValue('blueprint', blueprint)
  const handleRemoveFile = (event) => {
    setBlueprint(null)
  }

  const handleGetFile = (file, result) => {

    const type = file.target.files[0].type.split("/")[1]
    setBlueprint({
      type: 'upload',
      image: `${result.key}.${type}`,
      id: file.target.id,
    })
  }

  return (
    <Wrapper>
      <h3>Planta Baixa</h3>
      <p>Faça upload ou link da planta baixa</p>
      <Content>
        <div>
          <UploadFile
            id="blueprint"
            getFile={handleGetFile}
            deleteFile={(e) => handleRemoveFile(e)}
          />
        </div>
        <div>
          <NTextField
            placeholder={"Ou deixe um link"}
            fullWidth

            onChange={(e) => {
              setBlueprintUrl({
                type: 'url',
                image: e.target.value,
              })
              setValue('blueprintUrl', {
                type: 'url',
                image: e.target.value,
              })
            }}
          />
        </div>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  >div {
    flex: 1;
    gap: 30px;
    display: flex;
    flex-direction: column;
   
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
`

export default Blueprint;