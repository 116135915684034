import {
  Wrapper,
} from "./Styles";
import DoacaoListExpositor from '../components/DoacaoListExpositor';

const Doacao = () => {

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Doações</h2>
        <DoacaoListExpositor />
      </div >
    </Wrapper >
  );
};



export { Doacao }