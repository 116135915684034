import { CircularProgress } from '@mui/material';
import { COLORS } from '../helpers/types';
import { useUser } from '../context/UserContext';

export default function Loading({ role = 'EXPOSITOR' }) {

  const { userData } = useUser();

  const roleData = userData?.role?.name || role;

  return (
    <div
      style={{
        backdropFilter: 'blur(10px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10%',
      }}
    >
      <CircularProgress
        size={"6rem"}
        style={{
          color: roleData ? COLORS[roleData] : '#a3a1a1',
        }}
      />
    </div>
  )
}