import styled from "@emotion/styled"
import SideBarExpo from "../components/SidebarHeader/sideBarExpoNovo";
export default function ExpositorLayoutNovo({ children }) {

  return (
    <Wrapper>
     <SideBarExpo/>
      <main>
        {children}
      </main>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #F5F5F9;

  & > main {
    background-color: #F5F5F9;
    margin-left: 90px;
    width: calc(100% - 90px);
    padding: 20px;
  }

  @media only screen and (max-width: 770px) {

   & > main {
     margin: 0;
     width: 100%;
   }
  }
`;