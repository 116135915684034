import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ExpoLogo from "../../../images/Frame-123.png";
import axios from "axios";
import { useParams } from "react-router-dom";

const UserSolicitInfo = ({ id }) => {
  const [userInfo, setUserInfo] = useState(null);
  const params = useParams();

  useEffect(() => {
    axios
      .get(`nossostand-dev.herokuapp.com/api/user/profile/`)
      .then((response) => setUserInfo(response.data))
      .catch((error) => console.log(error));
  }, [id]);

  return (
    <Wrapper>
      {userInfo ? (
        <div className="card-info">
          <div className="profile">
            <span>
              <PersonIcon style={{ color: "black" }} />
              <p style={{ color: "black", fontWeight: "bold" }}>Perfil</p>
            </span>
            <h3>{userInfo.name}</h3>
          </div>
          <Stack>
            <Avatar
              alt="Remy Sharp"
              src={ExpoLogo}
              sx={{ width: 80, height: 80 }}
            />
          </Stack>
        </div>
      ) : (
        <p>Carregando dados...</p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 60%;
    height: 300px;
    border-radius: 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    margin: 10px 0;
    padding: 20px 50px;
    gap: 20px;

    .profile {
      > span {
        display: flex;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .card-info {
      width: 100%;
      flex-direction: column-reverse;
    }

    .profile {
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
export default UserSolicitInfo;
