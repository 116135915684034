import { useSimpleForm } from "../../../hooks/useSimpleForm";
import styled from "@emotion/styled";
import EditBriefing from "../editarBriefing/EditBriefing";
import { updateBriefing } from "../../../api/briefing";
import { useNotification } from "../../../context/NotificationContext";
import { Link } from "react-router-dom";
import { Column, SubTitle } from "./Styles";
import { formatDateLocale } from '../../../helpers/formatDate';
import { useUser } from "../../../context/UserContext";


function renderMemorialStatus(data) {

  if (data.Approval[0]?.status === 'approved') {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial aprovado pelo expositor
      </span>
    )
  }
  if (data.Approval[0]?.status === 'disapproved') {

    return (
      <div>
        <span style={{ fontWeight: '500' }}>
          Memorial rejeitado pelo expositor
        </span>
        <div>
          <span style={{ fontWeight: '500' }}>Motivo: </span> {data.Approval[0].comment}
        </div>

      </div>
    )
  }
  if (data.Memorial.length === 0) {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial ainda não foi preenchido
      </span>
    )
  }
  if (data.Memorial.length !== 0) {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial preenchido porém o expositor ainda não aprovou
      </span>
    )
  }
}

export default function FragmentBriefingOpen({ briefingData, ...otherProps }) {
  const { userData } = useUser();
  const notification = useNotification();
  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    BriefingStructs: briefingData?.BriefingStructs || [],
    BriefingElements: briefingData?.BriefingElements || [],
    BriefingMultimedia: briefingData?.BriefingMultimedia || [],
    paymentTypeName: briefingData?.BriefingPaymentType?.name,
    BriefingProjects: briefingData?.BriefingProjects || [],
    BriefingProjectsArte: briefingData?.BriefingProjectsArte || [],
    ...briefingData,
  });


  function submitUpdatedBriefing() {
    const updatedForm = {
      ...stateForm,
      structs: stateForm.BriefingStructs,
      elements: stateForm.BriefingElements,
      multimedias: stateForm.BriefingMultimedia,
      briefingId: briefingData.id,
      briefingTypeId: briefingData.briefingTypeId,
      promoter: stateForm.promoterQuantity,
      projects: stateForm.BriefingProjects,
      projectsArte: stateForm.BriefingProjectsArte,
      references: stateForm.BriefingReferences,
      lastStands: stateForm.BriefingLastStand,
    };

    updateBriefing(updatedForm)
      .then((res) => {
        notification.show({ message: "Briefing atualizado :)" });
      })
      .catch((err) => {
        notification.show({
          message: "Não foi possível atualziar o briefing",
          type: "error",
        });
      });
  }

  return (
    <Wrapper key={briefingData.id}>
      <Title>
        <h2 className="title-company">
          {briefingData.eventName} - {briefingData.city}
        </h2>
      </Title>
      <Column>
        <SubTitle>Resumo do Briefing</SubTitle>
        <div>
          <strong>Prazo</strong>: {formatDateLocale(briefingData?.deadline)}
        </div>
        <div>
          <strong>Tamanho: </strong>
          {briefingData?.length}m (altura)
          <span> x </span>
          {briefingData?.depth}m (largura)
          <span> = </span>
          {briefingData?.length * briefingData?.depth}m²
        </div>
        <div>
          <strong>Status do Memorial:</strong> {renderMemorialStatus(briefingData)}
        </div>
      </Column>
      <EditBriefing
        briefingData={briefingData}
        stateForm={stateForm}
        handleChange={handleChange}
        setStateForm={setStateForm}
      />
      <Action>
        <Button color={"#adb5bd"}>Cancelar</Button>
        {userData.role.name !== "EXPOSITOR" &&
          <Link to={`/admin/memorial-descritivo/${briefingData?.id}`}>
            <Button color={"#FF8400"}>Memorial</Button>
          </Link>
        }
        <Button color={"#FF8400"} onClick={submitUpdatedBriefing}>
          Atualizar
        </Button>
      </Action>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  min-height: 40rem;
  margin: 10px 0;
  padding: 0;
`;

const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;

  > div {
    display: flex;
    flex: 0.4;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const Button = styled.button`
  min-width: 130px;
  font-size: 1.4rem;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => (!color ? "#22da28" : color)};
  background: ${({ color }) => (!color ? "#22da28" : color)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e3e3e3;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;
