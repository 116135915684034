import styled from "@emotion/styled"
import { useQuery } from "react-query";
import SideBarExpo1 from "../components/sidebar/SideBarExpo1"
import { UserProvider } from "../context/UserContext"
import { checkRole } from "../api/login";
export default function ExpositorLayout({ children, sideBarOff }) {

  return (
    <Wrapper sideBarOff={sideBarOff}>
      <SideBarExpo1 sideBarOff={sideBarOff} />
      <main>
        {children}
      </main>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #F5F5F9;

  & > main {
    background-color: #F5F5F9;
    margin-left: ${({ sideBarOff }) => !sideBarOff && "90px"};
    width: ${({ sideBarOff }) => !sideBarOff && "calc(100% - 90px)"};
    padding: 20px;
  }
  
  @media only screen and (max-width: 770px) {

   & > main {
     margin: 0;
     width: 100%;
   }
  }
`

