import React, { useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import logo from "../../images/logo-colorido.png";
import { Link, useNavigate } from "react-router-dom";
import "./sidebarExpo1.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useMatchMedia } from "../../hooks/useMatchMedia";
import { useUser } from "../../context/UserContext";
import styled from "styled-components";
import { deleteToken } from "../../api/login";
const queries = ["(max-width: 768px)", "(min-width: 769px)"];

const SideBarExpoDesk = ({ sideBarOff }) => {
    // state and function to open/close sidebar
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [mobile, desktop] = useMatchMedia(queries);
    const { userData, saveUserData } = useUser();
    const navigate = useNavigate();

    function handleLogout() {
        window.location.assign("https://app-stage.westand.com.br");
        saveUserData({});
        deleteToken();
    }

    function handleNovoPedido() {
        navigate("/expositor/novo-briefing");
    }
    function handleChoosePlans() {
        navigate("/expositor/planos");
    }

    return (
        <div
            className="container1"
            style={{
                backgroundColor: "#F5F5F9",
            }}
        >
            {/* ----- INICIO: Bloco DESKTOP ------ */}
            {desktop && !sideBarOff && (
                <div
                    className="sidebar1"
                    style={{
                        width: isOpen ? "300px" : "90px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="top_section">
                        <img src={logo} alt="" width="130px" style={{ display: isOpen ? "block" : "none" }} />
                        <div
                            className="bars"
                            style={{
                                marginLeft: isOpen ? "80px" : "0px",
                            }}
                        >
                            <ArrowBackIosOutlinedIcon
                                onClick={toggle}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "orange",
                                    borderRadius: "50%",
                                    width: "24px",
                                    height: "24px",
                                    color: "white",
                                    position: "absolute",
                                    right: "-10px",
                                    transition: "all 1s",
                                    transform: `rotate(${isOpen ? "0deg" : "180deg"})`,
                                }}
                            />
                        </div>
                    </div>
                    <div className="icons-desk">
                        <Link to="/perfil-expositor" className="link2" id="active">
                            <div className="icon">
                                <HomeOutlinedIcon style={{ width: "25px", height: "40px" }} />
                            </div>
                            <div
                                cmenu-barlassName="link_text"
                                style={{
                                    display: isOpen ? "block" : "none",
                                }}
                            >
                                ADM Expositor
                            </div>
                        </Link>
                        <br />
                        <p className="tittle" style={{ display: isOpen ? "block" : "none" }}>
                            - FLUXOS
                        </p>
                        <Link to="/expositor-novo/lista" className="link2" id="active">
                            <div className="icon">
                                <RequestQuoteOutlinedIcon style={{ width: "25px", height: "40px" }} />
                            </div>
                            <div cmenu-barlassName="link_text" style={{ display: isOpen ? "block" : "none" }}>
                                Pedidos de <br /> orçamento
                            </div>
                        </Link>

                        {/* <Link to="/intercorrencias-expositor" className="link2" id="active">
              <div className="icon">
                <TableChartOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Intercorrências
              </div>
            </Link>
            
            <Link to="/contrato-expositor" className="link2" id="active">
              <div className="icon">
                <CheckBoxOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Contrato Aprovado
              </div>
            </Link> */}
                        <br />
                        <p className="tittle" style={{ display: isOpen ? "block" : "none" }}>
                            - PAGES
                        </p>
                        {/* <Link to="/montadoras" className="link2" id="active">
              <div className="icon" style={{ marginBottom: "10px" }}>
                <LockOutlinedIcon style={{ width: "25px", height: "40px" }} />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Ver empresas da Montadora
              </div>
            </Link> */}
                        {/* <br /> */}
                        <Link to="/perfil-expositor" className="link2" id="active">
                            <div className="icon">
                                <ManageAccountsOutlinedIcon style={{ width: "25px", height: "40px" }} />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "block" : "none" }}>
                                Editar Perfil
                            </div>
                        </Link>
                        {/* <br /> */}
                        {/* <Link to="/conversas-expositor" className="link2" id="active">
              <div className="icon">
                <TextsmsOutlinedIcon
                  style={{ width: "25px", height: "40px" }}
                />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Conversas
              </div>
            </Link> */}
                        <br />
                        <p className="tittle" style={{ display: isOpen ? "block" : "none" }}>
                            - FLUXOS BRIEFING
                        </p>
                        <br />
                        <Link to="/expositor-novo/projeto&montagem" className="link2" id="active">
                            <div className="icon">
                                <PlaylistAddIcon style={{ width: "25px", height: "40px" }} />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "block" : "none" }}>
                                Projeto + Montagem
                            </div>
                        </Link>
                        {/* <br /> */}
                        {/* <Link to="/montagem" className="link2" id="active">
              <div className="icon" id="active">
                <PlaylistAddIcon style={{ width: "25px", height: "40px" }} />
              </div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                Montagem
              </div>
            </Link> */}
                    </div>
                </div>
            )}
            {/* ----- FIM: Bloco DESKTOP ------ */}

            {/* ----- INICIO: Bloco MOBILE ------ */}
            {(mobile || sideBarOff) && (
                <div
                    className="sidebar1"
                    style={{
                        width: isOpen ? "300px" : "0px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="top_section">
                        <img src={logo} alt="" width="130px" style={{ display: isOpen ? "block" : "none" }} />
                        <div
                            className="bars"
                            style={{
                                marginLeft: isOpen ? "80px" : "0px",
                            }}
                        >
                            <ArrowBackIosOutlinedIcon
                                onClick={toggle}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "orange",
                                    borderRadius: "10px",
                                    width: "20px",
                                    height: "20px",
                                    color: "white",
                                    position: "absolute",
                                    marginTop: "-12px",
                                    transition: "all 0.5s",
                                    display: isOpen ? "block" : "none",
                                }}
                            />
                        </div>
                    </div>
                    <div className="icons-mobile">
                        <Link to="/perfil-expositor" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon">
                                <HomeOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div
                                className="link_text"
                                style={{
                                    display: isOpen ? "flex" : "none",
                                }}
                            >
                                ADM Expositor
                            </div>
                        </Link>
                        <br />
                        <p className="tittle" style={{ display: isOpen ? "flex" : "none" }}>
                            - FLUXOS
                        </p>
                        <Link to="/solicitacao-plataforma" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon" style={{ marginBottom: "10px" }}>
                                <RequestQuoteOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Pedidos de <br /> orçamento
                            </div>
                        </Link>
                        {/* <br /> */}
                        <Link to="/intercorrencias-expositor" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon">
                                <TableChartOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Intercorrências
                            </div>
                        </Link>
                        {/* <br /> */}
                        <Link to="/contrato-expositor" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon">
                                <CheckBoxOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Contrato Aprovado
                            </div>
                        </Link>
                        <br />
                        <p className="tittle" style={{ display: isOpen ? "flex" : "none" }}>
                            - PAGES
                        </p>
                        <Link to="/montadoras" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon" style={{ marginBottom: "10px" }}>
                                <LockOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Ver empresas da Montadora
                            </div>
                        </Link>
                        {/* <br /> */}
                        <Link to="/perfil-expositor" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon">
                                <ManageAccountsOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Editar Perfil
                            </div>
                        </Link>
                        {/* <br /> */}
                        <Link to="/conversas-expositor" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon">
                                <TextsmsOutlinedIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Conversas
                            </div>
                        </Link>
                        <br />
                        <p className="tittle" style={{ display: isOpen ? "flex" : "none" }}>
                            - FLUXOS BRIEFING
                        </p>
                        <br />
                        <Link to="/projeto&montagem" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon">
                                <PlaylistAddIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Projeto + Montagem
                            </div>
                        </Link>
                        {/* <br /> */}
                        <Link to="/montagem" className="link2" id="active" style={{ display: isOpen ? "flex" : "none" }}>
                            <div className="icon" id="active">
                                <PlaylistAddIcon
                                    style={{
                                        width: "25px",
                                        height: "40px",
                                    }}
                                />
                            </div>
                            <div className="link_text" style={{ display: isOpen ? "flex" : "none" }}>
                                Montagem
                            </div>
                        </Link>
                    </div>
                    <div className="overshadow" style={{ display: isOpen ? "block" : "none", overflowY: "scroll" }}></div>
                </div>
            )}
            {/* ----- FIM: Bloco MOBILE ------ */}
            <div
                className="profile-bar"
                style={{
                    marginLeft: sideBarOff ? "20px" : "",
                }}
            >
                {!sideBarOff && (
                    <>
                        <div className="menu-bar">
                            <MenuIcon onClick={toggle} />
                        </div>
                        <BoldSpan onClick={handleNovoPedido}>Criar um Briefing</BoldSpan>
                        <BoldSpan onClick={handleChoosePlans}>Escolher Planos</BoldSpan>
                    </>
                )}
                {userData ? <BoldSpan onClick={handleLogout}>Sair</BoldSpan> : ""}
            </div>
        </div>
    );
};

const BoldSpan = styled.span`
    font-weight: bold;
    margin: 10px;
`;

export default SideBarExpoDesk;
