import React from "react";
import styled from "styled-components";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { NRoundedButton } from './NRoundedButton';
export function renderViewFile(imageFile) {
  if (!imageFile) {
    return <></>
  }

  if (
    imageFile.indexOf('.jpeg') > 0 ||
    imageFile.indexOf('.png') > 0 ||
    imageFile.indexOf('.jpg') > 0) {
    return (<img src={imageFile} />);
  }
  if (imageFile.indexOf('.pdf')) {
    return (
      <CardWrapper>
        <div>
          <PictureAsPdfIcon />
          <span>Já existe um arquivo PDF aqui</span>
        </div>
      </CardWrapper>)
  }
  if (imageFile.indexOf('.pdf')) {
    return (
      <CardWrapper>
        <div>
          <InsertDriveFileIcon />
          <span>Já existe um arquivo aqui</span>
        </div>
      </CardWrapper>)
  }
}


function getImageLink(url) {
  if (!url) {
    return "";
  }
  if (url.indexOf('http') >= 0) {
    return url;
  }
  return `https://bucket-westand-prod.s3.us-east-2.amazonaws.com/${url}`;
}

const CardImageDownload = ({
  image,
  title,
  ...otherProps
}) => {

  const imageSource = getImageLink(image);
  const handleDownloadImage = (source) => {
    window.open(source, "__blank")
  };

  return (
    <>
      <CardWrapper
        onClick={() => handleDownloadImage(imageSource)}
        title="Clique para fazer o download"
      >
        {renderViewFile(imageSource)}
        <p>
          {title}
        </p>
      </CardWrapper>
    </>

  );
};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => props.width ? props.width : '300px'};
  height: ${props => props.height ? props.height : '260px'};
  cursor: pointer;
  
  > img {
    width: ${props => props.width ? props.width : '300px'};
    height: ${props => props.height ? props.height : '260px'};
    object-fit: cover;
  }

  > div {
    background-color: #E3E3E3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    > span {
      font-size: 1.4rem;
      text-align: center;
      font-weight: 700;
  }
  }

  > p {
    font-size: 1.8rem;
    padding: 10px;
  }
`;


export default CardImageDownload;
