import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { NRoundedButton } from "./NRoundedButton";
import { useBriefingManager } from "../components/BriefingManager/useBriefingManager";
import { getFluxBriefingManager } from "../api/briefingManager";
import { useUser } from "../context/UserContext";
import { formatCurrencyDecimal } from "../helpers";

//Array pra mostrar o nome da etapa a partir do número - note que isso não precisa ser uma variável de estado
const ETAPA_GESTOR = Object.freeze(["Regulamento da Feira",
  "Upload projeto 3D",
  "Pagamento",
  "Projeto revisado",
  "Projeto técnico",
  "PProjeto técnico Final + 3D Final",
  "ART de Projeto + Execução",
  "Fotos da Pré-Montagem e Montagem",
  "Doação",
  "Avaliação"])

//Quando a lógica começa a ficar complexa é melhor isolar nisso numa função que renderiza só aquele trecho
function renderActionsButtons({
  buttonColor,
  montador,
  expositor,
  projetista,
  briefing,
  handleOpenWindow,
}) {

  if (montador) {
    return (
      <Link to={`/montador/gestor/${briefing.briefingId}`}>
        <NRoundedButton
          title={"Fluxo Gestor"}
          color={buttonColor}
          active={true}
        />
      </Link>
    )
  }

  if (expositor) {
    return (
      <Link to={`/expositor/gestor/${briefing.briefingId}`}>
        <NRoundedButton
          title={"Fluxo Gestor"}
          color={buttonColor}
          active={true}
        />
      </Link>
    )
  }

  if (projetista) {
    return (
      <Link to={`/projetista/gestor/${briefing.briefingId}`}>
        <NRoundedButton
          title={"Fluxo Gestor"}
          color={buttonColor}
          active={true}
        />
      </Link>
    )
  }

  return (
    <>
      <NRoundedButton
        title={"Ver Boleto"}
        color={buttonColor}
        active={true}
        disabled={montador}
        onClick={() =>
          handleOpenWindow(
            briefing.BriefingRequest.Briefing.Payments[0].invoiceUrl
          )
        }
      />
      <Link to={`/admin/gestor/${briefing.briefingId}`}>
        <NRoundedButton
          title={"Fluxo Gestor"}
          color={buttonColor}
          active={true}
        />
      </Link>
    </>
  )
}

function renderOwnerAndBuilder(briefing) {


  const ownerId = briefing?.Briefing?.usersId;

  const owner = briefing?.Briefing?.User?.Exhibitors.find(v => v.usersId === ownerId) ||
    briefing?.Briefing?.User?.Builders.find(v => v.usersId === ownerId) ||
    briefing?.Briefing?.User?.Promoter.find(v => v.usersId === ownerId)

  const builder = briefing?.BriefingRequest?.Builder?.Builders[0]



  return (
    <>
      <span>
        <strong>Solicitante: </strong>{owner?.name}
      </span>
      <span>
        <strong>Montadora: </strong>{builder?.name || "Sem Montadora contratada"}
      </span>
    </>
  )
}

function renderTitle(briefing) {

  const totalArea = briefing?.Briefing?.length * briefing?.Briefing?.depth;
  return (
    <h3>{briefing?.Briefing?.eventName} - {briefing?.Briefing?.id} - {totalArea}m²</h3>
  )
}

const BriefingManagerList = () => {
  const { isBuilder, isExhibitor, isProjetist, isProfileColor } = useBriefingManager();
  const [briefingList, setBriefingList] = useState([]);
  const { userData } = useUser();

  useEffect(() => {
    getFluxBriefingManager().then((res) => {
      setBriefingList(res);
    });
  }, []);

  const montador = isBuilder();
  const expositor = isExhibitor();
  const projetista = isProjetist();
  const buttonColor = isProfileColor();

  const handleOpenWindow = (url) => {
    window.open(url, "_blank");
  };


  return (
    <Wrapper>
      {briefingList.map((briefing) => (
        <section key={briefing.id}>
          <div className="briefingManager-cards">
            {renderTitle(briefing)}
            <div className="briefingManager-info">
              {/* {renderOwnerAndBuilder(briefing)} */}
              <span>
                <strong>Preço estimado mínimo por m²: </strong>{formatCurrencyDecimal(briefing?.BriefingRequest?.finalPriceMin) || "Sem Montadora contratada"}
              </span>
              <span>
                <strong>Preço estimado máximo por m²: </strong>{formatCurrencyDecimal(briefing?.BriefingRequest?.finalPriceMax) || "Sem Montadora contratada"}
              </span>
              <span>
                <strong>Etapa atual do Gestor: </strong>{ETAPA_GESTOR[briefing.step]}
              </span>
            </div>
          </div>
          <div className="briefingManager-buttons">
            {renderActionsButtons({
              buttonColor,
              montador,
              expositor,
              projetista,
              briefing,
              handleOpenWindow,
            })}
          </div>
        </section>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  
  section {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    padding: 2rem 1.5rem;
    overflow: auto;
    border-radius: 0.375rem;
    max-height: 100%;
    

    > h3 {
      margin: 0;
    }

    .briefingManager-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 1rem;
    }

    .briefingManager-info {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: .8rem;
      font-size: 1.6rem;
    }
  }
`;
export default BriefingManagerList;
