import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import {
  Wrapper,
  Row,
  Column,
  Space,
} from "./Styles";
import { MenuItem } from "@mui/material";
import { listEvents } from "../../../../api/exhibitor";
import { createPaymentTax, simulateCheckout } from "../../../../api/payment";
import { useStepForm } from "../StepFormContext";
import { DashSelect, NRoundedButton } from "../../../../shared-components";
import { formatDateForInput, formatCurrencyDecimal } from "../../../../helpers";
import { createPayment } from "../../../../api/payment";
import { useNotification } from "../../../../context/NotificationContext";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";


const CardPaymentFinal = ({ price }) => {
  return (
    <div className="">
      <div className="options-profile" style={{ backgroundColor: "#EFEEEE" }}>
        <AddCardOutlinedIcon
          className="icons"
          style={{ width: "30px", height: "31px" }}
        />
        <h2 className="title-option">Pagamento</h2>
        <p className="text-option">
          Aqui você irá fazer o pagamento de contratação ( Projeto conceito / Projeto Técnico, RRT de projeto e execução/  Impostos, e Neutralização de Carbono) <strong>{formatCurrencyDecimal(price)}</strong>
        </p>
      </div>
    </div>
  );
};

function FragmentPayment({
  handleCreatePayment
}) {

  return (
    <>
      <NRoundedButton
        title={"Gerar Boleto"}
        active={true}
        color={"#FCA500"}
        onClick={handleCreatePayment}
      />
    </>
  )
}

const PagamentoTaxa = () => {

  const { data: events, isLoading, error } = useQuery('events', listEvents);
  const { stateForm, setStateForm } = useStepForm();
  const [eventId, setEventId] = useState(null);
  const [paymentInstallments, setPaymentInstallments] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const notification = useNotification();
  const finalPayment = stateForm?.Briefing?.Payments?.filter(v => v.type === "briefing");


  function handleCreatePayment(e) {
    e.preventDefault();
    createPaymentTax({
      briefingId: stateForm.Briefing.id,
    }).then(res => {
      notification.show({
        message: "Estamos gerando seu boleto, aguarde alguns segundos ou atualize a página."
      })
    }).catch(error => {
      notification.show({
        message: error.message || "Houve um erro",
        type: "error"
      })
    })
  }

  function handleOpenPayment(e) {
    e.preventDefault();
    window.open(finalPayment[0].invoiceUrl)
  }

  if (isLoading || error) {
    return;
  }

  if (!stateForm.briefingRequestId) {
    return (
      <Wrapper>

        <Row style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <p style={{
            fontSize: '2rem',
          }}>Você ainda não escolheu uma proposta para o seu projeto!</p>
        </Row>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Row>
        <Column>
          <CardPaymentFinal
            price={finalPayment.length > 0 ? finalPayment[0].value : ''} />
        </Column>
        <Space />
        <Column style={{ justifyContent: 'flex-start' }}>
          {finalPayment && finalPayment[0] ? (
            <>
              <NRoundedButton
                title={"Ver Boleto"}
                active={true}
                color={"#FCA500"}
                onClick={handleOpenPayment}
              />
            </>
          ) :
            (<FragmentPayment
              events={events}
              setSelectedInstallment={setSelectedInstallment}
              paymentInstallments={paymentInstallments}
              stateForm={stateForm}
              setEventId={setEventId}
              handleCreatePayment={handleCreatePayment}
            />)}
        </Column>
      </Row>
      <Row>
        <Space />
        <Column />
      </Row>
    </Wrapper>
  );
};


const Title = styled.span`
  font-size: 2rem;

`


export { PagamentoTaxa }