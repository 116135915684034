import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useQuery } from 'react-query';
import { getBriefing } from '../../../api/briefing';
import CardImageDownload from '../../../shared-components/CardImageDownload';
import { BRIEFING_TYPE_DB, BRIEFING_TYPE } from '../../../helpers/BriefingTypes';
import { formatDateLocale } from '../../../helpers';

function textFormater(text) {
  if (!text) {
    return "";
  }
  if (typeof text === "string") {
    return text.replaceAll("_", " ");
  }
  return text.toString()
    .replaceAll("_", " ")
    .replaceAll(",", ", ");
}

function priceFormater(number) {
  return `R$ ${number},00`
}

//TODO: Refactor - isolar cada parte em Fragments
export default function DialogBriefing({
  handleClose,
  open,
  title,
  briefingId,
}) {

  const { data, isLoading } = useQuery(`briefing-${briefingId}`, () => getBriefing(briefingId))


  if (isLoading) {
    return (
      <>
        carregando...
      </>
    )
  }

  return (
    <>
      <DialogStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <SubTitle>Onde e Quando</SubTitle>
          <Row>
            <div>
              <strong>Evento:</strong> {data.eventName}
            </div>
            <div>
              <strong>Tipo: </strong>
              {BRIEFING_TYPE[BRIEFING_TYPE_DB[data.briefingTypeId]]}
            </div>
            <div>
              <strong>Cidade:</strong> {data.city}
            </div>
            <div>
              <strong>Prazo máximo:</strong> {formatDateLocale(data.deadline)}
            </div>
          </Row>
          <Row>
            <div>
              <strong>Observações:</strong> {data.observation}
            </div>
          </Row>
          <SubTitle>Tamanho</SubTitle>
          <Row>
            <div>
              {data.length}m (comprimento)
              <span> x </span>
              {data.depth}m (profundidade)
              <span> = </span>
              {data.length * data.depth}m²
            </div>
            <Column>
              <SubTitle>Valor</SubTitle>
              <span>
                <strong>Valor mínimo:</strong>  {priceFormater(data.priceMin)}
              </span>
              <span>
                <strong>Valor máximo:</strong>  {priceFormater(data.priceMax)}
              </span>
            </Column>
          </Row>
          <Row>
            <Column>
              <SubTitle>Estrutura</SubTitle>
              {data?.BriefingStructs?.map((e, index) => {
                if (!isNaN(e.value)) {
                  return (
                    <div key={`dialog-structs-${index}`}>
                      <strong>{e.type}:</strong> {e.value}
                      <p>
                        <span>Obervações:</span> {e.observation}
                      </p>
                    </div>
                  )
                }
                return (
                  <div key={`dialog-structs-${index}`}>
                    <strong>{textFormater(e.type)}:</strong> {textFormater(e.value)}
                  </div>
                )
              })}
            </Column>
            <Column>
              <SubTitle>Multimídia</SubTitle>
              {data?.BriefingMultimedia?.map((e, index) => {
                if (!isNaN(e.value)) {
                  return (
                    <div key={`dialog-structs-${index}`}>
                      <strong>{e.type}:</strong> {e.value}
                      <p>
                        <span>Obervações:</span> {e.observation}
                      </p>
                    </div>
                  )
                }
                return (
                  <div key={`dialog-structs-${index}`}>
                    <strong>{textFormater(e.type)}:</strong> {textFormater(e.value)}
                  </div>
                )
              })}
            </Column>
          </Row>
          <Row>
            <Column>
              <SubTitle>Estrutura</SubTitle>
              {data?.BriefingElements?.map((e, index) => {
                if (!isNaN(e.value)) {
                  return (
                    <div key={`dialog-elements-${index}`}>
                      <strong>{e.type}:</strong> {e.value}
                      <p>
                        <span>Obervações:</span> {e.observation}
                      </p>
                    </div>
                  )
                }
                return (
                  <div key={`dialog-elements-${index}`}>
                    <strong>{textFormater(e.type)}:</strong> {textFormater(e.value)}
                  </div>
                )
              })}
            </Column>
          </Row>
          <Row>
            <Column>
              <SubTitle>Buffet e Promoter</SubTitle>
              <div>
                <strong>Buffet:</strong> {data.buffet || " N/A"}
              </div>
              <div>
                <strong>Promoter:</strong> {data.promoterQuantity}
              </div>
            </Column>
            <Column>
              <SubTitle>Informações</SubTitle>
              <div>
                <strong>Observações:</strong> {data.observations || " N/A"}
              </div>
            </Column>
          </Row>
          <SubTitle>Referências</SubTitle>
          <Row>
            <Grid>
              {data.BriefingReferences.map((reference, index) => (
                <div key={`dialog-last-${index}`}>
                  <CardImageDownload
                    image={reference.image}
                    title={`Referência: ${index + 1}`}
                  />
                </div>
              ))}
            </Grid>
          </Row>

          <SubTitle>Arquivos</SubTitle>
          <Row>
            <Grid>
              {data.BriefingLastStand.map((lastStand, index) => (
                <div key={`dialog-last-${index}`}>
                  <CardImageDownload
                    image={lastStand.link}
                    title={`Stand passado: ${index + 1}`}
                  />
                </div>
              ))}
              {data?.blueprint ? (<div key={`dialog-blueprint`}>
                <CardImageDownload
                  image={data.blueprint}
                  title={`Planta baixa`}
                />
              </div>) : ''}
            </Grid>

            {/* <div>
              <Button>baixa</Button>
            </div> */}
          </Row>

        </DialogContent>
        <DialogActions>
          <Button color={"#adb5bd"} onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }
  
`
const SubTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #22da28;
  margin: 14px 0;
  
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: '40%';
  margin: 10px 0;

  & p {
    font-size: 1.8rem;
    padding-bottom: 10px;
  }

  & span {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: '100%';
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.8rem;

  & > div {
    flex: ${({ size }) => size ? size : '1'};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

`
const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#22da28" : color};
  background: ${({ color }) => !color ? "#22da28" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`