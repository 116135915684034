import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import Header from "../DashboardExpositorComponents/Header";
import { CardProfileSession } from "./CardProfileSession";

const cardsSessions = [
  {
    Icon: BusinessCenterOutlinedIcon,
    title: "Sua página",
    subTitle: "Edite aqui sua página",
    link: "/edit-pagina-expositor"
  },
  {
    Icon: PeopleAltOutlinedIcon,
    title: "Informações pessoais",
    subTitle: "Edite aqui o seu perfil",
    link: "/informacoes-pessoais"
  },
  {
    Icon: LockOpenIcon,
    title: "Dados da empresa",
    subTitle: "Edite aqui o seu perfil da empresa",
    link: "/expositor/dados-empresa"
  },
  {
    Icon: HowToRegOutlinedIcon,
    title: "Entrar & Segurança",
    subTitle: "Edite aqui o seus dados de login",
    link: "/entrar-seguranca"
  },
]


export function ExpositorProfile() {
  return (
    <>
      <Header
        title={<span>Hey <strong>Expositor</strong>, edite seu cadastro aqui</span>}
      />
      < LinkSession >
        {
          cardsSessions.map((card, index) => (
            <CardProfileSession
              key={index}
              Icon={card.Icon}
              title={card.title}
              subTitle={card.subTitle}
              link={card.link}
            />
          ))
        }

      </LinkSession >

    </>
  )
}

const LinkSession = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  margin: 10px 0;

`