import styled from "@emotion/styled";
import { forwardRef } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from 'date-fns/locale/pt-BR';
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const popperSx = {
  "& .MuiPapper-root": {
    border: "20px solid black",
  }
}

const DashInputDate = forwardRef((props, ref) => {
  const {
    title,
    placeholder,
    variant,
    min = 0,
    max,
    width,
    borderless,
    background,
    ...otherProps
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Wrapper width={width}>
        {title ? <p>{title}</p> : ""}
        <Component
          ref={ref}
          width={width}
          borderless={borderless}
          background={background}
          InputProps={{ inputProps: { min, max } }}
          placeholder={placeholder}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="dd/MM/YYYY"
          {...otherProps}
          PopperProps={{
            sx: popperSx
          }}
        />
      </Wrapper>
    </LocalizationProvider>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: ${({ width }) => (width ? width : "parent")};

  > p {
    margin: 2px;
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const Component = styled(DatePicker)`
  width: 100%;
  border: none;
  outline: none;

  ${({ borderless }) => (borderless ? "& fieldset { border: none };" : "")}

  & input {
    height: 10px;
    width: 100%;
    border-radius: 6px;
    font-size: 1.6rem;
    padding-left: 0px;
    padding-right: 0px;
    
  } 

  & .MuiInputBase-root.MuiOutlinedInput-root {
    border: none;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    background-color: ${({ background }) =>
    background ? background : "#F3F3F3"}; 
  }
`;

export { DashInputDate };
