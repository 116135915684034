import { useState } from 'react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  MenuItem,
} from '@mui/material';
import { useQuery } from 'react-query';
import { listUsers } from "../../../../api/admin"
import { DashSelect } from '../../../../shared-components';

export default function DialogUser({
  handleClose,
  open,
  title,
  handleUser,
  submitUser,
  selectedUser
}) {

  const { data, isLoading } = useQuery(`users-admin-briefing`, listUsers)

  if (isLoading) {
    return (
      <>
        carregando...
      </>
    )
  }

  return (
    <>
      {data ? (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DashSelect
              id="designers-select"
              name="designers"
              onChange={handleUser}
              value={selectedUser}
            >
              {data.map(user => (
                <MenuItem
                  key={`user-${user.usersId}`}
                  value={user.usersId}
                >{user.name}
                </MenuItem>
              ))}
            </DashSelect>
          </DialogContent>
          <DialogActions>
            <Button
              color={"#adb5bd"}
              onClick={handleClose}
            >Fechar
            </Button>
            <Button
              onClick={submitUser}
              active={true}
              color={"#764faa"}
            >Ok, enviar para esse usuário
            </Button>
          </DialogActions>
        </DialogStyled>
      ) : ''}
    </>
  )
}



const DialogStyled = styled(Dialog)`
  & .MuiDialogTitle-root {
    font-size: 2.4rem;
    font-weight: 500;
  }

  & .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    color: #22da28;
  }
  
`

const Button = styled.button`
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid ${({ color }) => !color ? "#22da28" : color};
  background: ${({ color }) => !color ? "#22da28" : color};

  &:hover {
     box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  }

`