import styled from "@emotion/styled";
import FragmentReferences from "./FragmentReferences";
import { FragmentProjects } from "./FragmentProjects";
import { FragmentUploadProject } from "./FragmentUploadProject";
import { BRIEFING_TYPE_DB_NAME } from "../BriefingTypes";

function shouldUploadProject(briefingRequest) {
  if (
    briefingRequest?.Briefing?.briefingTypeId === BRIEFING_TYPE_DB_NAME.PROJETO ||
    briefingRequest?.Briefing?.briefingTypeId === BRIEFING_TYPE_DB_NAME.PROJETO_MONTADORA
  ) {
    return true;
  }

  return false;
}


export function FragmentReferencesOrProjects({
  briefingRequest,
  handleModalProject,
  projectFiles,
  handleChangeProject,
  handleForm
}) {

  //Briefing Projeto 
  if (shouldUploadProject(briefingRequest)) {
    return (
      projectFiles.map((project, index) => (
        <ProjectBlock
          key={index}
        >
          <FragmentUploadProject
            key={`upload-${index}`}
            id={`project_${index}`}
            briefingProjects={project}
            handleChangeProject={handleChangeProject}
          />
        </ProjectBlock>
      ))
    )
  }


  //Briefing Montagem com Projeto
  if (
    briefingRequest?.Briefing?.BriefingProjects &&
    briefingRequest?.Briefing?.BriefingProjects[0]?.id &&
    shouldUploadProject(briefingRequest) === false
  ) {
    return (
      <>
        {briefingRequest?.Briefing?.BriefingProjects?.map((project, index) => (
          <FragmentProjects
            key={`project-${index}`}
            briefingProject={project}
            handleClick={handleModalProject}
          />
        ))}
      </>
    )
  }

  //Briefing Montagem sem Projeto
  return (
    <>
      {
        briefingRequest?.Briefing?.BriefingReferences.map((reference, index) => {
          return (
            <FragmentReferences
              key={`references-${index}`}
              briefingReference={reference}
              BriefingRequestReference={briefingRequest?.BriefingRequestReference}
              position={index + 1}
              makeChoose={briefingRequest?.briefingReferencesId}
              handleChangeInput={handleForm}
            />
          )
        })
      }

    </>
  )
}

export const ProjectBlock = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 10px;
  width: 100%;

`
