import { useCallback } from "react";
import { MenuItem } from "@mui/material";
import { DashInput, DashSelect, NTextField } from "../../../shared-components";
import {
  FieldBlock,
  Title,
  Row
} from "./StyledComponents";
import {
  STRUCTS_NAME,
  ESTOQUE_ITEMS,
  MOBILIA_ITEMS,
  COFFEBREAK_ITEMS,
  SALA_REUNIAO_ITEMS,
  PAISAGISMO_ITEMS,
  MOVEL_EXPOSITOR_ITEMS,
} from "./FormsTypes";
import {
  getValueFromType,
  getValueFromTypeArray,
  getValueObservation
} from "./formHelper";



export default function FragmentStructs({ structs, setStateForm, stateForm }) {

  const handleStructState = useCallback(({ target: { name, value } }) => {
    const searchElement = structs.find(e => e.type === name);

    if (!searchElement) {
      structs = [
        ...structs,
        {
          type: name,
          value,
        }
      ]
    } else {
      Object.values(structs).forEach(struct => {
        if (struct.type === name) {
          struct.value = value;
        }
      })
    }

    setStateForm({
      ...stateForm,
      BriefingStructs: structs,
    })
  })

  const handleStructStateObs = useCallback(({ target: { name, value } }) => {

    name = name.replace("_observation", "");
    const searchElement = structs.find(e => e.type === name);
    if (!searchElement) {
      structs = [
        ...structs,
        {
          type: name,
          observation: value,
        }
      ]
    } else {
      Object.values(structs).forEach(struct => {
        if (struct.type === name) {
          struct.observation = value;
        }
      })
    }


    setStateForm({
      ...stateForm,
      BriefingStructs: structs,
    })
  })


  return (
    <>
      <Title>Estrutura</Title>
      <Row>
        <FieldBlock>
          <DashInput
            id={STRUCTS_NAME.BALCAO}
            key={STRUCTS_NAME.BALCAO}
            name={STRUCTS_NAME.BALCAO}
            value={getValueFromType(structs, STRUCTS_NAME.BALCAO)}
            onChange={handleStructState}
            title={"Balcão"}
            fullWidth
            placeholder="0"
            type="number"
          />
          <NTextField
            id={`${STRUCTS_NAME.BALCAO}_observation`}
            name={`${STRUCTS_NAME.BALCAO}_observation`}
            key={`${STRUCTS_NAME.BALCAO}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.BALCAO)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashInput
            id={STRUCTS_NAME.OBJETOS_SUSPENSOS}
            name={STRUCTS_NAME.OBJETOS_SUSPENSOS}
            key={STRUCTS_NAME.OBJETOS_SUSPENSOS}
            value={getValueFromType(structs, STRUCTS_NAME.OBJETOS_SUSPENSOS)}
            onChange={handleStructState}
            fullWidth
            title={"Objetos Suspensos"}
            placeholder="0"
            type="number"
          />
          <NTextField
            id={`${STRUCTS_NAME.OBJETOS_SUSPENSOS}_observation`}
            name={`${STRUCTS_NAME.OBJETOS_SUSPENSOS}_observation`}
            key={`${STRUCTS_NAME.OBJETOS_SUSPENSOS}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.OBJETOS_SUSPENSOS)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={STRUCTS_NAME.ESTOQUE}
            name={STRUCTS_NAME.ESTOQUE}
            key={STRUCTS_NAME.ESTOQUE}
            value={getValueFromTypeArray(structs, STRUCTS_NAME.ESTOQUE)}
            onChange={handleStructState}
            multiple
            title={"Estoque"}
          >
            {ESTOQUE_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>{v.value}</MenuItem>
            ))}

          </DashSelect>
          <NTextField
            id={`${STRUCTS_NAME.ESTOQUE}_observation`}
            name={`${STRUCTS_NAME.ESTOQUE}_observation`}
            key={`${STRUCTS_NAME.ESTOQUE}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.ESTOQUE)}
            onChange={handleStructStateObs}
            variant="standard"
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
      </Row>
      <Row>
        <FieldBlock>
          <DashSelect
            id={STRUCTS_NAME.MOBILIA}
            name={STRUCTS_NAME.MOBILIA}
            key={STRUCTS_NAME.MOBILIA}
            value={getValueFromTypeArray(structs, STRUCTS_NAME.MOBILIA)}
            onChange={handleStructState}
            fullWidth
            multiple
            title={"Mobília"}
            placeholder="0"
            type="number"
          >
            {MOBILIA_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>{v.value}</MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${STRUCTS_NAME.MOBILIA}_observation`}
            name={`${STRUCTS_NAME.MOBILIA}_observation`}
            key={`${STRUCTS_NAME.MOBILIA}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.MOBILIA)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={STRUCTS_NAME.COFFEEBREAK}
            name={STRUCTS_NAME.COFFEEBREAK}
            key={STRUCTS_NAME.COFFEEBREAK}
            value={getValueFromTypeArray(structs, STRUCTS_NAME.COFFEEBREAK)}
            onChange={handleStructState}
            fullWidth
            multiple
            title={"Área para coffee break"}
          >
            {COFFEBREAK_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>{v.value}</MenuItem>
            ))}

          </DashSelect>
          <NTextField
            id={`${STRUCTS_NAME.COFFEEBREAK}_observation`}
            name={`${STRUCTS_NAME.COFFEEBREAK}_observation`}
            key={`${STRUCTS_NAME.COFFEEBREAK}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.COFFEEBREAK)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={STRUCTS_NAME.MOVEL_EXPOSITOR}
            name={STRUCTS_NAME.MOVEL_EXPOSITOR}
            key={STRUCTS_NAME.MOVEL_EXPOSITOR}
            value={getValueFromTypeArray(structs, STRUCTS_NAME.MOVEL_EXPOSITOR)}
            onChange={handleStructState}
            fullWidth
            title={"Móvel expositor"}
            multiple
          >
            {MOVEL_EXPOSITOR_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>{v.value}</MenuItem>
            ))}

          </DashSelect>
          <NTextField
            id={`${STRUCTS_NAME.MOVEL_EXPOSITOR}_observation`}
            name={`${STRUCTS_NAME.MOVEL_EXPOSITOR}_observation`}
            key={`${STRUCTS_NAME.MOVEL_EXPOSITOR}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.MOVEL_EXPOSITOR)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
      </Row>
      <Row>
        <FieldBlock>
          <DashInput
            id={STRUCTS_NAME.FREEZER}
            name={STRUCTS_NAME.FREEZER}
            key={STRUCTS_NAME.FREEZER}
            value={getValueFromType(structs, STRUCTS_NAME.FREEZER)}
            onChange={handleStructState}
            fullWidth
            title={"Freezer"}
            placeholder="0"
            type="number"

          />
          <NTextField
            id={`${STRUCTS_NAME.FREEZER}_observation`}
            name={`${STRUCTS_NAME.FREEZER}_observation`}
            key={`${STRUCTS_NAME.FREEZER}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.FREEZER)}
            onChange={handleStructStateObs}
            variant="standard"
            placeholder="Observações"
            fullWidth
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={STRUCTS_NAME.SALA_REUNIAO}
            name={STRUCTS_NAME.SALA_REUNIAO}
            key={STRUCTS_NAME.SALA_REUNIAO}
            value={getValueFromType(structs, STRUCTS_NAME.SALA_REUNIAO)}
            onChange={handleStructState}
            fullWidth
            title={"Sala de reunião"}
          >
            {SALA_REUNIAO_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>{v.value}</MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${STRUCTS_NAME.SALA_REUNIAO}_observation`}
            name={`${STRUCTS_NAME.SALA_REUNIAO}_observation`}
            key={`${STRUCTS_NAME.SALA_REUNIAO}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.SALA_REUNIAO)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
        <FieldBlock>
          <DashSelect
            id={STRUCTS_NAME.PAISAGISMO}
            name={STRUCTS_NAME.PAISAGISMO}
            key={STRUCTS_NAME.PAISAGISMO}
            value={getValueFromTypeArray(structs, STRUCTS_NAME.PAISAGISMO)}
            onChange={handleStructState}
            fullWidth
            multiple
            title={"Paisagismo"}
          >
            {PAISAGISMO_ITEMS.map((v, index) => (
              <MenuItem key={index} value={v.name}>{v.value}</MenuItem>
            ))}
          </DashSelect>
          <NTextField
            id={`${STRUCTS_NAME.PAISAGISMO}_observation`}
            name={`${STRUCTS_NAME.PAISAGISMO}_observation`}
            key={`${STRUCTS_NAME.PAISAGISMO}_observation`}
            value={getValueObservation(structs, STRUCTS_NAME.PAISAGISMO)}
            onChange={handleStructStateObs}
            variant="standard"
            fullWidth
            placeholder="Observações"
            margin="normal"
          />
        </FieldBlock>
      </Row>
    </>
  )
}