import React from 'react'
import { Wrapper } from './Styles';
import styled from "@emotion/styled";
import { getMemorial, upsertApproval } from '../../../../api/gestor';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loading from '../../../../shared-components/Loading';
import { NRoundedButton } from '../../../../shared-components';
import useNotification from 'antd/es/notification/useNotification';
import FragmentMemorial from './FragmentMemorial';


const MemorialGestor = () => {

  const { briefingId } = useParams();
  const { data, isLoading } = useQuery(`memorial-${briefingId}`, () => getMemorial(briefingId));
  const notification = useNotification();

  if (isLoading) {
    return <Loading role='EXPOSITOR' />;
  }




  return (
    <Wrapper style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%"
    }}>
      <FragmentMemorial data={data} />
    </Wrapper>
  )
}

const Content = styled.div`
  width: 80%;
  height: 50vh;
  margin-top: 5rem;
  overflow-y: scroll;
  padding: 0 2rem;
  

  .memorial-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    padding-top: 3rem;

    > span {
      background-color: #00ff21;
      padding: 1rem 2rem;
      font-size: 2rem;
      font-weight: bolder;
      border-radius: 5px;
      color: #fff;
    }
  }

  .status-memorial {
    
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    


  }
`

export default MemorialGestor