import {
  getUserData,
  privateFetch
} from "./user";
import axios from "axios";

const API = process.env.REACT_APP_API;


export async function createQuickExhibitor({ email, name }) {

  const res = await fetch(`${API}/exhibitor/create-quick`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      name,
    }),
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}

export async function listEvents() {
  const token = await privateFetch();

  const res = await fetch(`${API}/events`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function memorialList() {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing/2/memorial`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}


// pega os dados de doação
export async function donationItems(briefingId) {
  const token = await privateFetch();

  const res = await fetch(`${API}/briefing-manager/${briefingId}/donations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return res.json();
}

// atualiza os dados de doação
export async function sendDonations(briefingId, donations) {
  const token = await privateFetch();

  const url = `${API}/briefing-manager/${briefingId}/donations`;

  const response = await axios.put(url, donations, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return response.data;
}