import { privateFetch } from "./user";

const API = process.env.REACT_APP_API;

export async function getEventById(id) {
  try {
    const token = await privateFetch();
    const response = await fetch(`${API}/event/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Error fetching event');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateEvent(id, updatedData) {
  try {
    const token = await privateFetch();
    const response = await fetch(`${API}/event/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Error updating event');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function listEvents() {
  const token = await privateFetch();

  const res = await fetch(`${API}/promoter/events`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function createEvent(data) {
  try {
    const token = await privateFetch();
    const response = await fetch(`${API}/event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Error creating event');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function listBuilders() {
  return [
    { id: 1, name: "MP Eventos" },
    { id: 2, name: "Luna Cenografia" },
  ];
}

export function listCauses() {
  return [
    { id: 1, name: "Fome e Pobreza" },
    { id: 2, name: "Saúde" },
    { id: 3, name: "Causas Climáticas" },
    { id: 4, name: "Educação" },
    { id: 5, name: "Igualdade de Gênero" },
    { id: 6, name: "Meio Ambiente e Diversidade" },
  ]
}

export function listTicketTypes() {
  return [
    { id: 1, name: "+ Ingresso pago" },
    { id: 2, name: "+ Ingresso gratuito" },
  ];
}
