import React from "react";
import {
  MenuItem,
} from "@mui/material";

import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import { NSelect } from "../../shared-components/NSelect";
import { NTextField } from "../../shared-components";
import { Row, FormWrapper } from "./FormLayout";

const MultimidiaInformation = () => {
  const { control } = useFormContext();
  const { register } = useForm();

  return (
    <>
      <FormWrapper>
        <h3>Material Multimídia</h3>
        <Row>
          <div>
            <p>TV <span style={{ color: '#ff8800' }}>(Quant.)</span></p>
            <Controller
              control={control}
              name="tvQuantidade"
              render={({ field }) => (
                <NTextField
                  className="form__medium"
                  id="tv-quantidade"
                  name="tvQuantidade"
                  {...register("tvQuantidade")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="tvQuantidade_obs"
              render={({ field }) => (
                <NTextField
                  {...register("tvQuantidade_obs")}
                  {...field}
                  id="tvQuantidade_obs"
                  name="tvQuantidade_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>TV <span style={{ color: '#ff8800' }}>(Tam.)</span></p>
            <Controller
              control={control}
              name="tvTamanho"
              defaultValue={[]}
              {...register("tvTamanho")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="tvTamanho"
                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={'32-34pol'}>32-34 pol</MenuItem>
                  <MenuItem value={'45-55pol'}>45-55 pol</MenuItem>
                  <MenuItem value={'56-70pol'}>56-70 pol</MenuItem>
                  <MenuItem value={'MÁX'}>Máx. possível</MenuItem>
                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="tvTamanho_obs"
              render={({ field }) => (
                <NTextField
                  {...register("tvTamanho_obs")}
                  {...field}
                  id="tvTamanho_obs"
                  name="tvTamanho_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>

            <p>Painel LED</p>
            <Controller
              control={control}
              name="painelLed"
              render={({ field }) => (
                <NTextField
                  className="form__medium"
                  id="painel-led"
                  name="painelLed"
                  {...register("painelLed")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="painelLed_obs"
              render={({ field }) => (
                <NTextField
                  {...register("painelLed_obs")}
                  {...field}
                  id="painelLed_obs"
                  name="painelLed_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
        <Row>
          <div>
            <p>Iluminação</p>
            <Controller
              control={control}
              name="iluminacao"
              defaultValue={[]}
              {...register("iluminacao")}
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="iluminacao"
                  defaultValue={[]}
                  multiple
                >
                  <MenuItem value={'REFLETORES_LED'}>Refletores LED</MenuItem>
                  <MenuItem value={'COLORAÇÃO_ESPECIAL'}>Colaração Especial</MenuItem>
                  <MenuItem value={'PROJETO_CENICO'}>Projeto Cênico</MenuItem>
                  <MenuItem value={'OUTROS'}>Outros</MenuItem>
                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="iluminacao_obs"
              render={({ field }) => (
                <NTextField
                  {...register("iluminacao_obs")}
                  {...field}
                  id="iluminacao_obs"
                  name="iluminacao_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Caixa Som</p>
            <Controller
              control={control}
              name="caixaSom"
              render={({ field }) => (
                <NSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...field}
                  label="caixaSom"
                >
                  <MenuItem value={'SIM'}>Sim</MenuItem>
                  <MenuItem value={'NÃO'}>Não</MenuItem>
                </NSelect>)}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="caixaSom_obs"
              render={({ field }) => (
                <NTextField
                  {...register("caixaSom_obs")}
                  {...field}
                  id="caixaSom_obs"
                  name="caixaSom_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <p>Lixeiras <span style={{ color: '#ff8800' }}>(Qtd.)</span></p>
            <Controller
              control={control}
              name="lixeiras"
              render={({ field }) => (
                <NTextField
                  className="form__medium"
                  id="lixeiras"
                  name="lixeiras"
                  {...register("lixeiras")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
            />
            <p>Observações:</p>
            <Controller
              control={control}
              name="lixeiras_obs"
              render={({ field }) => (
                <NTextField
                  {...register("lixeiras_obs")}
                  {...field}
                  id="lixeiras_obs"
                  name="lixeiras_obs"
                  type="text"
                  variant="standard"
                />
              )}
            />
          </div>
        </Row>
      </FormWrapper>

    </>
  );
};

export default MultimidiaInformation;