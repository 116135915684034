import { useEffect } from "react";
import styled from "@emotion/styled"
import { useQuery } from "react-query";
import {
  DashInput,
  DashTextArea,
  NRoundedButton,
  UploadAvatar,
} from "../../../shared-components"
import { CardPersonalInfo } from "./CardPersonalInfo";
import { useSimpleForm } from "../../../hooks";
import { useState } from "react";
import { updateProfileInfo, getProfileInfo } from "../../../api/user";
import { useNotification } from "../../../context/NotificationContext";

const pathAvatar = 'https://bucket-westand-prod.us-east-2.amazonaws.com';

export function FragmentProfileForm({ color }) {

  const { data, isLoading } = useQuery('profile-data', getProfileInfo);
  const notification = useNotification();

  const [avatar, setAvatar] = useState();
  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    name: "",
    bio: "",
    liveIn: "",
    avatar: ""
  });

  useEffect(() => {
    setStateForm(data);
    setAvatar(data?.avatar)
  }, [data])

  function submitForm() {
    updateProfileInfo({ profileInfo: stateForm })
      .then(res => {
        notification.show({
          message: 'Dados atualizados'
        })
      })
      .catch(err => {
        notification.show({
          message: 'Não foi possível atualizar os dados',
          type: 'error'
        })
      })
  }


  function getAvatar(e, file) {
    setAvatar(`${pathAvatar}/${file.name}`)
    setStateForm({
      ...stateForm,
      avatar: `${pathAvatar}/${file.name}`,
    })
  }

  return (
    <Wrapper>
      <div>
        <Column>
          <UploadAvatar
            imageFile={avatar}
            getFile={getAvatar}
          />
          <div className="div-line">
            <div className="line"></div>
          </div>
        </Column>
        <Column>
          <DashInput
            width="100%"
            title="Nome"
            name="name"
            value={stateForm?.name}
            onChange={handleChange}
          />
          <DashTextArea
            title="Bio"
            name="bio"
            value={stateForm?.bio}
            onChange={handleChange}
          />
          <DashInput
            width="100%"
            title="Mora em"
            name="liveIn"
            value={stateForm?.liveIn}
            onChange={handleChange}
          />

        </Column>
        <Actions>
          <NRoundedButton
            title={"Cancelar"}
          />
          <NRoundedButton
            title={"Gravar"}
            color={color}
            active={true}
            onClick={submitForm}
          />
        </Actions>
      </div>
      <div>
        <CardPersonalInfo />
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin: 10px 0;
  padding: 10px;
  gap: 20px;

  > div:nth-of-type(1) {
    flex: 3;
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 10px 0;
  }

  @media screen and (max-width: 898px) {
    flex-direction: column;
  }
`



const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 4vh;
  padding: 2vh 0;
`