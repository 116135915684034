import { UploadFileDownload } from "../../../../shared-components";
import {
  Wrapper,
  Row,
  Column,
  Space,
  AvatarExpositor,
  AvatarMontador,
  AvatarProjetista
} from "./Styles";
import { useStepForm } from "../StepFormContext";
import SwitchButton from "../switchButton/SwitchButton";
import { useStepActions } from "../useStepActions";


const ProjetoTecnicoArt = () => {

  const { stateForm, setStateForm } = useStepForm();


  const {
    handleChecked,
    handleGetFile,
    isBuilder,
    isExhibitor,
  } = useStepActions();

  const expositor = isExhibitor();
  const montador = isBuilder();


  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Projeto Técnico Final + 3D Final</h2>
      </div>
      <Row>
        <Column>
          <span>Projeto Técnico Final</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="projetoTecnico"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              link={stateForm?.BriefingManagerUploads?.projetoTecnico?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="projetoTecnico"
              checked={stateForm?.BriefingManagerUploads?.projetoTecnico?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
        </Column>
        <Space />
        <Column>
          <span>Projeto 3D Final</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="projeto3dFinal"
              link={stateForm?.BriefingManagerUploads?.projeto3dFinal?.link}
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              getFile={handleGetFile}
              disabled={expositor}
            />
          </Row>
        </Column>
      </Row>
    </Wrapper>
  );
};


export { ProjetoTecnicoArt }