import { useContext, createContext, useState } from "react";
import styled from "@emotion/styled";
import Snackbar from '@mui/material/Snackbar';

const NotificationContext = createContext();

function NotificationProvider({ children }) {

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: 'Oiii...',
    type: null
  });
  const { vertical, horizontal, open, message, type } = state;

  function show({ message, type }) {
    setState({ open: true, message, type });
  };

  function close() {
    setState({ ...state, open: false });
  };

  return (
    <NotificationContext.Provider value={{ show, close }}>
      <SnackStyle
        type={type}
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        onClose={close}
        message={message}
        key={vertical + horizontal}
      />
      {children}
    </NotificationContext.Provider>
  )
}


function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("Esse contexto não existe");
  }
  return context;
}



const SnackStyle = styled(Snackbar)`

  > .MuiSnackbarContent-root {
    font-size: 2rem;
    background-color: ${({ type }) => type ? "#E1524E" : "#22da28"};
    color: white;
  }



`


export {
  useNotification,
  NotificationProvider,
}