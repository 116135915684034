import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getBriefing } from "../../../api/briefing";
import Header from "../Header";
import FragmentBriefingOpen from "./fragments/FragmentBriefingOpen";
import { FragmentActionsLink } from "./fragments/FragmentActionLinks";
import Loading from "../../../shared-components/Loading";

function FragmentOpenBriefings({ data, error }) {
  if (error) {
    return (
      <>
        <h3>houve um erro</h3>
      </>
    );
  }

  if (data) {
    return (
      <>
        <FragmentBriefingOpen key={data.id} briefingData={data} />
      </>
    );
  }
  if (error) {
    return <span>Algo deu errado, carregue a página novamente...</span>;
  }
}

const SolicitEmAbertoDetails = (props) => {
  const params = useParams();
  const { briefingId } = params;
  const { data, isLoading, error } = useQuery(`briefing-${briefingId}`, () =>
    getBriefing(briefingId)
  );

  if (isLoading) {
    return (
      <>
        <Loading role="MONTADOR" />
      </>
    )
  }

  return (
    <>
      <Header title="em aberto" />
      <FragmentActionsLink clicked="em aberto" />
      <ContentWrapper>
        <FragmentOpenBriefings
          data={data}
          isLoading={isLoading}
          error={error}
        />
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default SolicitEmAbertoDetails;
