import styled from "@emotion/styled"
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from '@mui/material/Avatar';
import { DashInput } from "../../shared-components";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useChat } from "./useChat";

export function ChatContainer({
  openChat,
  setOpenChat,
  userColor,
  roomId
}) {

  const {
    messages,
    setMessages,
    inputMessage,
    setInputMessage,
    handleSendMessage,
    scrollBottomRef,
  } = useChat({
    roomId,
  });



  return (
    <>
      <Wrapper openChat={openChat}>
        <Title>
          Chat
          <ClearIcon
            onClick={() => setOpenChat(false)}
          />
        </Title>
        <Content >
          {Object.values(messages).map((v, index) => {
            if (v.type === "admin") {
              return (
                <div key={index}>
                  <ChatBubbleMe
                    userColor={userColor}
                  >{v.text}
                  </ChatBubbleMe>
                </div>
              )
            }
            return (
              <div key={index}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <ChatBubbleOther
                  userColor={userColor}
                >
                  {v.text}
                </ChatBubbleOther>
              </div>
            )
          })}
          <section ref={scrollBottomRef}></section>
        </Content>
        <ActionChat>
          <AttachFileIcon />
          <DashInput
            width="90%"
            onKeyDown={handleSendMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            value={inputMessage}
          />
        </ActionChat>
      </Wrapper>

    </>

  )
}


const Wrapper = styled.div`
  background-color: white;
  z-index: 10;
  width: 30%;
  height: 54%;
  position: fixed;
  bottom: 30px;
  left: 120px;
  box-shadow: 0 0 0.5rem 0.5rem rgb(161 172 184 / 15%);
  border-radius: 10px;
  opacity: ${(props) => props.openChat ? '1' : '0'};
  display: ${(props) => props.openChat ? 'inital' : 'none'};
  transition: all linear 300ms;


  > svg {
    cursor: pointer;
  }
`

const ActionChat = styled.div`
  position: sticky;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 15%;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
`

const Content = styled.div`
  background-color: rwhite;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  height: 75%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 2rem;

  > div {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: flex-end;
  }

`

const ChatBubbleMe = styled.span`
  font-size: 1.8rem;
  background-color: ${({ userColor }) => userColor};
  color: white;
  max-width: 80%;
  min-width: 30%;
  border-radius: 6px;
  padding: 10px;
  margin: 0 10px;
  line-break: anywhere;
`

const ChatBubbleOther = styled(ChatBubbleMe)`
  background-color: #F0EFEF;
  color: black;
`

const Title = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background-color: #F0EFEF;
  width: 100%;
  height: 60px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 700;
  padding: 0 2rem;
`
