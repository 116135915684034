import { S3Client } from "@aws-sdk/client-s3";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';


// Set the AWS Region.
const REGION = "us-east-2";
const creds = {
  accessKeyId: "AKIAWLWUBPPKRCVKVO7A",
  secretAccessKey: "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",
}
// Create an Amazon S3 service client object.
const s3Client = new S3Client({
  region: REGION,
  signatureVersion: 'v4',
  credentials: creds,
  apiVersion: '2006-03-01',
});


const uploadFileS3 = async (event) => {

  const key = uuidv4();
  const content = event.target.files[0];

  const type = event.target.files[0].type.split("/")[1]
  const result = await s3Client.send(new PutObjectCommand({
    Bucket: "bucket-westand-prod",
    Body: event.target.files[0],
    Key: `${key}.${type}`,
    ContentDisposition: "inline",
    ContentType: `application/${type}`,
  }))

  return {
    ...result,
    key,
    name: `${key}.${type}`
  };
}


export { s3Client, uploadFileS3 };