import styled from "@emotion/styled"
import { FragmentUploadReferences } from "./FragmentUploadReferences"
import { DashTextArea } from "../../../../../shared-components"
import { Title } from "./StyledComponents";

export default function FragmentReferences({
  briefingData,
  stateForm,
  setStateForm,
}) {


  function handleChangeImage(e, res) {
    const position = e.target.id.split("_")[1]

    const refs = stateForm.BriefingReferences;

    let uploadedImage = {
      type: "upload",
      image: res?.name,
      id: refs[position]?.id || undefined
    }

    refs[position] = uploadedImage;

    setStateForm({
      ...stateForm,
      BriefingReferences: [
        ...refs
      ]
    })
  }

  function handleInput(e) {
    const position = e.target.id.split("_")[1]

    stateForm.BriefingReferences[position] = {
      ...stateForm.BriefingReferences[position],
      observation: e.target.value,
    };

    setStateForm({
      ...stateForm,
    })
  }

  return (
    <>
      <Title>Referências</Title>
      <Row style={{
        justifyContent: 'space-between',
        padding: '0 0',
      }}>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingReferences[0]?.id}
            id={'references_0'}
            briefingReference={briefingData?.BriefingReferences[0]}
            handleChangeImage={handleChangeImage}
          />
          <DashTextArea
            id={`referenceObservation_0`}
            key={`referenceObservation_0`}
            fullWidth
            placeholder="Observações"
            name="reference0"
            value={briefingData?.BriefingReferences[0]?.observation || ""}
            onChange={handleInput}
          />
        </div>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingReferences[1]?.id}
            id={'references_1'}
            briefingReference={briefingData?.BriefingReferences[1]}
            handleChangeImage={handleChangeImage}
          />
          <DashTextArea
            id={`referenceObservation_1`}
            key={`referenceObservation_1`}
            fullWidth
            placeholder="Observações"
            name="reference1"
            value={briefingData?.BriefingReferences[1]?.observation || ""}
            onChange={handleInput}
          />
        </div>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingReferences[2]?.id}
            id={'references_2'}
            briefingReference={briefingData?.BriefingReferences[2]}
            handleChangeImage={handleChangeImage}
          />
          <DashTextArea
            id={`referenceObservation_2`}
            key={`referenceObservation_2`}
            fullWidth
            placeholder="Observações"
            name="reference2"
            value={briefingData?.BriefingReferences[2]?.observation || ""}
            onChange={handleInput}
          />
        </div>
        <div>
          <FragmentUploadReferences
            key={briefingData?.BriefingReferences[3]?.id}
            id={'references_3'}
            briefingReference={briefingData?.BriefingReferences[3]}
            handleChangeImage={handleChangeImage}
          />
          <DashTextArea
            id={`referenceObservation_3`}
            key={`referenceObservation_3`}
            fullWidth
            placeholder="Observações"
            name="reference3"
            value={briefingData?.BriefingReferences[3]?.observation || ""}
            onChange={handleInput}
          />
        </div>
      </Row>
    </>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0;
  flex-wrap: wrap;
  
  
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 40rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 950px) {
    align-items: center;
    flex-direction: column;

    > span {
      flex-direction: column;
      align-items: center;
      
    }
  }

`