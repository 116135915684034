import { UploadFileDownload } from "../../../../shared-components";
import {
  Wrapper,
  Row,
  Column,
  Space,
  AvatarExpositor,
  AvatarMontador,
  AvatarProjetista
} from "./Styles";
import { useStepForm } from "../StepFormContext";
import SwitchButton from "../switchButton/SwitchButton";
import { useStepActions } from "../useStepActions";



const ART = () => {

  const { stateForm, setStateForm } = useStepForm();


  const {
    handleChecked,
    handleGetFile,
    isBuilder,
    isExhibitor,
  } = useStepActions();


  const expositor = isExhibitor();
  const montador = isBuilder();

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">RRT de Projeto + Execução</h2>
      </div>
      <Row>
        <Column>
          <span>RRT Projeto</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="artProjeto"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              link={stateForm?.BriefingManagerUploads?.artProjeto?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="artProjeto"
              checked={stateForm?.BriefingManagerUploads?.artProjeto?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <span>Memorial Descritivo</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="memorialDescritivo"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              link={stateForm?.BriefingManagerUploads?.memorialDescritivo?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="memorialDescritivo"
              checked={stateForm?.BriefingManagerUploads?.memorialDescritivo?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <span>Planta Elétrica</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="plantaEletrica"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              link={stateForm?.BriefingManagerUploads?.plantaEletrica?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="plantaEletrica"
              checked={stateForm?.BriefingManagerUploads?.plantaEletrica?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <span>Cota de Elevação</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="cotaElevacao"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"60%"}
              link={stateForm?.BriefingManagerUploads?.cotaElevacao?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="cotaElevacao"
              checked={stateForm?.BriefingManagerUploads?.cotaElevacao?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
        </Column>
        <Space />
        <Column>
          <span>Planta Baixa</span>
          <Row>
            <AvatarProjetista />
            <UploadFileDownload
              name="plantaBaixa"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              link={stateForm?.BriefingManagerUploads?.plantaBaixa?.link}
              getFile={handleGetFile}
              disabled={expositor}
            />
            <SwitchButton
              name="plantaBaixa"
              checked={stateForm?.BriefingManagerUploads?.plantaBaixa?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <span>ART Execução</span>
          <Row>
            <AvatarProjetista />
            <AvatarMontador />
            <UploadFileDownload
              name="artExecucao"
              tooltip={"Expositor deve enviar o arquivo"}
              width={"80%"}
              link={stateForm?.BriefingManagerUploads?.artExecucao?.link}
              disabled={expositor}
              getFile={handleGetFile}
            />
            <SwitchButton
              name="artExecucao"
              checked={stateForm?.BriefingManagerUploads?.artExecucao?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
          <span>Manual do Evento ou Feria</span>
          <Row>
            <AvatarProjetista />
            <AvatarMontador />
            <UploadFileDownload
              name="listaEquipeMontagem"
              tooltip={"Expositor deve enviar o arquivo"}
              link={stateForm?.BriefingManagerUploads?.listaEquipeMontagem?.link}
              disabled={expositor}
              width={"80%"}
              getFile={handleGetFile}
            />
            <SwitchButton
              name="listaEquipeMontagem"
              checked={stateForm?.BriefingManagerUploads?.listaEquipeMontagem?.validated}
              handleChecked={handleChecked}
              disabled={montador}
            />
          </Row>
        </Column>
      </Row>
    </Wrapper >
  );
};


export { ART }