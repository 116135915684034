import { Chip, MenuItem, Stack } from "@mui/material";
import { DashSelect } from "../../../shared-components/DashSelect";
import styled from "@emotion/styled";
import { COLORS } from "../../../helpers/types";
import { listTicketTypes } from "../../../api/promoter";

export default function FragmentTickets({ role, handleChange, stateForm, handleChipTicket }) {
  const types = listTicketTypes();

  return (
    <>
      <span>Ingressos</span>
      <Row style={{ marginTop: "0" }}>
        <div>
          <p>Que tipo de ingresso você deseja criar?</p>
          <br />
          <WrapperStack role={role}>
            <Stack direction="row">
              {types.map((type) => (
                <Chip
                  key={type.id}
                  variant="outlined"
                  label={type.name}
                  clickable
                  required
                  sx={{
                    margin: '5px',
                    width: '98%',
                    color: stateForm.ticketType === type.name ? '#ffffff' : 'default',
                    background: stateForm.ticketType === type.name ? COLORS[role] : 'default',
                  }}
                  onClick={() => handleChipTicket(type.name)}
                />
              ))}
            </Stack>
          </WrapperStack>
        </div>

        <DashSelect
          title="Nomenclatura"
          placeholder="Ingresso"
          fullWidth
          name="ticketName"
          onChange={handleChange}
          required
          value={stateForm.ticketName ? stateForm.ticketName : ""}
        >
          <MenuItem value={"Inscrição"}>Inscrição</MenuItem>
          <MenuItem value={"Contribuição"}>Contribuição</MenuItem>
        </DashSelect>
      </Row >

    </>
  );
}

const WrapperStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & .MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium {
    cursor: pointer;

    span {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  & .MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium:active {
    background-color: ${props => COLORS[props.role]};
    color: white;
  }

  & .MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium:hover {
    background-color: ${props => COLORS[props.role]};
    color: white;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 30px;
  margin: 2rem 0;
  > p {
    flex: 2;
  }
`;