import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "antd/es/avatar/avatar";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useFilter from "./hooks/useFilter";
import exhibitorAvatar from "../../../images/Frame-122.png";
import adminAvatar from "../../../images/Frame-125.png";
import builderAvatar from "../../../images/Frame-124.png";
import designerAvatar from "../../../images/Frame-126.png";
import { DashInput, NRoundedButton } from "../../../shared-components";
import DialogUser from "./fragments/DialogUser";
import { formatDateLocale } from "../../../helpers";
import { changeOwnerBriefing, archiveBriefing } from "../../../api/admin";
import { useNotification } from "../../../context/NotificationContext";

const columns = [
  { id: "eventName", label: "Nome do Evento", minWidth: 170 },
  { id: "owner", label: "Solicitante", minWidth: 100 },
  { id: "date", label: "Criado/Atualizado", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "actions", label: "Ações", minWidth: 100 },
];

function MenuAction({
  openMenu,
  briefing,
  handleCloseMenu,
  menuAnchor,
  handleOpenModalUser,
  handleArchiveBriefing,
  handleGestor
}) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={menuAnchor}
      open={openMenu}
      onClose={handleCloseMenu}
      MenuListProps={{
        sx: {
          "& .MuiMenuItem-root": {
            fontSize: "1.6rem",
            fontWeight: "300",
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          handleOpenModalUser();
        }}
      >
        Atribuir para Usuário
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleArchiveBriefing(briefing.id);
          handleCloseMenu();
        }}
      >
        Arquivar
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          handleGestor();
        }}
      >
        Gestor
      </MenuItem>
    </Menu>
  );
}

function renderRoleBriefing(briefingData) {
  if (briefingData?.User?.Exhibitors[0]?.name) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem'
      }}>
        <Avatar src={exhibitorAvatar} />

        <div style={{
          display: 'flex',
          flexDirection: 'column',

        }}>
          <strong
          >
            {briefingData?.User?.Exhibitors[0]?.name}
          </strong>
          {briefingData?.User?.email || "Sem email"}
        </div>


      </div>
    );
  }

  if (briefingData?.User?.Builders[0]?.name) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem'
      }} >
        <Avatar alt="montador" src={builderAvatar} />
        <div style={{
          display: 'flex',
          flexDirection: 'column',

        }}>
          <strong
          >
            {briefingData?.User?.Exhibitors[0]?.name}
          </strong>
          {briefingData?.User?.email || "Sem email"}
        </div>
      </div>
    );
  }

  if (briefingData?.User?.Promoter[0]?.name) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem'
      }}>
        <Avatar alt="admin" src={adminAvatar} />
        <div style={{
          display: 'flex',
          flexDirection: 'column',

        }}>
          <strong
          >
            {briefingData?.User?.Exhibitors[0]?.name}
          </strong>
          {briefingData?.User?.email || "Sem email"}
        </div>
      </div>
    );
  }
  if (briefingData?.User?.Designers[0]?.name) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem'
      }}>
        <Avatar alt="designer" src={designerAvatar} />
        <strong
          style={{
            margin: "1rem",
          }}
        >
          {briefingData?.User?.Designers[0]?.name}
        </strong>
      </div>
    );
  }
}

function renderButtonStatus(status) {
  if (status === "ABERTO") {
    return <button className="btn-aberto">Sem proposta</button>;
  }
  if (status === "EM_NEGOCIAÇÃO") {
    return <button className="btn-andamento">Com proposta</button>;
  }
  if (status === "ACEITO") {
    return <button className="btn-aprovado">Proposta aceita</button>;
  }
}

function RowCell({ briefing }) {
  const [modalExhibitor, setModalExhibitor] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(briefing?.usersId || "");
  const notification = useNotification();
  const navigate = useNavigate();

  function handleCloseModalUser() {
    setModalExhibitor(false);
  }

  function handleOpenModalUser() {
    setModalExhibitor(true);
  }

  function handleOpenMenu(event) {
    setMenuAnchor(event.currentTarget);
    setOpenMenu(true);
  }

  function handleCloseMenu() {
    setOpenMenu(false);
  }

  function handleUser({ target: { value } }) {
    setSelectedUser(value);
  }

  function handleArchiveBriefing(briefingId) {
    archiveBriefing(briefingId)
      .then((res) => {
        //TODO: HERE
        notification.show({
          message: "Briefing arquivado",
        });
      })
      .catch((error) => {
        notification.show({
          message: "Não foi possível arquivar esse briefing",
          type: "error",
        });
      });
  }

  function submitUser() {
    changeOwnerBriefing({
      briefingId: briefing.id,
      usersId: selectedUser,
    })
      .then((res) => {
        notification.show({
          message: "Briefing atribuido com sucesso",
        });
      })
      .catch((err) => {
        notification.show({
          message: err.message || "Houve um erro",
          type: "error",
        });
      });
    handleCloseModalUser();
  }

  function openBriefing() {
    navigate(`/editar-briefing/${briefing.id}`);
  }

  function handleGestor() {
    navigate(`/admin/gestor/${briefing.id}`);
  }

  const totalArea = briefing.length * briefing.depth;
  const owner =
    briefing.User?.Exhibitors[0]?.name ||
    briefing.User?.Builders[0]?.name ||
    briefing.User?.Promoter[0]?.name ||
    "Usuário sem cadastro completo";

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={briefing.id}
      style={{
        cursor: "pointer",
      }}
    >
      <DialogUser
        title={"Para quem você quer enviar esse briefing"}
        open={modalExhibitor}
        handleClose={handleCloseModalUser}
        handleUser={handleUser}
        selectedUser={selectedUser}
        submitUser={submitUser}
      />
      <TableCell
        key={`eventName-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontWeight: 700,
          fontSize: "1.3rem",
          padding: "2rem",
        }}
      >
        {owner} - {briefing.eventName || "Sem nome de evento"} - {totalArea} m²
        - {briefing.id}
      </TableCell>
      <TableCell
        key={`owner-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontWeight: 700,
          fontSize: "1.2rem",
          padding: "2rem",
        }}
      >
        {renderRoleBriefing(briefing)}
      </TableCell>
      <TableCell
        key={`date-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontSize: "1.2rem",
          padding: "2rem",
        }}
      >
        <div>
          <strong>Criado:</strong> {formatDateLocale(briefing.createdAt)}
        </div>
        <div>
          <strong>Atualizado:</strong> {formatDateLocale(briefing.updatedAt)}
        </div>
      </TableCell>
      <TableCell
        key={`status-${briefing.id}`}
        onClick={openBriefing}
        sx={{
          fontSize: "1.2rem",
          padding: "2rem",
          justifyContent: "flex-start",
        }}
      >
        {renderButtonStatus(briefing.status)}
      </TableCell>
      <TableCell>
        <MoreVertSharpIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={handleOpenMenu}
        />
        <MenuAction
          key={`actions-${briefing.id}`}
          briefing={briefing}
          openMenu={openMenu}
          handleCloseMenu={handleCloseMenu}
          menuAnchor={menuAnchor}
          handleOpenModalUser={handleOpenModalUser}
          handleArchiveBriefing={handleArchiveBriefing}
          handleGestor={handleGestor}
        />
      </TableCell>
    </TableRow>
  );
}

const BriefingTable = ({ data, setFetchType }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const {
    handleFilter,
    handleFilterType,
    searchTerm,
    setSearchTerm,
    filteredBriefings,
    filter,
    filterType,
    setFilter,
  } = useFilter(data);

  const handleClearFilter = () => {
    setFilter("all");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Paper
        sx={{
          overflow: "hidden",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "wrap",
            padding: "2rem",
          }}
        >
          <span
            style={{
              flex: 1,
              display: "flex",
              flexWrap: "wrap",
              flexDirection: {
                md: "row",
                xs: "column",
              },
            }}
          >
            <DashInput
              value={searchTerm}
              placeholder={"Busque por um termo..."}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: {
                  xs: "100%",
                },
              }}
            />
            <ButtonType
              title={"Projeto"}
              active={filterType === "projeto"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("projeto");
                handleFilterType("projeto");
              }}
            />
            <ButtonType
              title={"Montagem"}
              active={filterType === "montagem"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("montagem");
                handleFilterType("montagem");
              }}
            />
            <ButtonType
              title={"Projeto + Montagem"}
              active={filterType === "projeto_montagem"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("projeto_montagem");
                handleFilterType("projeto_montagem");
              }}
            />
            <ButtonType
              title={"Todos"}
              active={filterType === "todos"}
              color={"#764FAA"}
              onClick={() => {
                setFetchType("todos");
                handleFilterType("todos");
              }}
            />
            <ButtonType
              title={"Sem proposta"}
              active={filter === "ABERTO"}
              color={"#764FAA"}
              onClick={() => handleFilter("ABERTO")}
            />
            <ButtonType
              title={"Com proposta"}
              active={filter === "EM_NEGOCIAÇÃO"}
              color={"#764FAA"}
              onClick={() => handleFilter("EM_NEGOCIAÇÃO")}
            />
            <ButtonType
              title={"Proposta aceita"}
              active={filter === "ACEITO"}
              color={"#764FAA"}
              onClick={() => handleFilter("ACEITO")}
            />
            <ButtonType
              title={"mostrar todos"}
              color={"#764FAA"}
              onClick={handleClearFilter}
              active={filter === "all"}
            />
            <ButtonType
              title={"Arquivados"}
              color={"#764FAAAA"}
              onClick={() => {
                setFetchType("arquivados");
                handleFilterType("arquivados");
                handleClearFilter();
              }}
              active={filterType === "arquivados"}
            />
          </span>
          <span>
            <MoreVertSharpIcon
              sx={{
                cursor: "pointer",
              }}
            />
          </span>
        </Toolbar>
        <TableContainer sx={{ height: "70vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontSize: "1.2rem" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBriefings
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((briefing) => (
                  <RowCell key={`cell-${briefing.id}`} briefing={briefing} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredBriefings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Linhas por página"}
        />
      </Paper>
    </div>
  );
};

const ButtonType = styled(NRoundedButton)`
  padding: 0rem 1rem;
  min-width: 2rem;
  margin: 0 1rem;
`;

const ButtonHidden = styled(NRoundedButton)`
  padding: 0rem 1rem;
  min-width: 1rem;
  margin: 0 1rem;
  font-size: 1rem;

  &:hover {
    color: #764faa;
  }
`;

export default BriefingTable;
