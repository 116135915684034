import { useLayoutEffect, useState } from "react";

function useMatchMedia(queries, defaultValues) {
    const initialValues = defaultValues && defaultValues.length ? defaultValues : Array(queries.length).fill(false);

    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    const getValue = () => {
        const matchedQueries = mediaQueryLists.map((mql) => mql.matches);

        return matchedQueries;
    };

    const [value, setValue] = useState(getValue);

    useLayoutEffect(() => {
        const handler = () => setValue(getValue);
        mediaQueryLists.forEach((mql) => mql.addListener(handler));
        return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    }, [mediaQueryLists]);

    if (typeof window === "undefined") return initialValues;

    return value;
}

export { useMatchMedia };
