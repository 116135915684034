import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import Header from "../../components/DashboardPromotorComponents/Header";
import { NRoundedButton } from "../../shared-components/NRoundedButton";
import { listEvents } from "../../api/promoter";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function FragmentEvents({ data, isLoading, error }) {
  if (isLoading) {
    return <>carregando...</>;
  }

  if (data) {
    return (
      <>
        <div>
          <ErrorOutlinedIcon style={{ width: "32px", height: "36px" }} />
          <p>Você ainda não tem eventos cadastrados</p>
        </div>
        <div>
          <Link to="/promotor-cadastro-evento">
            <button>novo evento +</button>
          </Link>
        </div>
      </>
    );
  }
}

function formatDateLocale(value) {
  if (!value) {
    return 'dd/mm/aaaa';
  }

  return new Date(value).toLocaleDateString('pt-Br');
}

const DashboardPromotor = () => {
  const { data, isLoading, error } = useQuery("promoter-events", listEvents);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (data) {
      setEvents(data);
    }
  }, [data]);

  return (
    <Content>
      <Header role={"PROMOTOR"} />
      <ActionButtons>
        <Link to="/promotor-cadastro-evento">
          <NRoundedButton title={"Cadastrar eventos"} color={"#E7054C"} />
        </Link>
        <Link to='/promotor-gerenciar-eventos'>
          <NRoundedButton title={"Meus eventos"} color={"#E7054C"} />
        </Link>
      </ActionButtons>

      <div>
        <div className="main-cont">
          {events.length > 0 ? (
            events.map((event) => (
              <div key={event.id} className="card-event">
                <div className="container-image">
                  <img
                    className="card-image"
                    src={event.largeImage}
                    alt={event.name} />
                </div>

                <div className="card-text">
                  <h1>{event.name}</h1>
                  <div className="card-description">
                    <div className="card-title">
                      <p><CalendarMonthIcon />{formatDateLocale(event.startDate)} </p>
                      <p><PlaceIcon /> {event.location}</p>
                    </div>
                    <div>
                      <p><AccessTimeIcon />{event.startTime}</p>
                    </div>
                  </div>
                </div>
                <Link className="event-edit" to={`/promotor-editar-evento/${event.id}`}>
                  <NRoundedButton title={"Editar"} active={true} color={"#E7054C"} />
                </Link>
              </div>
            ))
          ) : (
            <div>
              <Content>
                <FragmentEvents
                  data={data}
                  isLoading={isLoading}
                  error={error}
                />
              </Content>
            </div>
          )}
        </div>
      </div>
    </ Content>
  );
};

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 10px;
`;

const Content = styled.div`
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
  }

  .main-cont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .event-edit {
    font-size: 2rem;
    align-self: flex-end;
    padding: 20px;
    text-decoration: underline;
    color: #E7054C;
    cursor: pointer;
  }

  .card-event {
    width: 40rem;
    height: 60rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #ffff;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    border-radius: 10px;
    
    
    h1 {
      font-size: 3rem;
    }
  }

  .container-image {
    margin: 15px auto;
    margin-bottom: 0;
  }

  .card-image {
    width: 250px;
    height: 200px;    
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    object-fit: cover;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin-top: 0;
  }

  .card-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

  }

  .card-description {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 5rem;
    
    p {
      font-size: 2rem;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  
`;

export default DashboardPromotor;
