import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "../components/privateRoute/PrivateRoutes";
import BriefingManager from "../components/BriefingManager/BriefingManager";

import Gestor from "../components/DashboardExpositorComponents/fluxProjetoEmontagem/Gestor";

import ServicosProjetista from "../components/DashboardProjetistaComponents/editarPerfil/ServicosProjetista";
import EmAberto from "../components/DashboardProjetistaComponents/SolicitProjeto/EmAberto";
import ProjetistaLayout from "../layouts/ProjetistatLayout";
import { ProjetistaProfile } from "../components/dashboardProfile/ProjetistaProfile";
import { ProjetistaProfileForm } from "../components/dashboardProfile/ProjetistaProfileForm";
import { FragmentProfileForm } from "../components/dashboardProfile/fragments/FragmentProfileForm";
import { FragmentPersonForm } from "../components/dashboardProfile/fragments/FragmentPersonForm";
import { FragmentSecurity } from "../components/dashboardProfile/fragments/FragmentSecurity";
import EmNegociacao from "../components/DashboardProjetistaComponents/SolicitProjeto/EmNegociacao";
import MemorialDesc from "../components/DashboardProjetistaComponents/SolicitProjeto/MemorialDescritivo/MemorialDesc";
import Aprovado from "../components/DashboardProjetistaComponents/SolicitProjeto/Aprovado";
import EditBriefing from "../pages/EditBriefing/EditBriefing";
import ListBriefing from "../pages/ListBriefing/ListBriefing";
import NovoBriefing from "../pages/Briefing/NovoBriefing";
import TermsOfService from "../pages/TermosDeUso/TermsOfService";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route element={<PrivateRoutes role="PROJETISTA" />}>
    <Route
      path="/perfil-projetista"
      element={
        <ProjetistaLayout>
          <ProjetistaProfile />
        </ProjetistaLayout>
      }
      exact
      strict key="perfil-projetista"
    />
    <Route
      path="/projetista/novo-briefing"
      element={
        <ProjetistaLayout>
          <NovoBriefing />
        </ProjetistaLayout>
      }
      exact
      strict key="novo-briefing"
    />
    <Route
      path="/projetista/briefing/:briefingId"
      element={
        <ProjetistaLayout>
          <EditBriefing />
        </ProjetistaLayout>
      }
      exact
      strict key="projetista-briefing"
    />
    <Route
      path="/projetista/lista"
      element={
        <ProjetistaLayout>
          <ListBriefing />
        </ProjetistaLayout>
      }
      exact
      strict key="projetista-list-briefings"
    />
    <Route
      path="/edit-briefing-aaa/:briefingId"
      element={
        <ProjetistaLayout>
          <EditBriefing />
        </ProjetistaLayout>
      }
      exact
      strict key="projetista-list-briefings"
    />
    <Route
      path="/edit-pagina-projetista"
      element={
        <ProjetistaLayout>
          <ProjetistaProfileForm
            title={
              <span>
                Hey <strong>Projetista</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentProfileForm color="#FDC22A" />
          </ProjetistaProfileForm>
        </ProjetistaLayout>
      }
      exact
      strict key="edit-pagina-projetista"
    />
    <Route
      path="/info-pessoais-projetista"
      element={
        <ProjetistaLayout>
          <ProjetistaProfileForm
            title={
              <span>
                Hey <strong>Projetista</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentPersonForm color="#FDC22A" />
          </ProjetistaProfileForm>
        </ProjetistaLayout>
      }
      exact
      strict key="info-pessoais-projetista"
    />
    <Route
      path="/entrar-seguranca-projetista"
      element={
        <ProjetistaLayout>
          <ProjetistaProfileForm
            title={
              <span>
                Hey <strong>Projetista</strong>, edite seu cadastro aqui
              </span>
            }
          >
            <FragmentSecurity color="#FDC22A" />
          </ProjetistaProfileForm>
        </ProjetistaLayout>
      }
      exact
      strict key="entrar-seguranca-projetista"
    />
    <Route
      path="/servicos-projetista"
      element={<ServicosProjetista />}
      exact
      strict key="servicos-projetista"
    />
    <Route path="/solicit-aberto-projetista"
      element={<EmAberto />} exact strict key="solicit-aberto-projetista" />
    <Route
      path="/solicit-negociacao-projetista"
      element={<EmNegociacao />}
      exact
      strict key="solicit-negociacao-projetista"
    />
    <Route
      path="/solicit-aprovado-projetista"
      element={<Aprovado />}
      exact
      strict key="solicit-aprovado-projetista"
    />
    <Route
      path="/memorial-descritivo/:briefingId"
      element={
        <ProjetistaLayout>
          <MemorialDesc />
        </ProjetistaLayout>
      }
      exact
      strict key="memorial-descritivo"
    />
    <Route path="/projetista/gestor/:briefingId"
      element={<ProjetistaLayout>
        <Gestor />
      </ProjetistaLayout>}
      exact
      strict key="gestor-projetista"
    />
    <Route path="/projetista/projeto&montagem" element={<ProjetistaLayout>
      <BriefingManager />
    </ProjetistaLayout>} exact strict key="projeto&montagem" />
    <Route path="/projetista/termos-de-uso" element={<ProjetistaLayout sideBarOff>
      <TermsOfService />
    </ProjetistaLayout>} exact strict key="termos-de-uso" />
  </Route>
]

