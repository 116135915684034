import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DashTextArea, NRoundedButton } from "../../../shared-components";
import { updateBriefing, getBriefing, updateBriefingTodo } from "../../../api/briefing";
import { useNotification } from "../../../context/NotificationContext";
import {
  Row,
  Action,
  Column,
  SubTitle,
  Button,
  ProjectBlock,
} from "./Styles";
import DialogBriefing from "./fragments/DialogBriefing";
import { formatDateLocale } from "../../../helpers/formatDate";
import {
  CardContainer,
  ToDoTasks,
} from "../../../shared-components";
import { FragmentUploadProject } from "./fragments/FragmentUploadProject";
import { Link } from "react-router-dom";
import Loading from "../../../shared-components/Loading";


function renderMemorialStatus(data) {
  if (data.Memorial.length === 0) {
    return (
      <span style={{ fontWeight: '500' }}>
        Você ainda não preencheu o memorial
      </span>
    )
  }
  if (data.Memorial.length !== 0) {
    return (
      <span style={{ fontWeight: '500' }}>
        Você preencheu o memorial porém o expositor ainda não aprovou
      </span>
    )
  }
  if (data.Approval[0]?.status === 'approved') {
    return (
      <span style={{ fontWeight: '500' }}>
        Memorial aprovado pelo expositor
      </span>
    )
  }
  if (data.Approval[0]?.status === 'disapproved') {

    return (
      <div>
        <span style={{ fontWeight: '500' }}>
          Memorial rejeitado pelo expositor
        </span>
        <div>
          <span style={{ fontWeight: '500' }}>Motivo: </span> {data.Approval[0].comment}
        </div>

      </div>
    )
  }

}


export default function CardBriefing({
  briefingData,
}) {
  const { data, isLoading } = useQuery(`briefing-${briefingData?.Briefing.id}`, () => getBriefing(briefingData?.Briefing.id))

  const [modalBriefing, setModalBriefing] = useState(false);
  const [projectFiles, setProjectFiles] = useState(createProjectsArray)
  const [tasks, setTasks] = useState(() => {
    if (briefingData?.Briefing?.BriefingManagerTasks?.length !== 0) {
      return JSON.parse(briefingData?.Briefing?.BriefingManagerTasks[0].tasks);
    }
    return []
  })
  const notification = useNotification();


  function createProjectsArray() {
    const projects = briefingData?.Briefing.BriefingProjects || [];
    const projectsLength = projects?.length;

    for (let i = projectsLength; i < 4; i++) {
      projects.push({
        observations: ''
      })
    }

    return projects;
  }

  function closeBriefingModal() {
    setModalBriefing(false);
  }

  function showBriefing() {
    setModalBriefing(true)
  }


  function handleChangeProject(e, res) {
    const position = e.target.id.split("_")[1]

    const uploadedImage = {
      link: res?.name
    }

    const refs = projectFiles;
    refs[position] = uploadedImage;
    setProjectFiles([
      ...refs,
    ])
  }

  function handleChangeObservations(e) {
    const position = e.target.id.split("_")[1]

    const refs = projectFiles;
    refs[position] = {
      ...refs[position],
      observations: e.target.value,
    };
    setProjectFiles([
      ...refs,
    ])
  }

  function toggleTask(taskId) {
    const newTasks = tasks.map(v => {
      if (v.id === taskId) {
        return ({
          ...v,
          done: !v.done
        })
      } else {
        return v;
      }
    })

    setTasks(newTasks)
  }

  function submitForm() {
    updateBriefing({
      briefingId: briefingData.briefingId,
      projects: [
        ...projectFiles
      ]
    }).then(res => {
      updateBriefingTodo(briefingData.briefingId, tasks).then(res => {
        notification.show({ message: "Briefing atualizado :)" })
      })
    })
      .catch(err => {
        notification.show({ message: "Não foi possível atualziar o briefing", type: "error" })
      })
  }

  if (isLoading) {
    return (<Loading role="PROJETISTA" />)
  }

  return (
    <CardContainer
      title={`${briefingData?.Briefing?.eventName}`}
    >
      <DialogBriefing
        open={modalBriefing}
        handleClose={closeBriefingModal}
        title={"Briefing"}
        data={data}
      />
      <Column>
        <SubTitle>Resumo do Briefing</SubTitle>
        <div>
          <strong>Prazo</strong>: {formatDateLocale(briefingData?.Briefing.deadline)}
        </div>
        <div>
          <strong>Tamanho: </strong>
          {briefingData?.Briefing.length}m (altura)
          <span> x </span>
          {briefingData?.Briefing?.depth}m (largura)
          <span> = </span>
          {briefingData?.Briefing?.length * briefingData?.Briefing?.depth}m²
        </div>
        <div>
          <strong>Status do Memorial:</strong> {renderMemorialStatus(data)}
        </div>
      </Column>

      <SubTitle>Projeto</SubTitle>
      <Row style={{
        flexWrap: 'wrap',

      }}>
        {projectFiles.map((project, index) => (
          <ProjectBlock
            key={`project-${index}`}
          >
            <FragmentUploadProject
              key={`upload-${index}`}
              id={`project_${index}`}
              briefingProjects={project}
              handleChangeProject={handleChangeProject}
            />
            <DashTextArea
              key={`observations-${index}`}
              id={`project_${index}`}
              value={project.observations || ''}
              onChange={handleChangeObservations}
              placeholder={"Caso necessário adicione aqui uma observação"}
            />
          </ProjectBlock>
        ))}
      </Row>
      {tasks && tasks.length > 0 ? (
        <Row>
          <ToDoTasks
            tasks={tasks}
            disableTitle={true}
            disableRemove={true}
            onToggle={toggleTask}
            title={"Alterações do Expositor"}
            color={"#FDC22A"}
          />
        </Row>
      ) : ''}
      <Action>
        <Button onClick={showBriefing}>ver briefing completo</Button>
        <Link to={`/memorial-descritivo/${briefingData?.Briefing?.id}`} >
          <NRoundedButton
            title={"Memorial Descritivo"}
            active={"true"}
            color={"#FDC22A"}
          />
        </Link>
        <NRoundedButton
          title={"Atualizar Projeto"}
          active={"true"}
          color={"#FDC22A"}
          onClick={submitForm}
        />
      </Action>
    </CardContainer>
  )
}