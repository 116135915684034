import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from '@mui/material/Button';

export function Alert({
  open,
  handleClose,
  text
}) {

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontWeight: 500,
            }}
          >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{
            fontFamily: "Montserrat",
            fontSize: "1.5rem",
            color: "#92939c",
          }}
          >
            Revisar
          </Button>
          <Button
            onClick={() => { }}
            autoFocus
            style={{
              fontFamily: "Montserrat",
              fontSize: "1.5rem",
              color: "#ff8400",
              fontWeight: "bold"
            }}>
            Avançar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}