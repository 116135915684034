import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import "../style/styleSolicitPlataforma.css";
import { NRoundedButton } from "../../../shared-components";
import { listAcceptBriefings } from "../../../api/briefing";
import { ActionButton } from "./Styles";
import Header from "../Header";
import { DialogTerms } from "./fragments/DialogTerms";
import Loading from "../../../shared-components/Loading";

function FragmentAccetBriefings({ data, isLoading, error }) {
  const [clickBriefing, setClickBriefing] = useState("");
  const [open, setOpen] = useState(false);

  function handleModal() {
    setOpen(() => !open);
  }

  if (isLoading) {
    return <>carregando...</>;
  }

  if (data) {
    return (
      <>
        <DialogTerms
          title={"Termos de Serviço"}
          open={open}
          handleModal={handleModal}
          briefing={clickBriefing}
        />
        {data.map((briefing, index) => (
          <div className="solicits-open" key={`${briefing.id}`}>
            <h2 className="company-name">{briefing?.Briefing?.eventName}</h2>
            <Link to={`/gestor/${briefing?.Briefing?.id}`}>
              <NRoundedButton
                title="Ir para o gestor"
                active={true}
                color="#23B100"
              />
            </Link>
          </div>
        ))}
      </>
    );
  }
}

const SolicitEmNegociacao = (props) => {
  const { data, isLoading, error } = useQuery(
    "accept-briefings",
    listAcceptBriefings
  );

  if (isLoading) {
    return (
      <>
        <Loading role="MONTADOR" />
      </>
    )
  }

  return (
    <div>
      <Header title="Solicitações aprovadas" />
      <ActionButton>
        <Link to="/solicit-aberto-montador">
          <button className="btn-open-mont">em aberto</button>
        </Link>
        <Link to="/montador/lista">
          <button className="btn-open-mont">em negociação</button>
        </Link>
        <Link>
          <button className="btn-open6">aprovadas</button>
        </Link>
      </ActionButton>
      <ContentWrapper>
        <FragmentAccetBriefings
          data={data}
          isLoading={isLoading}
          error={error}
        />
      </ContentWrapper>
    </div>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default SolicitEmNegociacao;
