import {
  DashInput,
  DashTextArea
} from "../../../shared-components";
import {
  Title,
  Row,
} from "./StyledComponents";

export default function FragmentPromoter({
  stateForm,
  handleChange,
}) {

  return (
    <>
      <Title>Promoter e Buffet</Title>
      <Row style={{
        alignItems: 'flex-start'
      }}>
        <DashInput
          title={"Promoter (quantidade)"}
          type="number"
          id={"promoterQuantity"}
          name={"promoterQuantity"}
          fullWidth
          value={stateForm?.promoterQuantity || 0}
          onChange={handleChange}
        />
        <DashTextArea
          title={"Buffet "}
          id={"buffet"}
          name={"buffet"}
          value={stateForm?.buffet || ""}
          onChange={handleChange}
        />
      </Row>
      <Title>Etapa Livre</Title>

      <DashTextArea
        title={"Preencha aqui qualquer detalhe que você ache importante."}
        id={"observations"}
        name={"observations"}
        value={stateForm?.observations || ""}
        onChange={handleChange}
        width={"50%"}
      />

    </>
  )
}