import React from "react";
import "./styleEditProfileMontador.css";
import ExpoLogo from "../../../images/Frame-124.png";
import GreenCircle from "../../../images/green-circle.png";
import SideBarMontador from "../../sidebar/SideBarMontador";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const ServicosRegioesMMontador = () => {
  return (
    <div className="cont-geral">
      <SideBarMontador />
      <div className="expo1">
        <div className="expositor1">
          <div>
            <img className="expo-logo" src={ExpoLogo} alt="" width="120px" />
          </div>
          <h2 style={{ margin: "10px" }}>
            <div
              className="title-dash-expo"
              style={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <span style={{ color: "black" }}>Hey </span>
              <span style={{ color: "#22da28" }}>Montador</span>
              <span style={{ color: "black" }}>
                , edite seu <br /> cadastro aqui
              </span>
            </div>
          </h2>
          <div>
            <img
              className="orange-circle"
              src={GreenCircle}
              alt=""
              width="220px"
            />
          </div>
        </div>
      </div>
      <div className="expo1">
        <div className="expositor7">
          <div className="cont-inpus-page2">
            <div className="inputs-pagina">
              <div className="div-name">
                <label htmlFor="">
                  <h2 className="title-social">Serviços</h2>
                  <div className="google">
                    <p>Adicone mais opções para sua empresa</p>
                  </div>
                  <input type="text" className="form input-name" />
                </label>
              </div>
              <div>
                <button className="add-service">+ Projeto 3D</button>
                <button className="add-service">+ Projeto técnico</button>
                <button className="add-service">+ Stands</button>
                <button className="add-service">+ Cenografia em geral</button>
                <button className="add-service">+ Impressões gráficas</button>
                <button className="add-service">+ Palco</button>
                <button className="add-service">+ Piso</button>
                <button className="add-service">+ Estrutura de gride</button>
                <button className="add-service">+ Paisagismo</button>
                <button className="add-service">+ Tenda</button>
                <button className="add-service">+ Aluguel de mobiliário</button>
              </div>
              <div className="share-infos2">
                <VisibilityOffOutlinedIcon
                  style={{
                    color: "black",
                    width: "50px",
                    height: "50px",
                    marginTop: "20px",
                  }}
                />
                <p className="text-info">
                  Que informações são compartilhadas com outras pessoas? <br />
                  <br /> A Westand só disponibiliza informações de contato a
                  expositores e promotores depois da proposta ser confirmada.
                </p>
              </div>
              <div className="btns-register">
                <button className="btn-picture">Gravar</button>
                <button className="btn-remove">Cancelar</button>
              </div>
            </div>
            <div className="share-infos personal-info">
              <VisibilityOffOutlinedIcon
                style={{
                  color: "black",
                  width: "50px",
                  height: "50px",
                  marginTop: "20px",
                }}
              />
              <p className="text-info">
                Que informações são compartilhadas com outras pessoas? <br />
                <br /> A Westand só disponibiliza informações de contato a
                expositores e promotores depois da proposta ser confirmada.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicosRegioesMMontador;
