import React from "react";
import BriefingManagerListNovo from "../../shared-components/BriefingManagerListNovo";
import { useQuery } from 'react-query';
import { getFluxBriefingManager } from "../../api/briefingManager";

function BriefingManagerNovo() {

  const { data, error, isLoading } = useQuery('briefingManagerData', getFluxBriefingManager);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      {data && <BriefingManagerListNovo data={data} />}
    </>
  );
}

export default BriefingManagerNovo;

