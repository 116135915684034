import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import { Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Checkbox, Button, Divider, Typography } from '@mui/material';
import { getDonations, updateDonations } from '../../../../api/gestor';
import { useNotification } from '../../../../context/NotificationContext';
import { AvatarExpositor } from '../stepsComponents/Styles';
import { NRoundedButton } from '../../../../shared-components';
import Loading from "../../../../shared-components/Loading";


const colors = [
  'type-item color1',
  'type-item color2',
  'type-item color3',
  'type-item color4',
  'type-item pendency-color',
  'type-item approved-color',
  'type-item reject-color'
]

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function ListItemDonation({
  data,
  labelId,
  parent,
  handleToggle,
  index,
  shouldShowAvatar
}) {
  const [checked, setChecked] = useState(data.checked);

  return (
    <ListItem
      key={`list-${data.id}`}
      role="listitem"
      onClick={() => {
        setChecked(() => !checked)
        handleToggle(data)
      }}
    >
      <ListItemIcon>
        <Checkbox
          sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
          checked={checked || false}
          tabIndex={-1}
          disableRipple
          inputProps={{
            'aria-labelledby': labelId,
          }}

        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={
          <Typography component="span" variant="body2"
            style={{
              fontSize: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
              fontFamily: "Montserrat",
              fontWeight: "400"
            }}>
            <span
              style={{
                fontSize: "16px",
                alignSelf: "center"
              }}>{data.name}</span>
          </Typography>}
      />
      {shouldShowAvatar && data.expositorQuerDoacao ? (
        <AvatarExpositor sx={{ width: 30, height: 30 }} />
      ) : ''}
      <span className={colors[index % colors.length]}>
        {parent.name}
      </span>

    </ListItem>
  )
}

function renderList(list, handleToggle, shouldShowAvatar) {

  return (
    <>
      {list && list.filter(value => value.children.length).map((value, index) => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return value.children.map(child =>
          <ListItemDonation
            key={child.id}
            index={index}
            data={child}
            parent={value}
            labelId={labelId}
            handleToggle={handleToggle}
            shouldShowAvatar={shouldShowAvatar}
          />
        )
      })}
    </>
  )

}


function CustomList({ title, items, handleToggle, shouldShowAvatar }) {

  return (
    <Card>
      <CardHeader
        title={title}
      />
      <Divider />
      <List
        sx={{
          width: "45rem",
          minHeight: '50rem',
          maxHeight: '50rem',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
        className='list'
      >
        {renderList(items, handleToggle, shouldShowAvatar)}
      </List>
    </Card>
  )
};


function TransferList({ data, briefingId }) {
  const [checked, setChecked] = useState([]);
  const [memorial, setMemorial] = useState([]);
  const [expositor, setExpositor] = useState([]);
  const [instituicao, setInstituicao] = useState([]);
  const [montador, setMontador] = useState([]);
  const [donations, setDonations] = useState(data);
  const notification = useNotification();
  const [showContent, setShowContent] = useState(true);

  const memorialChecked = intersection(checked, memorial);
  const expositorChecked = intersection(checked, expositor);
  const instituicaoChecked = intersection(checked, instituicao);


  useEffect(() => {
    const expositorItens = donations.map(value => ({
      ...value,
      children: value.children.filter(v => v.MontadorAceitaDoarExpositor)
    }))
    setExpositor(expositorItens);

    const instituicaoItens = donations.map(value => ({
      ...value,
      children: value.children.filter(v => v.MontadorAceitaDoarOutros)
    }))
    setInstituicao(instituicaoItens);

    const montadorItens = donations.map(value => ({
      ...value,
      children: value.children.filter(v => !v.MontadorAceitaDoarOutros && !v.MontadorAceitaDoarExpositor)
    }))
    setMontador(montadorItens);

    setShowContent(donations.length > 0)
  }, [donations]);

  const sendDonations = async () => {
    try {
      await updateDonations(data[0].briefingId, donations);
      notification.show({
        message: 'Itens de interesse enviados para o montador'
      })
    } catch (error) {
      notification.show({
        message: 'Houve um erro ao enviar os itens...',
        type: 'error'
      })
    }
  };

  const handleToggle = (value) => {
    value.checked = !value.checked;
  };


  const handleCheckedMemorial = () => {
    const newDonation = donations.map(parent => ({
      ...parent,
      children: parent.children.map(child => {
        if (child.checked) {
          return ({
            ...child,
            checked: false,
            MontadorAceitaDoarExpositor: false,
            MontadorAceitaDoarOutros: false,
          })
        }
        return {
          ...child,
          checked: false,
        }
      })
    }))

    setDonations(newDonation)
  };

  const handleCheckedExpositor = () => {
    const newDonation = donations.map(parent => ({
      ...parent,
      children: parent.children.map(child => {
        if (child.checked) {
          return ({
            ...child,
            checked: false,
            MontadorAceitaDoarExpositor: true,
            MontadorAceitaDoarOutros: false,
          })
        }
        return {
          ...child,
          checked: false,
        }
      })
    }))

    setDonations(newDonation)
  };

  const handleCheckedInstituicao = () => {
    const newDonation = donations.map(parent => ({
      ...parent,
      children: parent.children.map(child => {
        if (child.checked) {
          return ({
            ...child,
            checked: false,
            MontadorAceitaDoarOutros: true,
            MontadorAceitaDoarExpositor: false,
          })
        }
        return {
          ...child,
          checked: false,
        }
      })
    }))

    setDonations(newDonation)
  };



  return (
    <ListWrapper>
      <div style={{ textAlign: "center", width: "65%", margin: "auto" }}>
        <p>
          <b>Doar itens: </b>Selecione os itens desejados e selecione a opção "DOAR EXPOSITOR" ou "DOAR INSTITUIÇÃO" para decidir para onde os itens serão destinados.
          <br />
          <b>Recuperar itens doados: </b>Selecione os itens desejados e clique em "PEGAR DE VOLTA".
          <br />
          <br />
        </p>
      </div>
      <Grid container spacing={6} justifyContent="center" alignItems="center">
        <Grid item>
          <CustomList
            title={"Itens do stand"}
            items={montador}
            handleToggle={handleToggle}
            shouldShowAvatar={true}
          />

          <div style={{ display: "flex", marginTop: "8px" }}>
            <AvatarExpositor />
            <p style={{ alignSelf: "center", marginLeft: "8px" }}> = Itens de interesse do expositor.</p>
          </div>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5, width: "21rem", height: "40px" }}
              variant="outlined"
              onClick={handleCheckedExpositor}
              aria-label="move selected right"
            >
              <Typography component="span" variant="body2" style={{ fontSize: '16px', fontFamily: "Montserrat", fontWeight: "400" }}>
                Doar Expositor
              </Typography>
            </Button>
            <Button
              sx={{ my: 0.5, width: "21rem", height: "40px" }}
              variant="outlined"
              onClick={handleCheckedInstituicao}
              aria-label="move selected left"
            >
              <Typography component="span" variant="body2" style={{ fontSize: '16px', fontFamily: "Montserrat", fontWeight: "400" }}>
                Doar Instituição
              </Typography>
            </Button>
            <Button
              sx={{ my: 0.5, width: "21rem", height: "40px" }}
              variant="outlined"
              onClick={handleCheckedMemorial}
              aria-label="move selected left"
            >
              <Typography component="span" variant="body2" style={{ fontSize: '16px', fontFamily: "Montserrat", fontWeight: "400" }}>
                Pegar de volta
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            title={"Doar para Expositor"}
            items={expositor}
            handleToggle={handleToggle}
            shouldShowAvatar={true}
          />
        </Grid>
        <Grid item>
          <CustomList
            title={"Doar para Instituição"}
            items={instituicao}
            handleToggle={handleToggle}
            shouldShowAvatar={true}
          />
        </Grid>
      </Grid>
      <div className='list-submit'>
        <NRoundedButton
          title={'Salvar doações'}
          color={'#ff8400'}
          active={true}
          onClick={sendDonations}
        />
      </div>
    </ListWrapper>
  )

}


export default function FragmentMontadorTransferList() {

  const { briefingId } = useParams();
  const { data, isLoading } = useQuery(`donations-${briefingId}`, () => getDonations(briefingId));

  if (isLoading) {
    return <Loading role={"MONTADOR"} />
  }

  return (
    <TransferList data={data} briefingId={briefingId} />
  )

}


const ListWrapper = styled.div`
  .list-submit {
    width: 20%;
    position: relative;
    left: 75%;
    padding: 2rem 0;
  }


   .type-item {
    padding: 5px 20px;
    border-radius: 2px;
    font-weight: bold;
    margin-right: 1rem;
  }

  .type-item.color1 {
    background-color: #edc69c;
    color: #414141;
  }

  .type-item.color2 {
    background-color: #ed9e9c;
    color: #414141;
  }

  .type-item.color3 {
    background-color: #c69ced;
    color: #414141;
  }

  .type-item.color4 {
    background-color: #9cedc6;
    color: #414141;
  }

  .type-item.pendency-color {
    background-color: #f2f2f2;
    color: #414141;
  }

  .type-item.approved-color {
    background-color: #00ff21;
    color: #fff;
  }

  .type-item.reject-color {
    background-color: #eb3535;
    color: #fff;
  }


  `