import { useEffect } from "react";
import styled from "@emotion/styled";
import { useQuery } from "react-query";
import {
  DashInput,
  NRoundedButton,
  DashSelect,
} from "../../../shared-components";
import { MenuItem } from "@mui/material";
import { useSimpleForm } from "../../../hooks";
import { CardPersonalInfo } from "./CardPersonalInfo";
import { updateProfileInfo, getProfileInfo } from "../../../api/user";
import { useNotification } from "../../../context/NotificationContext";
import { DataArray } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";

export function FragmentPersonForm({ color }) {
  const { data, isLoading } = useQuery("profile-data", getProfileInfo);
  const notification = useNotification();
  const navigate = useNavigate();
  const { userData, refetchUser } = useUser();

  const { stateForm, handleChange, setStateForm } = useSimpleForm({
    firstName: "",
    lastName: "",
    gender: "",
    birthDay: "",
    document: "",
    phone: "",
    emergencyPhone: ""
  });

  useEffect(() => {
    setStateForm(data);
  }, [data]);

  function verifyRequiredInputs(form) {
    let verified = true
    const required = [
      "firstName",
      "lastName",
      "document"
    ]
    required.forEach(input => {
      if (!form[input]) {
        verified = false
      }
    })
    return verified;
  }

  const isAcceptedTerms = user => user.Approval?.terms && user.Approval.terms.status === "approved";

  const isFilledEnterpriseForm = data => (
    !!data.cnpj &&
    !!data.razaoSocial &&
    // !!data.street &&
    // !!data.number &&
    // !!data.neighborhood &&
    !!data.cep &&
    !!data.city &&
    !!data.state
  )

  function submitForm() {
    const verifiedRequiredInputs = verifyRequiredInputs(stateForm);
    if (verifiedRequiredInputs) {
      updateProfileInfo({ profileInfo: stateForm })
        .then(res => {
          notification.show({
            message: 'Dados atualizados'
          })
          
          if (isAcceptedTerms(data.User) && isFilledEnterpriseForm(data)) {
            navigate(`/${userData?.role?.name?.toLowerCase()}/lista`)
          } else {
            navigate(`/${userData?.role?.name?.toLowerCase()}/termos-de-uso`)
          }
        })
        .catch(err => {
          console.log({
            error: err
          })
          notification.show({
            message: 'Não foi possível atualizar os dados',
            type: 'error'
          })
        })
    }
  }

  return (
    <Wrapper>
      <Content>
        <div>
          <Row>
            <DashInput
              width="100%"
              title="Primerio Nome"
              name="firstName"
              value={stateForm?.firstName}
              onChange={handleChange}
              required
              error={!stateForm?.firstName}
            />
            <DashInput
              width="100%"
              title="Último Nome"
              name="lastName"
              value={stateForm?.lastName}
              onChange={handleChange}
              required
              error={!stateForm?.lastName}
            />
            <DashSelect
              width="100%"
              title="Gênero"
              name="gender"
              value={stateForm?.gender}
              onChange={handleChange}
            >
              <MenuItem value={"female"}>Feminino</MenuItem>
              <MenuItem value={"male"}>Masculino</MenuItem>
              <MenuItem value={"other"}>Outro</MenuItem>
            </DashSelect>
            <DashInput
              width="100%"
              title="Data de Nascimento"
              type="date"
              name="birthDay"
              value={stateForm?.birthDay}
              onChange={handleChange}
            />
            {/* <DatePicker
              label="Data de Nascimento"
              value={stateForm?.birthDay}
              onChange={handleChange}
            /> */}
          </Row>
          <Row>
            <DashInput
              width="100%"
              title="CPF"
              name="document"
              value={stateForm?.document}
              onChange={handleChange}
              required
              error={!stateForm?.document}
            />
            <DashInput
              width="100%"
              title="E-mail (login)"
              name="email"
              value={stateForm?.User?.email}
              disabled={true}
            />
            <DashInput
              width="100%"
              title="Telefone"
              name="phone"
              value={stateForm?.phone}
              onChange={handleChange}
            />
            <DashInput
              width="100%"
              title="Contato de Emergência"
              name="emergencyPhone"
              value={stateForm?.emergencyPhone}
              onChange={handleChange}
            />
          </Row>
        </div>
        <div>
          <CardPersonalInfo />
        </div>
      </Content>
      <Actions>
        <NRoundedButton title={"Cancelar"} />
        <NRoundedButton
          title={"Gravar"}
          color={color}
          active={true}
          onClick={submitForm}
        />
      </Actions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin: 10px 0;
  padding: 10px;
  gap: 20px;
`;

const Content = styled.div`
  display: flex;
  gap: 10px;

  > div:nth-of-type(1) {
    flex: 3;
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    flex: 1;
    padding: 10px 0;
  }

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }

  @media screen and (max-width: 898px) {
    flex-direction: column;

    div {
      flex-direction: column;
    }
  }
`;

const Row = styled.div`
  display: flex;
  gap: 1.2rem;
  padding: 0.8rem 0;
  width: 100%;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 4vh;
  padding: 2vh 0;
`;
