import React, { useState } from "react";
import styled from "@emotion/styled";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import logo from "../../images/logo-colorido.png";
import { Link, useNavigate } from "react-router-dom";
import "./ADM.css";
import { Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMatchMedia from "use-match-media-hook";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { useUser } from "../../context/UserContext";
import { deleteToken } from "../../api/login";
import { ChatContainer } from "../../components/chat/ChatContainer";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const size = ["(max-width: 768px)", "(min-width: 769px)"];

const SideBarMontador = () => {
  // state and function to open/close sidebar
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [mobile, desktop] = useMatchMedia(size);
  const [openChat, setOpenChat] = useState(false);

  const { userData, saveUserData } = useUser();
  const navigate = useNavigate();

  function handleLogout() {
    window.location.assign("https://westand.com.br");
    saveUserData({});
    deleteToken();
  }

  function handleNovoPedido() {
    navigate("/admin/novo-briefing");
  }

  function handleHomePage() {
    navigate("/");
  }

  return (
    <>
      <div
        className="container1"
        style={{
          backgroundColor: "#F5F5F9",
        }}
      >
        {/* ----- INICIO: Bloco DESKTOP ------ */}
        {desktop && (
          <div
            className="sidebar1"
            style={{
              width: isOpen ? "300px" : "90px",
              backgroundColor: "white",
            }}
          >
            <div className="top_section">
              <img
                src={logo}
                alt=""
                width="130px"
                style={{ display: isOpen ? "block" : "none" }}
              />
              <div
                className="bars"
                style={{
                  marginLeft: isOpen ? "80px" : "0px",
                }}
              >
                <ArrowBackIosOutlinedIcon
                  onClick={toggle}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#764FAA',
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px',
                    color: 'white',
                    position: 'absolute',
                    right: '-10px',
                    transition: 'all 1s',
                    transform: `rotate(${isOpen ? "0deg" : "180deg"})`
                  }}
                />
              </div>
            </div>
            <div className="icons-desk">
              <Link to="/admin" className="link2" id="active">
                <div className="icon">
                  <HomeOutlinedIcon style={{ width: "25px", height: "40px" }} />
                </div>
                <div
                  className="link_text"
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Dashboard
                </div>
              </Link>
              <br />
              <p
                className="tittle"
                style={{ display: isOpen ? "block" : "none" }}
              >
                - DASH
              </p>
              <Link to="" className="link2" id="active">
                <div className="icon" style={{ marginBottom: "10px" }}>
                  <HomeOutlinedIcon style={{ width: "25px", height: "40px" }} />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  ADM
                </div>
              </Link>
              <br />
              <p
                className="tittle"
                style={{ display: isOpen ? "block" : "none" }}
              >
                - FLUXOS
              </p>
              <Link
                to="/solicitacao-cadastro-admin"
                className="link2"
                id="active"
              >
                <div className="icon">
                  <HowToRegOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Solicitações de <br /> cadastro
                </div>
              </Link>
              <Link
                to="/solicitacao-orcamento-admin"
                className="link2"
                id="active"
              >
                <div className="icon">
                  <RequestQuoteOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Solicitações de <br /> orçamento
                </div>
              </Link>
              {/* <Link to="" className="link2" id="active">
                <div className="icon">
                  <BusinessOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Administrativo
                </div>
              </Link> */}
              {/* <Link to="/pagamentos" className="link2" id="active">
                <div className="icon">
                  <CardTravelOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Financeiro
                </div>
              </Link> */}
              <Link to="" className="link2" id="active">
                <div className="icon">
                  <TextSnippetOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Contratos
                </div>
              </Link>
              {/* <Link to="" className="link2" id="active">
                <div className="icon">
                  <CampaignOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Marketing e SEO
                </div>
              </Link> */}
              <Link to="/admin/projeto&montagem" className="link2" id="active">
                <div className="icon">
                  <PlaylistAddIcon style={{ width: "25px", height: "40px" }} />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Projeto + Montagem
                </div>
              </Link>

              <Link to="/criar-evento" className="link2" id="active">
                <div className="icon">
                  <CalendarMonthOutlinedIcon style={{ width: "25px", height: "40px" }} />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Cadastrar Evento
                </div>
              </Link>
              <Link to="/gerenciar-eventos" className="link2" id="active">
                <div className="icon">
                  <ManageHistoryOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div className="link_text" style={{ display: isOpen ? "block" : "none" }}
                >
                  Gerenciar Eventos
                </div>
              </Link>

              <br />
              <p
                className="tittle"
                style={{ display: isOpen ? "block" : "none" }}
              >
                - PAGES
              </p>
              <br />
              <Link to="" className="link2" id="active">
                <div className="icon">
                  <ManageAccountsOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Editar Perfil
                </div>
              </Link>
              <Link to="" className="link2" id="active">
                <div className="icon">
                  <LockOpenOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Segurança
                </div>
              </Link>
              <Link to="" className="link2" id="active">
                <div className="icon">
                  <LockOpenOutlinedIcon
                    style={{ width: "25px", height: "40px" }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Editor do site
                </div>
              </Link>
              {/* <ChatMenu isOpen={isOpen}>
                <ChatButtonMenu onClick={() => setOpenChat(!openChat)}>
                  <span>Chat</span>
                </ChatButtonMenu>
              </ChatMenu> */}
            </div>
          </div>
        )}
        {/* ----- FIM: Bloco DESKTOP ------ */}

        {/* ----- INICIO: Bloco MOBILE ------ */}
        {mobile && (
          <div
            className="sidebar1"
            style={{
              width: isOpen ? "300px" : "0px",
              backgroundColor: "white",
            }}
          >
            <div className="top_section">
              <img
                src={logo}
                alt=""
                width="130px"
                style={{ display: isOpen ? "block" : "none" }}
              />
              <div
                className="bars"
                style={{
                  marginLeft: isOpen ? "80px" : "0px",
                }}
              >
                <ArrowBackIosOutlinedIcon
                  onClick={toggle}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#764FAA",
                    borderRadius: "10px",
                    width: "20px",
                    height: "20px",
                    color: "white",
                    position: "absolute",
                    marginTop: "-12px",
                    transition: "all 0.5s",
                    display: isOpen ? "block" : "none",
                  }}
                />
              </div>
            </div>
            <div className="icons-mobile">
              <Link
                to=""
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <HomeOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{
                    display: isOpen ? "flex" : "none",
                  }}
                >
                  Dashboard
                </div>
              </Link>
              <br />
              <p
                className="tittle"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                - DASH
              </p>
              <Link
                to=""
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon" style={{ marginBottom: "10px" }}>
                  <HomeOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  ADM
                </div>
              </Link>
              <br />
              <p
                className="tittle"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                - FLUXOS
              </p>
              <Link
                to="/solicitacao-cadastro-admin"
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <HowToRegOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Solicitações de <br /> cadastro
                </div>
              </Link>
              <Link
                to="/solicitacao-orcamento-admin"
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <RequestQuoteOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Solicitações de <br /> orçamento
                </div>
              </Link>
              <Link
                to=""
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <BusinessOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Administrativo
                </div>
              </Link>
              {/* <Link
                to=""
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <CardTravelOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Financeiro
                </div>
              </Link> */}
              <Link
                to=""
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <TextSnippetOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Contratos
                </div>
              </Link>
              {/* <Link
                to=""
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <CampaignOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Marketing e SEO
                </div>
              </Link> */}
              <br />
              <p
                className="tittle"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                - PAGES
              </p>
              <Link
                to="/perfil-montador"
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <ManageAccountsOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Editar Perfil
                </div>
              </Link>
              <Link
                to="/perfil-montador"
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <LockOpenOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Segurança
                </div>
              </Link>
              <Link
                to="/perfil-montador"
                className="link2"
                id="active"
                style={{ display: isOpen ? "flex" : "none" }}
              >
                <div className="icon">
                  <LockOpenOutlinedIcon
                    style={{
                      width: "25px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "flex" : "none" }}
                >
                  Editor do site
                </div>
              </Link>
            </div>
            <div
              className="overshadow"
              style={{
                display: isOpen ? "block" : "none",
                overflowY: "scroll",
              }}
            ></div>
          </div>
        )}
        {/* ----- FIM: Bloco MOBILE ------ */}
        <div className="profile-bar">
          <div className="menu-bar">
            <MenuIcon onClick={toggle} />
          </div>
          <span onClick={handleNovoPedido}>Criar um briefing</span>
          {userData ? <span onClick={handleLogout}>Sair</span> : ""}
          <Avatar style={{ position: "inherit" }} src={userData?.logo} />
        </div>
      </div>
      <ChatContainer
        openChat={openChat}
        setOpenChat={setOpenChat}
        userColor={"#764FAA"}
        roomId="3"
      />
    </>
  );
};

const ChatButtonMenu = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  background-color: #764faa;
  border-radius: 4px;
  padding: 6px 10px;
  color: white;
  cursor: pointer;
`;

const ChatMenu = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

export default SideBarMontador;
