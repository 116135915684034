import { useState } from "react";
import { useSimpleForm, useCustomForm } from "../../../../hooks";
import { useNotification } from "../../../../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import {
  makeBudgetForReferences,
  makeProposal,
} from "../../../../api/briefing";
export function useBudgetForm({ briefingRequest }) {
  const notification = useNotification();
  const navigate = useNavigate();

  const { stateForm, handleChange } = useSimpleForm({
    finalPriceMin: briefingRequest?.finalPriceMin || "",
    finalPriceMax: briefingRequest?.finalPriceMax || "",
    observation: briefingRequest?.observation || "",
    deliveryAt: briefingRequest?.deliveryAt?.split("T")[0] || "",
    finalPrice: briefingRequest?.finalPrice || ""
  });

  const [projectFiles, setProjectFiles] = useState(createProjectsArray);

  function createProjectsArray() {
    const projects = briefingRequest?.Briefing.BriefingProjects || [];
    const projectsLength = projects?.length;

    for (let i = projectsLength; i < 3; i++) {
      projects.push({
        observations: "",
      });
    }

    return projects;
  }

  const initFormValue = briefingRequest?.Briefing?.BriefingReferences.map(
    (v) => {
      const prices = briefingRequest.BriefingRequestReference.find(
        (e) => e.briefingReferencesId === v.id
      );

      return {
        referenceId: v?.id,
        maxPrice: prices?.maxPrice || "",
        minPrice: prices?.minPrice || "",
      };
    }
  );
  const [form, handleForm] = useCustomForm(initFormValue);

  function handleChangeProject(e, res) {
    const position = e.target.id.split("_")[1];

    const refs = projectFiles;
    refs[position] = {
      ...refs[position],
      link: res.name,
    };
    setProjectFiles([...refs]);
  }

  function submitBudget() {
    const { id } = briefingRequest;
    makeBudgetForReferences({
      requestId: id,
      projects: projectFiles,
      budgets: Object.values(form)
        .filter((v) => v.maxPrice || v.minPrice)
        .map((v) => v),
    }).then((res) => {
      notification.show({ message: "Seu orçamento foi enviado" });
    });
  }
  //Atualiza orçamento - request
  function submitProposal() {
    const { id } = briefingRequest;
    makeProposal({
      requestId: id,
      deliveryAt: stateForm?.deliveryAt,
      finalPriceMin: stateForm?.finalPriceMin,
      finalPriceMax: stateForm?.finalPriceMax,
      observation: stateForm?.observation,
      projects: projectFiles,
      finalPrice: stateForm?.finalPrice,
    })
      .then((res) => {
        notification.show({ message: "Seu orçamento foi enviado" });
        //navigate("/solicit-aberto-montador");
      })
      .catch((error) => {
        notification.show({
          message: error.message || "Não foi possível enviar sua proposta",
          type: "error",
        });
      });
  }

  return {
    stateForm,
    handleChange,
    form,
    handleForm,
    submitBudget,
    submitProposal,
    projectFiles,
    setProjectFiles,
    handleChangeProject,
  };
}
