import styled from "@emotion/styled";

const Chat = () => {

  return (
    <Wrapper>

    </Wrapper>
  )
};

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 5.875rem;
  align-items: center;
  margin-top: 10px;
  margin-right: 20px;
  gap: 10px;

`

export default Chat;
