import {
  Wrapper,
  Row,
  Content,
  Alert,
} from "./Styles";
import { useStepForm } from "../StepFormContext";


function renderPaymentStatus(paymentInit) {
  if (paymentInit[0]?.status === "PAGO") {
    return (
      <Content>
        <Alert style={{
          textAlign: 'center'
        }}>Já recebemos o pagamento da taxa inicial seu contratante.
          <br /><strong>Vamos para a próxima etapa.</strong></Alert>
      </Content>
    )
  }
  return (
    <Content>
      <Alert style={{
        textAlign: 'center'
      }}>Estamos aguardando o pagamento da taxa inicial do seu contratante.
        <br /><strong>Em breve iremos te atualizar.</strong></Alert>
    </Content>
  )
}

const Step1Builder = () => {

  const {
    stateForm,
  } = useStepForm();

  const payments = stateForm?.BriefingRequest?.Briefing?.Payments;
  const paymentInit = payments?.filter(p => p.type === "briefing") || [];

  return (
    <Wrapper>
      <div>
        <h2 className="reg-feira">Pagamento Taxa Inicial</h2>

      </div>
      <Row style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '2rem 0'
      }}>
        {renderPaymentStatus(paymentInit)}
      </Row>
    </Wrapper>
  );
};


export { Step1Builder }