import { useContext, createContext, useState } from "react";
import { useSimpleForm } from "../../../hooks";

const StepFormContext = createContext();

function StepFormProvider({ children, initState }) {
  const { stateForm, handleChange, setStateForm } = useSimpleForm(initState);

  return (
    <StepFormContext.Provider
      value={{
        stateForm,
        handleChange,
        setStateForm,
      }}
    >
      {children}
    </StepFormContext.Provider>
  );
}

function useStepForm() {
  const context = useContext(StepFormContext);
  if (!context) {
    throw new Error("Esse contexto não existe");
  }
  return context;
}

export { StepFormProvider, useStepForm };
