import React, { useState, useEffect, useDeferredValue } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom';
import { DashInput, DashTextArea, NRoundedButton, NTextField } from '../../../../shared-components';
import { getBriefing } from '../../../../api/briefing';
import { useQueries, useQuery } from 'react-query';
import { useSimpleForm } from '../../../../hooks/useSimpleForm';
import exportPDF from './MomorialPdf';
import 'draft-js/dist/Draft.css';
import DialogBriefing from "../../../DashboardProjetistaComponents/SolicitProjeto/fragments/DialogBriefing";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { useTreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from "@mui/material/Icon";
import ChevronRightIcon from "@mui/material/Icon";
//import { makeStyles } from "@mui/styles";
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { NDialog } from '../../../../shared-components';
import { upsertMemorial, getMemorial } from '../../../../api/gestor';
import Loading from '../../../../shared-components/Loading';
import { useNotification } from '../../../../context/NotificationContext';



function initConsGerais(memorial) {

  const text = memorial?.memorial?.ConsideracoesGerais ? memorial.memorial.ConsideracoesGerais[0].text : null;

  if (text) {
    return text;
  }

  return `O memorial descritivo refere-se à obra de execução, montagem e desmontagem do estande  na NOME DO EVENTO – LOCAL DO EVENTO conforme descrito abaixo:

Toda e qualquer dúvida que ocorrer durante a execução da obra, conflitos entre os projetos e  intenções de alterações, deverá ser verificada junto ao Responsável técnico e Proprietária do  Estande.
  
O Encarregado de Obra, Empreiteiro, Pedreiro ou qualquer outro profissional que atuar na  obra em qualquer fase que seja deverá obedecer ao especificado no Memorial Descritivo e as  informações fornecidas pelo responsável técnico, respeitando as devidas áreas de atuação.`
}

function initPreliminares(totalArea, memorial) {

  const text = memorial?.memorial?.Preliminares ? memorial.memorial.Preliminares[0].text : null;

  if (text) {
    return text;
  }


  return `Será protocolado projeto de Legalização junto à Administradora da Feira, com o objetivo de  obter a autorização para execução da obra com a área de ${totalArea}m² conforme Memorial.
            
  Observações:
  
  4.1-Todo resíduo gerado na obra deverá ser ensacado e depositado ao redor do estande, e  descarregado pelo empreiteiro em caçamba contratada (regularizada junto à PMSP) ou conforme  determinado pelo administrador da feira.
  
  4.2-Período da obra deverá estar dentro dos horários estipulados pelo administrador do evento (__  a __/ __, das __:__ às __:__);
  
  4.3-Toda a equipe de trabalho deverá utilizar EPIs conforme norma de segurança no trabalho;  
  
  4.4 – Verificar tabela de especificações (Anexo I). 
  
  
              `
}

function initMontagem(memorial) {

  const text = memorial?.memorial?.Montagem ? memorial.memorial.Montagem[0].text : null;

  if (text) {
    return text;
  }

  return `4.1- Execução da base do estande elevada _cm do nível do piso do pavilhão, nas medidas de  _ x __,00m para receber piso de aplicação de adesivo com arte do cliente;

  4.2- Execução de estrutura de madeira e metálica para suporte de paisagismo e móveis em mdf revestidos.
  
  4.3 – Execução de testeira metálica, conforme desenho técnico;
  `
}

function desmontagem(memorial) {

  const text = memorial?.memorial?.Desmontagem ? memorial.memorial.Desmontagem[0].text : null;

  if (text) {
    return text;
  }
  return `A equipe de desmontagem deverá iniciar a desmontagem a partir das 8:00h do dia __, conforme  determinado pela administradora da feira, para descarte e retiradas de produtos dentro do prazo  estabelecido.`
}

const initItems = [
  {
    name: "Mobilia",
    type: "MontagemItems",
    children: [
      { id: '11', name: "Exemplo mobília", qtd: 1 }
    ]
  }
];


/*const useStyles = makeStyles({
  treeItem: {
    fontSize: "2rem !important;",
    fontWeight: 'bold !important;',

  },
  treeSubItem: {
    fontSize: "1.6rem !important;",
  },
});*/

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    handleRemove,
    id,
    handleChangeItem
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const [open, setOpen] = React.useState(false);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      /*className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}*/
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <NDialog
        title="Atenção"
        titleConfirm="Apagar"
        open={open}
        setOpen={setOpen}
        activeColor={"#FDC22A"}
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          handleRemove(id)
          setOpen(false)
        }}
      >
        <span>Você tem certeza que deseja apagar o item <strong>{label}</strong>?</span>
      </NDialog>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} >
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        contentEditable={true}
        onInput={(e) => handleChangeItem(e, id)}
      >
        {label}
      </Typography>
      <NRoundedButton
        title={"apagar"}
        color={"#FDC22A"}
        active={"true"}
        style={{
          minWidth: '80px',
        }}
        onClick={() => setOpen(true)}
      />
    </div>
  );
});

function CustomTreeItem(props) {

  return (
    <div>
      <TreeItem ContentComponent={CustomContent}
        {...props}
        ContentProps={{
          handleRemove: props.handleRemove,
          id: props.id,
          handleChangeItem: props.handleChangeItem
        }} />
    </div>
  )

}

function FragmentMemorial({ data, memorial, refetch }) {

  const [items, setItems] = React.useState(() => memorial?.memorial.MontagemItems || initItems);
  const deferredItems = useDeferredValue(items);
  const totalArea = data?.length * data?.depth;
  const [modalBriefing, setModalBriefing] = useState(false);
  {/*const classes = useStyles();*/}
  const notification = useNotification()

  const {
    stateForm,
    handleChange
  } = useSimpleForm({
    Cliente: memorial?.memorial?.Cliente ? memorial.memorial.Cliente[0].text : "",
    Obra: memorial?.memorial?.Obra ? memorial.memorial.Obra[0].text : "",
    Endereco: memorial?.memorial?.Endereco ? memorial.memorial.Endereco[0].text : "",
    ResponsavelTecnico: memorial?.memorial?.ResponsavelTecnico ? memorial.memorial.ResponsavelTecnico[0].text : "",
    EquipeMontagem: memorial?.memorial?.EquipeMontagem ? memorial.memorial.EquipeMontagem[0].text : "",
    ConsideracoesGerais: initConsGerais(memorial),
    Preliminares: initPreliminares(totalArea, memorial),
    Montagem: initMontagem(memorial),
    Eletrica: memorial?.memorial?.Eletrica ? memorial.memorial.Eletrica[0].text : "",
    Desmontagem: desmontagem(memorial),
    editorContent: ""
  });

  function closeBriefingModal() {
    setModalBriefing(false);
  }

  function showBriefing() {
    setModalBriefing(true)
  }


  function submitForm(event) {
    const memorialData = [
      {
        briefingId: data.id,
        id: memorial?.memorial?.Cliente ? memorial.memorial.Cliente[0].id : undefined,
        text: stateForm.Cliente,
        name: "Cliente",
        type: "Cliente"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.Obra ? memorial.memorial.Obra[0].id : undefined,
        text: stateForm.Obra,
        name: "Obra",
        type: "Obra"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.Endereco ? memorial.memorial.Endereco[0].id : undefined,
        text: stateForm.Endereco,
        name: "Endereco",
        type: "Endereco"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.ResponsavelTecnico ? memorial.memorial.ResponsavelTecnico[0].id : undefined,
        text: stateForm.ResponsavelTecnico,
        name: "ResponsavelTecnico",
        type: "ResponsavelTecnico"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.Eletrica ? memorial.memorial.Eletrica[0].id : undefined,
        text: stateForm.Eletrica,
        name: "Eletrica",
        type: "Eletrica"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.EquipeMontagem ? memorial.memorial.EquipeMontagem[0].id : undefined,
        text: stateForm.EquipeMontagem,
        name: "EquipeMontagem",
        type: "EquipeMontagem"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.ConsideracoesGerais ? memorial.memorial.ConsideracoesGerais[0].id : undefined,
        text: stateForm.ConsideracoesGerais,
        name: "ConsideracoesGerais",
        type: "ConsideracoesGerais"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.Preliminares ? memorial.memorial.Preliminares[0].id : undefined,
        text: stateForm.Preliminares,
        name: "Preliminares",
        type: "Preliminares"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.Montagem ? memorial.memorial.Montagem[0].id : undefined,
        text: stateForm.Montagem,
        name: "Montagem",
        type: "Montagem"
      },
      {
        briefingId: data.id,
        id: memorial?.memorial?.Desmontagem ? memorial.memorial.Desmontagem[0].id : undefined,
        text: stateForm.Desmontagem,
        name: "Desmontagem",
        type: "Desmontagem"
      },
      ...items,
    ]

    upsertMemorial(data.id, memorialData)
      .then(res => {
        refetch()
        notification.show({ message: 'Memorial atualizado' })
      })
      .catch(err => {
        notification.show({ message: 'Não foi possível atualizar o memorial', type: 'error' })
      })
  }


  function handleAddSubItem(position) {

    const ref = items[position];
    ref.children.push({ id: +new Date(), name: "novo item", qtd: 1 })
    setItems([...items])
  }

  function handleAddItem() {
    setItems([
      ...items,
      {
        id: + new Date(),
        name: "Clique aqui para editar",
        type: "MontagemItems",
        status: 'new',
        children: [
          { id: +new Date(), name: "novo item", qtd: 1 },
        ]
      },
    ])
  }

  function handleRemoveItem(id) {
    const newItems = items.filter(v => v.id !== id);
    setItems(newItems)
  }

  function handleRemoveSubItem(childId, parentId) {
    const refParent = items.find(e => e.id === parentId);
    refParent.children = refParent.children.filter(e => e.id != childId);

    setItems([
      ...items,
    ])
  }

  function handleChangeSubItem(event, parentId) {
    const refParent = items.find(e => e.id === parentId);
    const subItem = refParent.children.find(e => e.id == event.target.id);
    subItem[event.target.name] = event.target.value;
    setItems([
      ...items,
    ])
  }

  function handleChangeItem(event, id) {
    const refParent = items.find(e => e.id === id);
    refParent.name = event.currentTarget.textContent;
  }

  return (
    <>
      <DialogBriefing
        open={modalBriefing}
        handleClose={closeBriefingModal}
        title={"Briefing"}
        data={data}
      />
      <h2>Memorial Descritivo</h2>
      <section>
        <h3>1- Dados da Obra </h3>
        <div>
          <p>1.1 Cliente</p>
          <DashTextArea
            key="Cliente"
            width={"100%"}
            height={"10rem"}
            name="Cliente"
            value={stateForm.Cliente}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>1.2 Obra</p>
          <DashTextArea
            key="Obra"
            width={"100%"}
            height={"10rem"}
            name="Obra"
            value={stateForm.Obra}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>1.3 Endereço</p>
          <DashTextArea
            key="Endereco"
            width={"100%"}
            height={"10rem"}
            name="Endereco"
            value={stateForm.Endereco}
            onChange={handleChange}
          />
        </div>
      </section>

      <section>
        <h3>2- Equipe Técnica</h3>
        <div>
          <p>2.1 Responsável Técnico do Projeto </p>
          <DashTextArea
            key="ResponsavelTecnico"
            width={"100%"}
            height={"10rem"}
            name="ResponsavelTecnico"
            value={stateForm.ResponsavelTecnico}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>2.2 Equipe de Montadora  </p>
          <DashTextArea
            key="EquipeMontagem"
            width={"100%"}
            height={"10rem"}
            name="EquipeMontagem"
            value={stateForm.EquipeMontagem}
            onChange={handleChange}
          />
        </div>
      </section>

      <section>
        <h3>3- Considerações Gerais</h3>
        <span>*Preencher o nome do evento e local</span>
        <div>
          <DashTextArea
            key="ConsideracoesGerais"
            width={"100%"}
            height={"30rem"}
            name="ConsideracoesGerais"
            value={stateForm.ConsideracoesGerais}
            onChange={handleChange}
          >
          </DashTextArea>
        </div>
      </section>

      <section>
        <h3>4- Preliminares</h3>
        <span>*Conferir área do estande</span>
        <span>*Preencher dia e hora de montagem</span>
        <div>
          <DashTextArea
            key="Preliminares"
            width={"100%"}
            height={"30rem"}
            name="Preliminares"
            value={stateForm.Preliminares}
            onChange={handleChange}
          >
          </DashTextArea>
        </div>
      </section>

      <section>
        <h3>5- Montagem</h3>
        <div>
          <DashTextArea
            key="Montagem"
            width={"100%"}
            height={"30rem"}
            name="Montagem"
            value={stateForm.Montagem}
            onChange={handleChange}
          >
          </DashTextArea>
        </div>
        <h5>Memorial do Projeto</h5>

        {deferredItems.map((v, index) => (
          <TreeView
            key={`tree-${index}`}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <CustomTreeItem
              key={`custom-tree-${index}`}
              id={v.id}
              nodeId={`custom-tree-${index}`}
              label={v.name}
              handleRemove={handleRemoveItem}
              handleChangeItem={handleChangeItem}
            >

              <div key={`parent-${v.id}-${index}`}>
                {v.children?.map((child, index) => (
                  <div>
                    <TreeItem
                      key={`child-${v.id}-${index}`}
                      nodeId={`${child.nodeId}`}
                      label={
                        <div
                          key={`child-div-${v.id}-${index}`}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "1rem",
                            margin: "1rem 0",
                          }}
                        >
                          <span>Item</span>
                          <DashInput
                            key={`child-input-item-${v.id}-${index}`}
                            id={child.id}
                            value={child.name}
                            name="name"
                            variant="outlined"
                            onChange={(e) => handleChangeSubItem(e, v.id)}
                          />
                          <span>Quantidade</span>
                          <DashInput
                            key={`child-input-qtd-${v.id}-${index}`}
                            id={child.id}
                            value={child.qtd}
                            width={"10rem"}
                            variant="outlined"
                            name="qtd"
                            type={"number"}
                            min={"1"}
                            onChange={(e) => handleChangeSubItem(e, v.id)}
                          />
                          <NRoundedButton
                            key={`child-button-${v.id}-${index}`}
                            title={"apagar"}
                            color={"#FDC22A"}
                            active={"true"}
                            style={{
                              minWidth: '80px',
                            }}
                            onClick={() => handleRemoveSubItem(child.id, v.id)}
                          />
                        </div>
                      }
                    />
                  </div>
                ))}
                <NRoundedButton
                  title={"+ Subitem"}
                  color={"#FDC22A"}
                  active={"true"}
                  onClick={() => handleAddSubItem(index)}
                />
              </div>
            </CustomTreeItem>

          </TreeView>
        ))}
        <NRoundedButton
          title={"+ Item"}
          color={"#FDC22A"}
          active={"true"}
          onClick={handleAddItem}
        />
      </section>

      <section>
        <h3>6- Elétrica</h3>
        <span>*Preencher parte elétrica.</span>
        <div>
          <DashTextArea
            key="Eletrica"
            width={"100%"}
            height={"30rem"}
            name="Eletrica"
            value={stateForm.Eletrica}
            onChange={handleChange}
          >

          </DashTextArea>
        </div>
      </section>

      <section>
        <h3>7- Desmontagem </h3>
        <span>*Preencher período de desmontagem.</span>
        <div>
          <DashTextArea
            key="Desmontagem"
            width={"100%"}
            height={"30rem"}
            name="Desmontagem"
            value={stateForm.Desmontagem}
            onChange={handleChange}
          >
          </DashTextArea>
        </div>
      </section>

      <div className='memorial-btn'>

        <NRoundedButton title={"ver briefing"} color={"#FDC22A"} active={"true"} onClick={showBriefing} />
        {/* <NRoundedButton
          title={"exportar"}
          color={"#FDC22A"}
          active={"true"}
          onClick={(e) => exportPDF(stateForm, items)}
        /> */}
        <NRoundedButton
          title={"salvar"}
          color={"#FDC22A"}
          active={"true"}
          onClick={submitForm} />

      </div>
    </>
  )
}

function MemorialDesc() {
  const params = useParams();
  const { briefingId } = params;

  const [briefingQuery, memorialQuery] = useQueries([
    {
      queryKey: ['briefing', briefingId],
      queryFn: () => getBriefing(briefingId),
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    },
    {
      queryKey: ['memorial', briefingId],
      queryFn: () => getMemorial(briefingId),
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    },
  ])

  if (memorialQuery.data === undefined) {
    return <></>;
  }



  if (briefingQuery.isLoading || memorialQuery.isLoading) {
    return (<Loading />)
  }

  return (
    <MemorialWrapper>
      <FragmentMemorial
        data={briefingQuery.data}
        memorial={memorialQuery.data}
        refetch={memorialQuery.refetch}
      />
    </MemorialWrapper>
  )
}

const MemorialWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 0 0 0.375rem 0.375rem;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    padding: 1rem 2rem;

    > h2 {
      margin-bottom: 5rem ;
    }
    
    > h3 {
      margin-top: 7rem;
      
    }

    > section {
      display: flex;
      flex-direction: column;
      width: 50%;
      > div {
        padding: 2rem 0;
        
      }

      p {
        font-weight: bold;
        padding: 1rem 0;
      }
    }

    .memorial-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      padding: 5rem 0;
      
    }

    .info-msg {
      flex-direction: column;
    }

  @media (max-width: 900px) {
    > section {
      width: 100%;

      textarea {
        width: 100%;
        height: 300px;
      }
    }

    .memorial-btn {
      flex-wrap: wrap;
      justify-content: center;
    }


  }
`;

export default MemorialDesc;