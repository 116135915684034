import React, { useState } from "react";
import styled from "@emotion/styled";
//import "./BriefingStyle.css";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import UploadFile from "../../shared-components/UploadFile";
import { NTextField } from "../../shared-components";

const inputs = [
  { id: 'lastStand01', placeholder: 'Ou deixe um link' },
  { id: 'lastStand02', placeholder: 'Ou deixe um link' },
  { id: 'lastStand03', placeholder: 'Ou deixe um link' }
]

const ReferenceInformation = () => {
  const { control, setValue, getValues } = useFormContext();


  const { register } = useForm();

  const defaultValues = {
    lastStands: getValues('lastStands') || [],
    "lastStand01": getValues('lastStand01'),
    "lastStand02": getValues('lastStand02'),
    "lastStand03": getValues('lastStand03'),
  }

  const persistedForm = getValues('lastStands');
  const [imagesSelected, setImagesSelected] = useState(persistedForm);
  const [urlImages, setUrlImages] = useState({});

  setValue('lastStands', imagesSelected)

  const handleRemoveFile = (event) => {
    setImagesSelected(imagesSelected.filter(v => v.id === event.target.id))
  }

  const handleGetFile = (file, result) => {
    const type = file.target.files[0].type.split("/")[1]
    setImagesSelected([
      ...imagesSelected,
      {
        type: 'upload',
        link: `${result.key}.${type}`,
        id: file.target.id,
      }
    ])
  }


  const handleUrlInput = ({ target: { name, value } }) => {
    if (!value) {
      return;
    }
    setUrlImages({
      ...urlImages,
      [name]: {
        type: 'url',
        link: value,
      }
    })
    setValue('lastStandsUrls', urlImages)
  }


  return (
    <Wrapper>
      <Title>
        <h3>Stands passados:</h3>
        <p>Subir link de fotos ou arquivo</p>
      </Title>
      <Content>
        <div>
          <UploadFile
            id="1001"
            getFile={handleGetFile}
            deleteFile={(e) => handleRemoveFile(e)}
          />
          <UploadFile
            id="1001"
            getFile={handleGetFile}
            deleteFile={(e) => handleRemoveFile(e)}
          />
          <UploadFile
            id="1001"
            getFile={handleGetFile}
            deleteFile={(e) => handleRemoveFile(e)}
          />
        </div>
        <div>
          {inputs.map(v => (
            <NTextField
              id={v.id}
              name={v.id}
              placeholder={v.placeholder}
              fullWidth
              onChange={handleUrlInput}
            />
          ))}

        </div>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  >div {
    flex: 1;
    gap: 30px;
    display: flex;
    flex-direction: column;
   
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
`

export default ReferenceInformation;