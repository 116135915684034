import React from "react";
import BriefingManagerList from "../../shared-components/BriefingManagerList";

const BriefingManager = () => {
  return (
    <>
      <BriefingManagerList />
    </>
  );
};

export default BriefingManager;
